import { useMemo } from "react";
import { createUseStyles } from "react-jss";
import ReactFlow, { Edge, Node } from "reactflow";
import "reactflow/dist/style.css";
import { Battery } from "../../../../__generated__/types/Battery";
import { useCustomers } from "../../customers/customers";
import { useProperties } from "../../properties/properties";
import GridEdge from "./GridEdge.react";
import { getTooltipContent } from "./GridGraph";
import GridNode, {
  SOURCE_BOTTOM_HANDLE_ID,
  SOURCE_RIGHT_HANDLE_ID,
  SOURCE_TOP_HANDLE_ID,
  TARGET_BOTTOM_HANDLE_ID,
  TARGET_LEFT_HANDLE_ID,
  TARGET_TOP_HANDLE_ID,
} from "./GridNode.react";

const SPACING = 130;

interface BatteryGraphProps {
  battery: Battery | null;
}

const useStyles = createUseStyles({
  defaultCursor: {
    "& .react-flow__pane": {
      cursor: "default",
    },
    "& .react-flow__node": {
      cursor: "default",
    },
  },
});

export default function BatteryGraph({ battery }: BatteryGraphProps) {
  const classes = useStyles();

  const gridPower = battery?.grid_power ?? 0;
  const batteryPower = battery?.battery_power ?? 0;
  const solarPower = battery?.solar_power ?? 0;
  const consumerPower = battery?.consumer_power ?? 0;
  const isStationOffline = battery?.station_state === "OFFLINE";

  const { properties } = useProperties();
  const { customers } = useCustomers();

  const nodeTypes = useMemo(() => ({ gridNode: GridNode }), []);
  const edgeTypes = useMemo(() => ({ gridEdge: GridEdge }), []);

  const nodes: Node[] = useMemo(() => {
    if (battery === null || battery?.id == null) {
      return [];
    }

    const hasBatteryPower =
      battery.battery_power !== null && battery.battery_power !== undefined;
    const hasSolarPower =
      battery.solar_power !== null && battery.solar_power !== undefined;

    const addedNodes: Node[] = [];

    addedNodes.push({
      id: `grid-inner-${battery.id}`,
      type: "gridNode",
      position: { x: 0, y: -SPACING },
      data: {
        label: `${gridPower} W`,
        subLabel: "Grid",
        icon: "grid",
        color: "#2196f3",
        isChild: true,
        isOffline: isStationOffline,
      },
    });

    addedNodes.push({
      id: `battery-${battery.id}`,
      type: "gridNode",
      position: { x: 0, y: SPACING },
      data: {
        tooltipContent: getTooltipContent(battery, "battery"),
        label: `${battery.battery_power ?? 0} W`,
        subLabel: "Battery",
        icon: "battery",
        color: hasBatteryPower ? "#9c27b0" : "#666",
        battery: battery,
        isChild: true,
        isOffline: isStationOffline,
      },
    });

    addedNodes.push({
      id: `solar-${battery.id}`,
      type: "gridNode",
      position: { x: -SPACING, y: 0 },
      data: {
        label: `${battery.solar_power ?? 0} W`,
        subLabel: "Solar",
        icon: "solar",
        color: hasSolarPower ? "#ffeb3b" : "#666",
        isChild: true,
        isOffline: isStationOffline,
      },
    });

    addedNodes.push({
      id: `home-${battery.id}`,
      type: "gridNode",
      position: { x: SPACING, y: 0 },
      data: {
        tooltipContent: getTooltipContent(
          battery,
          "home",
          properties,
          customers
        ),
        label: `${battery.consumer_power} W`,
        subLabel: "Home",
        icon: "home",
        color: "#00A396",
        isChild: true,
        isOffline: isStationOffline,
      },
    });
    return addedNodes;
  }, [battery, customers, gridPower, isStationOffline, properties]);

  const edges: Edge[] = useMemo(() => {
    if (battery === null || battery?.id == null) {
      return [];
    }

    const addedEdges = [];

    if (gridPower < 0 && batteryPower < 0) {
      addedEdges.push({
        id: `e-grid-inner-${battery.id}-battery-${battery.id}`,
        source: `grid-inner-${battery.id}`,
        target: `battery-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#2196f3",
          color2: "#2196f3",
          isChild: true,
        },
        sourceHandle: SOURCE_BOTTOM_HANDLE_ID,
        targetHandle: TARGET_TOP_HANDLE_ID,
      });
    } else if (gridPower > 0 && batteryPower > 0) {
      addedEdges.push({
        id: `e-battery-inner-${battery.id}-grid-${battery.id}`,
        source: `battery-${battery.id}`,
        target: `grid-inner-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#9c27b0",
          color2: "#9c27b0",
          isChild: true,
        },
        sourceHandle: SOURCE_TOP_HANDLE_ID,
        targetHandle: TARGET_BOTTOM_HANDLE_ID,
      });
    } else {
      addedEdges.push({
        id: `e-battery-inner-${battery.id}-grid-${battery.id}`,
        source: `battery-${battery.id}`,
        target: `grid-inner-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#9c27b0",
          color2: "#9c27b0",
          isChild: true,
          isDisabled: true,
        },
        sourceHandle: SOURCE_TOP_HANDLE_ID,
        targetHandle: TARGET_BOTTOM_HANDLE_ID,
      });
    }

    addedEdges.push({
      id: `e-grid-inner-${battery.id}-home-${battery.id}`,
      source: `grid-inner-${battery.id}`,
      target: `home-${battery.id}`,
      type: "gridEdge",
      data: {
        color1: "#2196f3",
        color2: "#2196f3",
        isChild: true,
        isDisabled: consumerPower <= 0 || gridPower >= 0,
      },
      sourceHandle: SOURCE_BOTTOM_HANDLE_ID,
      targetHandle: TARGET_LEFT_HANDLE_ID,
    });

    addedEdges.push({
      id: `e-solar-${battery.id}-home-${battery.id}`,
      source: `solar-${battery.id}`,
      target: `home-${battery.id}`,
      type: "gridEdge",
      data: {
        color1: "#ffeb3b",
        color2: "#ffeb3b",
        isChild: true,
        isDisabled: solarPower <= 0 || consumerPower <= 0,
      },
      sourceHandle: SOURCE_RIGHT_HANDLE_ID,
      targetHandle: TARGET_LEFT_HANDLE_ID,
    });

    addedEdges.push({
      id: `e-solar-${battery.id}-battery-${battery.id}`,
      source: `solar-${battery.id}`,
      target: `battery-${battery.id}`,
      type: "gridEdge",
      data: {
        color1: "#ffeb3b",
        color2: "#ffeb3b",
        isChild: true,
        isDisabled: solarPower <= 0 || batteryPower >= 0,
      },
      sourceHandle: SOURCE_RIGHT_HANDLE_ID,
      targetHandle: TARGET_TOP_HANDLE_ID,
    });

    addedEdges.push({
      id: `e-solar-${battery.id}-grid-inner-${battery.id}`,
      source: `solar-${battery.id}`,
      target: `grid-inner-${battery.id}`,
      type: "gridEdge",
      data: {
        color1: "#ffeb3b",
        color2: "#ffeb3b",
        isChild: true,
        isDisabled: solarPower <= 0 || gridPower <= 0,
      },
      sourceHandle: SOURCE_RIGHT_HANDLE_ID,
      targetHandle: TARGET_BOTTOM_HANDLE_ID,
    });

    addedEdges.push({
      id: `e-battery-${battery.id}-home-${battery.id}`,
      source: `battery-${battery.id}`,
      target: `home-${battery.id}`,
      type: "gridEdge",
      data: {
        color1: "#9c27b0",
        color2: "#9c27b0",
        isChild: true,
        isDisabled: batteryPower <= 0 || consumerPower <= 0,
      },
      sourceHandle: SOURCE_TOP_HANDLE_ID,
      targetHandle: TARGET_LEFT_HANDLE_ID,
    });

    addedEdges.sort((a, b) =>
      a.data.isDisabled === b.data.isDisabled ? 0 : !a.data.isDisabled ? 1 : -1
    );

    return addedEdges;
  }, [battery, batteryPower, consumerPower, gridPower, solarPower]);

  if (!battery || !battery.id) {
    return null;
  }

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      proOptions={{ hideAttribution: true }}
      edgeTypes={edgeTypes}
      fitView
      elementsSelectable={false}
      draggable={false}
      nodesFocusable={false}
      edgesFocusable={false}
      zoomOnScroll={false}
      zoomOnPinch={false}
      autoPanOnNodeDrag={false}
      nodesConnectable={false}
      panOnDrag={false}
      panOnScroll={false}
      preventScrolling={false}
      zoomOnDoubleClick={false}
      className={classes.defaultCursor}
    />
  );
}
