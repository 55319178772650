import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";

const useStyles = createUseStyles({
  navigationBar: {
    position: "fixed",
    height: "64px",
    right: "24px",
    paddingTop: 12,
    top: 0,

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    zIndex: 100,
  },
});

export function PlatformHorizontalNavigationBar() {
  const classes = useStyles();
  const location = useLocation();

  if (location.pathname.startsWith("/canvas/")) {
    return null;
  }

  return (
    <div className={classes.navigationBar}>
      <div>
        {/*        <Button icon="notifications" large minimal intent="primary" /> */}
      </div>
    </div>
  );
}
