import { Spinner } from "@blueprintjs/core";
import { BatteryMetrics1h } from "@ec1/types/BatteryMetrics1h";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {
  TooltipFormatterContextObject,
} from "highcharts/highstock";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFetch } from "src/common/fetcher/effects";
import {
  ChartState,
  getChartGranularity,
  getChartPadding,
} from "src/ui/utils/chartUtils";
import { usePropertyTimezone } from "../properties/properties";
import { useDevice } from "./devices";
import IHighchartsWithNavigator from "./IHighchartsWithNavigator.react";

interface PlatformBatteryMetricsBatteryPowerChartProps {
  batteryId: number;
  height?: number;
}

export default function PlatformBatteryMetricsBatteryPowerChart({
  batteryId,
  height = 320,
}: PlatformBatteryMetricsBatteryPowerChartProps) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [chartType, setChartType] = useState("SOC");
  const [chartState, setChartState] = useState<ChartState>({
    start: null,
    end: null,
    granularity: "30m",
  });

  const { device } = useDevice(batteryId);
  const { timezone, timezoneLabel } = usePropertyTimezone(
    device?.assigned_property
  );

  const handleChartTypeChange = (event: SelectChangeEvent) => {
    setChartType(event.target.value as string);
  };

  const [fullRangeStart, fullRangeEnd] = useMemo(() => {
    if (!device?.utc_time_metrics_start || !device?.utc_time_metrics_end) {
      return [null, null];
    }
    return [
      DateTime.fromISO(device.utc_time_metrics_start, {
        zone: "utc",
      }).toMillis(),
      DateTime.fromISO(device.utc_time_metrics_end, {
        zone: "utc",
      }).toMillis(),
    ];
  }, [device?.utc_time_metrics_start, device?.utc_time_metrics_end]);

  const hasDataForSelectedType = useMemo(() => {
    return fullRangeStart && fullRangeEnd && fullRangeEnd > fullRangeStart;
  }, [fullRangeEnd, fullRangeStart]);

  const fetchUrl = useMemo(() => {
    if (chartState.start && chartState.end) {
      return `/api/devices/${chartState.granularity}/battery-metrics/?battery=${batteryId}&utc_time__gte=${chartState.start}&utc_time__lte=${chartState.end}&ordering=utc_time`;
    }
    return null;
  }, [batteryId, chartState]);

  const { data, isLoading: isLoadingData } = useFetch<{
    results: BatteryMetrics1h[];
  }>(fetchUrl);

  useEffect(() => {
    if (!hasDataForSelectedType) {
      setIsLoading(false);
    } else if (
      !isLoadingData &&
      data &&
      Array.isArray(data?.results) &&
      chartRef.current?.chart
    ) {
      const chart = chartRef.current.chart as IHighchartsWithNavigator;

      const formattedData = data.results.map((item: BatteryMetrics1h) => {
        return [
          DateTime.fromISO(item.utc_time, {
            zone: "utc",
          }).toMillis(),
          chartType === "Power" ? item.battery_power_in_kw : item.soc,
        ];
      });

      if (chart.navigator && chart.navigator.series[0]) {
        chart.navigator.series[0].setData([
          [fullRangeStart, null],
          [fullRangeEnd, null],
        ]);
      }

      chart.series[0].setData(formattedData);

      setIsLoading(false);
    }
  }, [
    data,
    chartRef,
    chartType,
    hasDataForSelectedType,
    isLoadingData,
    fullRangeStart,
    fullRangeEnd,
  ]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        height: height - 65,
        marginRight: 20,
        marginLeft: 64,
        events: {
          load: function (e: any) {
            if (fullRangeEnd) {
              const oneDayAgo = fullRangeEnd - 24 * 3600 * 1000;
              // Initially show the last 24h
              e.target.xAxis[0].setExtremes(oneDayAgo, fullRangeEnd);
            }
          },
        },
      },
      time: {
        timezone,
        useUTC: timezone !== undefined,
      },
      xAxis: {
        minRange: 4 * 60 * 60 * 1000,
        crosshair: true,
        title: {
          y: 5,
          text: `Time<br><span style="font-size: 11px; color: #999;">${timezoneLabel}</span>`,
        },
        events: {
          afterSetExtremes: function (e: any) {
            const chart = e.target.chart;

            if (!chart || !e.min || !e.max) {
              return;
            }

            const { startStr, endStr } = getChartPadding(
              Math.round(e.min),
              Math.round(e.max)
            );

            const granularity = getChartGranularity(startStr, endStr);

            setChartState({
              start: startStr,
              end: endStr,
              granularity: granularity,
            });
          },
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          if (typeof this.x !== "number" || typeof this.y !== "number") {
            return "";
          }

          const pointDate = DateTime.fromMillis(this.x).setZone(
            timezone || "system"
          );

          let format = "cccc, dd LLLL HH:mm";
          let startHour = pointDate.toFormat("HH:mm");
          let endHour = pointDate.plus({ hours: 1 }).toFormat("HH:mm");

          const chart = this.series.chart;
          const xAxis = chart.xAxis[0];
          const min = xAxis.min || 0;
          const max = xAxis.max || 0;

          const { startStr, endStr } = getChartPadding(
            Math.round(min),
            Math.round(max)
          );

          const granularity = getChartGranularity(startStr, endStr);

          switch (granularity) {
            case "15m":
              endHour = pointDate.plus({ minutes: 15 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "30m":
              endHour = pointDate.plus({ minutes: 30 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "1h":
              endHour = pointDate.plus({ hours: 1 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "1d":
              format = "cccc, dd LLLL";
              break;
            default:
              break;
          }

          const dateStr = pointDate.toFormat(format);
          const seriesName = this.series.name;
          const valueStr = `${this.y.toFixed(chartType === "Power" ? 2 : 1)} ${
            chartType === "Power" ? "kW" : "%"
          }`;

          const bulletPoint = `<span style="color:${this.color};">\u25CF</span>`;

          return `<div style="font-size: 12px; margin-bottom: 5px;">${dateStr}</div>${bulletPoint} ${seriesName}: <b>${valueStr}</b>`;
        },
        style: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      yAxis: [
        {
          endOnTick: chartType === "Power",
          min: chartType === "Power" ? undefined : 0,
          max: chartType === "Power" ? undefined : 101, // +1 for linewidth
          title: {
            text: chartType === "Power" ? "Power (kW)" : "Percentage (%)",
          },
        },
      ],
      series: [
        {
          type: chartType === "Power" ? "areaspline" : "column",
          lineWidth: chartType === "Power" ? 1 : 2,
          color:
            chartType === "Power" ? "rgba(156,39,176,0.75)" : "rgb(156,39,176)",
          name: chartType === "Power" ? "Power" : "Battery level",
          dataGrouping: {
            enabled: false,
          },
        },
      ],
      navigator: {
        series: {
          dataGrouping: {
            enabled: false,
          },
        },
      },
      plotOptions: {
        column: {
          maxPointWidth: 20,
        },
      },
    };
  }, [height, timezone, timezoneLabel, chartType, fullRangeEnd]);

  return (
    <div
      style={{
        height: height,
        backgroundColor: "rgb(37, 42, 49)",
        borderRadius: 5,
        padding: 20,
        color: "white",
      }}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 21,
              marginBottom: 10,
            }}
          >
            <b>Battery</b>
          </div>
          <div
            style={{
              width: "100%",
              flex: 1,
              textAlign: "right",
              fontSize: "16px",
              marginBottom: 10,
              color: "rgba(255,255,255,.75)",
              marginTop: 4,
            }}
          >
            <FormControl
              sx={{ width: 140 }}
              disabled={isLoading || isLoadingData}
            >
              <Select
                id="chart-type-select"
                value={chartType}
                onChange={handleChartTypeChange}
                sx={{
                  height: 26,
                  fontSize: "0.875rem",
                  "& .MuiSelect-select": {
                    paddingTop: "3px",
                    paddingBottom: "3px",
                    textAlign: "left",
                  },
                  "& .MuiOutlinedInput-input": {
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      "& .MuiMenu-list": {
                        padding: "2px 0",
                      },
                    },
                  },
                }}
              >
                <MenuItem
                  value="Power"
                  sx={{
                    height: 26,
                    fontSize: "0.875rem",
                    padding: "4px 10px",
                  }}
                >
                  Power
                </MenuItem>
                <MenuItem
                  value="SOC"
                  sx={{
                    height: 26,
                    fontSize: "0.875rem",
                    padding: "4px 10px",
                  }}
                >
                  Battery Level
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div>
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: height - 100,
            }}
          >
            <Spinner />
          </div>
        )}
        {hasDataForSelectedType ? (
          <div
            style={{
              visibility: isLoading ? "hidden" : "visible",
            }}
          >
            <HighchartsReact
              key={chartType}
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={chartOptions}
              ref={chartRef}
            />
            {isLoadingData && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  height: height - 75,
                  marginTop: 65 - height,
                  zIndex: 100, // Above Highcharts
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 260,
              width: "100%",
            }}
          >
            No data available
          </div>
        )}
      </div>
    </div>
  );
}
