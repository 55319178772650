interface EllipsisNowrapTextDivProps {
  children: string | undefined;
}
export default function EllipsisNowrapTextDiv({
  children,
}: EllipsisNowrapTextDivProps) {
  return (
    <div
      style={{
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {children ?? ""}
    </div>
  );
}
