import { useCallback, useEffect, useMemo, useState } from "react";
import useAssistant from "../assistant/assistant";
import { useAuthHeaders } from "../common/authentication/authentication";

export interface Campaign {
  id: number;
  title: string;
  objservation: string;
  assistant_thread: number | null;
}

export function useCampaign(campaignID: number) {
  const headers = useAuthHeaders();

  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [isFetchingCampaign, setiIsFetchingCampaign] = useState<boolean>(false);

  const { messages } = useAssistant(String(campaign?.assistant_thread) ?? null);

  const suggestedContent = useMemo(() => {
    const filtered = messages.filter((m) => m.role === "assistant");
    if (filtered.length > 0) {
      return filtered[0];
    }
    return null;
  }, [messages]);

  const fetchCampaign = useCallback(() => {
    async function createNewCampaignAsync() {
      setiIsFetchingCampaign(true);
      const response = await fetch(`/api/campaign/${campaignID}/`, {
        method: "get",
        headers,
      });
      if (response.ok) {
        setCampaign((await response.json()) as Campaign);
        setiIsFetchingCampaign(false);
      } else {
        setiIsFetchingCampaign(false);
        setCampaign(null);
        throw Error(await response.text());
      }
    }
    return createNewCampaignAsync();
  }, [campaignID, headers]);

  useEffect(() => {
    if (!isFetchingCampaign) {
      if (!campaign) {
        fetchCampaign();
      }
    }
  }, [campaign, fetchCampaign, isFetchingCampaign]);

  return { campaign, isFetchingCampaign, suggestedContent };
}

export function useCreateCampaign() {
  const headers = useAuthHeaders();

  const [campaign, setCampaign] = useState<null | Campaign>(null);
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const [threadID, setThreadID] = useState<null | number>(null);

  const { messages, isProcessing: isAssistantProcessing } = useAssistant(
    String(threadID)
  );

  useEffect(() => {
    if (threadID !== null) {
      if (isAssistantProcessing) {
        setIsProcessing(true);
        return;
      } else {
        if (messages.length === 0) {
          setIsProcessing(true);
          return;
        }
        if (messages.filter((m) => m.role === "user").length === 0) {
          setIsProcessing(true);
          return;
        }
      }
    }
    setIsProcessing(false);
  }, [threadID, isAssistantProcessing, messages]);

  useEffect(() => {
    if (messages.filter((m) => m.role === "user").length > 0) {
      setIsReady(true);
    }
  }, [messages]);

  useEffect(() => {
    setThreadID((prevThreadID) => campaign?.assistant_thread ?? prevThreadID);
  }, [campaign?.assistant_thread]);

  const createNewCampaign = useCallback(
    (title: string, objective: string) => {
      async function createNewCampaignAsync() {
        setIsCreatingCampaign(true);
        const response = await fetch("/api/campaign/", {
          method: "post",
          body: JSON.stringify({ title, objective }),
          headers,
        });
        if (response.ok) {
          setCampaign((await response.json()) as Campaign);
          setIsCreatingCampaign(false);
        } else {
          setIsCreatingCampaign(false);
          setCampaign(null);
          throw Error(await response.text());
        }
      }
      return createNewCampaignAsync();
    },
    [headers]
  );

  return {
    createNewCampaign,
    campaign,
    isCreatingCampaign,
    isProcessing,
    isReady,
  };
}
