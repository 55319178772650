import { Button, Tooltip } from "@blueprintjs/core";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import capitalizeName from "./capitalizeName";
import { useCustomer } from "./customers";

interface PlatformSiteCustomerClickableLabelProps {
  customerId: string;
  width?: number;
  maxChars?: number;
  tooltipEnabled?: boolean;
}

export default function PlatformSiteCustomerClickableLabel({
  customerId,
  width = 156,
  maxChars = 15,
  tooltipEnabled = true,
}: PlatformSiteCustomerClickableLabelProps) {
  const customer = useCustomer(parseInt(customerId));

  const customerFullName = useMemo(() => {
    if (customer?.first_name && customer?.last_name) {
      return capitalizeName(`${customer.first_name} ${customer.last_name}`);
    }
    return null;
  }, [customer]);

  const customerFullNameTruncated = useMemo(() => {
    if (customerFullName !== null) {
      return customerFullName.length > maxChars
        ? customerFullName.slice(0, maxChars - 3) + "..."
        : customerFullName;
    }
    return null;
  }, [customerFullName, maxChars]);

  const navigate = useNavigate();

  if (customer === null) {
    return null;
  }

  return tooltipEnabled ? (
    <Tooltip
      content={
        <span style={{ fontFamily: "Barlow" }}> {customerFullName}</span>
      }
    >
      <Button
        style={{ width, borderRadius: "6px" }}
        alignText="left"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          navigate(`/customers/${customerId}`);
        }}
        outlined
        icon="person"
      >
        {customerFullNameTruncated}
      </Button>
    </Tooltip>
  ) : (
    <Button
      style={{ width: width, borderRadius: "6px" }}
      alignText="left"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/customers/${customerId}`);
      }}
      outlined
      icon="person"
    >
      {customerFullNameTruncated}
    </Button>
  );
}
