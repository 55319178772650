import { Spinner } from "@blueprintjs/core";
import { BatteryMetrics1h } from "@ec1/types/BatteryMetrics1h";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {
  TooltipFormatterContextObject,
} from "highcharts/highstock";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFetch } from "src/common/fetcher/effects";
import {
  ChartState,
  getChartGranularity,
  getChartPadding,
} from "src/ui/utils/chartUtils";
import { usePropertyTimezone } from "../properties/properties";
import { useDevice } from "./devices";

interface PlatformBatteryMetricsBatteryPowerChartProps {
  batteryId: number;
  height?: number;
}

export default function PlatformBatteryMetricsBatteryPowerChart({
  batteryId,
  height = 320,
}: PlatformBatteryMetricsBatteryPowerChartProps) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [chartType, setChartType] = useState("Energy");
  const [chartState, setChartState] = useState<ChartState>({
    start: null,
    end: null,
    granularity: "30m",
  });

  const { device } = useDevice(batteryId);
  const { timezone, timezoneLabel } = usePropertyTimezone(
    device?.assigned_property
  );

  const handleChartTypeChange = (event: SelectChangeEvent) => {
    setChartType(event.target.value as string);
    setIsInitialLoad(true);
  };

  const [fullRangeStart, fullRangeEnd] = useMemo(() => {
    if (!device?.utc_time_metrics_start || !device?.utc_time_metrics_end) {
      return [null, null];
    }
    return [
      DateTime.fromISO(device.utc_time_metrics_start, {
        zone: "utc",
      }).toMillis(),
      DateTime.fromISO(device.utc_time_metrics_end, {
        zone: "utc",
      }).toMillis(),
    ];
  }, [device?.utc_time_metrics_start, device?.utc_time_metrics_end]);

  const hasDataForSelectedType = useMemo(() => {
    return fullRangeStart && fullRangeEnd && fullRangeEnd > fullRangeStart;
  }, [fullRangeEnd, fullRangeStart]);

  const fetchUrlForecast = useMemo(() => {
    if (chartState.start && chartState.end) {
      return `/api/devices/${chartState.granularity}/battery-forecasts/?battery=${batteryId}&utc_time__gte=${chartState.start}&utc_time__lte=${chartState.end}&ordering=utc_time`;
    }
    return null;
  }, [batteryId, chartState]);

  const { data: forecastData, isLoading: isLoadingForecastData } = useFetch<{
    results: BatteryMetrics1h[];
  }>(fetchUrlForecast);

  const hasForecastData = useMemo(() => {
    if (
      !forecastData ||
      !forecastData.results ||
      forecastData.results.length === 0 ||
      !fullRangeEnd
    ) {
      return false;
    }

    // Check if there are any forecast points after the last metric timestamp
    return forecastData.results.some((item) => {
      const itemTimestamp = DateTime.fromISO(item.utc_time, {
        zone: "utc",
      }).toMillis();
      return (
        itemTimestamp > fullRangeEnd &&
        (chartType === "Power"
          ? item.solar_power_in_kw !== null
          : item.solar_energy_generation_in_kwh !== null)
      );
    });
  }, [forecastData, fullRangeEnd, chartType]);

  const fetchUrl = useMemo(() => {
    if (chartState.start && chartState.end) {
      return `/api/devices/${chartState.granularity}/battery-metrics/?battery=${batteryId}&utc_time__gte=${chartState.start}&utc_time__lte=${chartState.end}&ordering=utc_time`;
    } else if (fullRangeStart && fullRangeEnd) {
      return `/api/devices/${
        chartState.granularity
      }/battery-metrics/?battery=${batteryId}&utc_time__gte=${new Date(
        fullRangeStart
      ).toISOString()}&utc_time__lte=${new Date(
        fullRangeEnd
      ).toISOString()}&ordering=utc_time`;
    }
    return null;
  }, [batteryId, chartState, fullRangeEnd, fullRangeStart]);

  const { data, isLoading: isLoadingData } = useFetch<{
    results: BatteryMetrics1h[];
  }>(fetchUrl);

  // Set initial extremes after both datasets have loaded
  useEffect(() => {
    if (
      isInitialLoad &&
      !isLoading &&
      !isLoadingData &&
      !isLoadingForecastData &&
      hasDataForSelectedType &&
      chartRef.current
    ) {
      const chart = chartRef.current.chart;
      const localNow = DateTime.now().setZone(timezone || "system");

      // Set legend
      chart.update({
        legend: {
          enabled: hasForecastData,
        },
      });

      if (hasForecastData) {
        // If it has forecast data, show the current day in full
        chart.xAxis[0].setExtremes(
          localNow.startOf("day").toMillis(),
          localNow.endOf("day").toMillis() + 1 // +1 needed to include last tick label
        );
      } else {
        // Else show initially the last 24h from the last point
        const oneDayAgo = fullRangeEnd! - 24 * 3600 * 1000;
        chart.xAxis[0].setExtremes(oneDayAgo, fullRangeEnd!);
      }

      setIsInitialLoad(false);
    }
  }, [
    fullRangeEnd,
    fullRangeStart,
    hasDataForSelectedType,
    hasForecastData,
    isInitialLoad,
    isLoading,
    isLoadingData,
    isLoadingForecastData,
    timezone,
  ]);

  useEffect(() => {
    if (!hasDataForSelectedType) {
      setIsLoading(false);
    } else if (
      !isLoadingData &&
      !isLoadingForecastData &&
      data &&
      Array.isArray(data?.results) &&
      chartRef.current?.chart
    ) {
      const chart = chartRef.current.chart;
      const localNow = DateTime.now().setZone(timezone || "system");

      const metrics = data.results.map((item: BatteryMetrics1h) => {
        return [
          DateTime.fromISO(item.utc_time, {
            zone: "utc",
          }).toMillis(),
          chartType === "Power"
            ? item.solar_power_in_kw
            : item.solar_energy_generation_in_kwh,
        ];
      });

      const lastMetricTimestamp =
        metrics.length > 0 ? metrics[metrics.length - 1][0] ?? 0 : 0;

      const forecasts = hasForecastData
        ? forecastData?.results
            .map((item: BatteryMetrics1h) => {
              return [
                DateTime.fromISO(item.utc_time, {
                  zone: "utc",
                }).toMillis(),
                chartType === "Power"
                  ? item.solar_power_in_kw
                  : item.solar_energy_generation_in_kwh,
              ];
            })
            .filter((item) => item[0] && item[0] > lastMetricTimestamp)
        : ([] as any);

      const result = [...metrics, ...forecasts]
        .sort((a, b) => a[0] - b[0])
        .reduce((acc: [number, number][], curr) => {
          const existingIndex = acc.findIndex((item) => item[0] === curr[0]);
          if (existingIndex !== -1) {
            // If timestamp already exists, keep the value from metrics
            if (metrics.some((item) => item[0] === curr[0])) {
              return acc;
            }
            // Otherwise, update with the new value only if it's after the last metric
            if (curr[0] > lastMetricTimestamp) {
              acc[existingIndex] = curr;
            }
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);

      if (hasForecastData) {
        // Set plotLine
        chart.xAxis[0].update(
          {
            plotLines: [
              {
                color: "#544fc5",
                width: 2,
                value: localNow.toMillis(),
              },
            ],
          },
          false
        );
      }

      chart.series[0].setData(result);

      setIsLoading(false);
    }
  }, [
    data,
    chartRef,
    chartType,
    hasDataForSelectedType,
    isLoadingData,
    hasForecastData,
    chartState,
    forecastData,
    isLoadingForecastData,
    timezone,
    isInitialLoad,
    fullRangeEnd,
  ]);

  const chartOptions = useMemo(() => {
    const localNow = DateTime.now().setZone(timezone || "system");
    const localDate = localNow.toMillis();

    return {
      chart: {
        height: height - 65,
        marginRight: 20,
        marginLeft: 64,
      },
      time: {
        timezone,
        useUTC: timezone !== undefined,
      },
      xAxis: {
        minRange: 4 * 60 * 60 * 1000,
        crosshair: true,
        title: {
          y: 5,
          text: `Time<br><span style="font-size: 11px; color: #999;">${timezoneLabel}</span>`,
        },
        events: {
          afterSetExtremes: function (e: any) {
            const chart = e.target.chart;

            if (!chart || !e.min || !e.max) {
              return;
            }

            const { startStr, endStr } = getChartPadding(
              Math.round(e.min),
              Math.round(e.max)
            );

            const granularity = getChartGranularity(startStr, endStr);

            setChartState({
              start: startStr,
              end: endStr,
              granularity: granularity,
            });
          },
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          if (typeof this.x !== "number" || typeof this.y !== "number") {
            return "";
          }

          const pointDate = DateTime.fromMillis(this.x).setZone(
            timezone || "system"
          );

          let format = "cccc, dd LLLL HH:mm";
          let startHour = pointDate.toFormat("HH:mm");
          let endHour = pointDate.plus({ hours: 1 }).toFormat("HH:mm");

          const chart = this.series.chart;
          const xAxis = chart.xAxis[0];
          const min = xAxis.min || 0;
          const max = xAxis.max || 0;

          const { startStr, endStr } = getChartPadding(
            Math.round(min),
            Math.round(max)
          );

          const granularity = getChartGranularity(startStr, endStr);

          switch (granularity) {
            case "15m":
              endHour = pointDate.plus({ minutes: 15 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "30m":
              endHour = pointDate.plus({ minutes: 30 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "1h":
              endHour = pointDate.plus({ hours: 1 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "1d":
              format = "cccc, dd LLLL";
              break;
            default:
              break;
          }

          const dateStr = pointDate.toFormat(format);
          const seriesName = this.series.name;
          const valueStr = `${this.y.toFixed(2)} ${
            chartType === "Power" ? "kW" : "kWh"
          }`;

          const bulletPoint = `<span style="color:${this.color};">\u25CF</span>`;

          const forecast = this.x > localDate ? " (Forecast)" : "";

          return `<div style="font-size: 12px; margin-bottom: 5px;">${dateStr}</div>${bulletPoint} ${seriesName}${forecast}: <b>${valueStr}</b>`;
        },
        style: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      yAxis: [
        {
          min: 0,
          title: {
            text: chartType === "Power" ? "Power (kW)" : "Energy (kWh)",
          },
        },
      ],
      series: [
        {
          type: chartType === "Power" ? "areaspline" : "column",
          lineWidth: chartType === "Power" ? 1 : 2,
          color:
            chartType === "Power"
              ? "rgba(255, 235, 59,0.75)"
              : "rgb(255, 235, 59)",
          name: chartType === "Power" ? "Power" : "Energy",
          data: [
            [fullRangeStart, null],
            [localNow.endOf("day").toMillis() + 1, null],
          ],
          dataGrouping: {
            enabled: false,
          },
          showInLegend: false,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color:
                chartType === "Power"
                  ? "rgba(255,235,59,0.75)"
                  : "rgb(255,235,59)",
            },
            {
              color: "rgb(255,235,59,0.5)",
              fillColor: "rgba(255,235,59,0.3)",
              dashStyle: "ShortDash",
            },
          ],
        },
        // Fake series to add legend toggles
        {
          name: "Actual",
          type: "line",
          color: "rgb(255,235,59)",
          events: {
            legendItemClick: function (e: any) {
              const series = e.target.chart.series;
              if (series.length) {
                series[0].update({
                  fillColor: e.target.visible
                    ? "rgba(255, 235, 59, .0)"
                    : chartType === "Power"
                    ? "rgba(255,235,59,0.75)"
                    : "rgb(255,235,59)",
                });
                series[0].data.forEach((point: any) => {
                  if (point.x <= localDate) {
                    point.update({ visible: !e.target.visible }, false, false);
                  }
                });
              }
            },
          },
        },
        {
          name: "Forecast",
          type: "line",
          color: "rgba(255, 235, 59, .75)",
          dashStyle: "ShortDash",
          events: {
            legendItemClick: function (e: any) {
              if (e.target.chart.series.length) {
                e.target.chart.series[0].data.forEach((point: any) => {
                  if (point.x > localDate) {
                    point.update({ visible: !e.target.visible }, false, false);
                  }
                });
              }
            },
          },
        },
      ],
      legend: {
        align: "right",
        verticalAlign: "top",
        x: -4,
        y: -25,
        padding: 0,
        symbolWidth: 14,
        itemStyle: {
          color: "white",
          fontFamily: "Barlow",
        },
        itemHoverStyle: {
          color: "#ccc",
          fontFamily: "Barlow",
        },
      },
      loading: {
        labelStyle: {
          color: "transparent",
        },
        style: { backgroundColor: "transparent" },
      },
      navigator: {
        series: {
          data: [
            [fullRangeStart, null],
            [localNow.endOf("day").toMillis() + 1, null],
          ],
          dataGrouping: {
            enabled: false,
          },
        },
      },
      plotOptions: {
        column: {
          maxPointWidth: 20,
        },
      },
    };
  }, [timezone, height, timezoneLabel, chartType, fullRangeStart]);

  return (
    <div
      style={{
        height: height,
        backgroundColor: "rgb(37, 42, 49)",
        borderRadius: 5,
        padding: 20,
        color: "white",
      }}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 21,
              marginBottom: 10,
            }}
          >
            <b>Solar</b>
          </div>
          <div
            style={{
              width: "100%",
              flex: 1,
              textAlign: "right",
              fontSize: "16px",
              marginBottom: 10,
              color: "rgba(255,255,255,.75)",
              marginTop: 4,
            }}
          >
            <FormControl
              sx={{ width: 140 }}
              disabled={isLoading || isLoadingData || isLoadingForecastData}
            >
              <Select
                id="chart-type-select"
                value={chartType}
                onChange={handleChartTypeChange}
                sx={{
                  height: 26,
                  fontSize: "0.875rem",
                  "& .MuiSelect-select": {
                    paddingTop: "3px",
                    paddingBottom: "3px",
                    textAlign: "left",
                  },
                  "& .MuiOutlinedInput-input": {
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      "& .MuiMenu-list": {
                        padding: "2px 0",
                      },
                    },
                  },
                }}
              >
                <MenuItem
                  value="Power"
                  sx={{
                    height: 26,
                    fontSize: "0.875rem",
                    padding: "4px 10px",
                  }}
                >
                  Power
                </MenuItem>
                <MenuItem
                  value="Energy"
                  sx={{
                    height: 26,
                    fontSize: "0.875rem",
                    padding: "4px 10px",
                  }}
                >
                  Energy
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div>
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: height - 100,
            }}
          >
            <Spinner />
          </div>
        )}
        {hasDataForSelectedType ? (
          <div
            style={{
              visibility: isLoading ? "hidden" : "visible",
            }}
          >
            <HighchartsReact
              key={chartType}
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={chartOptions}
              ref={chartRef}
            />
            {(isLoadingData || isLoadingForecastData) && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  height: height - 75,
                  marginTop: 65 - height,
                  zIndex: 100, // Above Highcharts
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 260,
              width: "100%",
            }}
          >
            No data available
          </div>
        )}
      </div>
    </div>
  );
}
