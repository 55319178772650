import { PropertyTariffMapping } from "@ec1/types/PropertyTariffMapping";
import { Tariff } from "@ec1/types/Tariff";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetch } from "../../../common/fetcher/effects";
import { useAuthHeaders } from "../common/authentication/authentication";
import { getCurrencySymbol, getCurrentTariffId } from "../properties/tariffs";
import { usePropertyTimezone } from "./properties";

export const PRICES_URL = `/api/prices/ts/`;

export interface ChartPropertyPricePoint {
  x: number;
  y: number | null;
}

export interface ChartPropertyPriceSeries {
  name: "import" | "export";
  data: ChartPropertyPricePoint[];
}

export default function usePropertyPrices(propertyId: number) {
  const headers = useAuthHeaders();
  const { timezone, timezoneLabel, isTimezoneLoaded } =
    usePropertyTimezone(propertyId);

  const [initialTimestamps, setInitialTimestamps] = useState<number[]>([]);
  const [isFetchingInitialTimestamps, setIsFetchingInitialTimestamps] =
    useState<boolean>(true);
  const [granularity, setGranularity] = useState(60);
  const [currency, setCurrency] = useState("");

  const tariffMappingFetchUrl = useMemo(() => {
    return `/api/tariffs/property-mapping/?property=${propertyId}`;
  }, [propertyId]);

  const { data: propertyTariffMappingResponse } = useFetch<{
    results: PropertyTariffMapping[];
  }>(tariffMappingFetchUrl, { useCache: true });

  const tariffId = useMemo(() => {
    return getCurrentTariffId(propertyTariffMappingResponse?.results) ?? null;
  }, [propertyTariffMappingResponse?.results]);

  const tariffFetchUrl = useMemo(() => {
    if (tariffId) {
      return `/api/tariffs/${tariffId}/`;
    }
    return null;
  }, [tariffId]);

  const { data: tariff } = useFetch<Tariff>(tariffFetchUrl, { useCache: true });

  useEffect(() => {
    if (tariff?.currency) {
      setCurrency(getCurrencySymbol(tariff.currency));
    }
  }, [tariff]);

  const fetchInitialPropertyPrices = useCallback(async () => {
    setIsFetchingInitialTimestamps(true);
    try {
      const startFetchImport = fetch(
        `${PRICES_URL}?related_property=${propertyId}&is_export_price=false&offset=0&limit=1&ordering=local_date`,
        { headers }
      );
      const endFetchImport = fetch(
        `${PRICES_URL}?related_property=${propertyId}&is_export_price=false&offset=0&limit=1&ordering=-local_date`,
        { headers }
      );
      const startFetchExport = fetch(
        `${PRICES_URL}?related_property=${propertyId}&is_export_price=true&offset=0&limit=1&ordering=local_date`,
        { headers }
      );
      const endFetchExport = fetch(
        `${PRICES_URL}?related_property=${propertyId}&is_export_price=true&offset=0&limit=1&ordering=-local_date`,
        { headers }
      );

      const [startDataImport, endDataImport, startDataExport, endDataExport] =
        await Promise.all([
          startFetchImport,
          endFetchImport,
          startFetchExport,
          endFetchExport,
        ]).then((responses) => Promise.all(responses.map((res) => res.json())));

      const startImportDt =
        startDataImport.results && startDataImport.results.length > 0
          ? DateTime.fromISO(
              startDataImport.results[0].utc_start_time
            ).toMillis()
          : Infinity;
      const endImportDt =
        endDataImport.results && endDataImport.results.length > 0
          ? DateTime.fromISO(endDataImport.results[0].utc_start_time).toMillis()
          : 0;
      const startExportDt =
        startDataExport.results && startDataExport.results.length > 0
          ? DateTime.fromISO(
              startDataExport.results[0].utc_start_time
            ).toMillis()
          : Infinity;
      const endExportDt =
        endDataExport.results && endDataExport.results.length > 0
          ? DateTime.fromISO(endDataExport.results[0].utc_start_time).toMillis()
          : 0;

      const startDt = Math.min(startImportDt, startExportDt);
      const endDt = Math.max(endImportDt, endExportDt);

      if (startDt !== Infinity && endDt !== 0) {
        setInitialTimestamps([startDt, endDt]);

        // Set granularity
        setGranularity(
          [
            startDataImport.results[0],
            endDataImport.results[0],
            startDataExport.results[0],
            endDataExport.results[0],
          ].find((result) => result?.slot_duration_in_minutes)
            ?.slot_duration_in_minutes || 60
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.name !== "AbortError") {
          console.error("Fetch error:", error.message);
        } else {
          console.error("An unexpected error occurred", error);
        }
      }
    } finally {
      setIsFetchingInitialTimestamps(false);
    }
  }, [propertyId, headers]);

  const fetchPropertyPriceSeries = useCallback(
    async (start: number, end: number) => {
      let startDt = DateTime.fromMillis(start);
      let endDt = DateTime.fromMillis(end);

      // Padding
      startDt = startDt.minus({ hours: 1 });
      endDt = endDt.plus({ hours: 1 });

      const startStr = startDt.startOf("day").toUTC().toFormat("yyyy-MM-dd");
      const endStr = endDt.endOf("day").toUTC().toFormat("yyyy-MM-dd");

      try {
        const responseImport = fetch(
          `${PRICES_URL}?related_property=${propertyId}&is_export_price=false&start_date=${startStr}&end_date=${endStr}&ordering=local_date`,
          { headers }
        );
        const responseExport = fetch(
          `${PRICES_URL}?related_property=${propertyId}&is_export_price=true&start_date=${startStr}&end_date=${endStr}&ordering=local_date`,
          { headers }
        );

        const [dataImport, dataExport] = await Promise.all([
          responseImport,
          responseExport,
        ]).then((responses) => Promise.all(responses.map((res) => res.json())));

        let result: ChartPropertyPriceSeries[] = [];

        // Create padded series with null values
        const createDummySeries = (
          name: "import" | "export",
          interval: number
        ) => {
          const series: ChartPropertyPriceSeries = {
            name,
            data: [],
          };
          // Round startDt to the nearest interval
          let currentTime = DateTime.fromMillis(
            Math.floor(startDt.toMillis() / (interval * 60 * 1000)) *
              (interval * 60 * 1000)
          ).toUTC();
          while (currentTime < endDt) {
            const nextTime = currentTime.plus({ minutes: interval });
            series.data.push({
              x: currentTime.toMillis(),
              y: null,
            });
            currentTime = nextTime;
          }
          return series;
        };

        // Populate series with actual data
        const populateSeries = (
          series: ChartPropertyPriceSeries,
          data: any[],
          interval: number
        ) => {
          data.forEach((item) => {
            const utcStartTime = DateTime.fromISO(item.utc_start_time, {
              zone: "utc",
            });
            item.prices.forEach((price: number, index: number) => {
              const x = utcStartTime
                .plus({ minutes: index * interval })
                .toMillis();
              const existingPoint = series.data.find((point) => point.x === x);
              if (existingPoint) {
                existingPoint.y = price;
              }
            });
          });
        };

        if (dataImport && dataImport.results && dataImport.results.length > 0) {
          const importSeries = createDummySeries(
            "import",
            dataImport.results[0].slot_duration_in_minutes
          );
          populateSeries(
            importSeries,
            dataImport.results,
            dataImport.results[0].slot_duration_in_minutes
          );
          result.push(importSeries);
        }

        if (dataExport && dataExport.results && dataExport.results.length > 0) {
          const exportSeries = createDummySeries(
            "export",
            dataExport.results[0].slot_duration_in_minutes
          );
          populateSeries(
            exportSeries,
            dataExport.results,
            dataExport.results[0].slot_duration_in_minutes
          );
          result.push(exportSeries);
        }

        return result;
      } catch (error) {
        if (error instanceof Error) {
          if (error.name !== "AbortError") {
            console.error("Fetch error:", error.message);
          } else {
            console.error("An unexpected error occurred", error);
          }
        }
      }
    },
    [propertyId, headers]
  );

  useEffect(() => {
    if (isTimezoneLoaded) {
      fetchInitialPropertyPrices();
    }
  }, [fetchInitialPropertyPrices, isTimezoneLoaded]);

  return {
    initialTimestamps,
    isFetchingInitialTimestamps,
    fetchPropertyPriceSeries,
    timezone,
    timezoneLabel,
    granularity,
    currency,
  };
}
