import { BsLinkedin } from "react-icons/bs";
import { createUseStyles } from "react-jss";
import "../../fonts/fonts.css";
import SpinningLogo from "../../ui/logo/SpinningLogo.react";
import VantaPage from "../../ui/vanta/VantaPage.react";
const useStyles = createUseStyles({
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  "@keyframes slideAndFade": {
    "0%": {
      opacity: 0,
      transform: "translateY(40px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  logoText: {
    marginLeft: "12px",
    letterSpacing: "3px",
    fontWeight: 500,
    fontSize: "20px",
    fontFamily: "Barlow",
    animation: "$fadeIn 2s",
  },
  slideAndFadeDiv: {
    opacity: 0,
    animation: "$slideAndFade 1s 1s forwards",
    marginTop: "-40%",
    fontFamily: "Space Grotesk",
  },
  footerText: {
    fontFamily: "Space Grotesk",
    fontSize: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 768px)": {
      flexDirection: "column-reverse",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  contentWrapper: {
    color: "white",
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: "22px",
    width: "840px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "36px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  largeText: {
    fontSize: "56px",
    marginTop: "120px",
    "@media (max-width: 768px)": {
      fontSize: "24px",
    },
  },
  middleText: {
    fontSize: "32px",
    "@media (max-width: 768px)": {
      marginTop: "8px",
      fontSize: "20px",
    },
  },
  boldMiddleText: {
    fontWeight: 900,
  },
  footerCol: {
    fontSize: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "@media (max-width: 768px)": {
      marginTop: "8px",
      alignItems: "center",
      justifyContent: "center",
    },
  },
});

const LandingHomePage = () => {
  const classes = useStyles();

  return (
    <VantaPage>
      <div className={classes.contentWrapper}>
        <div className={classes.flexRow}>
          <SpinningLogo />
          <div className={classes.logoText}>ELECTRYONE AI</div>
        </div>
        <div className={classes.slideAndFadeDiv}>
          <div className={classes.largeText}>Smart Energy at scale</div>
          <div className={classes.middleText}>
            Powering the energy suppliers of tomorrow,{" "}
            <span className={classes.boldMiddleText}>today</span>.
          </div>
        </div>
        <div className={classes.footerText}>
          <div className={classes.footerCol}>
            <div>Copyright © 2023, Electryone AI LTD.</div>
            <div>Company no. 14508320</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              info@electryone.ai
            </div>
            <div>
              <a
                href="https://uk.linkedin.com/company/electryone-ai"
                target="_blank"
                rel="noreferrer"
                style={{
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <BsLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </VantaPage>
  );
};

export default LandingHomePage;
