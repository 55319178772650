import { Card, H1, Text } from "@blueprintjs/core";
import { useMemo } from "react";
import { MdBatteryChargingFull, MdSolarPower } from "react-icons/md";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { EnergyUtils } from "src/ui/utils/chartUtils";
import { PlatformEnterpriseMetrics } from "../../__generated__/types/PlatformEnterpriseMetrics";
import { useFetch } from "../../common/fetcher/effects";
import PlatformSiteDeviceMapView from "./devices/PlatformSiteDeviceMapView.react";

type StyleProps = {
  cardWidth: number;
};

const useStyles = createUseStyles({
  container: {
    margin: "24px 48px",
    display: "flex",
    fontFamily: "Barlow",
    height: "240px",
    justifyContent: "space-between",
    gap: "36px",
  },
  metricsContainer: {
    width: ({ cardWidth }: StyleProps) => `${cardWidth * 3 + 40}px`,
  },
  metricBox: {
    fontFamily: "Barlow",
    fontSize: "20px",
    backgroundColor: "#383E47",
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
  },
  metricBoxMargin: {
    marginTop: "16px",
  },
  icon: {
    marginRight: "12px",
  },
  cardContainer: {
    marginTop: "16px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
  },
});

export default function PlatformHomePage() {
  const classes = useStyles({ cardWidth: 120 });

  const { data: enterpriseMetricsData } = useFetch<{
    results: PlatformEnterpriseMetrics[];
  }>("/api/enterprise-metrics/", { useCache: true });

  const latestEnterpriseMetrics = useMemo(() => {
    if (
      Array.isArray(enterpriseMetricsData?.results) &&
      enterpriseMetricsData?.results?.length
    ) {
      return enterpriseMetricsData.results[0];
    }
    return null;
  }, [enterpriseMetricsData?.results]);

  return (
    <div>
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundColor: "#383E47",
            height: "600px",

            alignItems: "center",

            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <div
            style={{
              height: "580px",
              display: "flex",
              flex: 1,
              overflow: "hidden",
            }}
          >
            <PlatformSiteDeviceMapView height="580px" />
          </div>
        </div>
        <div className={classes.metricsContainer}>
          <div className={classes.metricBox}>
            <MdSolarPower className={classes.icon} />
            {`Solar Capacity: ${String(
              EnergyUtils.humanize(
                latestEnterpriseMetrics?.solar_capacity_in_kw ?? 0,
                "kw"
              )
            )}`}
          </div>
          <div className={`${classes.metricBox} ${classes.metricBoxMargin}`}>
            <MdBatteryChargingFull className={classes.icon} />
            {`Battery Capacity: ${String(
              EnergyUtils.humanize(
                latestEnterpriseMetrics?.total_battery_capacity_in_kwh ?? 0,
                "kw"
              )
            )}h`}
          </div>

          <div className={classes.cardContainer}>
            <div className={classes.cardWrapper}>
              <MetricCard
                url="/customers"
                label="customers"
                color="#68C1EE"
                number={latestEnterpriseMetrics?.customer_count ?? null}
              />
            </div>
            <div className={classes.cardWrapper}>
              <MetricCard
                url="/devices"
                label="devices"
                color="#8EB125"
                number={latestEnterpriseMetrics?.device_count ?? null}
              />
            </div>
            <div className={classes.cardWrapper}>
              <MetricCard
                url="/properties"
                label="properties"
                color="#00A396"
                number={latestEnterpriseMetrics?.property_count ?? null}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type MetricCardStyleProps = {
  url?: string;
};

const useMetricCardStyles = createUseStyles({
  card: {
    width: "100%",
    cursor: ({ url }: MetricCardStyleProps) => (url ? "pointer" : "default"),
  },
  heading: {
    textAlign: "center",
    color: "inherit",
  },
  text: {
    color: "inherit",
    textAlign: "center",
  },
});

interface MetricCardProps {
  number: number | null;
  label: string;
  color: string;
  url?: string;
}

function MetricCard({ number, label, color, url }: MetricCardProps) {
  const navigate = useNavigate();
  const classes = useMetricCardStyles({ url });

  return (
    <Card
      className={classes.card}
      style={{ color }}
      onClick={url ? () => navigate(`${url}`) : undefined}
    >
      <H1 className={classes.heading} style={{ color }}>
        {number ?? 0}
      </H1>
      <Text className={classes.text}>{label}</Text>
    </Card>
  );
}
