import { Tab, Tabs, TabsExpander } from "@blueprintjs/core";
import { capitalize } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PlatformContractListView from "../contracts/PlatformContractListView.react";
import { useEnterpriseLabels } from "../enterpriseLabels/useEnterpriseLabels";
import PlatformInvoiceListView from "../invoices/PlatformInvoiceListView.react";
import PlatformCustomerListView from "./PlatformCustomerListView.react";

type TabId = "customers" | "contracts" | "invoices";

function getPageTitle(title: TabId) {
  return capitalize(title);
}

interface PlatformSiteCustomerListViewPageProps {
  pageType: TabId;
}

export default function PlatformSiteCustomerListViewPage({
  pageType,
}: PlatformSiteCustomerListViewPageProps) {
  const navigate = useNavigate();

  const { hasContracts, hasInvoices } = useEnterpriseLabels();

  const [tabId, setTabId] = useState<TabId>(pageType);

  useEffect(() => {
    setTabId(pageType);
  }, [pageType]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        padding: "24px 24px 38px 24px",
      }}
    >
      <div>
        <Tabs
          id="tabs"
          onChange={(tabId: TabId) => {
            navigate(`/${tabId}/`);
          }}
          selectedTabId={tabId}
        >
          <div
            style={{
              fontFamily: "Barlow",
              letterSpacing: 1.5,
              fontWeight: 700,
              fontSize: 24,
              color: "#D3D8DE",
            }}
          >
            {getPageTitle(tabId)}
          </div>
          <TabsExpander />
          {hasContracts || hasInvoices ? (
            <Tab id="customers" title="Customers" />
          ) : null}
          {hasContracts ? <Tab id="contracts" title="Contracts" /> : null}
          {hasInvoices ? <Tab id="invoices" title="Invoices" /> : null}
        </Tabs>
      </div>
      {tabId === "customers" ? <PlatformCustomerListView /> : null}
      {hasContracts && tabId === "contracts" ? (
        <PlatformContractListView />
      ) : null}
      {hasInvoices && tabId === "invoices" ? <PlatformInvoiceListView /> : null}
    </div>
  );
}
