import { Button, Spinner } from "@blueprintjs/core";
import {
  BatteryFull,
  DonutLarge,
  ElectricCar,
  EvStation,
  SolarPower,
} from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { GridColDef, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Battery } from "../../../__generated__/types/Battery";
import PlatformListView from "../../../common/layout/PlatformListView.react";
import Ec1DataGrid from "../../../ui/components/Ec1DataGrid.react";
import PlatformSiteCustomerClickableLabel from "../customers/PlatformSiteCustomerClickableLabel.react";
import { useEnterpriseLabels } from "../enterpriseLabels/useEnterpriseLabels";
import BatteryChargingModeChip from "./BatteryChargingModeChip.react";
import BatteryStatusChip from "./BatteryStatusChip.react";
import PlatformAddDeviceDialog from "./PlatformAddDeviceDialog.react";
import PlatformSiteDeviceMapView from "./PlatformSiteDeviceMapView.react";
import { useDevices } from "./devices";
import PlatformSiteDeviceGridView from "./grid/PlatformSiteDeviceGridView.react";

function truncName(name: string) {
  const max_length = 12;
  if (name.length > max_length) {
    return name.slice(0, max_length - 3) + "...";
  }
  return name;
}

export function getTypeColor(status: string) {
  switch (status) {
    case "EV":
      return "#F44336";
    case "EV charger":
      return "#F44336";
    case "Solar inverter":
      return "#F44336";
    case "Battery":
      return "#F44336";
    default:
      return "";
  }
}

export function getTypeIcon(status: string) {
  switch (status) {
    case "EV":
      return <ElectricCar style={{ color: "#6ba2ce", fontSize: 20 }} />;
    case "EV charger":
      return <EvStation style={{ color: "#f3a843", fontSize: 20 }} />;
    case "Solar inverter":
      return <SolarPower style={{ color: "#f3c443", fontSize: 20 }} />;
    case "Battery":
      return <BatteryFull style={{ color: "#4CAF50", fontSize: 20 }} />;
    default:
      return <DonutLarge />;
  }
}

function getCustomFilterOperators() {
  return getGridSingleSelectOperators().filter(
    (operator) => operator.value === "is"
  );
}

type DeviceListViewType = "List" | "Grid" | "Map";

interface PlatformSiteDeviceListViewPageProps {
  pageType?: DeviceListViewType;
}

export default function PlatformSiteDeviceListViewPage({
  pageType = "List",
}: PlatformSiteDeviceListViewPageProps) {
  const { fetchDevices } = useDevices();
  const { selectedLabelObj } = useEnterpriseLabels();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [gridKey, setGridKey] = useState<number>(0);
  const [deviceListViewType, setDeviceListViewType] = useState(pageType);

  const navigate = useNavigate();

  useEffect(() => {
    setDeviceListViewType(pageType);
  }, [pageType]);

  const handleActionClick = useCallback(
    (deviceId: string, newTab = false) => {
      const url = `/devices/${deviceId}`;
      if (newTab) {
        window.open(url, "_blank");
      } else {
        navigate(url);
      }
    },
    [navigate]
  );

  const handleContextMenuItemClick = useCallback(
    (action: string, id: string | null) => {
      if (action === "view" && id) {
        handleActionClick(id);
      }
      if (action === "view_in_new_tab" && id) {
        handleActionClick(id, true);
      }
    },
    [handleActionClick]
  );

  const refreshDeviceList = useCallback(() => {
    setIsDialogOpen(false);
    setGridKey((prevKey) => prevKey + 1);
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: selectedLabelObj?.device_uid_field_name ?? "Device ID",
        flex: 1,
      },
      {
        field: "type",
        headerName: "Type",
        filterable: false,
        flex: 2,
        renderCell: (params) => (
          <div
            style={{
              display: "flex",
              alignItems: "left",
              flexDirection: "row",
              marginTop: 4,
            }}
          >
            <div
              style={{
                marginRight: 5,
                marginTop: 1,
                color: getTypeColor(params.value),
              }}
            >
              {getTypeIcon(params.value)}
            </div>
            <div style={{ marginTop: 1 }}>{params.value}</div>
          </div>
        ),
      },
      {
        field: "display_name",
        headerName: "Name",
        flex: 2,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => <div>{truncName(params.value ?? "")}</div>,
      },
      { field: "brand", headerName: "Brand", flex: 2 },
      { field: "model", headerName: "Model", flex: 2 },
      {
        field: "battery_status",
        headerName: "Status",
        type: "singleSelect",
        valueOptions: [
          { code: "charging", name: "Charging" },
          { code: "discharging", name: "Discharging" },
          { code: "idle", name: "Idle" },
        ],
        getOptionValue: (value: any) => value && value.code,
        getOptionLabel: (value: any) => value && value.name,
        filterOperators: getCustomFilterOperators(),
        flex: 2,
        renderCell: (params) => (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <BatteryStatusChip batteryStatus={params.value} />
          </div>
        ),
      },
      {
        field: "is_smart_charging",
        headerName: "Mode",
        type: "singleSelect",
        valueOptions: [
          { code: "smart", name: "Smart" },
          { code: "manual", name: "Manual" },
        ],
        getOptionValue: (value: any) => value && value.code,
        getOptionLabel: (value: any) => value && value.name,
        filterOperators: getCustomFilterOperators(),
        flex: 2,
        renderCell: (params) => (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <BatteryChargingModeChip
              chargingMode={params.value === true ? "SMART" : "MANUAL"}
            />
          </div>
        ),
      },
      {
        field: "battery_level",
        headerName: "Battery Level",
        type: "number",
        align: "left",
        headerAlign: "left",
        flex: 2,
        renderCell: (params) => (
          <div
            style={{ display: "flex", alignItems: "center" }}
          >{`${params.value} %`}</div>
        ),
      },
      {
        field: "customer",
        headerName: "Customer",
        flex: 2,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
          <PlatformSiteCustomerClickableLabel customerId={params.value} />
        ),
      },
    ],
    [selectedLabelObj]
  );

  const contextMenuOptions = useMemo(
    () => [
      {
        label: "Open device in new tab",
        icon: <OpenInNewIcon />,
        action: (id: string | null) =>
          handleContextMenuItemClick("view_in_new_tab", id),
      },
    ],
    [handleContextMenuItemClick]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {deviceListViewType === "List" ? (
        <PlatformListView title="Devices">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                fontFamily: "Barlow",
                letterSpacing: 1.5,
                fontWeight: 700,
                fontSize: 24,
                color: "#D3D8DE",
                marginBottom: 24,
                marginLeft: "4px",
              }}
            >
              <div style={{ marginTop: "12px" }}>
                <Button
                  minimal
                  style={{ marginRight: "12px", display: "none" }}
                  outlined
                  icon="people"
                  onClick={() => {
                    navigate("/vendor/account");
                  }}
                >
                  Vendor accounts
                </Button>
                <Button
                  disabled={true}
                  minimal
                  outlined
                  rightIcon={dialogLoading ? <Spinner size={16} /> : "add"}
                  onClick={() => {
                    navigate("/vendor/account/");
                  }}
                >
                  Enroll new devices
                </Button>
                <PlatformAddDeviceDialog
                  isOpen={isDialogOpen}
                  onClose={() => setIsDialogOpen(false)}
                  onEnrollmentSuccess={refreshDeviceList}
                  setIsLoadingParent={setDialogLoading}
                />
              </div>
            </div>
            <Ec1DataGrid<Battery>
              id="gridDevices"
              key={gridKey}
              get={fetchDevices}
              columns={columns}
              contextMenuOptions={contextMenuOptions}
              getRowId={(row: Battery) => row.id ?? "0"}
              onRowClick={(device, event) => {
                handleActionClick(String(device.id));
              }}
            />
          </div>
        </PlatformListView>
      ) : deviceListViewType === "Grid" ? (
        <div style={{ flex: 1 }}>
          <PlatformSiteDeviceGridView />
        </div>
      ) : (
        <div style={{ flex: 1, height: "100vh" }}>
          <PlatformSiteDeviceMapView />
        </div>
      )}
      <div
        style={{
          width: "50px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "4px",
          borderLeftColor: "#111418",
          borderLeftStyle: "solid",
          borderLeftWidth: "1px",
        }}
      >
        <Button
          large
          minimal
          icon="th"
          active={deviceListViewType === "List"}
          onClick={() => {
            navigate(`/devices/`);
          }}
        />
        <Button
          large
          minimal
          icon="globe"
          style={{ marginTop: "4px" }}
          active={deviceListViewType === "Map"}
          onClick={() => {
            navigate(`/devices/map`);
          }}
        />
        <Button
          large
          minimal
          icon="graph"
          style={{ marginTop: "4px" }}
          active={deviceListViewType === "Grid"}
          onClick={() => {
            navigate(`/devices/grid`);
          }}
        />
      </div>
    </div>
  );
}
