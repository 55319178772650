import { H3, H5 } from "@blueprintjs/core";
import { Cell, Column, Table2 } from "@blueprintjs/table";

const experimentParameters = [
  { name: "start_date", value: "2023-07-04T00:00:00" },
  { name: "end_date", value: "2023-07-06T00:00:00" },
  { name: "timezone", value: "utc" },
  { name: "iterations", value: "10" },
  { name: "sample_rate", value: "0.4" },
];

function getParameterName(index: number) {
  if (index < experimentParameters.length) {
    return <Cell>{experimentParameters[index]["name"]}</Cell>;
  }
}

function getParameterValue(index: number) {
  if (index < experimentParameters.length) {
    return <Cell>{experimentParameters[index]["value"]}</Cell>;
  }
}

export default function PlatformDigitalTwinExperimentSidebar() {
  return (
    <div
      style={{
        width: "320px",
        backgroundColor: "#252A31",
        fontFamily: "Barlow",
        padding: "12px 12px",
      }}
    >
      <H3 style={{ fontFamily: "Barlow" }}>EV aFRR VPP Version 2.1</H3>

      <div style={{ marginTop: "24px" }}>
        <H5 style={{ fontFamily: "Barlow" }}>Experiment parameters</H5>
        <Table2 numRows={experimentParameters.length} enableRowHeader={false}>
          <Column name="Parameter" cellRenderer={getParameterName} />
          <Column name="Value" cellRenderer={getParameterValue} />
        </Table2>
      </div>

      <div style={{ marginTop: "24px" }}>
        <H5 style={{ fontFamily: "Barlow" }}>Outputs</H5>
        <div style={{ marginTop: "8px" }}>
          Number of Vehicles: <span style={{ fontWeight: "bold" }}>32</span>
        </div>
        <div style={{ marginTop: "8px" }}>
          Number of Vehicles charging:{" "}
          <span style={{ fontWeight: "bold" }}>24</span>
        </div>
        <div style={{ marginTop: "8px" }}>
          Number of Vehicles enrolled in VPP:{" "}
          <span style={{ fontWeight: "bold" }}>16</span>
        </div>
      </div>
    </div>
  );
}
