import React from "react";
import { createUseStyles } from "react-jss";

// Define an interface for the component props
interface RotatingGearProps {
  color?: string;
  height?: string;
  width?: string;
}

// Define an interface for style props, which includes the theme (if any) and other props
interface StyleProps {
  height: string;
  width: string;
}

// Use createUseStyles with proper typing for props
const useStyles = createUseStyles({
  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
  rotatingGear: {
    animation: "$spin 4s linear infinite",
    height: (props: StyleProps) => props.height,
    width: (props: StyleProps) => props.width,
  },
});

const RotatingSyncIcon: React.FC<RotatingGearProps> = ({
  color = "rgb(0,0,0)",
  height = "512px", // default height
  width = "512px", // default width
}) => {
  const classes = useStyles({ height, width });

  return (
    <svg
      className={classes.rotatingGear}
      id="fi_2680215"
      enableBackground="new 0 0 24 24"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={color}>
        <path d="m4 9.1c.2-1.8 1-3.5 2.3-4.8 1.6-1.5 3.6-2.3 5.7-2.3 2 0 4.1.8 5.7 2.3l-1.7 1.7h5v-5l-1.9 1.9c-3.9-3.9-10.3-3.9-14.2 0-1.6 1.6-2.6 3.7-2.9 6v1.1h2c0-.3 0-.6 0-.9z"></path>
        <path d="m20 10c0 2.1-.8 4.2-2.3 5.7-1.6 1.5-3.6 2.3-5.7 2.3s-4.1-.8-5.7-2.3l1.7-1.7h-5v5l1.9-1.9c1.9 1.9 4.4 2.9 7.1 2.9s5.2-1 7.1-2.9 2.9-4.5 2.9-7.1z"></path>
      </g>
    </svg>
  );
};

export default RotatingSyncIcon;
