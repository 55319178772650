import { Checkbox } from "@blueprintjs/core";
import { TbSolarElectricity } from "react-icons/tb";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    marginTop: "8px",
    padding: "8px",
    backgroundColor: "#404854",
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.1s ease, opacity 0.1s ease",
    cursor: "pointer",
    "&:hover:not($disabledContainer)": {
      backgroundColor: "#4F5B69", // Lighter shade for hover
    },
  },
  selectedContainer: {
    backgroundColor: "#2F3841", // Darker shade for selected state
    "&:hover:not($disabledContainer)": {
      backgroundColor: "#374350", // Slightly lighter shade for hover on selected state
    },
  },
  disabledContainer: {
    opacity: 0.5,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  checkbox: {
    margin: 0,
  },
  iconContainer: {
    marginRight: "8px",
  },
  contentContainer: {
    flex: 1,
  },
  locationText: {
    fontSize: "12px",
  },
});

interface PlatformVendorAccountDeviceEnrolmentCandidateItemProps {
  selected?: boolean;
  disabled?: boolean;
  name: string;
  location?: string;
  asset_type: string;
  size?: string;
  onSelect: () => void;
  onUnselect: () => void;
}

export default function PlatformVendorAccountDeviceEnrolmentCandidateItem({
  selected = false,
  disabled = false,
  name,
  location = "",
  asset_type,
  size = "",
  onSelect,
  onUnselect,
}: PlatformVendorAccountDeviceEnrolmentCandidateItemProps) {
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    if (selected) {
      onUnselect();
    } else {
      onSelect();
    }
    e.stopPropagation();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    if (e.target.checked) {
      onSelect();
    } else {
      onUnselect();
    }
  };

  return (
    <div
      className={`${classes.container} ${
        selected ? classes.selectedContainer : ""
      } ${disabled ? classes.disabledContainer : ""}`}
      onClick={handleClick}
    >
      <div>
        <Checkbox
          disabled={disabled}
          className={classes.checkbox}
          checked={selected}
          onChange={handleCheckboxChange}
        />
      </div>

      <div className={classes.iconContainer}>
        {asset_type === "Inverter" ? <TbSolarElectricity size={36} /> : null}
      </div>
      <div className={classes.contentContainer}>
        <div>{name}</div>
        <div className={classes.locationText}>{location}</div>
      </div>
      <div> Size: {size}</div>
    </div>
  );
}
