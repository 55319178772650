import { Button } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "src/common/fetcher/effects";
import PlatformTable, {
  TableOrdering,
} from "src/common/table/PlatformTable.react";
import { EnergyUtils } from "src/ui/utils/chartUtils";
import PlatformListView from "../../../common/layout/PlatformListView.react";
import BatteryBrandNameTag from "./BatteryBrandNameTag.react";
import BatteryChargingModeTag from "./BatteryChargingModeTag.react";
import BatteryGridStatusTag from "./BatteryGridStatusTag.react";
import BatteryStationStateTag from "./BatteryStationStateTag.react";
import BatteryStatusTag from "./BatteryStatusTag.react";
import EllipsisNowrapTextDiv from "./EllipsisNowrapTextdivProps.react";

export default function PlatformDeviceListView() {
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);

  const [ordering, setOrdering] = useState<TableOrdering[]>([]);
  const navigate = useNavigate();

  const fetchUrl = useMemo(() => {
    const orderingParams = ordering
      .map((o) => {
        const field = o.field.toLowerCase();
        const prefix = o.order === "ASC" ? "" : "-";

        switch (o.field) {
          case "BRAND":
          case "STATION_STATE":
          case "STATION_NAME":
          case "BATTERY_LEVEL":
          case "SOLAR_POWER":
          case "GRID_POWER":
          case "SYNCED_AT":
          case "GRID_STATE":
            return `${prefix}${field}`;
          case "BATTERY_STATE":
            return `${prefix}battery_power`;
          case "CHARGING_MODE":
            return `${prefix}is_smart_charging`;
          default:
            return null;
        }
      })
      .filter(Boolean);

    const params_part_of_url =
      `limit=${pageSize}&offset=${pageSize * (currentPage - 1)}` +
      (orderingParams.length ? `&ordering=${orderingParams.join(",")}` : "");

    navigate(`/devices/?` + params_part_of_url);

    return "/api/devices/batteries/?" + params_part_of_url;
  }, [currentPage, navigate, ordering, pageSize]);

  const { data: deviceData, isLoading: isLoadingBatteries } = useFetch<{
    results: Battery[];
    count: number;
  }>(`${fetchUrl}`, {
    useCache: fetchUrl === "/api/devices/batteries/",
  });

  return (
    <PlatformListView title="Devices">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontFamily: "Barlow",
            letterSpacing: 1.5,
            fontWeight: 700,
            fontSize: 24,
            color: "#D3D8DE",

            marginLeft: "4px",
          }}
        >
          <div style={{ marginTop: "12px" }}>
            <Button
              minimal
              style={{ marginRight: "12px" }}
              outlined
              icon="people"
              onClick={() => {
                navigate("/vendor/account");
              }}
            >
              Vendor accounts
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingBottom: "12px",
            minHeight: 0,
          }}
        >
          <PlatformTable<Battery>
            interactive
            ordering={ordering}
            onOrderingChange={setOrdering}
            glimmer={isLoadingBatteries}
            data={deviceData?.results ?? []}
            columns={COLUMNS}
            numberOfAllItems={deviceData?.count ?? 5}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageSizeChange={setPageSize}
            handlePageChange={setCurrentPage}
          />
        </div>
      </div>
    </PlatformListView>
  );
}

const COLUMNS = [
  {
    id: "BRAND",
    header: "Brand",
    sortable: true,
    render: (item: Battery) => <BatteryBrandNameTag battery={item} />,
  },
  {
    id: "STATION_NAME",
    header: "Station name",
    sortable: true,
    render: (item: Battery) => (
      <EllipsisNowrapTextDiv>
        {String(item.station_name ?? "-")}
      </EllipsisNowrapTextDiv>
    ),
  },
  {
    id: "STATION_STATE",
    header: "Status",
    sortable: true,
    render: (item: Battery) => <BatteryStationStateTag battery={item} />,
  },
  {
    id: "BATTERY_STATE",
    header: "Battery State",
    sortable: true,
    render: (item: Battery) => <BatteryStatusTag battery={item} />,
  },
  {
    id: "CHARGING_MODE",
    header: "Charging Mode",
    sortable: true,
    render: (item: Battery) => <BatteryChargingModeTag battery={item} />,
  },
  {
    id: "BATTERY_LEVEL",
    header: "Battery level",
    sortable: true,
    render: (item: Battery) => (
      <div>
        {item?.battery_level || item?.battery_level === 0
          ? `${item?.battery_level}%`
          : "-"}
      </div>
    ),
  },
  {
    id: "SOLAR_POWER",
    header: "Solar Power",
    sortable: true,
    render: (item: Battery) => (
      <EllipsisNowrapTextDiv>
        {String(EnergyUtils.humanize(item.solar_power ?? 0, "w"))}
      </EllipsisNowrapTextDiv>
    ),
  },

  {
    id: "GRID_STATE",
    header: "Grid Power",
    sortable: true,
    render: (item: Battery) => <BatteryGridStatusTag battery={item} />,
  },
  {
    id: "SYNCED_AT",
    header: "Last sync",
    sortable: true,
    render: (item: Battery) => (
      <EllipsisNowrapTextDiv>
        {item.synced_at
          ? String(DateTime.fromISO(item.synced_at).toRelative())
          : "-"}
      </EllipsisNowrapTextDiv>
    ),
  },
].map((c) => ({ ...c, href: (item: Battery) => `/devices/${item.id}` }));
