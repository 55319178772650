import { HTMLTable, Spinner } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { Battery } from "../../../__generated__/types/Battery";
import BatteryStatusChip from "./BatteryStatusChip.react";
import {
  getTypeColor,
  getTypeIcon,
} from "./PlatformSiteDeviceListViewPage.react";
import { useEntityDevices } from "./devices";

interface PlatformDevicesWidgetProps {
  height: number;
  customerId?: number;
  propertyId?: number;
}

const useStyles = createUseStyles({
  widgetContainer: {
    backgroundColor: "rgb(37, 42, 49)",
    borderRadius: 5,
    padding: 20,
    color: "white",
  },
  header: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    fontSize: 21,
    marginBottom: 10,
    fontWeight: "bold",
  },
  tableContainer: {
    overflowY: "auto",
  },
  table: {
    width: "100%",
    fontFamily: "Barlow",
  },
  tableHead: {
    position: "sticky",
    top: 0,
    backgroundColor: "rgb(37, 42, 49)",
    zIndex: 1,
  },
  tableHeader: {
    width: "25%",
    minWidth: 70,
    borderBottom: "1px solid rgba(255, 255, 255, 0.15)",
  },
  tableCell: {
    verticalAlign: "middle !important",
    width: "25%",
    minWidth: 70,
    boxShadow: "none",
  },
  typeCell: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 2,
    textAlign: "center",
  },
  typeIcon: {
    alignItems: "center",
    textAlign: "center",
    marginLeft: 5,
  },
  typeText: {
    marginTop: 1,
  },
  statusCell: {
    display: "flex",
    alignItems: "center",
    marginLeft: -1,
  },
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});

export default function PlatformDevicesWidget({
  height,
  customerId,
  propertyId,
}: PlatformDevicesWidgetProps) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const entityDevices = useEntityDevices(customerId, propertyId);

  const handleRowClick = (deviceId: number | undefined) => {
    if (deviceId) {
      navigate(`/devices/${deviceId}`);
    }
  };

  useEffect(() => {
    if (entityDevices !== null) {
      setIsLoading(false);
      setIsInitialLoad(false);
    }
  }, [entityDevices]);

  return (
    <div className={classes.widgetContainer} style={{ height }}>
      <div>
        <div className={classes.header}>
          <div className={classes.title}>Devices</div>
        </div>
      </div>
      <div>
        {isInitialLoad || isLoading ? (
          <div
            className={classes.spinnerContainer}
            style={{ height: height - 100 }}
          >
            <Spinner />
          </div>
        ) : (
          <div
            className={classes.tableContainer}
            style={{
              height: `calc(${height - 100}px)`,
              visibility: isInitialLoad || isLoading ? "hidden" : "visible",
            }}
          >
            <HTMLTable className={classes.table} compact interactive>
              <thead className={classes.tableHead}>
                <tr>
                  <th
                    className={classes.tableHeader}
                    style={{ minWidth: 50, maxWidth: 50, width: 50 }}
                  >
                    Type
                  </th>
                  <th className={classes.tableHeader} style={{ minWidth: 45 }}>
                    Brand
                  </th>
                  <th className={classes.tableHeader} style={{ minWidth: 80 }}>
                    Model
                  </th>
                  <th className={classes.tableHeader} style={{ minWidth: 105 }}>
                    Station name
                  </th>
                  <th className={classes.tableHeader} style={{ minWidth: 110 }}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {entityDevices &&
                  entityDevices.map((b: Battery) => (
                    <tr key={b.id} onClick={() => handleRowClick(b.id)}>
                      <td
                        className={classes.tableCell}
                        style={{ minWidth: 50, maxWidth: 50, width: 50 }}
                      >
                        <div className={classes.typeCell}>
                          <div
                            className={classes.typeIcon}
                            style={{ color: getTypeColor("Battery") }}
                            title={"Battery"}
                          >
                            {getTypeIcon("Battery")}
                          </div>
                        </div>
                      </td>
                      <td
                        className={classes.tableCell}
                        style={{ minWidth: 45 }}
                      >
                        {b.brand}
                      </td>
                      <td
                        className={classes.tableCell}
                        style={{ minWidth: 80 }}
                      >
                        {b.model}
                      </td>
                      <td
                        className={classes.tableCell}
                        style={{ minWidth: 105 }}
                      >
                        {b.station_name}
                      </td>
                      <td
                        className={classes.tableCell}
                        style={{ minWidth: 110 }}
                      >
                        <div className={classes.statusCell}>
                          <BatteryStatusChip
                            batteryStatus={b.battery_status ?? ""}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </HTMLTable>
          </div>
        )}
      </div>
    </div>
  );
}
