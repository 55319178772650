import { Button, ButtonGroup, EditableText, H3, Icon } from "@blueprintjs/core";
import {
  $convertFromMarkdownString,
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  HEADING,
  ITALIC_UNDERSCORE,
} from "@lexical/markdown";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { Campaign } from "@mui/icons-material";
import { Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { AssistantDatasetNode } from "../composer/nodes/AssistantDatasetNode";
import AssistantDatasetPlugin from "../composer/plugins/AssistantDatasetPlugin";
import { useCampaign } from "./campaign";

interface PlatformCampaignDetailViewProps {
  campaignID: number;
}
interface Document {
  id: string;
  title: string;
  content: any;
}
/*function MyCustomAutoFocusPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.focus();
  }, [editor]);

  return null;
}*/
const useStyles = createUseStyles({
  errorStyle: { color: "red", fontSize: "12px" },
  textBold: {
    fontWeight: "bold",
  },
  textItalic: {
    fontStyle: "italic",
  },
  textUnderline: {
    textDecoration: "underline",
  },
});
function onError(error: Error): void {
  console.error(error);
}

export default function PlatformCampaignDetailView({
  campaignID,
}: PlatformCampaignDetailViewProps) {
  const editorRef = useRef(null);

  const { campaign, suggestedContent } = useCampaign(campaignID);

  const classes = useStyles();
  const [draftDocument] = useState<Document | null>(null);
  const [markdown, setMarkdown] = useState<string | null>(null);

  useEffect(() => {
    if (suggestedContent && draftDocument === null && markdown === null) {
      // Get the RootNode from the EditorState
      console.log(
        JSON.parse(suggestedContent.text)?.body ?? "<Unable to parse>"
      );

      setMarkdown(
        JSON.parse(suggestedContent.text)?.body ?? "<Unable to parse>"
      );
    }
  }, [draftDocument, markdown, suggestedContent]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#404854",
        padding: "0px 24px",
      }}
    >
      {/*JSON.stringify(campaign)*/}

      {/*JSON.stringify(draftDocument)*/}

      {/*JSON.stringify(suggestedContent)*/}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",

          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/*<div>Document {JSON.stringify(document)}</div>
    <div style={{ padding: "24px", width: "800px", margin: "64px" }}>
      Draft Document {JSON.str
        ingify(draftDocument)}
  </div>*/}

        <div
          style={{
            width: "100%",
            fontFamily: "Barlow",
            color: "#F6F7F9",
            outline: "none",
            minHeight: "400px",
            padding: "24px",
          }}
        >
          <div style={{ marginBottom: "24px" }}>
            <H3>
              <EditableText
                value={campaign?.title ?? ""}
                className={!campaign ? "bp5-skeleton" : undefined}
              />
            </H3>
            <div
              style={{
                marginTop: "12px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "-8px",
              }}
            >
              <ButtonGroup minimal alignText="left">
                <Button text="File" />
                <Button text="Edit" />
                <Button text="Help" />
              </ButtonGroup>
            </div>
            <div
              style={{
                color: "black",
                backgroundColor: "#5F6B7C",
                borderRadius: "12px",
                marginTop: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ButtonGroup large minimal>
                <Button
                  icon={
                    <Icon icon="floppy-disk" style={{ color: "#F6F7F9" }} />
                  }
                />
                <Button
                  icon={<Icon icon="print" style={{ color: "#F6F7F9" }} />}
                />
                <Divider />
                <Button
                  icon={<Icon icon="undo" style={{ color: "#F6F7F9" }} />}
                />
                <Button
                  icon={<Icon icon="redo" style={{ color: "#F6F7F9" }} />}
                />
                <Divider />
                <Button
                  icon={<Icon icon="bold" style={{ color: "#F6F7F9" }} />}
                />
                <Button
                  icon={<Icon icon="italic" style={{ color: "#F6F7F9" }} />}
                />
                <Button
                  icon={<Icon icon="underline" style={{ color: "#F6F7F9" }} />}
                />
                <Divider />
                <Button
                  icon={<Icon icon="align-left" style={{ color: "#F6F7F9" }} />}
                />
                <Button
                  icon={
                    <Icon icon="align-center" style={{ color: "#F6F7F9" }} />
                  }
                />
                <Button
                  icon={
                    <Icon icon="align-right" style={{ color: "#F6F7F9" }} />
                  }
                />
                <Button
                  icon={
                    <Icon icon="align-justify" style={{ color: "#F6F7F9" }} />
                  }
                />
                <Button
                  icon={
                    <Campaign
                      style={{ fontSize: 19, color: "white", margin: "-4px" }}
                    />
                  }
                />
              </ButtonGroup>
            </div>
          </div>

          {markdown ? (
            <LexicalComposer
              initialConfig={{
                namespace: "MyLexicalEditor",
                editorState: () =>
                  $convertFromMarkdownString(markdown ?? "", [
                    BOLD_STAR,
                    HEADING,

                    BOLD_ITALIC_STAR,
                    ITALIC_UNDERSCORE,
                    BOLD_ITALIC_UNDERSCORE,
                    BOLD_UNDERSCORE,
                  ]),
                nodes: [AssistantDatasetNode, HeadingNode, QuoteNode],
                theme: {
                  text: {
                    bold: classes.textBold,
                    italic: classes.textItalic,
                    underline: classes.textUnderline,
                  },
                },
                onError,
              }}
            >
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    style={{
                      backgroundColor: "none",
                      outline: "none",
                      minHeight: "400px",
                    }}
                  />
                }
                placeholder={<div />}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <AssistantDatasetPlugin />
              <EditorRefPlugin editorRef={editorRef} />
              <TabIndentationPlugin />
            </LexicalComposer>
          ) : null}
        </div>
      </div>
    </div>
  );
}
