import { Button, Card, Dialog, H3, Intent, Spinner } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SpinningLogo from "../../../ui/logo/SpinningLogo.react";

import { Trash } from "@blueprintjs/icons";
import { createUseStyles } from "react-jss";
import { Ec1Toaster } from "../../../ui/components/Ec1Toaster.react";
import PlatformBatteryMetricsDevicesConsumptionChart from "../devices/PlatformBatteryMetricsDevicesConsumptionChart.react";
import PlatformDevicesWidget from "../devices/PlatformDevicesWidget.react";
import { useEnterpriseLabels } from "../enterpriseLabels/useEnterpriseLabels";
import capitalizeName from "./capitalizeName";
import CustomerContractsTable from "./CustomerContractsTable.react";
import CustomerInvoicesTable from "./CustomerInvoicesTable.react";
import { Customer, useCustomers } from "./customers";

interface RenderIconOrSpinnerProps {
  isSending: boolean;
}

const useStyles = createUseStyles({
  button: {
    width: 50,
    height: 42,
    borderRadius: 0,
    textAlign: "center",
    borderRightWidth: "0 !important",
    position: "relative",
    "&:active": {
      outline: "none",
      outlineOffset: 0,
    },
    "&:focus": {
      outline: "none",
      outlineOffset: 0,
    },
  },
  buttonFirst: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  buttonLast: {
    borderWidth: "1px !important",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  buttonLabel: {
    fontFamily: "Barlow",
    color: "#c5cbd3",
    fontSize: 10,
  },
  disabled: {
    opacity: 0.5,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  spinner: {
    width: 20,
    height: 20,
  },
  dialog: {
    fontFamily: "Barlow",
    width: 360,
    boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.2) !important",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    margin: 10,
    marginBottom: 15,
  },
  dialogBody: {
    fontFamily: "Barlow",
    padding: 20,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 14,
    color: "white",
  },
  warningBody: {
    fontFamily: "Barlow",
    padding: 20,
    paddingTop: 10,
    fontSize: 14,
    color: "#c39900",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(440px, 1fr))",
    gap: "16px",
    marginTop: "24px",
  },
});

function showToast(
  message: string,
  intent: Intent,
  buttonText?: string,
  buttonOnClick?: () => void
) {
  Ec1Toaster.show({
    timeout: 5000,
    message: (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: 18,
            fontFamily: "Barlow",
          }}
        >
          <div style={{ marginRight: 15, height: 20 }}>{message}</div>
          {buttonText && (
            <Button
              intent="primary"
              text={buttonText}
              onClick={buttonOnClick}
              style={{
                height: 20,
                padding: "0 5px",
                minHeight: "unset",
                minWidth: "unset",
                fontSize: 12,
              }}
            />
          )}
        </div>
      </>
    ),
    intent: intent,
  });
}

export default function PlatformSiteCustomerDetailViewPage() {
  const classes = useStyles();
  const { customerId } = useParams<{ customerId: string }>();
  const { fetchCustomers, customers, deleteCustomer } = useCustomers();
  const { hasContracts, hasInvoices, selectedLabelObj } = useEnterpriseLabels();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const renderIcon = ({ isSending }: RenderIconOrSpinnerProps) =>
    isSending ? (
      <Spinner className={classes.spinner} size={14} />
    ) : (
      <Trash size={12} style={{ margin: 5, color: "white" }} />
    );

  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    setShowDeleteConfirm(false);

    const response = await deleteCustomer(parseInt(customerId ?? "-1"));

    if (response.success) {
      showToast("Customer deleted successfully.", Intent.SUCCESS);
      navigate("/customers/");
    } else {
      showToast(response.error, Intent.DANGER);
    }

    setIsDeleting(false);
  };

  const handleCloseDialog = () => {
    setShowDeleteConfirm(false);
  };

  const renderDeleteConfirmDialog = () => (
    <Dialog
      className={`bp5-dark ${classes.dialog}`}
      isOpen={showDeleteConfirm}
      onClose={handleCloseDialog}
      title="Confirm Deletion"
      icon="trash"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
    >
      <div style={{ marginTop: "8px" }}>
        <div className={classes.dialogBody}>
          Are you sure you want to delete this customer?
        </div>
        <div className={classes.warningBody}>
          <b>Warning</b>: If this customer is part of an integration it might be
          recreated on the next system sync.
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button outlined onClick={handleCloseDialog} disabled={isDeleting}>
          Cancel
        </Button>
        <div style={{ width: "8px" }} />
        <Button
          outlined
          intent={Intent.DANGER}
          onClick={handleConfirmDelete}
          disabled={isDeleting}
        >
          Delete
        </Button>
      </div>
    </Dialog>
  );

  useEffect(() => {
    if (!customerId) {
      setError("Customer ID is not specified.");
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        const queryParams = new URLSearchParams();
        queryParams.append("id", customerId);
        const data = await fetchCustomers({ params: queryParams });
        if (data.rows.length > 0) {
          setCustomer(data.rows[0]);
        } else {
          setError("No customer found with the given ID.");
        }
      } catch (err) {
        setError("Failed to fetch customer data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    const filteredCustomers = customers.filter(
      (c) => c.id === parseInt(customerId)
    );
    if (filteredCustomers.length === 0) {
      fetchData();
    } else {
      setCustomer(filteredCustomers[0]);
      setLoading(false);
    }
  }, [customerId, customers, fetchCustomers]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
        padding: "24px",
      }}
    >
      {renderDeleteConfirmDialog()}
      {!loading ? (
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                borderRadius: "6px",
                padding: "24px",
                fontFamily: "Barlow",
                width: "100%",
                backgroundColor: "#252A31",
                fontFeatureSettings: "Barlow",
                display: "flex",
                flexDirection: "row",
                flex: 1,
              }}
            >
              <div style={{ marginLeft: 5, flex: 1 }}>
                <H3 style={{ fontFamily: "Barlow" }}>
                  {capitalizeName(
                    customer?.first_name + " " + customer?.last_name
                  )}
                </H3>
                <div style={{ marginBottom: "0.75em" }}>
                  <strong>
                    {selectedLabelObj?.customer_uid_field_name ??
                      "Customer UID"}
                    :
                  </strong>{" "}
                  {customer?.customer_uid}
                </div>
                <div style={{ marginBottom: "0.75em" }}>
                  <strong>Email:</strong> {customer?.email}
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginBottom: "0.75em" }}>
                    <strong>Landline:</strong> {customer?.landline}
                  </div>
                  <div style={{ marginBottom: "0.75em", marginLeft: "24px" }}>
                    <strong>Mobile:</strong> {customer?.mobile}
                  </div>
                </div>
              </div>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ marginTop: -6, marginBottom: 16 }}>
                  <Button
                    minimal
                    outlined
                    disabled={isDeleting}
                    className={`${classes.button} ${classes.buttonFirst} ${classes.buttonLast}`}
                    onClick={handleDeleteClick}
                  >
                    <div className={classes.iconContainer}>
                      {renderIcon({
                        isSending: isDeleting,
                      })}
                    </div>
                    <div
                      className={`${classes.buttonLabel} ${
                        isDeleting ? classes.disabled : ""
                      }`}
                    >
                      Delete
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            {customerId && (
              <div className={classes.gridContainer}>
                <PlatformDevicesWidget
                  customerId={parseInt(customerId)}
                  height={360}
                />
                <PlatformBatteryMetricsDevicesConsumptionChart
                  customerId={parseInt(customerId)}
                  height={360}
                />
              </div>
            )}
          </div>

          {customerId && (hasContracts || hasInvoices) && (
            <div
              style={{
                marginTop: 24,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {hasContracts && (
                <Card style={{ width: "calc(50% - 5px)", height: "560px" }}>
                  <CustomerContractsTable customerId={customerId} />
                </Card>
              )}
              {hasInvoices && (
                <Card style={{ width: "calc(50% - 5px)", height: "560px" }}>
                  <CustomerInvoicesTable customerId={customerId} />
                </Card>
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            margin: "auto 0",
          }}
        >
          <SpinningLogo isLoadingSpinner={true} height="50px" />
        </div>
      )}
    </div>
  );
}
