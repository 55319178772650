import { ReactNode } from "react";

interface PlatformSiteDeviceDetailViewMetricsCardProps {
  icon: ReactNode;
  metric: string;
  label: string;
}
export default function PlatformSiteDeviceDetailViewMetricsCard({
  icon,
  metric,
  label,
}: PlatformSiteDeviceDetailViewMetricsCardProps) {
  return (
    <div
      style={{
        padding: 12,
        borderRadius: 4,
        display: "flex",
        flexDirection: "row",
        fontFamily: "Barlow",
        alignItems: "center",
        marginRight: 6,
        marginLeft: 6,
        backgroundColor: "#404854",
        minWidth: "100px",
      }}
    >
      <div style={{ marginRight: "12px" }}>{icon}</div>
      <div>
        <div style={{ fontSize: 24, fontWeight: 600 }}>{metric}</div>
        <div style={{ fontSize: 14 }}>{label}</div>
      </div>
    </div>
  );
}
