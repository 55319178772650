import { Dialog, DialogProps, H3 } from "@blueprintjs/core";
import { createUseStyles } from "react-jss";
import PlatformCampaignCreationDetailsTab from "./PlatformCampaignCreationDetailsTab.react";

// Step 2: Define your styles
const useStyles = createUseStyles({
  dialogStyle: {
    minWidth: "500px",
    width: "35%",
    fontFamily: "Barlow",
  },
});

//interface Audience {
//  id: number;
//  name: string;
//  audience_size: number;
//}

/*const AUDIENCES: Audience[] = [
  { id: 1, name: "All customers", audience_size: 36911 },
  { id: 2, name: "All active customers", audience_size: 4612 },
  { id: 3, name: "All active customers with EVs", audience_size: 340 },
  {
    id: 4,
    name: "All active customers with EVs and Solar",
    audience_size: 340,
  },
];*/

//function renderAudience(audience: Audience) {
//  return <MenuItem text={`${audience.name}`} />;
//}

interface PlatformCampaignCreationDialogProps extends DialogProps {
  campaignID: number | null;
  createNewCampaign: (title: string, objective: string) => Promise<void>;
  isCreatingCampaign: boolean;
  isProcessing: boolean;
}

export default function PlatformCampaignCreationDialog({
  isOpen,
  campaignID,
  onClose,
  createNewCampaign,
  isCreatingCampaign,
  isProcessing,
}: PlatformCampaignCreationDialogProps) {
  // Step 3: Use styles hook
  const classes = useStyles();

  return (
    <Dialog
      className={`bp5-dark ${classes.dialogStyle}`}
      isOpen={isOpen}
      icon="info-sign"
      onClose={onClose}
    >
      <div style={{ margin: "24px" }}>
        <H3>Create new campaign</H3>
        <PlatformCampaignCreationDetailsTab
          createNewCampaign={createNewCampaign}
          isCreatingCampaign={isCreatingCampaign}
          isProcessing={isProcessing}
        />
      </div>
    </Dialog>
  );
}
