import { useEffect } from "react";
import LandingSiteApp from "./sites/landing/LandingSiteApp.react";
import PlatformSiteApp from "./sites/platform/PlatformSiteApp.react";
import { AuthenticationProvider } from "./sites/platform/common/authentication/authentication";
import { EnterpriseLabelProvider } from "./sites/platform/enterpriseLabels/useEnterpriseLabels";

export default function App() {
  useEffect(() => {
    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.style.display = "none";
    }
  }, []);

  if (process.env.REACT_APP_WEB_PLATFORM === "true") {
    return (
      <AuthenticationProvider>
        <EnterpriseLabelProvider>
          <PlatformSiteApp />
        </EnterpriseLabelProvider>
      </AuthenticationProvider>
    );
  }
  return <LandingSiteApp />;
}
