import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Connection,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import { useDevices } from "../devices/devices";
import NormalEdgeWithTypeAssertion from "./CustomEdge.react";
import PowerNode from "./graphNodes/PowerNode";
import {
  getInitiaDeviceGraphEdges,
  getInitiaDeviceGraphNodes,
} from "./graphNodes/deviceGraphView";

const graphStyles = {
  width: "100%",
  height: "100%",
  backgroundColor: "rgb(17, 17, 17)",
  color: "rgb(243, 244, 246)",
  overflow: "none",
};

const edgeTypes = {
  normal: NormalEdgeWithTypeAssertion, // Add this line
};

export default function PowerFlowChart() {
  const { devices, fetchAllDevices } = useDevices();

  useEffect(() => {
    fetchAllDevices();
    const intervalId = setInterval(() => {
      fetchAllDevices();
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearInterval(intervalId);
  }, [fetchAllDevices]);

  const [lastUpdateTime, setLastUpdateTime] = useState<Date | null>(null);

  useEffect(() => {
    if (devices !== null) {
      setLastUpdateTime(new Date()); // Sets to current time
    }
  }, [devices]); // This effect depends on devices

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nodes, setNodes, onNodesChange] = useNodesState(
    getInitiaDeviceGraphNodes(devices ?? [])
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    getInitiaDeviceGraphEdges(devices ?? [])
  );

  const onConnect = useCallback(
    (connection: Connection) => {
      setEdges((eds) =>
        addEdge({ ...connection, style: { stroke: "#888" } }, eds)
      );
    },
    [setEdges]
  );

  const nodeTypes = useMemo(
    () => ({
      powerNode: PowerNode,
    }),
    []
  );

  if (devices === null) {
    return (
      <div
        style={{ backgroundColor: "#1C2127", width: "100%", height: "100%" }}
      />
    );
  }

  return (
    <div style={{ ...graphStyles, position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          fontFamily: "Barlow",
          color: "#ABB3BF",
          fontSize: "14px",
          padding: "20px",
        }}
      >
        {`Last updated at ${
          lastUpdateTime
            ? DateTime.fromJSDate(lastUpdateTime).toLocaleString(
                DateTime.DATETIME_MED
              )
            : "N/A"
        }`}
      </div>
      <div style={{ height: "100%" }}>
        <ReactFlow
          minZoom={0.2}
          nodes={getInitiaDeviceGraphNodes(devices)}
          edges={getInitiaDeviceGraphEdges(devices)}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          proOptions={{ hideAttribution: true }}
          edgeTypes={edgeTypes} // Update this line
          fitView
        >
          <Background
            variant={BackgroundVariant.Dots}
            size={1}
            color={"#383E47"}
          />
        </ReactFlow>
      </div>
    </div>
  );
}
