import { Button, IconSize, Spinner } from "@blueprintjs/core";
import { useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { Navigate, useLocation, useParams } from "react-router-dom";
import PlatformAIAssistantMessage from "./PlatformAssistantMessage.react";
import PlatformAssistantTextArea from "./PlatformAssistantTextArea.react";
import useAssistant, { useAssistantThreadCreator } from "./assistant";

export default function PlatformAIAssistant() {
  const classes = useStyles();

  const [textAreaValue, setTextAreaValue] = useState<string>("");

  const { threadID: paramThreadID } = useParams();
  const [threadID, setThreadID] = useState<null | string>(
    paramThreadID ?? null
  );

  const createNewThread = useAssistantThreadCreator();
  useEffect(() => {
    if (threadID === null) {
      createNewThread().then((id) => setThreadID(id));
    }
  }, [createNewThread, threadID]);

  const {
    messages,
    fetchThreadMessages,
    sendMessage,
    isSendingMessage,
    isFetchingMessages,
    isProcessing,
  } = useAssistant(threadID);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [messages.length]);

  useEffect(() => {
    fetchThreadMessages();
  }, [fetchThreadMessages]);

  const location = useLocation();
  const currentPath = location.pathname;

  const isLastMessageUserMessage = useMemo(() => {
    if (messages.length === 0) {
      return false;
    }
    return messages[messages.length - 1].role === "user";
  }, [messages]);

  useEffect(() => {
    if (
      messagesEndRef.current &&
      (isFetchingMessages || isProcessing) &&
      isLastMessageUserMessage
    ) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [isFetchingMessages, isLastMessageUserMessage, isProcessing]);

  if (currentPath === "/assistant/" && threadID !== null) {
    return <Navigate to={`/assistant/${threadID}`} />;
  }

  if (threadID !== null && currentPath !== `/assistant/${threadID}`) {
    return <Navigate to={`/assistant/${threadID}`} />;
  }

  function handleSubmit() {
    sendMessage(textAreaValue).then(() => setTextAreaValue(""));
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ height: "64px" }}></div>
      <div
        style={{
          flexGrow: 1,
          flexBasis: 0,
          padding: "0 120px",
          overflow: "scroll",
        }}
      >
        {messages.map((message, idx) => (
          <PlatformAIAssistantMessage
            message={message}
            key={idx}
            showSuggestions={idx === messages.length - 1}
            onSetMessage={setTextAreaValue}
          />
        ))}
        <div>
          {(isFetchingMessages || isProcessing) && isLastMessageUserMessage ? (
            <Spinner size={44} style={{ marginTop: "24px" }} />
          ) : null}
        </div>
        <div ref={messagesEndRef} />
      </div>
      <div style={{ padding: "24px 128px" }}>
        <PlatformAssistantTextArea
          value={textAreaValue}
          disabled={
            isSendingMessage ||
            threadID === null ||
            (threadID !== null && currentPath !== `/assistant/${threadID}`)
          }
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setTextAreaValue(event.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault(); // Prevents the default action of the Enter key
              handleSubmit(); // Calls the function to submit the message
            }
          }}
        />
        <div className={classes.buttonContainer}>
          <Button
            disabled={
              threadID === null ||
              (threadID !== null && currentPath !== `/assistant/${threadID}`) ||
              isSendingMessage ||
              isFetchingMessages ||
              (isProcessing && isLastMessageUserMessage) ||
              textAreaValue.length === 0
            }
            icon={
              isSendingMessage ? (
                <Spinner size={IconSize.STANDARD} />
              ) : (
                "send-message"
              )
            }
            onClick={handleSubmit}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px",
  },
});
