import React, { useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useAuthentication } from "./common/authentication/authentication";

const useStyles = createUseStyles({
  form: {
    width: "200px",
    height: "190px",
  },
  formGroup: ({ loading }: { loading: boolean }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "16px",
    pointerEvents: loading ? "none" : "auto",
  }),
  inputField: {
    width: "100%",
    padding: "7px 12px",
    backgroundColor: "#00001e",
    color: "white",
    borderRadius: "4px",
    border: "none",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Space Grotesk",
    transition: "background-color 5000s ease-in-out 0s",
    boxShadow: "inset 0 0 0 1000px #00001e",
    "&:focus": {
      borderColor: "#4a90e2",
      boxShadow: "0 0 0 2px rgba(74, 144, 226, 0.5)",
      outline: "none",
      backgroundColor: "#00001e",
    },
    "&:disabled": {
      backgroundColor: "#333",
      cursor: "not-allowed",
    },
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 30px #00001e inset",
      WebkitTextFillColor: "white",
      WebkitFontFamily: "'Space Grotesk'",
    },
    "&:-webkit-autofill:focus": {
      WebkitBoxShadow: "0 0 0 30px #00001e inset",
      WebkitTextFillColor: "white",
      WebkitFontFamily: "'Space Grotesk'",
    },
  },
  submitButton: {
    width: "100%",
    padding: "6px",
    fontSize: "14px",
    fontFamily: "Space Grotesk",
    fontWeight: "900",
    color: "#000019",
    backgroundColor: "#4a90e2",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#3a78c3",
    },
    "&:disabled": {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
    },
  },
  linkButton: {
    background: "none",
    color: "#ccc",
    border: "none",
    padding: 0,
    margin: 0,
    "&:hover": { textDecoration: "underline" },
    cursor: "pointer",
    fontSize: "12px",
    fontFamily: "Space Grotesk",
    marginTop: "0px",
  },
  message: {
    marginBottom: "15px",
    color: "#ccc",
    fontSize: "12px",
    fontFamily: "Space Grotesk",
  },
  loadingMessage: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
  clickableText: {
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    fontWeight: "bold",
    "&:hover": { textDecoration: "underline" },
    cursor: "pointer",
    fontSize: "12px",
    fontFamily: "Space Grotesk",
  },
});

type PlatformLoginFormProps = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  onSuccess: () => void;
};

export default function PlatformLoginForm({
  isLoading,
  setIsLoading,
  onSuccess,
}: PlatformLoginFormProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRequestingReset, setIsRequestingReset] = useState(false);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const classes = useStyles({ loading: isLoading });
  const { login, requestReset, setAuthError } = useAuthentication();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setAuthError(null);

    if (!isRequestingReset && (!email.trim() || !password.trim())) {
      let errorMessage = "Please enter your ";

      if (!email.trim() && !password.trim()) {
        errorMessage += "email and password.";
      } else if (!email.trim()) {
        errorMessage += "email.";
      } else {
        errorMessage += "password.";
      }

      setAuthError(errorMessage);
      return;
    }

    if (isRequestingReset && !email.trim()) {
      setAuthError("Please enter your email.");
      return;
    }

    setIsLoading(true);

    try {
      if (isRequestingReset) {
        const success = await requestReset(email);
        if (success) {
          onSuccess();
        }
      } else {
        await login(email, password);
      }
    } catch (error) {
      setAuthError(error as string);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setIsRequestingReset(false);
    setAuthError(null);
  };

  const handleBackClick = () => {
    resetForm();
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div className={classes.formGroup}>
        {isRequestingReset && (
          <div className={classes.message}>
            Type your email to receive password reset instructions
          </div>
        )}
        <input
          ref={emailInputRef}
          className={classes.inputField}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isLoading}
        />
      </div>
      {!isRequestingReset && (
        <div className={classes.formGroup}>
          <input
            className={classes.inputField}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={isLoading}
          />
        </div>
      )}
      <div className={classes.formGroup}>
        <button
          className={classes.submitButton}
          type="submit"
          disabled={isLoading}
        >
          {isRequestingReset ? "Reset Password" : "Sign in"}
        </button>
      </div>
      {!isRequestingReset && (
        <div className={classes.formGroup}>
          <button
            className={classes.linkButton}
            onClick={() => {
              setAuthError(null);
              setIsRequestingReset(true);
            }}
            type="button"
          >
            Forgot Password?
          </button>
        </div>
      )}
      {isRequestingReset && (
        <div className={classes.formGroup}>
          <button
            className={classes.linkButton}
            onClick={handleBackClick}
            type="button"
          >
            Back
          </button>
        </div>
      )}
    </form>
  );
}
