import { useEffect, useState } from "react";
import { useAuthHeaders } from "../../common/authentication/authentication";

export interface Brand {
  id: number;
  name: string;
}

const DEVICE_BRANDS = "DEVICE_BRAND_LS_KEY";

export default function useBrands() {
  const headers = useAuthHeaders();

  const [brands, setBrands] = useState<Brand[]>(
    JSON.parse(localStorage.getItem(DEVICE_BRANDS) ?? "[]") as Brand[]
  );

  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function fetchBrandsAsync() {
      setIsFetching(true);

      // Try to load brands from localStorage first
      const storedBrands = localStorage.getItem("brands");
      if (storedBrands) {
        setBrands(JSON.parse(storedBrands) as Brand[]);
      }

      // fetch in case there are updates
      const url = "/api/devices/brands/";
      const response = await fetch(url, { method: "get", headers });

      if (response.ok) {
        const result = await response.json();
        const fetchedBrands = result.results as Brand[];

        setBrands(fetchedBrands);
        localStorage.setItem(DEVICE_BRANDS, JSON.stringify(fetchedBrands));
      } else {
        console.error(await response.text());
      }
      setIsFetching(false);
    }

    if (!isFetching) {
      fetchBrandsAsync();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers]); // do not add isFetching

  return brands;
}
