import { Button, ButtonGroup } from "@blueprintjs/core";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AceEditor from "react-ace";
import { useLocation } from "react-router-dom";
import PlatformAssistantDataset from "../assistant/PlatformAssistantDataset.react";
import { useAssistantDatasetFetcher } from "../assistant/assistant";
import { useAuthHeaders } from "../common/authentication/authentication";
import PlatformBuilderSideView from "./PlatformBuilderSideView.react";
import PlatformBuilderToolbar from "./PlatformBuilderToolbar.react";
export default function PlatformBuilder() {
  const [dataset, setDataset] = useState(null);
  const [code, setCode] = useState<string>(`def generate_dataset():\n\tpass`);

  const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false);

  const fetchAssistantDataset = useAssistantDatasetFetcher();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uuid = queryParams.get("dataset") ?? null;

  const [datasetUUID, setDatasetUUID] = useState<string | null>(uuid);
  const [isExecuting, setIsExecuting] = useState(false);

  useEffect(() => {
    if (uuid !== null) {
      fetchAssistantDataset(uuid).then(setDataset);
    } else {
      setDataset(null);
    }
  }, [uuid, setDataset, fetchAssistantDataset]);

  useEffect(() => {
    if (dataset !== null) {
      const query = String(dataset["dask_query"] ?? "");
      let codeBlock = "111";
      if (query.length > 0) {
        codeBlock = `def generate_dataset():\n\treturn ${query}`;
      } else {
        codeBlock = "xxxxx";
      }

      setCode(codeBlock);
    }
  }, [dataset]);

  const editorRef = useRef<any>(null);

  function handleChange(newValue: string) {
    setCode(newValue);
  }
  const headers = useAuthHeaders();
  const executeCode = useCallback(async () => {
    if (isExecuting) {
      return;
    }
    setIsExecuting(true);
    const url = "/api/copilot/execution/";

    const response = await fetch(url, {
      method: "post",
      body: JSON.stringify({ code }),
      headers,
    });

    const result = await response.json();
    setDatasetUUID(result?.dataset ?? null);
    setIsExecuting(false);
    setIsBottomDrawerOpen(true);
  }, [code, headers, isExecuting]);

  const platformAssistantDataset = useMemo(() => {
    return (
      <PlatformAssistantDataset
        key={uuid}
        showTitle={false}
        uuid={datasetUUID ?? uuid}
      />
    );
  }, [datasetUUID, uuid]);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#24292e",

        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          margin: "4px",
          borderBottom: "1px solid #1C2127",
          width: "100%",
        }}
      >
        <ButtonGroup minimal>
          <Button small>File</Button>
          <Button small>Edit</Button>
          <Button small>Formatting</Button>
          <Button small>Tools</Button>
          <Button small>Help</Button>
        </ButtonGroup>
      </div>
      <div
        style={{
          display: "flex",
          height: "100%",

          flexDirection: "column",
        }}
      >
        <div></div>

        <div style={{ height: "100%" }}>
          <div
            style={{
              display: "flex",

              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflow: "hidden",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  height: "100%",

                  overflow: "scroll",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <PlatformBuilderToolbar
                    handleRun={executeCode}
                    isRunning={isExecuting}
                  />
                  <div style={{ flex: 1, marginTop: "12px" }}>
                    <AceEditor
                      ref={editorRef}
                      width="100%"
                      height="100%"
                      onLoad={function (editor) {}}
                      mode="python"
                      theme="github_dark"
                      onChange={handleChange}
                      name="UNIQUE_ID_OF_DIV"
                      editorProps={{ $blockScrolling: true }}
                      value={code}
                      highlightActiveLine={false}
                      style={{ padding: "8px" }}
                      setOptions={{
                        showLineNumbers: true,
                        tabSize: 4,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "#111418",

                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "4px 0",
                        borderBottom: "1px solid #1C2127",
                        borderTop: "1px solid #404854",
                      }}
                    >
                      <Button
                        minimal
                        onClick={() => {
                          setIsBottomDrawerOpen((prev) => !prev);
                        }}
                        icon={
                          isBottomDrawerOpen
                            ? "double-chevron-down"
                            : "double-chevron-up"
                        }
                      />
                    </div>
                    {isBottomDrawerOpen ? (
                      <div
                        style={{
                          height: "400px",
                          width: "100%",
                          padding: "8px",
                          display: "flex",
                        }}
                      >
                        <div>{platformAssistantDataset}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <PlatformBuilderSideView
              currentContent={code ?? ""}
              setContent={setCode}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
