import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useRef, useState } from "react";

interface Bucket {
  category: string;
  frequency: number;
}

interface PlatformAssistantHistogramChartProps {
  data: Bucket[];
  chartTitle?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
  tooltipSuffix?: string;
}

const PlatformAssistantHistogramChart: React.FC<
  PlatformAssistantHistogramChartProps
> = ({
  data,
  chartTitle = "Histogram Chart",
  xAxisTitle = "Buckets",
  yAxisTitle = "Frequency",
  tooltipSuffix = " units",
}) => {
  const [parentHeight, setParentHeight] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("XX");
      if (
        containerRef?.current !== null &&
        containerRef?.current?.parentNode !== null
      ) {
        const parentsParent = containerRef.current.parentNode;
        // Now you have the parent's parent element
        // You can get its width like this:
        console.log(
          "PARENT HEIGHT",
          (parentsParent as HTMLElement).offsetWidth
        );
        const containerHeight = (parentsParent as HTMLElement).offsetHeight;

        setParentHeight(containerHeight);

        // Add any other logic you need here
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [setParentHeight]);

  const options: Highcharts.Options = {
    chart: {
      height: parentHeight,
      type: "column",
      backgroundColor: "#C5CBD3", // Light blue background color
      style: {
        fontFamily: "Barlow, sans-serif",
        color: "#252A31",
      },
    },
    legend: { enabled: false },

    title: {
      text: chartTitle,
      style: {
        color: "#252A31",
      },
    },
    xAxis: {
      categories: data.map((bucket) => bucket.category),
      title: {
        text: xAxisTitle,
        style: {
          color: "#252A31",
        },
      },
      labels: {
        style: {
          color: "#252A31",
        },
      },
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: yAxisTitle,
        style: {
          color: "#252A31",
        },
      },
      labels: {
        style: {
          color: "#252A31",
        },
      },
    },
    series: [
      {
        name: yAxisTitle,
        type: "column",
        data: data.map((bucket) => bucket.frequency),
        color: "#1F4B99",
        tooltip: {
          valueSuffix: tooltipSuffix,
        },
      },
    ],
    tooltip: {
      style: {
        color: "#252A31",
      },
    },
  };

  return (
    <div ref={containerRef}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PlatformAssistantHistogramChart;
