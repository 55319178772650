import React, { useMemo } from "react";
import { BaseEdge, EdgeProps, getBezierPath, useStore } from "reactflow";
import { ZOOM_THRESHOLD } from "./GridNode.react";

interface GridEdgeTypedProps extends Partial<EdgeProps> {
  data: {
    color1: string;
    color2: string;
    isChild: boolean;
    isDisabled: boolean;
  };
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
}

const GridEdgeTyped: React.FC<GridEdgeTypedProps> = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const isZoomed = useStore((state) => state.transform[2] >= ZOOM_THRESHOLD);

  const animationStyle = useMemo(() => {
    return {
      offsetPath: `path("${edgePath}")`,
      animation: "moveAlongPath 6s linear infinite, fadeIn 0.5s ease-out",
    };
  }, [edgePath]);

  if (data.isChild && !isZoomed) {
    return null;
  }

  if (data.isDisabled) {
    return <BaseEdge path={edgePath} style={{ stroke: "#404854" }} />;
  }

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{ stroke: data.color1, strokeWidth: data.isChild ? 1 : 2 }}
      />
      <circle
        style={{
          ...animationStyle,
          filter: `drop-shadow(3px 3px 5px ${data.color2})`,
        }}
        r={data.isChild ? 2 : 4}
        fill={data.color2}
      />
      <style>
        {`
          @keyframes moveAlongPath {
            0% {
              offset-distance: 0%;
            }
            100% {
              offset-distance: 100%;
            }
          }
          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        `}
      </style>
    </>
  );
};

const GridEdge = GridEdgeTyped as React.FC<EdgeProps>;

export default GridEdge;
