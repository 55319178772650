import { createUseStyles } from "react-jss";

interface StylesProps {
  disableAnimation?: boolean;
  animationDuration?: number;
  animationDelay?: number;
  fontSize?: string;
}

interface ILogoTextProps extends StylesProps {}

const useStyles = createUseStyles({
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  logoText: ({
    disableAnimation,
    animationDuration,
    animationDelay,
    fontSize,
  }: StylesProps) => ({
    letterSpacing: "2px",
    fontWeight: 500,
    fontSize,
    fontFamily: "Barlow",
    opacity: disableAnimation ? 1 : 0,
    animation: disableAnimation
      ? "none"
      : `$fadeIn ${animationDuration}s ease-in-out ${animationDelay}s forwards`,
    color: "white",
  }),
});

export default function LogoText({
  disableAnimation = false,
  animationDuration = 3,
  animationDelay = 1,
  fontSize = "14px",
}: ILogoTextProps) {
  const classes = useStyles({
    disableAnimation,
    animationDuration,
    animationDelay,
    fontSize,
  });

  return <div className={classes.logoText}>ELECTRYONE AI</div>;
}
