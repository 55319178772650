import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Vendor } from "../../../__generated__/types/Vendor";
import { VendorAccount } from "../../../__generated__/types/VendorAccount";
import { VendorAccountEnrollmentSession } from "../../../__generated__/types/VendorAccountEnrollmentSession";
import { useAuthHeaders } from "../common/authentication/authentication";

const VENDOR_URL = "/api/asset/vendor/";
const VENDOR_ACCOUNT_URL = "/api/asset/vendor/account/";
const ENROLLMENT_URL = "/api/asset/vendor/account/enrollment-session/";

interface IVendorAccountContext {
  vendors: Vendor[];
  totalVendors: number;
  vendorAccounts: VendorAccount[];
  totalVendorAccounts: number;
  fetchVendor: (vendorId: number) => void;
  fetchVendors: (params?: {
    params: URLSearchParams;
  }) => Promise<{ rows: Vendor[]; count: number }>;
  fetchVendorAccount: (vendorAccountId: number) => void;
  fetchVendorAccounts: (params?: {
    params: URLSearchParams;
  }) => Promise<{ rows: VendorAccount[]; count: number }>;
  createEnrollmentSession: (
    vendorId: number,
    formData: any
  ) => Promise<VendorAccountEnrollmentSession | null>;
}

const VendorAccountContext = createContext<IVendorAccountContext | undefined>(
  undefined
);

interface IVendorAccountProviderProps {
  children: ReactNode;
}

export const VendorAccountProvider: React.FC<IVendorAccountProviderProps> = ({
  children,
}) => {
  const headers = useAuthHeaders();

  const [totalVendors, setTotalVendors] = useState(
    parseInt(localStorage.getItem("@CACHED_NUMBER_OF_VENDORS") ?? "0")
  );
  const [totalVendorAccounts, setTotalVendorAccounts] = useState(
    parseInt(localStorage.getItem("@CACHED_NUMBER_OF_VENDOR_ACCOUNTS") ?? "0")
  );

  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [isFetchingAllVendors, setIsFetchingAllVendors] =
    useState<boolean>(false);
  const [isFetchingVendor, setIsFetchingVendor] = useState<{
    [key: number]: boolean;
  } | null>(null);

  const [vendorAccounts, setVendorAccounts] = useState<VendorAccount[]>([]);
  const [isFetchingAllVendorAccounts, setIsFetchingAllVendorAccounts] =
    useState<boolean>(false);
  const [isFetchingVendorAccount, setIsFetchingVendorAccount] = useState<{
    [key: number]: boolean;
  } | null>(null);

  const fetchVendor = useCallback(
    (vendorId: number) => {
      async function fetchVendorAsync() {
        const filteredVendors = vendors.filter(
          (vendor) => vendor.id === vendorId
        );

        if (filteredVendors.length === 1) {
          return filteredVendors[0];
        } else {
          if (
            isFetchingVendor === null ||
            isFetchingVendor[vendorId] !== true
          ) {
            setIsFetchingVendor((prev) => {
              return { ...(prev ?? undefined), [vendorId]: true };
            });
            if (vendorId) {
              const response = await fetch(`${VENDOR_URL}${vendorId}/`, {
                headers,
              });

              const vendor = (await response.json()) as Vendor;

              setVendors((prevVendors) => {
                return [
                  ...prevVendors.filter((c) => c.id !== vendorId),
                  vendor,
                ];
              });
            }

            setIsFetchingVendor((prev) => {
              return { ...(prev ?? undefined), [vendorId]: false };
            });
          }
        }
      }

      fetchVendorAsync();
    },

    [vendors, headers, isFetchingVendor]
  );

  const fetchAllVendors = useCallback(() => {
    async function fetchAllVendorsAsync() {
      setIsFetchingAllVendors(true);
      const response = await fetch(VENDOR_URL, { headers });
      const data = await response.json();
      if (response.ok) {
        setVendors(data.results as Vendor[]);
      } else {
        setVendors([]);
      }

      localStorage.setItem("@CACHED_NUMBER_OF_VENDORS", data.count);
      setTotalVendors(data.count);

      setIsFetchingAllVendors(false);
    }
    if (!isFetchingAllVendors) {
      fetchAllVendorsAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, totalVendors]); // don't add isFetchingAllVendors

  const fetchVendors = useCallback(
    async ({ params }: { params?: URLSearchParams } = {}): Promise<{
      rows: Vendor[];
      count: number;
    }> => {
      // Check if we can use the cached vendors
      if (vendors && vendors.length > 0 && params) {
        const offset = params.get("offset");
        const limit = params.get("limit");
        const paramCount = Array.from(params.keys()).length;

        if (
          offset !== null &&
          limit &&
          !isNaN(parseInt(offset)) &&
          !isNaN(parseInt(limit)) &&
          paramCount === 2
        ) {
          const offsetNum = parseInt(offset);
          const limitNum = parseInt(limit);

          // Use cached vendors if offset is within range
          if (offsetNum < vendors.length) {
            return {
              rows: vendors.slice(
                offsetNum,
                Math.min(offsetNum + limitNum, vendors.length)
              ) as Vendor[],
              count: totalVendors,
            };
          }
        }
      }

      // If we can't use cached vendors, proceed with the API fetch
      try {
        const queryParams = params || new URLSearchParams();
        const response = await fetch(
          `${VENDOR_URL}?${queryParams.toString()}`,
          {
            headers,
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        setVendors((prevVendors) => {
          const prevVendorsFiltered = prevVendors.filter(
            (vendor) =>
              !data.results.map((c: Vendor) => c.id).includes(vendor.id)
          );
          return [...prevVendorsFiltered, ...data.results];
        });

        return {
          rows: data.results as Vendor[],
          count: data.count,
        };
      } catch (error) {
        console.error(error);
        return { rows: [], count: 0 };
      }
    },
    [vendors, headers, totalVendors]
  );

  const fetchVendorAccount = useCallback(
    (vendorAccountId: number) => {
      async function fetchVendorAccountAsync() {
        const filteredVendorAccounts = vendorAccounts.filter(
          (vendorAccount) => vendorAccount.id === vendorAccountId
        );

        if (filteredVendorAccounts.length === 1) {
          return filteredVendorAccounts[0];
        } else {
          if (
            isFetchingVendorAccount === null ||
            isFetchingVendorAccount[vendorAccountId] !== true
          ) {
            setIsFetchingVendorAccount((prev) => {
              return { ...(prev ?? undefined), [vendorAccountId]: true };
            });
            if (vendorAccountId) {
              const response = await fetch(
                `${VENDOR_ACCOUNT_URL}${vendorAccountId}/`,
                {
                  headers,
                }
              );

              const vendorAccount = (await response.json()) as VendorAccount;

              setVendorAccounts((prevVendorAccounts) => {
                return [
                  ...prevVendorAccounts.filter((c) => c.id !== vendorAccountId),
                  vendorAccount,
                ];
              });
            }

            setIsFetchingVendorAccount((prev) => {
              return { ...(prev ?? undefined), [vendorAccountId]: false };
            });
          }
        }
      }

      fetchVendorAccountAsync();
    },

    [vendorAccounts, headers, isFetchingVendorAccount]
  );

  const fetchAllVendorAccounts = useCallback(() => {
    async function fetchAllVendorAccountsAsync() {
      setIsFetchingAllVendorAccounts(true);
      const response = await fetch(VENDOR_ACCOUNT_URL, { headers });
      const data = await response.json();
      if (response.ok) {
        setVendorAccounts(data.results as VendorAccount[]);
      } else {
        setVendorAccounts([]);
      }

      localStorage.setItem("@CACHED_NUMBER_OF_VENDOR_ACCOUNTS", data.count);
      setTotalVendorAccounts(data.count);

      setIsFetchingAllVendorAccounts(false);
    }
    if (!isFetchingAllVendorAccounts) {
      fetchAllVendorAccountsAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, totalVendorAccounts]); // don't add isFetchingAllVendorAccounts

  const fetchVendorAccounts = useCallback(
    async ({ params }: { params?: URLSearchParams } = {}): Promise<{
      rows: VendorAccount[];
      count: number;
    }> => {
      // Check if we can use the cached vendorAccounts
      if (vendorAccounts && vendorAccounts.length > 0 && params) {
        const offset = params.get("offset");
        const limit = params.get("limit");
        const paramCount = Array.from(params.keys()).length;

        if (
          offset !== null &&
          limit &&
          !isNaN(parseInt(offset)) &&
          !isNaN(parseInt(limit)) &&
          paramCount === 2
        ) {
          const offsetNum = parseInt(offset);
          const limitNum = parseInt(limit);

          // Use cached vendorAccounts if offset is within range
          if (offsetNum < vendorAccounts.length) {
            return {
              rows: vendorAccounts.slice(
                offsetNum,
                Math.min(offsetNum + limitNum, vendorAccounts.length)
              ) as VendorAccount[],
              count: totalVendorAccounts,
            };
          }
        }
      }

      // If we can't use cached vendorAccounts, proceed with the API fetch
      try {
        const queryParams = params || new URLSearchParams();
        const response = await fetch(
          `${VENDOR_ACCOUNT_URL}?${queryParams.toString()}`,
          {
            headers,
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        setVendorAccounts((prevVendorAccounts) => {
          const prevVendorAccountsFiltered = prevVendorAccounts.filter(
            (vendorAccount) =>
              !data.results
                .map((c: VendorAccount) => c.id)
                .includes(vendorAccount.id)
          );
          return [...prevVendorAccountsFiltered, ...data.results];
        });

        return {
          rows: data.results as VendorAccount[],
          count: data.count,
        };
      } catch (error) {
        console.error(error);
        return { rows: [], count: 0 };
      }
    },
    [vendorAccounts, headers, totalVendorAccounts]
  );

  const getErrorMessageHTML = useCallback(async (response: any) => {
    try {
      const text = await response.text();
      const match = text.match(/<pre class="exception_value">(.+?)<\/pre>/);
      if (match && match[1]) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          `<!doctype html><body>${match[1]}`,
          "text/html"
        );
        const decodedString = doc.body.textContent || "";
        const filteredMessage = decodedString.replace(/[^a-zA-Z\s]/g, "");
        return filteredMessage;
      }
      return "An error has occurred.";
    } catch (error) {
      return "An error has occurred.";
    }
  }, []);

  const createEnrollmentSession = useCallback(
    async (
      vendorId: number,
      formData: any
    ): Promise<VendorAccountEnrollmentSession | null> => {
      let result = null;
      try {
        const response = await fetch(ENROLLMENT_URL, {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            vendor: vendorId,
            form_data: formData,
          }),
        });

        if (response.ok) {
          result = (await response.json()) as VendorAccountEnrollmentSession;
        } else {
          throw new Error(await getErrorMessageHTML(response));
        }
      } catch (error) {
        console.error(
          error instanceof Error ? error.message : "An error has occurred."
        );
        result = null;
      }
      return result;
    },
    [headers, getErrorMessageHTML]
  );

  useEffect(() => {
    fetchAllVendors();
    fetchAllVendorAccounts();
  }, [fetchAllVendors, fetchAllVendorAccounts, headers]);

  return (
    <VendorAccountContext.Provider
      value={{
        vendors,
        totalVendors,
        vendorAccounts,
        totalVendorAccounts,
        fetchVendor,
        fetchVendors,
        fetchVendorAccount,
        fetchVendorAccounts,
        createEnrollmentSession,
      }}
    >
      {children}
    </VendorAccountContext.Provider>
  );
};

export function useVendorAccounts(): IVendorAccountContext {
  const context = useContext(VendorAccountContext);
  if (context === undefined) {
    throw new Error(
      "useVendorAccounts must be used within an VendorAccountProvider"
    );
  }
  return context;
}

export function useVendorAccount(vendorAccountId: number) {
  const { vendorAccounts, fetchVendorAccount } = useVendorAccounts();

  const [vendorAccount, setVendorAccount] = useState<VendorAccount | null>(
    null
  );

  useEffect(() => {
    const filteredVendorAccounts = vendorAccounts.filter(
      (c) => c.id === vendorAccountId
    );
    if (filteredVendorAccounts.length > 0) {
      setVendorAccount(filteredVendorAccounts[0]);
    } else {
      setVendorAccount(null);
    }
  }, [vendorAccountId, vendorAccounts]);

  useEffect(() => {
    if (vendorAccountId) {
      fetchVendorAccount(vendorAccountId);
    }
  }, [fetchVendorAccount, vendorAccountId]);

  return {
    vendorAccount,
    fetchVendorAccount,
  };
}
