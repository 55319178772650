import { Button } from "@blueprintjs/core";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PlatformCampaignCreationDialog from "./PlatformCampaignCreationDialog.react";
import PlatformCampaignDetailView from "./PlatformCampaignDetailView.react";
import { useCreateCampaign } from "./campaign";

export default function PlatformCampaigns() {
  const [isOpen, setIsOpen] = useState(true);

  const {
    createNewCampaign,
    campaign,
    isCreatingCampaign,
    isProcessing,
    isReady,
  } = useCreateCampaign();

  const { campaignID } = useParams();

  const navigate = useNavigate();

  function handleCreateCampaign() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  // TODO: SEPARATE LSIT VIEW AND HOOKS INTO SEPARATE COMPONENT FOR EFFICIENCY

  if (campaignID) {
    return <PlatformCampaignDetailView campaignID={parseInt(campaignID)} />;
  }

  if (isReady) {
    console.log("REDIRECTING");
    navigate(`/campaigns/${campaign?.id}`);
  }
  return (
    <div>
      <PlatformCampaignCreationDialog
        isOpen={isOpen}
        onClose={handleClose}
        isCreatingCampaign={isCreatingCampaign}
        createNewCampaign={createNewCampaign}
        campaignID={campaign?.id ?? null}
        isProcessing={isProcessing}
      />
      <Button icon="add" onClick={handleCreateCampaign}>
        New Campaign
      </Button>
    </div>
  );
}
