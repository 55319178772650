import { PlatformPropertyAddress } from "@ec1/types/PlatformPropertyAddress";
import { PropertyTariffMapping } from "@ec1/types/PropertyTariffMapping";
import { DateTime } from "luxon";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { PlatformProperty } from "../../../__generated__/types/PlatformProperty";
import { useAuthHeaders } from "../common/authentication/authentication";

const URL = "/api/properties/";
const TARIFF_MAPPINGS_URL = "/api/tariffs/property-mapping/";

export interface PropertyData {
  id?: number;
  property_uid: string | null;
  customer: number | null;
  local_timezone: string | null;
  address: PlatformPropertyAddress | null;
}

interface IPropertyContext {
  properties: PlatformProperty[];
  totalProperties: number;
  fetchProperty: (propertyId: number) => void;
  fetchProperties: (params?: {
    params: URLSearchParams;
  }) => Promise<{ rows: PlatformProperty[]; count: number }>;
  fetchAllProperties: () => void;
  assignCustomerToProperty: (
    propertyId: number,
    customerId: number | null
  ) => Promise<{ success: boolean; result: string | null }>;
  addProperty: (property: PropertyData) => Promise<number | null>;
  editProperty: (property: PropertyData) => Promise<number | null>;
  deleteProperty: (
    propertyId: number
  ) => Promise<{ success: boolean; error: string }>;
  assignPropertyTariff: (
    propertyTariffMapping: PropertyTariffMapping
  ) => Promise<{ success: boolean; error: string }>;
  deletePropertyTariff: (
    propertyTariffMapping: PropertyTariffMapping
  ) => Promise<{ success: boolean; error: string }>;
}

const PropertyContext = createContext<IPropertyContext | undefined>(undefined);

interface IPropertyProviderProps {
  children: ReactNode;
}

export const PropertyProvider: React.FC<IPropertyProviderProps> = ({
  children,
}) => {
  const [totalProperties, setTotalProperties] = useState(
    parseInt(localStorage.getItem("@CACHED_NUMBER_OF_PROPERTIES") ?? "0")
  );
  const headers = useAuthHeaders();

  const [properties, setProperties] = useState<PlatformProperty[]>([]);
  const [isFetchingAllProperties, setIsFetchingAllProperties] =
    useState<boolean>(false);

  const [isFetchingProperty, setIsFetchingProperty] = useState<{
    [key: number]: boolean;
  } | null>(null);

  const fetchProperty = useCallback(
    (propertyId: number) => {
      async function fetchPropertyAsync() {
        const filteredProperties = properties.filter(
          (p) => p.id === propertyId
        );

        if (filteredProperties.length === 1) {
          return filteredProperties[0];
        } else {
          if (
            isFetchingProperty === null ||
            isFetchingProperty[propertyId] !== true
          ) {
            setIsFetchingProperty((prev) => {
              return { ...(prev ?? undefined), [propertyId]: true };
            });
            if (propertyId) {
              const response = await fetch(`${URL}${propertyId}/`, {
                headers,
              });

              const property = (await response.json()) as PlatformProperty;

              setProperties((prevProperties) => {
                return [
                  ...prevProperties.filter((c) => c.id !== propertyId),
                  property,
                ];
              });
            }

            setIsFetchingProperty((prev) => {
              return { ...(prev ?? undefined), [propertyId]: false };
            });
          }
        }
      }

      fetchPropertyAsync();
    },

    [properties, headers, isFetchingProperty]
  );

  const fetchAllProperties = useCallback(() => {
    async function fetchAllPropertiesAsync() {
      setIsFetchingAllProperties(true);
      const response = await fetch(URL, { headers });
      const data = await response.json();
      if (response.ok) {
        setProperties(data.results as PlatformProperty[]);
      } else {
        setProperties([]);
      }

      localStorage.setItem("@CACHED_NUMBER_OF_PROPERTIES", data.count);
      setTotalProperties(data.count);

      setIsFetchingAllProperties(false);
    }
    if (!isFetchingAllProperties) {
      fetchAllPropertiesAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, totalProperties]); // don't add isFetchingAllProperties

  const fetchProperties = useCallback(
    async ({ params }: { params?: URLSearchParams } = {}): Promise<{
      rows: PlatformProperty[];
      count: number;
    }> => {
      // Check if we can use the cached properties
      if (properties && properties.length > 0 && params) {
        const offset = params.get("offset");
        const limit = params.get("limit");
        const paramCount = Array.from(params.keys()).length;

        if (
          offset !== null &&
          limit &&
          !isNaN(parseInt(offset)) &&
          !isNaN(parseInt(limit)) &&
          paramCount === 2
        ) {
          const offsetNum = parseInt(offset);
          const limitNum = parseInt(limit);

          // Use cached properties if offset is within range
          if (offsetNum < properties.length) {
            return {
              rows: properties.slice(
                offsetNum,
                Math.min(offsetNum + limitNum, properties.length)
              ) as PlatformProperty[],
              count: totalProperties,
            };
          }
        }
      }

      // If we can't use cached properties, proceed with the API fetch
      try {
        const queryParams = params || new URLSearchParams();
        const response = await fetch(`${URL}?${queryParams.toString()}`, {
          headers,
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        setProperties((prevProperties) => {
          const prevPropertiesFiltered = prevProperties.filter(
            (property) =>
              !data.results
                .map((c: PlatformProperty) => c.id)
                .includes(property.id)
          );
          return [...prevPropertiesFiltered, ...data.results];
        });

        return {
          rows: data.results as PlatformProperty[],
          count: data.count,
        };
      } catch (error) {
        console.error(error);
        return { rows: [], count: 0 };
      }
    },
    [properties, headers, totalProperties]
  );

  const assignCustomerToProperty = async (
    propertyId: number,
    customerId: number | null
  ): Promise<{ success: boolean; result: string | null }> => {
    try {
      const response = await fetch(`${URL}${propertyId}/assign-customer/`, {
        headers,
        method: "POST",
        body: JSON.stringify({ customer_id: customerId }),
      });

      if (!response.ok) {
        const data = await response.json();
        // Update the property in the context
        setProperties((prevProperties) => {
          if (prevProperties) {
            return prevProperties.map((p) =>
              p.id === propertyId
                ? { ...p, customer: customerId ?? undefined }
                : p
            );
          }
          return prevProperties;
        });
        return { success: false, result: data.customer };
      }

      return { success: true, result: null };
    } catch (error) {
      console.error(error);
      return { success: false, result: error as string };
    }
  };

  const getErrorMessageHTML = useCallback(async (response: any) => {
    try {
      const text = await response.text();
      const match = text.match(/<pre class="exception_value">(.+?)<\/pre>/);
      if (match && match[1]) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          `<!doctype html><body>${match[1]}`,
          "text/html"
        );
        const decodedString = doc.body.textContent || "";
        const filteredMessage = decodedString.replace(/[^a-zA-Z\s]/g, "");
        return filteredMessage;
      }
      return "An error has occurred.";
    } catch (error) {
      return "An error has occurred.";
    }
  }, []);

  const addProperty = useCallback(
    async (property: PropertyData): Promise<number | null> => {
      let result = null;
      try {
        const response = await fetch(URL, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(property),
        });

        if (response.ok) {
          const data = (await response.json()) as PlatformProperty;
          result = data.id ?? null;
          // Update the properties state after successful addition
          setProperties((prevProperties) => [...prevProperties, data]);
          // Update the total properties count
          setTotalProperties((prevTotal) => prevTotal + 1);
        } else {
          throw new Error(await getErrorMessageHTML(response));
        }
      } catch (error) {
        console.error(
          error instanceof Error ? error.message : "An error has occurred."
        );
        result = null;
      }
      return result;
    },
    [headers, getErrorMessageHTML]
  );

  const editProperty = useCallback(
    async (property: PropertyData): Promise<number | null> => {
      let result = null;
      try {
        const response = await fetch(`${URL}${property.id}/`, {
          headers,
          method: "PATCH",
          body: JSON.stringify(property),
        });

        if (response.ok) {
          const data = (await response.json()) as PlatformProperty;
          result = data.id ?? null;
          // Update the property in the context
          setProperties((prevProperties) => {
            if (prevProperties) {
              return prevProperties.map((p) =>
                p.id === data.id ? { ...p, ...data } : p
              );
            }
            return prevProperties;
          });
        } else {
          throw new Error(await getErrorMessageHTML(response));
        }
      } catch (error) {
        console.error(
          error instanceof Error ? error.message : "An error has occurred."
        );
        result = null;
      }
      return result;
    },
    [headers, getErrorMessageHTML]
  );

  const deleteProperty = useCallback(
    async (
      propertyId: number
    ): Promise<{ success: boolean; error: string }> => {
      try {
        const response = await fetch(`${URL}${propertyId}/`, {
          headers,
          method: "DELETE",
        });
        if (response.ok) {
          // Update the properties state after successful deletion
          setProperties((prevProperties) =>
            prevProperties.filter((p) => p.id !== propertyId)
          );
          // Update the total properties count
          setTotalProperties((prevTotal) => prevTotal - 1);
          return { success: true, error: "" };
        }
        throw new Error(await getErrorMessageHTML(response));
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          return {
            success: false,
            error: error.message || "An error has occurred.",
          };
        } else {
          console.error("An error has occurred.");
          return { success: false, error: "An error has occurred." };
        }
      }
    },
    [headers, getErrorMessageHTML]
  );

  const assignPropertyTariff = useCallback(
    async (
      propertyTariffMapping: PropertyTariffMapping
    ): Promise<{ success: boolean; error: string }> => {
      try {
        const response = await fetch(`${TARIFF_MAPPINGS_URL}`, {
          headers,
          method: "POST",
          body: JSON.stringify(propertyTariffMapping),
        });
        if (response.ok) {
          return { success: true, error: "" };
        }
        throw new Error(await getErrorMessageHTML(response));
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          return {
            success: false,
            error: error.message || "An error has occurred.",
          };
        } else {
          console.error("An error has occurred.");
          return { success: false, error: "An error has occurred." };
        }
      }
    },
    [headers, getErrorMessageHTML]
  );

  const deletePropertyTariff = useCallback(
    async (
      propertyTariffMapping: PropertyTariffMapping
    ): Promise<{ success: boolean; error: string }> => {
      try {
        const response = await fetch(
          `${TARIFF_MAPPINGS_URL}${propertyTariffMapping.id}/`,
          {
            headers,
            method: "DELETE",
          }
        );
        if (response.ok) {
          return { success: true, error: "" };
        }
        throw new Error(await getErrorMessageHTML(response));
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          return {
            success: false,
            error:
              error.message || "Tariff assignment failed. Please try again.",
          };
        } else {
          console.error("Tariff assignment failed. Please try again.");
          return {
            success: false,
            error: "Tariff assignment failed. Please try again.",
          };
        }
      }
    },
    [headers, getErrorMessageHTML]
  );

  useEffect(() => {
    fetchAllProperties();
  }, [fetchAllProperties, headers]);

  return (
    <PropertyContext.Provider
      value={{
        properties,
        totalProperties,
        fetchProperty,
        fetchProperties,
        fetchAllProperties,
        assignCustomerToProperty,
        addProperty,
        editProperty,
        deleteProperty,
        assignPropertyTariff,
        deletePropertyTariff,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

export function useProperties(): IPropertyContext {
  const context = useContext(PropertyContext);
  if (context === undefined) {
    throw new Error("useProperties must be used within an PropertyProvider");
  }
  return context;
}

export function useProperty(propertyId: number) {
  const { properties, fetchProperty, deleteProperty, deletePropertyTariff } =
    useProperties();
  const headers = useAuthHeaders();

  const [property, setProperty] = useState<PlatformProperty | null>(null);
  const [address, setAddress] = useState<PlatformPropertyAddress | null>(null);

  useEffect(() => {
    const filteredProperties = properties.filter((c) => c.id === propertyId);
    if (filteredProperties.length > 0) {
      setProperty(filteredProperties[0]);
    } else {
      setProperty(null);
    }
  }, [propertyId, properties]);

  useEffect(() => {
    if (propertyId) {
      fetchProperty(propertyId);
    }
  }, [fetchProperty, propertyId]);

  const fetchAddress = useCallback(
    async (addressId: number) => {
      try {
        const response = await fetch(`/api/address/${addressId}/`, { headers });
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        if (data) {
          setAddress(data as PlatformPropertyAddress);
        }
      } catch (error) {
        console.error("Failed to fetch address:", error);
        setAddress(null);
      }
    },
    [headers]
  );

  useEffect(() => {
    if (property && property.address) {
      fetchAddress(property.address);
    } else {
      setAddress(null);
    }
  }, [property, fetchAddress]);

  return {
    property,
    address,
    fetchProperty,
    deleteProperty,
    deletePropertyTariff,
  };
}

export function usePropertyTariffMappings(propertyId: number) {
  const headers = useAuthHeaders();

  const [propertyTariffMappings, setPropertyTariffMappings] = useState<
    null | PropertyTariffMapping[]
  >(null);

  const fetchPropertyTariffs = useCallback(async (): Promise<void> => {
    try {
      const response = await fetch(
        `${TARIFF_MAPPINGS_URL}?property=${propertyId}`,
        {
          headers,
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();

      setPropertyTariffMappings(data["results"] as PropertyTariffMapping[]);
    } catch (error) {
      console.error(error);
    }
  }, [headers, propertyId]);

  useEffect(() => {
    fetchPropertyTariffs();
  }, [fetchPropertyTariffs, propertyId]);

  return { propertyTariffMappings, fetchPropertyTariffs };
}

export function usePropertyTimezone(propertyId: number | null | undefined) {
  const { properties, fetchProperty } = useProperties();

  const [timezone, setTimezone] = useState<string | undefined>(undefined); // Undefined to play nice with Highcharts defaults
  const [timezoneLabel, setTimezoneLabel] = useState<string>("Local");
  const [isTimezoneLoaded, setIsTimezoneLoaded] = useState(false);

  useEffect(() => {
    const filteredProperties = properties.filter((c) => c.id === propertyId);
    if (filteredProperties.length > 0) {
      const propertyTimezone =
        filteredProperties[0].local_timezone ?? undefined;
      setTimezone(propertyTimezone);

      // Calculate the timezone offset with GMT prefix
      const offsetHours =
        DateTime.now().setZone(propertyTimezone || "system").offset / 60;
      const gmtOffset =
        offsetHours === 0
          ? "GMT"
          : `GMT${offsetHours > 0 ? "+" : ""}${Math.abs(offsetHours)}`;
      const timezoneName = propertyTimezone || "Local";
      setTimezoneLabel(`${timezoneName} (${gmtOffset})`);
      setIsTimezoneLoaded(true);
    }
  }, [propertyId, properties]);

  useEffect(() => {
    if (propertyId) {
      fetchProperty(propertyId);
    }
  }, [fetchProperty, propertyId]);

  return { timezone, timezoneLabel, isTimezoneLoaded };
}
