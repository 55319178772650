import {
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
} from "@blueprintjs/core";
import { useCanvasWithDataset, useFetchRecentCanvases } from "../canvas/canvas";

type Props = {
  uuid: string;
  children: any;
};

export default function PlatformAssistantMessageExportWrapper({
  uuid,
  children,
}: Props) {
  const { canvases } = useFetchRecentCanvases();
  const { createCanvasWithDataset, addDatasetToCanvas, isCreatingDataset } =
    useCanvasWithDataset();

  const OpenWithMenu = ({ args }: { args: string }) => (
    <Menu>
      <MenuItem
        text="Open with composer"
        icon="document-open"
        onClick={() => window.open("/composer/" + args, "_blank")}
      />
      <MenuItem text="Open with canvas" icon="page-layout">
        <MenuItem
          disabled={isCreatingDataset}
          icon="insert"
          text="Create new canvas"
          onClick={() => {
            createCanvasWithDataset(uuid).then((obj: any) => {
              console.log(obj);
              window.open(`/canvas/${obj?.id}`, "_blank");
            });
          }}
        />
        {canvases === null || canvases.length > 0 ? <MenuDivider /> : null}
        {canvases === null ? (
          <MenuItem icon="control" text="Loading Recent..." />
        ) : (
          <>
            {canvases.map((c) => (
              <MenuItem
                disabled={isCreatingDataset}
                icon="control"
                text={c.title}
                onClick={() => {
                  addDatasetToCanvas(c.id, uuid).then((obj: any) => {
                    console.log(obj);
                    window.open(`/canvas/${obj?.id}`, "_blank");
                  });
                }}
              />
            ))}
          </>
        )}
      </MenuItem>
      <MenuItem
        text="Open with builder"
        onClick={() => {
          window.open("/builder/?dataset=" + uuid, "_blank");
        }}
        icon="code"
      ></MenuItem>
    </Menu>
  );

  return (
    <div
      style={{
        display: "flex",
        marginTop: "16px",
      }}
    >
      <div style={{ minHeight: "100px", overflow: "scroll", flex: 1 }}>
        {children}
      </div>
      <div
        style={{
          marginLeft: "8px",
          //backgroundColor: "red",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Popover
          content={<OpenWithMenu args={`?create_with_dataset=${uuid}`} />}
          placement="right-start"
        >
          <Button icon="add-to-artifact" style={{ outline: "none" }} />
        </Popover>
      </div>
    </div>
  );
}
