/*
  const renewToken = useCallback(async () => {
    if (tokens && tokens.refresh) {
      try {
        const response = await fetch("/api/jwt/refresh/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refresh: tokens.refresh }),
        });

        if (!response.ok) throw new Error(await getErrorMessageJson(response));

        const data = await response.json();
        setTokens({
          access: data.access,
          refresh: data.refresh,
        });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          setAuthError(error.message || "An error has occurred.");
        } else {
          console.error("An error has occurred.");
          setAuthError("An error has occurred.");
        }
        logout();
      }
    }
  }, [tokens, logout]); */

const REFRESH_API_ENDPOINT = "/api/jwt/refresh/";

export async function refreshAuthenticationTokens(refreshToken: string) {
  const response = await fetch(REFRESH_API_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refresh: refreshToken }),
  });

  if (!response.ok) {
    throw new Error(JSON.stringify(await response.text()));
  }

  return (await response.json()) as { access: string; refresh: string };
}
