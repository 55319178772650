import {
  Button,
  Icon,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Slider,
  Spinner,
  Switch,
  Tab,
  Tabs,
} from "@blueprintjs/core";
import { PropertyTariffMapping } from "@ec1/types/PropertyTariffMapping";
import { Clear, Edit, Save } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CiExport, CiImport } from "react-icons/ci";
import { GiPriceTag } from "react-icons/gi";
import { MdHomeFilled } from "react-icons/md";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { GateKeeper } from "src/gk/__generated__/gatekeepers";
import useGatekeeper from "src/gk/useGatekeeper";
import { Ec1Toaster } from "src/ui/components/Ec1Toaster.react";
import { PropertyDailyMetrics } from "../../../__generated__/types/PropertyDailyMetrics";
import { useFetch } from "../../../common/fetcher/effects";
import SpinningLogo from "../../../ui/logo/SpinningLogo.react";
import PlatformPropertyAssignWidget from "../properties/PlatformPropertyAssignWidget.react";
import { getCurrencySymbol } from "../properties/tariffs";
import { useDevice } from "./devices";
import BatteryGraph from "./grid/BatteryGraph.react";
import PlatformBatteryChargingScheduleChart from "./PlatformBatteryChargingScheduleChart.react";
import PlatformBatteryMetricsBatteryPowerChart from "./PlatformBatteryMetricsBatteryPowerChart.react";
import PlatformBatteryMetricsGridPowerChart from "./PlatformBatteryMetricsGridPowerChart.react";
import PlatformBatteryMetricsHomePowerChart from "./PlatformBatteryMetricsHomePowerChart.react";
import PlatformBatteryMetricsSolarPowerChart from "./PlatformBatteryMetricsSolarPowerChart.react";
import PlatformDeviceDetailViewHeader from "./PlatformDeviceDetailViewHeader.react";
import PlatformPropertySavingsBarChartWidget from "./PlatformPropertySavingsBarChartWidget.react";
import PlatformSiteDeviceDetailViewMetricsCard from "./PlatformSiteDeviceDetailViewMetricsCard.react";

const useStyles = createUseStyles({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(440px, 1fr))",
    gap: "16px",
    marginTop: "24px",
  },
  validationError: {
    color: "#f17575",
    height: 10,
    marginTop: -20,
    marginLeft: 115,
    fontSize: 12,
  },
  validationErrorList: {
    color: "#f17575",
    marginTop: -5,
    marginLeft: 113,
    fontSize: 12,
  },
  slider: {
    "& .bp5-slider-progress": {
      background: "#343b44",
    },
    "& .bp5-slider-progress.bp5-intent-primary": {
      backgroundColor: "#2d72d2",
    },
  },
});

export default function PlatformSiteDeviceDetailViewPage() {
  const classes = useStyles();

  const { deviceId } = useParams<{ deviceId: string }>();
  const parsedDeviceId = parseInt(deviceId ?? "");

  const canViewCostComparison = useGatekeeper(
    GateKeeper.CAN_VIEW_COST_COMPARISON
  );

  const { device, fetchDevice, patchDevice } = useDevice(parsedDeviceId);

  const [selectedProperty, setSelectedProperty] = useState(
    device?.assigned_property
  );

  const [smartCharging, setSmartCharging] = useState(false);
  const [isSavingSmartCharging, setIsSavingSmartCharging] = useState(false);

  const [capacityValue, setCapacityValue] = useState("");
  const [capacityUnit, setCapacityUnit] = useState("");
  const [capacityIntent, setCapacityIntent] = useState<Intent>(Intent.NONE);
  const [isEditingCapacity, setIsEditingCapacity] = useState(false);
  const [isSavingCapacity, setIsSavingCapacity] = useState(false);

  const [solarValue, setSolarValue] = useState("");
  const [solarUnit, setSolarUnit] = useState("");
  const [solarIntent, setSolarIntent] = useState<Intent>(Intent.NONE);
  const [isEditingSolar, setIsEditingSolar] = useState(false);
  const [isSavingSolar, setIsSavingSolar] = useState(false);

  const [backupSoc, setBackupSoc] = useState(0);
  const [isSavingBackupSoc, setIsSavingBackupSoc] = useState(false);

  const [syncLocation, setSyncLocation] = useState(false);
  const [isSavingSyncLocation, setIsSavingSyncLocation] = useState(false);

  const [syncSolar, setSyncSolar] = useState(false);
  const [isSavingSyncSolar, setIsSavingSyncSolar] = useState(false);

  const [syncCapacity, setSyncCapacity] = useState(false);
  const [isSavingSyncCapacity, setIsSavingSyncCapacity] = useState(false);

  const [selectedTab, setSelectedTab] = useState<string>("charging_mode");

  const [propertyChangeCounter, setPropertyChangeCounter] = useState(0);

  useEffect(() => {
    setPropertyChangeCounter((prev) => prev + 1);
  }, [device?.assigned_property]);

  const isPatchInProgress = useMemo(() => {
    return (
      isSavingSmartCharging ||
      isSavingBackupSoc ||
      isSavingSyncLocation ||
      isSavingSyncSolar ||
      isSavingSyncCapacity ||
      isSavingCapacity ||
      isSavingSolar
    );
  }, [
    isSavingSmartCharging,
    isSavingBackupSoc,
    isSavingSyncLocation,
    isSavingSyncSolar,
    isSavingSyncCapacity,
    isSavingCapacity,
    isSavingSolar,
  ]);

  const setActiveTab = (tabId: string) => {
    setSelectedTab(tabId);
  };

  const { data: propertyDailyMetricsDataToday } = useFetch<{
    results: PropertyDailyMetrics[];
  }>(
    selectedProperty
      ? `/api/property/daily-metrics/?limit=1&property=${selectedProperty}`
      : null,
    { useCache: true }
  );

  const showToast = useCallback(
    (
      message: string,
      intent: Intent,
      buttonText?: string,
      buttonOnClick?: () => void
    ) => {
      Ec1Toaster.show({
        timeout: 5000,
        message: (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 18,
                fontFamily: "Barlow",
              }}
            >
              <div style={{ marginRight: 15, height: 20 }}>{message}</div>
              {buttonText && (
                <Button
                  intent="primary"
                  text={buttonText}
                  onClick={buttonOnClick}
                  style={{
                    height: 20,
                    padding: "0 5px",
                    minHeight: "unset",
                    minWidth: "unset",
                    fontSize: 12,
                  }}
                />
              )}
            </div>
          </>
        ),
        intent: intent,
      });
    },
    []
  );

  // Auto-refreshing device data every minute
  useEffect(() => {
    const interval = setInterval(() => {
      fetchDevice(parsedDeviceId);
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [parsedDeviceId, fetchDevice]);

  const setCapacity = useCallback((capacity: number | undefined) => {
    if (capacity) {
      let formattedUnit = "Wh";
      let formattedCapacity = capacity;

      if (capacity >= 1000000) {
        formattedCapacity = +(capacity / 1000000).toFixed(3);
        formattedUnit = "MWh";
      } else if (capacity >= 1000) {
        formattedCapacity = +(capacity / 1000).toFixed(3);
        formattedUnit = "kWh";
      }

      setCapacityValue(formattedCapacity.toString());
      setCapacityUnit(formattedUnit);
    } else {
      setCapacityValue("");
      setCapacityUnit("");
    }
  }, []);

  useEffect(() => {
    if (device?.battery_capacity_in_wh !== undefined) {
      setCapacity(device?.battery_capacity_in_wh);
    }
  }, [device?.battery_capacity_in_wh, setCapacity]);

  const setSolar = useCallback((size: number | undefined) => {
    if (size) {
      let formattedUnit = "kW";
      let formattedCapacity = size;

      if (size >= 1000) {
        formattedCapacity = +(size / 1000).toFixed(3);
        formattedUnit = "MW";
      } else if (size < 1) {
        formattedCapacity = +(size * 1000).toFixed(3);
        formattedUnit = "W";
      }

      setSolarValue(formattedCapacity.toString());
      setSolarUnit(formattedUnit);
    } else {
      setSolarValue("");
      setSolarUnit("");
    }
  }, []);

  useEffect(() => {
    if (device?.solar_size_in_kw !== undefined) {
      setSolar(device?.solar_size_in_kw);
    }
  }, [device?.solar_size_in_kw, setSolar]);

  useEffect(() => {
    if (device?.battery_backup_soc !== undefined) {
      setBackupSoc(device.battery_backup_soc);
    }
  }, [device?.battery_backup_soc]);

  useEffect(() => {
    if (device?.sync_location_from_property !== undefined) {
      setSyncLocation(device.sync_location_from_property);
    }
  }, [device?.sync_location_from_property]);

  useEffect(() => {
    if (device?.enable_manual_set_solar_capacity !== undefined) {
      setSyncSolar(!device.enable_manual_set_solar_capacity);
    }
  }, [device?.enable_manual_set_solar_capacity]);

  useEffect(() => {
    if (device?.enable_manual_set_battery_capacity !== undefined) {
      setSyncCapacity(!device.enable_manual_set_battery_capacity);
    }
  }, [device?.enable_manual_set_battery_capacity]);

  useEffect(() => {
    if (device?.is_smart_charging !== undefined) {
      setSmartCharging(device.is_smart_charging);
    }
  }, [device?.is_smart_charging]);

  const deviceFlow = useMemo(() => {
    return <BatteryGraph battery={device} />;
  }, [device]);

  useEffect(() => {
    if (device) {
      setSelectedProperty(device.assigned_property);
    }
  }, [device]);

  const onCapacitySave = useCallback(async () => {
    if (capacityUnit && capacityValue && capacityIntent !== Intent.DANGER) {
      const parsedCapacity = parseFloat(capacityValue);
      if (parsedCapacity <= 0) {
        showToast("Battery capacity must be greater than zero.", Intent.DANGER);
        return;
      }

      setIsSavingCapacity(true);

      const capacityInWh =
        capacityUnit === "MWh"
          ? parsedCapacity * 1000000
          : capacityUnit === "kWh"
          ? parsedCapacity * 1000
          : parsedCapacity;

      const roundedCapacity = Math.round(capacityInWh);

      try {
        const response = await patchDevice({
          battery_capacity_in_wh: roundedCapacity,
        });

        if (response.success) {
          if (response.result) {
            setCapacity(parseFloat(response.result));
          }
          fetchDevice(parsedDeviceId); // Refresh device
          setIsSavingCapacity(false);
          setIsEditingCapacity(false);
          showToast(`Battery capacity changed successfully!`, Intent.SUCCESS);
        } else {
          showToast(
            "Battery capacity change failed. Please try again.",
            Intent.DANGER
          );
          setIsSavingCapacity(false);
        }
      } catch (error) {
        setIsSavingCapacity(false);
        showToast("Battery capacity failed. Please try again.", Intent.DANGER);
      }
    }
  }, [
    capacityUnit,
    capacityValue,
    capacityIntent,
    showToast,
    patchDevice,
    parsedDeviceId,
    fetchDevice,
    setCapacity,
  ]);

  const onCapacityReset = useCallback(() => {
    setCapacity(device?.battery_capacity_in_wh);
    setCapacityIntent(Intent.NONE);
    setIsEditingCapacity(false);
  }, [device?.battery_capacity_in_wh, setCapacity]);

  const validateCapacity = useCallback(
    (newValue: string) => {
      // Regex for matching only valid float or integer values
      const isValidFloat = /^-?\d+(\.\d+)?$/;
      if (isEditingCapacity) {
        if (!newValue || !isValidFloat.test(newValue)) {
          setCapacityIntent(Intent.DANGER);
        } else {
          setCapacityIntent(Intent.NONE);
        }
        setCapacityValue(newValue);
      }
    },
    [isEditingCapacity]
  );

  const onSolarEdit = useCallback(() => {
    onCapacityReset();
    setIsEditingSolar(true);
  }, [onCapacityReset]);

  const onSolarSave = useCallback(async () => {
    if (solarUnit && solarValue && solarIntent !== Intent.DANGER) {
      const parsedSolar = parseFloat(solarValue);
      if (parsedSolar <= 0) {
        showToast("Solar capacity must be greater than zero.", Intent.DANGER);
        return;
      }

      setIsSavingSolar(true);

      const solarInW =
        solarUnit === "MW"
          ? parsedSolar * 1000000
          : solarUnit === "kW"
          ? parsedSolar * 1000
          : parsedSolar;

      const roundedSolar = Math.round(solarInW);

      try {
        const response = await patchDevice({
          solar_size_in_kw: roundedSolar / 1000.0,
        }); // Backend is in kW

        if (response.success) {
          if (response.result) {
            setSolar(parseFloat(response.result));
          }
          fetchDevice(parsedDeviceId); // Refresh device
          setIsSavingSolar(false);
          setIsEditingSolar(false);
          showToast(`Solar capacity changed successfully!`, Intent.SUCCESS);
        } else {
          showToast(
            "Solar capacity change failed. Please try again.",
            Intent.DANGER
          );
          setIsSavingSolar(false);
        }
      } catch (error) {
        setIsSavingSolar(false);
        showToast("Solar capacity failed. Please try again.", Intent.DANGER);
      }
    }
  }, [
    solarUnit,
    solarValue,
    solarIntent,
    showToast,
    patchDevice,
    fetchDevice,
    parsedDeviceId,
    setSolar,
  ]);

  const onSolarReset = useCallback(() => {
    setSolar(device?.solar_size_in_kw ?? 0);
    setSolarIntent(Intent.NONE);
    setIsEditingSolar(false);
  }, [device?.solar_size_in_kw, setSolar]);

  const onCapacityEdit = useCallback(() => {
    onSolarReset();
    setIsEditingCapacity(true);
  }, [onSolarReset]);

  const validateSolar = useCallback(
    (newValue: string) => {
      // Regex for matching only valid float or integer values
      const isValidFloat = /^-?\d+(\.\d+)?$/;
      if (isEditingSolar) {
        if (!newValue || !isValidFloat.test(newValue)) {
          setSolarIntent(Intent.DANGER);
        } else {
          setSolarIntent(Intent.NONE);
        }
        setSolarValue(newValue);
      }
    },
    [isEditingSolar]
  );

  const onBackupSocSave = useCallback(
    async (newBackupSoc: number) => {
      setIsSavingBackupSoc(true);

      try {
        const response = await patchDevice({
          battery_backup_soc: newBackupSoc,
        });

        if (response.success) {
          if (response.result) {
            setBackupSoc(parseInt(response.result));
          }
          showToast(
            `Backup battery level changed successfully!`,
            Intent.SUCCESS
          );
        } else {
          showToast(
            "Backup battery level change failed. Please try again.",
            Intent.DANGER
          );
        }
      } catch (error) {
        showToast(
          "Backup battery level change failed. Please try again.",
          Intent.DANGER
        );
      } finally {
        setIsSavingBackupSoc(false);
      }
    },
    [patchDevice, showToast]
  );

  const onSyncLocationSave = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const newSyncLocation = e.currentTarget.checked;
      setSyncLocation(newSyncLocation);
      setIsSavingSyncLocation(true);

      try {
        const response = await patchDevice({
          sync_location_from_property: newSyncLocation,
        });

        if (response.success) {
          if (response.result) {
            setSyncLocation(Boolean(response.result));
          }
          showToast(
            `Sync location setting changed successfully!`,
            Intent.SUCCESS
          );
        } else {
          showToast(
            "Sync location setting change failed. Please try again.",
            Intent.DANGER
          );
        }
      } catch (error) {
        showToast(
          "Sync location setting change failed. Please try again.",
          Intent.DANGER
        );
      } finally {
        setIsSavingSyncLocation(false);
      }
    },
    [patchDevice, showToast]
  );

  const onSyncSolarSave = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const newSyncSolar = e.currentTarget.checked;
      setSyncSolar(newSyncSolar);
      setIsSavingSyncSolar(true);

      try {
        const response = await patchDevice({
          enable_manual_set_solar_capacity: !newSyncSolar,
        });

        if (response.success) {
          if (response.result) {
            setSyncSolar(Boolean(response.result));
          }
          showToast(`Sync solar setting changed successfully!`, Intent.SUCCESS);
        } else {
          showToast(
            "Sync solar setting change failed. Please try again.",
            Intent.DANGER
          );
        }
      } catch (error) {
        showToast(
          "Sync solar setting change failed. Please try again.",
          Intent.DANGER
        );
      } finally {
        setIsSavingSyncSolar(false);
      }
    },
    [patchDevice, showToast]
  );

  const onSyncCapacitySave = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const newSyncCapacity = e.currentTarget.checked;
      setSyncCapacity(newSyncCapacity);
      setIsSavingSyncCapacity(true);

      try {
        const response = await patchDevice({
          enable_manual_set_battery_capacity: !newSyncCapacity,
        });

        if (response.success) {
          if (response.result) {
            setSyncCapacity(Boolean(response.result));
          }
          showToast(
            `Sync capacity setting changed successfully!`,
            Intent.SUCCESS
          );
        } else {
          showToast(
            "Sync capacity setting change failed. Please try again.",
            Intent.DANGER
          );
        }
      } catch (error) {
        showToast(
          "Sync capacity setting change failed. Please try again.",
          Intent.DANGER
        );
      } finally {
        setIsSavingSyncCapacity(false);
      }
    },
    [patchDevice, showToast]
  );

  const tariffMappingFetchUrl = useMemo(() => {
    if (device?.assigned_property) {
      return `/api/tariffs/property-mapping/?property=${device?.assigned_property}`;
    }
    return null;
  }, [device?.assigned_property]);

  const { data: propertyTariffMappingResponse } = useFetch<{
    results: PropertyTariffMapping[];
  }>(tariffMappingFetchUrl, { useCache: true });

  const hasTariff = useMemo(() => {
    if (!device?.assigned_property || !propertyTariffMappingResponse?.results) {
      return false;
    }
    const currentDate = DateTime.now();
    return propertyTariffMappingResponse.results.some((mapping) => {
      const validFrom = DateTime.fromISO(mapping.valid_from);
      const validTo = DateTime.fromISO(mapping.valid_to);
      return (
        currentDate >= validFrom &&
        currentDate <= validTo &&
        mapping.tariff != null
      );
    });
  }, [device?.assigned_property, propertyTariffMappingResponse?.results]);

  const smartChargingDisabled = useMemo(() => {
    return (
      !device?.is_smart_charging &&
      (!hasTariff ||
        !device?.solar_size_in_kw ||
        !device?.battery_capacity_in_wh)
    );
  }, [
    device?.is_smart_charging,
    device?.solar_size_in_kw,
    device?.battery_capacity_in_wh,
    hasTariff,
  ]);

  const smartChargingErrorMessages = useMemo(() => {
    const messages = [];
    if (!hasTariff) {
      messages.push("A tariff must be assigned");
    }
    if (!device?.battery_capacity_in_wh) {
      messages.push("Storage capacity must be set");
    }
    if (!device?.solar_size_in_kw) {
      messages.push("Solar panel capacity must be set");
    }
    return messages;
  }, [hasTariff, device?.solar_size_in_kw, device?.battery_capacity_in_wh]);

  const onSmartChargingSave = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const newSmartCharging = e.currentTarget.checked;
      if (smartChargingDisabled) {
        return;
      }

      setIsSavingSmartCharging(true);
      setSmartCharging(newSmartCharging);

      try {
        const response = await patchDevice({
          is_smart_charging: newSmartCharging,
        });

        if (response.success) {
          showToast(
            `Smart charging ${
              newSmartCharging ? "enabled" : "disabled"
            } successfully!`,
            Intent.SUCCESS
          );
        } else {
          showToast(
            `Failed to ${
              newSmartCharging ? "enable" : "disable"
            } smart charging. Please try again.`,
            Intent.DANGER
          );
          setSmartCharging(!newSmartCharging); // Revert the state if the API call fails
        }
      } catch (error) {
        showToast(
          `An error occurred while ${
            newSmartCharging ? "enabling" : "disabling"
          } smart charging. Please try again.`,
          Intent.DANGER
        );
        setSmartCharging(!newSmartCharging); // Revert the state if an error occurs
      } finally {
        setIsSavingSmartCharging(false);
      }
    },
    [smartChargingDisabled, patchDevice, showToast]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
        padding: "24px",
        marginBottom: "64px",
      }}
    >
      {device !== null ? (
        <div style={{ flex: 1, fontFamily: "Barlow" }}>
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <div
              style={{
                borderRadius: 6,
                padding: "24px",
                paddingBottom: 18,
                fontFamily: "Barlow",
                width: "100%",
                backgroundColor: "#252A31",
                fontFeatureSettings: "Barlow",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
                gap: "24px",
              }}
            >
              <PlatformDeviceDetailViewHeader
                device={device}
                setActiveTab={setActiveTab}
              />

              <div
                style={{
                  display: "grid",
                  gridTemplateRows: "auto 1fr",
                  height: "100%",
                  marginBottom: "12px",
                }}
              >
                {device?.assigned_property && (
                  <div
                    style={{
                      position: "relative",
                      height: 240,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: 240,
                      }}
                    >
                      <PlatformBatteryChargingScheduleChart
                        batteryId={parsedDeviceId}
                        height={240}
                        key={`schedule-${propertyChangeCounter}`}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {deviceId && (
            <div>
              <div
                id="device-tabs-container"
                style={{
                  marginTop: "24px",
                  borderColor: "#5F6B7C",
                  borderWidth: "1px",
                  borderStyle: "dashed",
                  padding: "8px 12px",
                  height: "280px",
                  borderRadius: "8px",
                  flex: 1,
                }}
              >
                <Tabs
                  selectedTabId={selectedTab}
                  onChange={(newTabId) => setSelectedTab(String(newTabId))}
                >
                  <Tab
                    id="charging_mode"
                    title="Charging"
                    tagContent={
                      smartChargingErrorMessages.length === 0
                        ? undefined
                        : smartChargingErrorMessages.length
                    }
                    tagProps={{
                      intent: "danger",
                      style: {
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      },
                    }}
                    panel={
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  opacity: smartChargingDisabled ? 0.5 : 1,
                                  cursor: smartChargingDisabled
                                    ? "not-allowed"
                                    : "default",
                                  marginRight: 10,
                                  width: 100,
                                }}
                              >
                                Smart Charging:
                              </div>
                              <Switch
                                disabled={
                                  isPatchInProgress ||
                                  smartChargingDisabled ||
                                  isEditingCapacity ||
                                  isEditingSolar
                                }
                                onChange={onSmartChargingSave}
                                checked={smartCharging}
                                innerLabel="disabled"
                                innerLabelChecked={"enabled"}
                                large
                              />
                              {isSavingSmartCharging && (
                                <div>
                                  <Spinner size={18} style={{ marginTop: 1 }} />
                                </div>
                              )}
                            </div>
                            {smartChargingDisabled && (
                              <div className={classes.validationErrorList}>
                                The following are mandatory to enable smart
                                charging:
                                <ul
                                  style={{
                                    margin: "8px 0 0 20px",
                                    padding: 0,
                                  }}
                                >
                                  {smartChargingErrorMessages.map(
                                    (message, index) => (
                                      <li key={index}>{message}</li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: "0.5em",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span
                                  style={{
                                    width: 140,
                                  }}
                                >
                                  Battery Backup Level:
                                </span>
                                <div
                                  style={{
                                    marginLeft: 8,
                                    marginTop: 2,
                                    display: "flex",
                                    flexDirection: "row",
                                    width: 300,
                                  }}
                                >
                                  <Slider
                                    min={0}
                                    max={100}
                                    stepSize={1}
                                    labelStepSize={20}
                                    disabled={
                                      isPatchInProgress ||
                                      isEditingCapacity ||
                                      isEditingSolar
                                    }
                                    onChange={setBackupSoc}
                                    onRelease={onBackupSocSave}
                                    labelRenderer={(val: number) => {
                                      return `${val}%`;
                                    }}
                                    value={backupSoc}
                                    className={classes.slider}
                                  />
                                </div>
                                {isSavingBackupSoc && (
                                  <div>
                                    <Spinner
                                      size={18}
                                      style={{ marginLeft: 20 }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    id="entity_connections"
                    tagContent={device?.assigned_property ? undefined : "1"}
                    tagProps={{
                      intent: "danger",
                      style: {
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      },
                    }}
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Entity Connections
                      </div>
                    }
                    panel={
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "4px",
                            }}
                          >
                            <div
                              style={{
                                marginBottom: "6px",
                              }}
                            >
                              <strong>Property</strong>
                            </div>
                            <div
                              style={{
                                opacity:
                                  syncLocation || isPatchInProgress ? 0.5 : 1,
                                cursor:
                                  syncLocation || isPatchInProgress
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <div
                                style={{
                                  pointerEvents:
                                    syncLocation || isPatchInProgress
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                <PlatformPropertyAssignWidget
                                  propertyId={selectedProperty}
                                  deviceId={device?.id}
                                />
                              </div>
                            </div>
                          </div>
                          <div style={{ flex: 1 }}>
                            <div
                              style={{
                                display: "flex",
                                marginTop: -6,
                              }}
                            >
                              <Switch
                                checked={syncLocation}
                                label="Sync location from property"
                                disabled={
                                  isPatchInProgress ||
                                  isEditingCapacity ||
                                  isEditingSolar
                                }
                                onChange={onSyncLocationSave}
                              />
                              {isSavingSyncLocation && (
                                <div>
                                  <Spinner
                                    size={18}
                                    style={{ marginLeft: 10 }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    id="device_properties"
                    tagContent={
                      !device?.solar_size_in_kw &&
                      !device?.battery_capacity_in_wh
                        ? "2"
                        : !device?.solar_size_in_kw ||
                          !device?.battery_capacity_in_wh
                        ? "1"
                        : undefined
                    }
                    tagProps={{
                      intent: "danger",
                      style: {
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      },
                    }}
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Device Properties
                      </div>
                    }
                    panel={
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: "0.5em",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span
                                  style={{
                                    width: 110,
                                  }}
                                >
                                  Solar Capacity:
                                </span>
                                <div
                                  style={{
                                    marginLeft: 5,
                                    marginTop: -2,
                                    display: "flex",
                                    flexDirection: "row",
                                    width: 140,
                                  }}
                                >
                                  <InputGroup
                                    small
                                    readOnly={!isEditingSolar}
                                    disabled={isPatchInProgress}
                                    value={solarValue?.toString()}
                                    onChange={(e) =>
                                      validateSolar(e.target.value)
                                    }
                                    intent={
                                      !device?.solar_size_in_kw
                                        ? Intent.DANGER
                                        : solarIntent
                                    }
                                    style={{
                                      width: 95,
                                      fontFamily: "Barlow",
                                      fontSize: 13,
                                      paddingBottom: 1,
                                      color: isPatchInProgress
                                        ? "rgba(95, 107, 124, 0.6)"
                                        : "white",
                                      cursor: isEditingSolar
                                        ? isPatchInProgress
                                          ? "not-allowed"
                                          : "text"
                                        : "default",
                                      textAlign: "center",
                                      backgroundColor: "rgb(61 67 74)",
                                      borderRadius: 3,
                                    }}
                                    rightElement={
                                      <Popover
                                        disabled={!isEditingSolar}
                                        content={
                                          <Menu style={{ minWidth: 50 }}>
                                            <MenuItem
                                              text="W"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 0,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() => setSolarUnit("W")}
                                            />
                                            <MenuItem
                                              text="kW"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 4,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() => setSolarUnit("kW")}
                                            />
                                            <MenuItem
                                              text="MW"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 0,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() => setSolarUnit("MW")}
                                            />
                                          </Menu>
                                        }
                                        placement="bottom-end"
                                      >
                                        <Button
                                          minimal
                                          disabled={isPatchInProgress}
                                          rightIcon={
                                            <Icon
                                              icon="caret-down"
                                              style={{
                                                color:
                                                  isEditingSolar &&
                                                  !isPatchInProgress
                                                    ? "white"
                                                    : "transparent",
                                              }}
                                            />
                                          }
                                          style={{
                                            fontFamily: "Barlow",
                                            fontSize: 12,
                                            width: 50,
                                            color: "white",
                                            pointerEvents: isEditingSolar
                                              ? "unset"
                                              : "none",
                                          }}
                                        >
                                          {solarUnit}
                                        </Button>
                                      </Popover>
                                    }
                                  />
                                  {isSavingSolar ? (
                                    <Spinner
                                      size={18}
                                      style={{ marginLeft: 5 }}
                                    />
                                  ) : isEditingSolar ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: 20,
                                          padding: 5,
                                          paddingRight: 0,
                                        }}
                                      >
                                        <Clear
                                          style={{
                                            color: "#aaa",
                                            cursor: "pointer",
                                            fontSize: 14,
                                          }}
                                          onMouseOver={({ currentTarget }) =>
                                            (currentTarget.style.color = "#eee")
                                          }
                                          onMouseOut={({ currentTarget }) =>
                                            (currentTarget.style.color = "#aaa")
                                          }
                                          onClick={onSolarReset}
                                        />
                                      </div>
                                      <div style={{ height: 20, padding: 5 }}>
                                        <Save
                                          style={{
                                            color: "#aaa",
                                            cursor: "pointer",
                                            fontSize: 14,
                                          }}
                                          onMouseOver={({ currentTarget }) =>
                                            (currentTarget.style.color = "#eee")
                                          }
                                          onMouseOut={({ currentTarget }) =>
                                            (currentTarget.style.color = "#aaa")
                                          }
                                          onClick={onSolarSave}
                                        />
                                      </div>
                                    </div>
                                  ) : syncSolar ? null : (
                                    <div
                                      style={{
                                        height: 20,
                                        padding: 5,
                                        cursor: isPatchInProgress
                                          ? "not-allowed"
                                          : "default",
                                      }}
                                    >
                                      <Edit
                                        style={{
                                          color: "#aaa",
                                          cursor: "pointer",
                                          fontSize: 14,
                                          opacity: isPatchInProgress ? 0.5 : 1,
                                          pointerEvents: isPatchInProgress
                                            ? "none"
                                            : "auto",
                                        }}
                                        onMouseOver={({ currentTarget }) =>
                                          (currentTarget.style.color = "#eee")
                                        }
                                        onMouseOut={({ currentTarget }) =>
                                          (currentTarget.style.color = "#aaa")
                                        }
                                        onClick={onSolarEdit}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                marginTop: 1,
                              }}
                            >
                              <Switch
                                checked={syncSolar}
                                label="Sync from device"
                                disabled={
                                  isPatchInProgress ||
                                  isEditingSolar ||
                                  isEditingCapacity
                                }
                                onChange={onSyncSolarSave}
                              />
                              {isSavingSyncSolar && (
                                <div>
                                  <Spinner
                                    size={18}
                                    style={{ marginLeft: 6, marginTop: 1 }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={classes.validationError}>
                            {!device.solar_size_in_kw
                              ? "Please set the solar capacity"
                              : ""}
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: "0.5em",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span
                                  style={{
                                    width: 110,
                                  }}
                                >
                                  Storage Capacity:
                                </span>
                                <div
                                  style={{
                                    marginLeft: 5,
                                    marginTop: -2,
                                    display: "flex",
                                    flexDirection: "row",
                                    width: 140,
                                  }}
                                >
                                  <InputGroup
                                    small
                                    readOnly={!isEditingCapacity}
                                    disabled={isPatchInProgress}
                                    value={capacityValue?.toString()}
                                    onChange={(e) =>
                                      validateCapacity(e.target.value)
                                    }
                                    intent={
                                      !device?.battery_capacity_in_wh
                                        ? Intent.DANGER
                                        : capacityIntent
                                    }
                                    style={{
                                      width: 95,
                                      fontFamily: "Barlow",
                                      fontSize: 13,
                                      paddingBottom: 1,
                                      color: isPatchInProgress
                                        ? "rgba(95, 107, 124, 0.6)"
                                        : "white",
                                      cursor: isEditingCapacity
                                        ? isPatchInProgress
                                          ? "not-allowed"
                                          : "text"
                                        : "default",
                                      textAlign: "center",
                                      backgroundColor: "rgb(61 67 74)",
                                      borderRadius: 3,
                                    }}
                                    rightElement={
                                      <Popover
                                        disabled={!isEditingCapacity}
                                        content={
                                          <Menu style={{ minWidth: 50 }}>
                                            <MenuItem
                                              text="Wh"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 0,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() =>
                                                setCapacityUnit("Wh")
                                              }
                                            />
                                            <MenuItem
                                              text="kWh"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 4,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() =>
                                                setCapacityUnit("kWh")
                                              }
                                            />
                                            <MenuItem
                                              text="MWh"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 0,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() =>
                                                setCapacityUnit("MWh")
                                              }
                                            />
                                          </Menu>
                                        }
                                        placement="bottom-end"
                                      >
                                        <Button
                                          minimal
                                          disabled={isPatchInProgress}
                                          rightIcon={
                                            <Icon
                                              icon="caret-down"
                                              style={{
                                                color:
                                                  isEditingCapacity &&
                                                  !isPatchInProgress
                                                    ? "white"
                                                    : "transparent",
                                              }}
                                            />
                                          }
                                          style={{
                                            fontFamily: "Barlow",
                                            fontSize: 12,
                                            width: 50,
                                            color: "white",
                                            pointerEvents: isEditingCapacity
                                              ? "unset"
                                              : "none",
                                          }}
                                        >
                                          {capacityUnit}
                                        </Button>
                                      </Popover>
                                    }
                                  />
                                  {isSavingCapacity ? (
                                    <Spinner
                                      size={18}
                                      style={{ marginLeft: 5 }}
                                    />
                                  ) : isEditingCapacity ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: 20,
                                          padding: 5,
                                          paddingRight: 0,
                                        }}
                                      >
                                        <Clear
                                          style={{
                                            color: "#aaa",
                                            cursor: "pointer",
                                            fontSize: 14,
                                          }}
                                          onMouseOver={({ currentTarget }) =>
                                            (currentTarget.style.color = "#eee")
                                          }
                                          onMouseOut={({ currentTarget }) =>
                                            (currentTarget.style.color = "#aaa")
                                          }
                                          onClick={onCapacityReset}
                                        />
                                      </div>
                                      <div style={{ height: 20, padding: 5 }}>
                                        <Save
                                          style={{
                                            color: "#aaa",
                                            cursor: "pointer",
                                            fontSize: 14,
                                          }}
                                          onMouseOver={({ currentTarget }) =>
                                            (currentTarget.style.color = "#eee")
                                          }
                                          onMouseOut={({ currentTarget }) =>
                                            (currentTarget.style.color = "#aaa")
                                          }
                                          onClick={onCapacitySave}
                                        />
                                      </div>
                                    </div>
                                  ) : syncCapacity ? null : (
                                    <div
                                      style={{
                                        height: 20,
                                        padding: 5,
                                        cursor: isPatchInProgress
                                          ? "not-allowed"
                                          : "default",
                                      }}
                                    >
                                      <Edit
                                        style={{
                                          color: "#aaa",
                                          cursor: "pointer",
                                          fontSize: 14,
                                          opacity: isPatchInProgress ? 0.5 : 1,
                                          pointerEvents: isPatchInProgress
                                            ? "none"
                                            : "auto",
                                        }}
                                        onMouseOver={({ currentTarget }) =>
                                          (currentTarget.style.color = "#eee")
                                        }
                                        onMouseOut={({ currentTarget }) =>
                                          (currentTarget.style.color = "#aaa")
                                        }
                                        onClick={onCapacityEdit}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                marginTop: 1,
                              }}
                            >
                              <Switch
                                checked={syncCapacity}
                                label="Sync from device"
                                disabled={
                                  isPatchInProgress ||
                                  isEditingCapacity ||
                                  isEditingSolar
                                }
                                onChange={onSyncCapacitySave}
                              />
                              {isSavingSyncCapacity && (
                                <div>
                                  <Spinner
                                    size={18}
                                    style={{ marginLeft: 6, marginTop: 1 }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className={classes.validationError}>
                            {!device.battery_capacity_in_wh
                              ? "Please set the storage capacity"
                              : ""}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Tabs>
              </div>
              <div style={{ display: "flex" }}></div>
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "-6px",
                    marginLeft: "-6px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <PlatformSiteDeviceDetailViewMetricsCard
                      icon={<MdHomeFilled size={40} />}
                      metric={`${(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.consumed_energy_in_kwh ?? 0
                      ).toFixed(1)} kWh`}
                      label={"Home Consumption"}
                    />
                    <PlatformSiteDeviceDetailViewMetricsCard
                      icon={<CiImport size={40} />}
                      metric={`${(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.imported_grid_energy_in_kwh ?? 0
                      ).toFixed(1)} kWh`}
                      label={"Grid Energy Import"}
                    />
                    <PlatformSiteDeviceDetailViewMetricsCard
                      icon={<CiExport size={40} />}
                      metric={`${(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.exported_grid_energy_in_kwh ?? 0
                      ).toFixed(1)} kWh`}
                      label={"Grid Energy Export"}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <PlatformSiteDeviceDetailViewMetricsCard
                      icon={<GiPriceTag size={40} color="#BDADFF" />}
                      metric={`${getCurrencySymbol(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.tariff_currency ?? "EUR"
                      )} ${(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.avg_price_per_kwh ?? 0
                      ).toFixed(2)} `}
                      label={"Average import price / kWh"}
                    />
                    <PlatformSiteDeviceDetailViewMetricsCard
                      icon={<GiPriceTag size={40} color="#D69FD6" />}
                      metric={`${getCurrencySymbol(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.tariff_currency ?? "EUR"
                      )} ${(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.avg_export_price_per_kwh ?? 0
                      ).toFixed(2)} `}
                      label={"Average export price / kWh"}
                    />
                  </div>
                </div>
              </div>

              <div className={classes.gridContainer}>
                <PlatformBatteryMetricsSolarPowerChart
                  batteryId={parsedDeviceId}
                  height={360}
                  key={`solar-${propertyChangeCounter}`}
                />
                <PlatformBatteryMetricsBatteryPowerChart
                  batteryId={parsedDeviceId}
                  height={360}
                  key={`battery-${propertyChangeCounter}`}
                />

                <PlatformBatteryMetricsGridPowerChart
                  batteryId={parsedDeviceId}
                  height={360}
                  key={`grid-${propertyChangeCounter}`}
                />
                <PlatformBatteryMetricsHomePowerChart
                  batteryId={parsedDeviceId}
                  height={360}
                  key={`home-${propertyChangeCounter}`}
                />

                {/* Needed to fix pixelation */}
                <div style={{ height: 358, paddingTop: 1, paddingBottom: 1 }}>
                  {deviceFlow}
                </div>
              </div>
              {canViewCostComparison && (
                <div style={{ marginTop: "32px" }}>
                  {device?.assigned_property && (
                    <PlatformPropertySavingsBarChartWidget
                      propertyId={device?.assigned_property}
                      height={360}
                    />
                  )}
                </div>
              )}

              {/* Uncomment to enable the combo chart */}
              {/* <div className={classes.gridContainer}>
                <PlatformBatteryMetricsComboChart
                  batteryId={parsedDeviceId}
                  height={420}
                />
              </div> */}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            margin: "auto 0",
          }}
        >
          <SpinningLogo isLoadingSpinner={true} height="50px" />
        </div>
      )}
    </div>
  );
}
