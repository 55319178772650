import { Edge } from "reactflow";
import {
  SOURCE_BOTTOM_HANDLE_ID,
  SOURCE_LEFT_HANDLE_ID,
  SOURCE_RIGHT_HANDLE_ID,
  SOURCE_TOP_HANDLE_ID,
  TARGET_BOTTOM_HANDLE_ID,
  TARGET_LEFT_HANDLE_ID,
  TARGET_RIGHT_HANDLE_ID,
  TARGET_TOP_HANDLE_ID,
} from "../platform/studio/graphNodes/DigitalTwinNode";

export function getEVs() {
  return [
    { id: 0, battery_level: 38, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 48, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 28, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 18, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 68, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 33, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 63, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 43, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 73, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 83, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 93, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 13, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 23, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 53, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 73, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 12, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 22, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 32, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 42, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 52, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 62, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 72, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 82, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 92, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 2, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 11, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 21, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 31, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 41, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 41, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 51, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 61, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 61, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 71, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 51, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 61, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 61, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 71, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 38, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 48, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 28, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 18, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 68, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 33, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 63, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 43, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 73, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 83, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 93, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 13, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 23, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 53, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 73, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 12, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 22, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 32, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 42, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 52, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 62, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 72, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 82, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 92, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 2, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 11, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 21, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 31, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 41, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 41, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 51, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 61, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 61, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 71, isPlugged: true, isCharging: true },
    { id: 0, battery_level: 51, isPlugged: true, isCharging: false },
    { id: 0, battery_level: 61, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 61, isPlugged: false, isCharging: false },
    { id: 0, battery_level: 71, isPlugged: true, isCharging: true },
  ].map((ev, idx) => ({
    ...ev,
    id: 16100001 + idx,
  }));
}
function getCirclePosition(
  index: number,
  total: number,
  centerX: number,
  centerY: number,
  radius: number
) {
  const angle = (index / total) * 2 * Math.PI; // Calculate the angle for this node
  const x = centerX + radius * Math.cos(angle);
  const y = centerY + radius * Math.sin(angle);
  return { x, y };
}

function getBatteryLevelColor(batteryLevel: number) {
  if (batteryLevel <= 20) {
    return "#B83211";
  }
  if (batteryLevel <= 40) {
    return "#C87619";
  }
  if (batteryLevel <= 55) {
    return "#F0B726";
  }
  if (batteryLevel <= 75) {
    return "#8EB125";
  }
  return "#29A634";
}
export function getDigitalTwinNodes() {
  const evs = getEVs();
  const total = evs.length;
  const centerX = 500; // Center of the circle on the X axis
  const centerY = 500; // Center of the circle on the Y axis
  const radius = 1200; // Radius of the circle

  const evNodes = evs.map((ev, index) => {
    const position = getCirclePosition(index, total, centerX, centerY, radius);
    return {
      id: `ev-${ev.id}`,
      type: "powerNode",
      position: position,
      data: {
        label: "2,204 W",
        subLabel: "EV",
        icon: "car",
        color: getBatteryLevelColor(ev.battery_level),
      },
    };
  });

  const gridNode = {
    id: `grid`,
    type: "powerNode",
    position: { x: centerX, y: centerY },
    data: {
      label: "2,204 W",
      subLabel: "EV",
      icon: "grid",
      color: "blue",
    },
  };

  return [...evNodes, gridNode];
}

export function getDigitalTwinEdges() {
  const evs = getEVs();

  const nodes = getDigitalTwinNodes();

  const edges: Edge[] = [];
  evs.forEach((ev) => {
    const node = nodes.filter((node) => node.id === `ev-${ev.id}`)[0];
    let sourceHandle = SOURCE_TOP_HANDLE_ID;
    let targetHandle = TARGET_BOTTOM_HANDLE_ID;
    if (node.position.y > 500) {
      sourceHandle = SOURCE_BOTTOM_HANDLE_ID;
      targetHandle = TARGET_TOP_HANDLE_ID;
    }

    if (node.position.x < 500) {
      targetHandle = TARGET_RIGHT_HANDLE_ID;
      if (Math.round(node.position.y) === 500) {
        targetHandle = TARGET_RIGHT_HANDLE_ID;
        sourceHandle = SOURCE_LEFT_HANDLE_ID;
      }
    } else if (node.position.y === 500) {
      targetHandle = TARGET_LEFT_HANDLE_ID;
      sourceHandle = SOURCE_RIGHT_HANDLE_ID;
    } else {
      targetHandle = TARGET_LEFT_HANDLE_ID;
    }

    if (ev.isPlugged) {
      edges.push({
        id: `e-grid-ev-${ev.id}`,
        source: `grid`,
        target: `ev-${ev.id}`,
        sourceHandle,
        targetHandle,
        type: ev.isCharging ? "normal" : "default", // Add this line
        data: {
          color1: getBatteryLevelColor(ev.battery_level),
          color2: "#68C1EE",
        },

        style: { stroke: "#2F343C" },
      });
    }
  });

  return edges;
}
