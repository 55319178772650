import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthHeaders } from "../common/authentication/authentication";

interface IAssistant {
  fetchThreadMessages: () => void;
  sendMessage: (message: string) => Promise<void>;
  messages: IMessage[];
  isFetchingMessages: boolean;
  isProcessing: boolean;
  isSendingMessage: boolean;
}

export interface IMessage {
  id: string;
  role: string;
  completion_time: string;
  text: string;
}

export function useAssistantThreadCreator() {
  const headers = useAuthHeaders();
  return async function createNewThread() {
    const response = await fetch("/api/assistant/thread/", {
      method: "post",
      headers,
    });
    if (response.ok) {
      return String((await response.json())?.id);
    } else {
      throw Error(await response.text());
    }
  };
}

export function useAssistantDatasetFetcher() {
  const headers = useAuthHeaders();
  const fetchDataset = useCallback(
    (uuid: string) => {
      async function fetchDatasetAsync(uuid: string) {
        const response = await fetch(`/api/assistant/dataset/${uuid}/`, {
          method: "get",
          headers,
        });

        if (!response.ok) {
          throw Error(await response.text());
        }
        return await response.json();
      }

      return fetchDatasetAsync(uuid);
    },
    [headers]
  );

  return fetchDataset;
}

export default function useAssistant(threadID: string | null): IAssistant {
  const headers = useAuthHeaders();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isFetchingMessages, setIsFetchingMessages] = useState<boolean>(false);
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const fetchThreadMessages = useCallback(() => {
    async function fetchMessagesAsync() {
      if (threadID == null) {
        return;
      }
      setIsFetchingMessages(true);
      const response = await fetch(
        `/api/assistant/thread/${threadID}/messages/`,
        {
          headers,
        }
      );
      const response_json = await response.json();
      setMessages(response_json.results.reverse());
      setIsFetchingMessages(false);
    }

    return fetchMessagesAsync();
  }, [threadID, headers]);

  useEffect(() => {
    let isMounted = true;

    async function getThreadStatus() {
      try {
        if (threadID == null || threadID === "") {
          setIsProcessing(true);
          console.log("THREADID IS NULL");
          return;
        }

        console.log("THREAAAD", threadID);

        while (true) {
          const url = `/api/assistant/thread/${threadID}/is-processing/`;
          const response = await fetch(url, {
            headers,
          });

          const result = await response.json();

          console.log(result);

          const is_processing = result?.is_processing ?? false;
          console.log(result.is_processing);

          if (is_processing) {
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Sleep for 1 second
            console.log("A");
            setIsProcessing(is_processing);
          } else {
            console.log("B");
            fetchThreadMessages().then(() => setIsProcessing(is_processing));

            break;
          }
        }

        if (isMounted) {
          setIsProcessing(false);
        }
      } catch (error) {
        console.error("Error fetching thread status:", error);
      }
    }

    getThreadStatus();

    return () => {
      isMounted = false; // Only set state on mounted component
    };
  }, [
    headers,
    threadID,
    setIsProcessing,
    fetchThreadMessages,
    messages.length,
  ]);

  const sendMessage = useCallback(
    (message: string) => {
      if (message.length === 0) {
        throw Error("Empty message provided");
      }
      setIsSendingMessage(true);

      async function sendMessageAsync() {
        const response = await fetch(
          `/api/assistant/thread/${threadID}/send-message/`,
          {
            method: "post",
            body: JSON.stringify({ message }),
            headers,
          }
        );
        console.log(await response.json());
        return fetchThreadMessages().then(() => {
          setIsSendingMessage(false);
        });
      }

      return sendMessageAsync();
    },
    [fetchThreadMessages, headers, threadID]
  );

  const assistant: IAssistant = useMemo(
    () => ({
      fetchThreadMessages,
      messages,
      isFetchingMessages,
      isSendingMessage,
      isProcessing,
      sendMessage,
    }),
    [
      fetchThreadMessages,
      messages,
      isFetchingMessages,
      isSendingMessage,
      isProcessing,
      sendMessage,
    ]
  );

  return assistant;
}
