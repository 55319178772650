import { Button, ButtonGroup, Spinner } from "@blueprintjs/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GateKeeper } from "../../../gk/__generated__/gatekeepers";
import useGatekeeper from "../../../gk/useGatekeeper";
import Ec1DataGrid from "../../../ui/components/Ec1DataGrid.react";
import { useEnterpriseLabels } from "../enterpriseLabels/useEnterpriseLabels";
import PlatformAddCustomerDialog from "./PlatformAddCustomerDialog.react";
import { Customer, useCustomers } from "./customers";

export default function PlatformCustomerListView() {
  const navigate = useNavigate();
  const { fetchCustomers } = useCustomers();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [gridKey, setGridKey] = useState<number>(0);
  const canUseCustomerMap = useGatekeeper(GateKeeper.CAN_USE_CUSTOMER_MAP);
  const { selectedLabelObj } = useEnterpriseLabels();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "customer_uid",
        headerName: selectedLabelObj?.customer_uid_field_name ?? "Customer UID",
        flex: 2,
      },
      { field: "first_name", headerName: "First Name", flex: 3 },
      { field: "last_name", headerName: "Last Name", flex: 3 },
      { field: "email", headerName: "Email", flex: 3 },
      { field: "landline", headerName: "Landline", flex: 2 },
      { field: "mobile", headerName: "Mobile", flex: 2 },
    ],
    [selectedLabelObj]
  );

  const handleActionClick = useCallback(
    (customerId: string) => {
      navigate(`/customers/${customerId}`);
    },
    [navigate]
  );

  const handleContextMenuItemClick = useCallback(
    (action: string, id: string | null) => {
      if (action === "view" && id) {
        handleActionClick(id);
      }
    },
    [handleActionClick]
  );

  const contextMenuOptions = useMemo(
    () => [
      {
        label: "View Customer",
        icon: <VisibilityIcon />,
        action: (id: string | null) => handleContextMenuItemClick("view", id),
      },
    ],
    [handleContextMenuItemClick]
  );

  const refreshCustomerList = useCallback(() => {
    setIsDialogOpen(false);
    setGridKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
        width: "100%",
        overflow: "hidden",
        marginTop: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "4px",
          paddingLeft: "4px",
          fontFamily: "Barlow",
          letterSpacing: 1.5,
          fontWeight: 700,
          fontSize: 24,
          color: "#D3D8DE",
          marginBottom: 24,
        }}
      >
        <ButtonGroup>
          <Button
            minimal
            outlined
            rightIcon={dialogLoading ? <Spinner size={16} /> : "add"}
            onClick={() => setIsDialogOpen(true)}
            data-testid="add-new-customer-btn"
          >
            Add new Customer
          </Button>
          {canUseCustomerMap && (
            <>
              <Button icon="th" active>
                Table
              </Button>
              <Button
                icon="map"
                active={false}
                onClick={() => navigate("/customers/map")}
              >
                Map
              </Button>
            </>
          )}
        </ButtonGroup>

        <PlatformAddCustomerDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onEnrollmentSuccess={refreshCustomerList}
          setIsLoadingParent={setDialogLoading}
        />
      </div>
      <Ec1DataGrid<Customer>
        id="gridCustomers"
        key={gridKey}
        get={fetchCustomers}
        columns={columns}
        contextMenuOptions={contextMenuOptions}
        getRowId={(row: Customer) => row.id}
        onRowClick={(customer, event) => {
          handleActionClick(String(customer.id));
        }}
      />
    </div>
  );
}
