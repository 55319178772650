// Define the component with props
export default function TaskDoneIcon({
  color = "#48b02c",
  height = "512",
  width = "512",
}) {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      height={height} // Set height from props
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 254000 254000"
      width={width} // Set width from props
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="图层_x0020_1">
        <path
          d="m127000 254000c-70129 0-127000-56871-127000-127000s56871-127000 127000-127000 127000 56871 127000 127000-56871 127000-127000 127000zm-17473-104654 76212-76211c2637-2638 6967-2611 9578 0l8642 8642c2611 2611 2611 6968 0 9578l-89509 89510c-1601 1601-3850 2231-5957 1869-1442-181-2833-814-3919-1899l-54525-54525c-2606-2606-2637-6941 0-9579l8642-8642c2638-2637 6941-2637 9579 0z"
          fill={color} // Set fill color from props
        />
      </g>
    </svg>
  );
}
