import {
  Button,
  FormGroup,
  H3,
  InputGroup,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import { Ec1Toaster } from "../../../ui/components/Ec1Toaster.react";
import { useSettings } from "./settings";

type IntentState = {
  oldPassword: Intent;
  newPassword: Intent;
  confirmPassword: Intent;
};

const useStyles = createUseStyles({
  validationError: {
    color: "#f17575",
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: 14,
  },
});

export default function PlatformSiteSettingsPage() {
  const classes = useStyles();

  const { changePassword } = useSettings();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [intents, setIntents] = useState<IntentState>({
    oldPassword: Intent.NONE,
    newPassword: Intent.NONE,
    confirmPassword: Intent.NONE,
  });

  const [validationErrors, setValidationErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const validateForm = () => {
    const errors = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };

    let isValid = true;

    // Left to 6 for backwards compatibility
    if (values.oldPassword.length < 6) {
      errors.oldPassword = "Old password is not valid.";
      isValid = false;
    }

    if (values.newPassword.length < 8) {
      errors.newPassword = "New password must be at least 8 characters.";
      isValid = false;
    }

    if (values.confirmPassword.length < 8) {
      errors.confirmPassword = "New password must be at least 8 characters.";
      isValid = false;
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword =
        "New password and confirmation password do not match.";
      isValid = false;
    }

    setValidationErrors(errors);

    setIntents({
      oldPassword: errors.oldPassword === "" ? Intent.NONE : Intent.DANGER,
      newPassword: errors.newPassword === "" ? Intent.NONE : Intent.DANGER,
      confirmPassword:
        errors.confirmPassword === "" ? Intent.NONE : Intent.DANGER,
    });

    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await changePassword(
        values.oldPassword,
        values.newPassword
      );

      if (response.status) {
        showToast(response.status, Intent.DANGER);
        setValidationErrors({
          oldPassword: response.detail?.old_password?.join(" ") ?? "",
          newPassword: response.detail?.new_password?.join(" ") ?? "",
          confirmPassword: "",
        });
        setIntents({
          oldPassword: response.detail?.old_password
            ? Intent.DANGER
            : Intent.NONE,
          newPassword: response.detail?.new_password
            ? Intent.DANGER
            : Intent.NONE,
          confirmPassword: Intent.NONE,
        });
      } else {
        handleClear();
        showToast("Password changed successfully!", Intent.SUCCESS);
      }
    } catch (error) {
      showToast("Changing password failed. Please try again.", Intent.DANGER);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClear = () => {
    setValues({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setValidationErrors({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setIntents({
      oldPassword: Intent.NONE,
      newPassword: Intent.NONE,
      confirmPassword: Intent.NONE,
    });
  };

  const showToast = (message: string, intent: Intent) => {
    if (intent === Intent.DANGER) {
      setIntents({
        oldPassword: Intent.DANGER,
        newPassword: Intent.DANGER,
        confirmPassword: Intent.DANGER,
      });
    }

    Ec1Toaster.show({
      timeout: 5000,
      message: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: 20,
              fontFamily: "Barlow",
            }}
          >
            <div style={{ marginRight: 15, height: 20 }}>{message}</div>
          </div>
        </>
      ),
      intent: intent,
    });
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "64px 24px 38px 24px",
      }}
    >
      <div
        style={{
          flex: 1,
          fontFamily: "Barlow",
        }}
      >
        <div
          style={{
            borderRadius: 6,
            padding: 24,
            paddingBottom: 24,
            fontFamily: "Barlow",
            width: "100%",
            height: 345,
            backgroundColor: "#252A31",
          }}
        >
          {isSubmitting && (
            <div
              style={{
                background: "rgba(0,0,0,.3)",
                borderRadius: 6,
                fontFamily: "Barlow",
                width: "calc(100% - 48px)",
                height: 345,
                position: "absolute",
                margin: -24,
                zIndex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 298,
                  marginLeft: 29,
                  width: 100,
                }}
              >
                <Spinner size={20} style={{ marginRight: 10 }} />
                <div>Resetting...</div>
              </div>
            </div>
          )}
          <H3>
            <strong>Change Password</strong>
          </H3>
          <form
            onSubmit={handleSubmit}
            style={{ width: 300, marginTop: 20, marginLeft: 5 }}
          >
            <FormGroup label="Old Password" labelFor="old-password">
              <InputGroup
                id="old-password"
                type="password"
                value={values.oldPassword}
                intent={intents.oldPassword}
                onChange={(e) => {
                  setValues((prevValues) => ({
                    ...prevValues,
                    oldPassword: e.target.value,
                  }));
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    oldPassword: "",
                  }));
                  setIntents((prevIntents) => ({
                    ...prevIntents,
                    oldPassword: Intent.NONE,
                  }));
                }}
              />
              <div className={classes.validationError}>
                {validationErrors.oldPassword}
              </div>
            </FormGroup>
            <FormGroup
              label="New Password"
              labelFor="new-password"
              style={{ marginTop: -11 }}
            >
              <InputGroup
                id="new-password"
                type="password"
                value={values.newPassword}
                intent={intents.newPassword}
                onChange={(e) => {
                  setValues((prevValues) => ({
                    ...prevValues,
                    newPassword: e.target.value,
                  }));
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    newPassword: "",
                  }));
                  setIntents((prevIntents) => ({
                    ...prevIntents,
                    newPassword: Intent.NONE,
                  }));
                }}
              />
              <div className={classes.validationError}>
                {validationErrors.newPassword}
              </div>
            </FormGroup>
            <FormGroup
              label="Confirm New Password"
              labelFor="confirm-password"
              style={{ marginTop: -11 }}
            >
              <InputGroup
                id="confirm-password"
                type="password"
                value={values.confirmPassword}
                intent={intents.confirmPassword}
                onChange={(e) => {
                  setValues((prevValues) => ({
                    ...prevValues,
                    confirmPassword: e.target.value,
                  }));
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    confirmPassword: "",
                  }));
                  setIntents((prevIntents) => ({
                    ...prevIntents,
                    confirmPassword: Intent.NONE,
                  }));
                }}
              />
              <div className={classes.validationError}>
                {validationErrors.confirmPassword}
              </div>
            </FormGroup>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: 13,
              }}
            >
              <Button
                outlined
                intent="danger"
                onClick={handleClear}
                style={{ marginRight: 10 }}
              >
                Clear
              </Button>
              <Button outlined intent="primary" type="submit">
                Change Password
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
