import {
  Button,
  Dialog,
  DialogProps,
  FormGroup,
  H4,
  InputGroup,
  Intent,
  Spinner,
  Tab,
  Tabs,
  TabsExpander,
} from "@blueprintjs/core";
import { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { Ec1Toaster } from "../../../ui/components/Ec1Toaster.react";
import { useEnterpriseLabels } from "../enterpriseLabels/useEnterpriseLabels";
import { Customer, useCustomers } from "./customers";

type SELECTED_TAB_ID_TYPE = "addCustomerManual" | "addCustomerNIF";

interface PlatformAddCustomerDialogProps extends DialogProps {
  onEnrollmentSuccess?: () => void;
  setIsLoadingParent: (loading: boolean) => void;
}

const useStyles = createUseStyles({
  dialog: {
    fontFamily: "Barlow",
    padding: 24,
    width: "400px",
    boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.2) !important",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "8px",
  },
  validationError: {
    color: "#f17575",
    height: 10,
  },
  selectError: {
    border: "1px solid #DB3737",
    borderRadius: 3,
  },
});

export default function PlatformAddCustomerDialog({
  onEnrollmentSuccess,
  setIsLoadingParent,
  onClose,
  ...dialogProps
}: PlatformAddCustomerDialogProps) {
  const classes = useStyles();
  const { selectedLabelObj } = useEnterpriseLabels();
  const { enrollCustomer, enrollCustomerManual, checkEnrollmentStatus } =
    useCustomers();

  const [NIF, setNIF] = useState<string>("");
  const [NIFValidationError, setNIFValidationError] = useState<string>("");
  const [NIFIntent, setNIFIntent] = useState<Intent>(Intent.NONE);

  const [firstName, setFirstName] = useState("");
  const [firstNameValidationError, setFirstNameValidationError] =
    useState<string>("");
  const [firstNameIntent, setFirstNameIntent] = useState<Intent>(Intent.NONE);

  const [lastName, setLastName] = useState("");
  const [lastNameValidationError, setLastNameValidationError] =
    useState<string>("");
  const [lastNameIntent, setLastNameIntent] = useState<Intent>(Intent.NONE);

  const [customerUID, setCustomerUID] = useState("");
  const [customerUIDValidationError, setCustomerUIDValidationError] =
    useState<string>("");
  const [customerUIDIntent, setCustomerUIDIntent] = useState<Intent>(
    Intent.NONE
  );

  const [email, setEmail] = useState("");
  const [emailValidationError, setEmailValidationError] = useState<string>("");
  const [emailIntent, setEmailIntent] = useState<Intent>(Intent.NONE);

  const [landline, setLandline] = useState("");
  const [landlineValidationError, setLandlineValidationError] =
    useState<string>("");
  const [landlineIntent, setLandlineIntent] = useState<Intent>(Intent.NONE);

  const [mobile, setMobile] = useState("");
  const [mobileValidationError, setMobileValidationError] =
    useState<string>("");
  const [mobileIntent, setMobileIntent] = useState<Intent>(Intent.NONE);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTabId, setSelectedTabId] =
    useState<SELECTED_TAB_ID_TYPE>("addCustomerManual");

  const setLoadingState = useCallback(
    (loading: boolean) => {
      setIsLoading(loading);
      setIsLoadingParent(loading);
    },
    [setIsLoadingParent]
  );

  const showToast = useCallback(
    (
      message: string,
      intent: Intent,
      buttonText?: string,
      buttonOnClick?: () => void
    ) => {
      if (selectedTabId === "addCustomerNIF" && intent === Intent.DANGER) {
        setNIFIntent(Intent.DANGER);
      }

      Ec1Toaster.show({
        timeout: 5000,
        message: (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 18,
                fontFamily: "Barlow",
              }}
            >
              <div style={{ marginRight: 15, height: 20 }}>{message}</div>
              {buttonText && (
                <Button
                  intent="primary"
                  text={buttonText}
                  onClick={buttonOnClick}
                  style={{
                    height: 20,
                    padding: "0 5px",
                    minHeight: "unset",
                    minWidth: "unset",
                    fontSize: 12,
                  }}
                />
              )}
            </div>
          </>
        ),
        intent: intent,
      });
    },
    [selectedTabId]
  );

  const resetManualForm = useCallback(() => {
    setFirstName("");
    setFirstNameValidationError("");
    setFirstNameIntent(Intent.NONE);

    setLastName("");
    setLastNameValidationError("");
    setLastNameIntent(Intent.NONE);

    setCustomerUID("");
    setCustomerUIDValidationError("");
    setCustomerUIDIntent(Intent.NONE);

    setEmail("");
    setEmailValidationError("");
    setEmailIntent(Intent.NONE);

    setLandline("");
    setLandlineValidationError("");
    setLandlineIntent(Intent.NONE);

    setMobile("");
    setMobileValidationError("");
    setMobileIntent(Intent.NONE);
  }, []);

  const resetNIFForm = useCallback(() => {
    setNIF("");
    setNIFValidationError("");
    setNIFIntent(Intent.NONE);
  }, []);

  const resetForm = useCallback(() => {
    resetNIFForm();
    resetManualForm();
    setLoadingState(false);
  }, [resetManualForm, resetNIFForm, setLoadingState]);

  const checkEnrollment = useCallback(
    async (customerEnrollmentId: number) => {
      try {
        const data = await checkEnrollmentStatus(customerEnrollmentId);

        if (data.status === "completed") {
          setLoadingState(false);
          onEnrollmentSuccess?.();
          showToast(
            `Customer${
              data.customerIds && data.customerIds.length > 1 ? "s" : ""
            } enrolled successfully!`,
            Intent.SUCCESS,
            data.customerIds && data.customerIds.length > 0
              ? `View Customer${
                  data.customerIds && data.customerIds.length > 1 ? "s" : ""
                }`
              : undefined,
            data.customerIds && data.customerIds.length > 0
              ? () => {
                  if (data.customerIds && data.customerIds.length) {
                    data.customerIds.forEach((customerId) => {
                      window.open(`/customers/${customerId}`, "_blank");
                    });
                  }
                }
              : undefined
          );
          resetForm();
        } else if (data.status === "queued" || data.status === "processing") {
          setTimeout(() => {
            checkEnrollment(customerEnrollmentId);
          }, 1000);
        } else {
          setLoadingState(false);
          showToast("Enrollment failed. Please try again.", Intent.DANGER);
        }
      } catch (error) {
        setLoadingState(false);
        showToast("Enrollment failed. Please try again.", Intent.DANGER);
      }
    },
    [
      checkEnrollmentStatus,
      onEnrollmentSuccess,
      showToast,
      setLoadingState,
      resetForm,
    ]
  );

  const handleEnrollClick = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      let isValid = true;

      if (selectedTabId === "addCustomerNIF") {
        if (!NIF.trim()) {
          setNIFIntent(Intent.DANGER);
          setNIFValidationError("NIF/NIE cannot be blank.");
          isValid = false;
        } else {
          setNIFValidationError("");
        }

        if (!isValid) {
          return;
        }

        setLoadingState(true);

        try {
          const customerEnrollmentId = await enrollCustomer({
            nif: NIF,
          });
          if (!customerEnrollmentId) {
            setLoadingState(false);
            showToast("Enrollment failed. Please try again.", Intent.DANGER);
            return;
          }
          await checkEnrollment(customerEnrollmentId);
        } catch (error) {
          setLoadingState(false);
          showToast("Enrollment failed. Please try again.", Intent.DANGER);
        }
      } else if (selectedTabId === "addCustomerManual") {
        if (!firstName.trim()) {
          setFirstNameIntent(Intent.DANGER);
          setFirstNameValidationError("First name cannot be blank.");
          isValid = false;
        } else {
          setFirstNameValidationError("");
        }

        if (!lastName.trim()) {
          setLastNameIntent(Intent.DANGER);
          setLastNameValidationError("Last name cannot be blank.");
          isValid = false;
        } else {
          setLastNameValidationError("");
        }

        if (!customerUID.trim()) {
          setCustomerUIDIntent(Intent.DANGER);
          setCustomerUIDValidationError(
            `${
              selectedLabelObj?.customer_uid_field_name ?? "Customer UID"
            } cannot be blank.`
          );
          isValid = false;
        } else {
          setCustomerUIDValidationError("");
        }

        if (!isValid) {
          return;
        }

        setLoadingState(true);

        try {
          const response = await enrollCustomerManual({
            first_name: firstName,
            last_name: lastName,
            customer_uid: customerUID,
            email: email,
            landline: landline,
            mobile: mobile,
          } as Customer);

          setLoadingState(false);

          const data = response.result as any;

          if (response.success) {
            onEnrollmentSuccess?.();
            showToast(
              `Customer enrolled successfully!`,
              Intent.SUCCESS,
              data ? `View Customer` : undefined,
              data
                ? () => {
                    window.open(`/customers/${data}`, "_blank");
                  }
                : undefined
            );
            resetForm();
          } else {
            if (data["first_name"]) {
              setFirstNameIntent(Intent.DANGER);
              setFirstNameValidationError(data["first_name"]);
            }

            if (data["last_name"]) {
              setLastNameIntent(Intent.DANGER);
              setLastNameValidationError(data["last_name"]);
            }

            if (data["customer_uid"]) {
              setCustomerUIDIntent(Intent.DANGER);
              setCustomerUIDValidationError(data["customer_uid"]);
            }

            if (data["email"]) {
              setEmailIntent(Intent.DANGER);
              setEmailValidationError(data["email"]);
            }

            if (data["landline"]) {
              setLandlineIntent(Intent.DANGER);
              setLandlineValidationError(data["landline"]);
            }

            if (data["mobile"]) {
              setMobileIntent(Intent.DANGER);
              setMobileValidationError(data["mobile"]);
            }

            showToast("Enrollment failed. Please try again.", Intent.DANGER);

            return;
          }
        } catch (error) {
          setLoadingState(false);
          showToast("Enrollment failed. Please try again.", Intent.DANGER);
        }
      }
    },
    [
      selectedTabId,
      NIF,
      setLoadingState,
      enrollCustomer,
      checkEnrollment,
      showToast,
      firstName,
      lastName,
      customerUID,
      selectedLabelObj,
      enrollCustomerManual,
      email,
      landline,
      mobile,
      onEnrollmentSuccess,
      resetForm,
    ]
  );

  const handleClose = useCallback(
    (event?: React.SyntheticEvent<HTMLElement, Event>) => {
      if (!isLoading) {
        resetForm();
      }
      if (event && onClose) {
        onClose(event);
      }
      setSelectedTabId("addCustomerManual");
    },
    [isLoading, onClose, resetForm]
  );

  const handleTabChange = useCallback((newTabId: SELECTED_TAB_ID_TYPE) => {
    setSelectedTabId(newTabId);
  }, []);

  const height = selectedTabId === "addCustomerNIF" ? 211 : 601;
  const margin = selectedTabId === "addCustomerNIF" ? 162 : 552;

  const renderManualForm = useCallback(
    () => (
      <div style={{ marginTop: "8px" }}>
        <FormGroup label="First name" disabled={isLoading}>
          <InputGroup
            type="text"
            disabled={isLoading}
            value={firstName}
            autoComplete="new-firstName"
            data-testid="new-firstName"
            onChange={(e) => {
              const isEmpty = e.target.value === "";
              const intent = isEmpty ? Intent.DANGER : Intent.NONE;
              const error = isEmpty ? "First name cannot be blank." : "";
              setFirstNameIntent(intent);
              setFirstNameValidationError(error);
              setFirstName(e.target.value);
            }}
            intent={firstNameIntent}
          />
          <div className={classes.validationError}>
            {firstNameValidationError}
          </div>
        </FormGroup>
        <FormGroup label="Last name" disabled={isLoading}>
          <InputGroup
            type="text"
            disabled={isLoading}
            value={lastName}
            autoComplete="new-lastName"
            data-testid="new-lastName"
            onChange={(e) => {
              const isEmpty = e.target.value === "";
              const intent = isEmpty ? Intent.DANGER : Intent.NONE;
              const error = isEmpty ? "Last name cannot be blank." : "";
              setLastNameIntent(intent);
              setLastNameValidationError(error);
              setLastName(e.target.value);
            }}
            intent={lastNameIntent}
          />
          <div className={classes.validationError}>
            {lastNameValidationError}
          </div>
        </FormGroup>
        <FormGroup
          label={selectedLabelObj?.customer_uid_field_name ?? "Customer UID"}
          disabled={isLoading}
        >
          <InputGroup
            type="text"
            disabled={isLoading}
            value={customerUID}
            autoComplete="new-customerUID"
            data-testid="new-customerUID"
            onChange={(e) => {
              const isEmpty = e.target.value === "";
              const intent = isEmpty ? Intent.DANGER : Intent.NONE;
              const error = isEmpty
                ? `${
                    selectedLabelObj?.customer_uid_field_name ?? "Customer UID"
                  } cannot be blank.`
                : "";
              setCustomerUIDIntent(intent);
              setCustomerUIDValidationError(error);
              setCustomerUID(e.target.value);
            }}
            intent={customerUIDIntent}
          />
          <div className={classes.validationError}>
            {customerUIDValidationError}
          </div>
        </FormGroup>
        <FormGroup label="Email" disabled={isLoading}>
          <InputGroup
            type="email"
            disabled={isLoading}
            value={email}
            autoComplete="new-email"
            data-testid="new-email"
            onChange={(e) => {
              const isEmpty = e.target.value === "";
              const intent = isEmpty ? Intent.DANGER : Intent.NONE;
              const error = isEmpty ? "Email cannot be blank." : "";
              setEmailIntent(intent);
              setEmailValidationError(error);
              setEmail(e.target.value);
            }}
            intent={emailIntent}
          />
          <div className={classes.validationError}>{emailValidationError}</div>
        </FormGroup>
        <FormGroup label="Landline" disabled={isLoading}>
          <InputGroup
            type="tel"
            disabled={isLoading}
            value={landline}
            autoComplete="new-landline"
            data-testid="new-landline"
            onChange={(e) => {
              const isEmpty = e.target.value === "";
              const intent = isEmpty ? Intent.DANGER : Intent.NONE;
              const error = isEmpty ? "Landline cannot be blank." : "";
              setLandlineIntent(intent);
              setLandlineValidationError(error);
              setLandline(e.target.value);
            }}
            intent={landlineIntent}
          />
          <div className={classes.validationError}>
            {landlineValidationError}
          </div>
        </FormGroup>
        <FormGroup label="Mobile" disabled={isLoading}>
          <InputGroup
            type="tel"
            disabled={isLoading}
            value={mobile}
            autoComplete="new-mobile"
            data-testid="new-mobile"
            onChange={(e) => {
              const isEmpty = e.target.value === "";
              const intent = isEmpty ? Intent.DANGER : Intent.NONE;
              const error = isEmpty ? "Mobile cannot be blank." : "";
              setMobileIntent(intent);
              setMobileValidationError(error);
              setMobile(e.target.value);
            }}
            intent={mobileIntent}
          />
          <div className={classes.validationError}>{mobileValidationError}</div>
        </FormGroup>
      </div>
    ),
    [
      isLoading,
      firstName,
      firstNameIntent,
      classes.validationError,
      firstNameValidationError,
      lastName,
      lastNameIntent,
      lastNameValidationError,
      selectedLabelObj,
      customerUID,
      customerUIDIntent,
      customerUIDValidationError,
      email,
      emailIntent,
      emailValidationError,
      landline,
      landlineIntent,
      landlineValidationError,
      mobile,
      mobileIntent,
      mobileValidationError,
    ]
  );

  const renderAudaxNIFForm = useCallback(
    () => (
      <div style={{ marginTop: "8px" }}>
        <FormGroup label="NIF/NIE" disabled={isLoading}>
          <InputGroup
            type="NIF"
            disabled={isLoading}
            value={NIF}
            autoComplete="new-NIF"
            data-testid="new-NIF"
            onChange={(e) => {
              const isEmpty = e.target.value === "";
              const intent = isEmpty ? Intent.DANGER : Intent.NONE;
              const error = isEmpty ? "NIF/NIE cannot be blank." : "";
              setNIFIntent(intent);
              setNIFValidationError(error);
              setNIF(e.target.value);
            }}
            intent={NIFIntent}
          />
          <div className={classes.validationError}>{NIFValidationError}</div>
        </FormGroup>
      </div>
    ),
    [isLoading, NIF, NIFIntent, classes.validationError, NIFValidationError]
  );

  return (
    <>
      <Dialog
        className={`bp5-dark ${classes.dialog}`}
        {...dialogProps}
        onClose={handleClose}
      >
        <form>
          {isLoading && (
            <div
              style={{
                background: "rgba(0, 0, 0, 0.3)",
                width: 400,
                height: height,
                position: "absolute",
                margin: -24,
                borderRadius: 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: margin,
                  marginLeft: 24,
                  width: 100,
                }}
              >
                <Spinner size={20} style={{ marginRight: 10 }} />
                <div style={{}}>Enrolling...</div>
              </div>
            </div>
          )}
          <div style={{ position: "absolute" }}>
            <H4>
              {selectedTabId === "addCustomerNIF"
                ? "Import new Customer"
                : "Add new Customer"}
            </H4>
          </div>

          {selectedLabelObj?.name.toLocaleLowerCase() === "audax" ? (
            <div style={{ marginTop: -3 }}>
              <Tabs onChange={handleTabChange} selectedTabId={selectedTabId}>
                <TabsExpander />
                <Tab
                  id="addCustomerManual"
                  title="Add"
                  disabled={isLoading}
                  panel={renderManualForm()}
                />
                <Tab
                  id="addCustomerNIF"
                  title="Import"
                  disabled={isLoading}
                  panel={renderAudaxNIFForm()}
                />
              </Tabs>
            </div>
          ) : (
            <div style={{ marginTop: 39 }}>{renderManualForm()}</div>
          )}
          <div className={classes.buttonContainer}>
            <Button
              outlined
              intent="danger"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <div style={{ width: "8px" }} />
            <Button
              outlined
              intent="primary"
              onClick={handleEnrollClick}
              disabled={isLoading}
              type="submit"
            >
              Enroll
            </Button>
          </div>
        </form>
      </Dialog>
    </>
  );
}
