import { ReactNode, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import FOG from "vanta/dist/vanta.fog.min";
import "../../fonts/fonts.css";
const useStyles = createUseStyles({
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  logoText: {
    animation: "$fadeIn 2s",
  },
  root: {
    backgroundColor: "black",
    position: "fixed",
    top: 0,
    width: "100%",
    height: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
});

interface VantaPageProps {
  children: ReactNode;
}
export default function VantaPage({ children }: VantaPageProps) {
  const [vantaEffect, setVantaEffect] = useState<any>(null);
  const myRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        FOG({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          highlightColor: 0x220011,
          midtoneColor: 0x1014d,
          lowlightColor: 0x2f00,
          baseColor: 0x0,
          blurFactor: 0.57,
          speed: 5.0,
          zoom: 1.4,
        })
      );
    }
    return () => {
      if (vantaEffect) {
        vantaEffect.destroy();
      }
    };
  }, [vantaEffect]);

  return (
    <div className={classes.root} ref={myRef}>
      {children}
    </div>
  );
}
