import { Spinner } from "@blueprintjs/core";
import mapboxgl, { Marker, NavigationControl } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { MAPBOX_TOKEN } from "../../barcelonaLocations";
import useResizeObserver from "../useResizeObserver";

mapboxgl.accessToken = MAPBOX_TOKEN;

interface PlatformSitePropertyMapProps {
  height?: string;
  latitude?: number | null;
  longitude?: number | null;
  disabled?: boolean;
  loading?: boolean;
}

export default function PlatformSitePropertyMap({
  height = "200px",
  latitude = null,
  longitude = null,
  disabled = false,
  loading = false,
}: PlatformSitePropertyMapProps) {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const dimensions = useResizeObserver(mapContainer);

  const markersRef = useRef<mapboxgl.Marker[]>([]);

  useEffect(() => {
    if (map.current || !mapContainer.current) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v11",
      center: [longitude ?? -0.1276, latitude ?? 51.5074], // Center on London
      zoom: longitude && latitude ? 16 : 5, // Zoom out if centered on London
      attributionControl: false,
      projection: {
        name: "mercator",
      },
    });

    // Add zoom and rotation controls to the map.
    map.current.addControl(new NavigationControl(), "bottom-left");

    // Apply dark theme to navigation controls
    const style = document.createElement("style");
    style.textContent = `
              .mapboxgl-ctrl-group {
                margin-left: 15px !important;
                margin-bottom: 5px !important;
                background: #3c4147 !important;
              }
              .mapboxgl-ctrl-group button {
                background-color: #3c4147 !important;
                border-color: #7c8289 !important;
                border-radius: 0 !important;
              }
              .mapboxgl-ctrl-group button:hover {
                background-color: #545c66 !important;
              }          
              .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
              .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon,
              .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
                background-color: transparent;
              }
              .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
              }
              .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
              }
            `;
    document.head.appendChild(style);

    // Remove Mapbox logo
    if (map.current) {
      const logoEl = map.current
        .getContainer()
        .querySelector(".mapboxgl-ctrl-logo");
      if (logoEl) {
        logoEl.remove();
      }
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (!map.current) {
      return;
    }

    // Remove existing marker
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];

    // Prepare to fit marker within the view
    const bounds = new mapboxgl.LngLatBounds();

    // Create a marker and add to the map
    if (map.current && longitude !== null && latitude !== null) {
      const marker = new Marker({ color: "#ff5257" })
        .setLngLat([longitude, latitude])
        .addTo(map.current);

      // Extend the bounds to include marker's position
      bounds.extend([longitude, latitude]);
      markersRef.current.push(marker);
    }

    if (markersRef.current.length > 0) {
      map.current.fitBounds(bounds, {
        maxZoom: 16,
        animate: false,
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    map.current?.resize();
  }, [dimensions]);

  return (
    <div style={{ position: "relative", height, width: "100%" }}>
      <div
        ref={mapContainer}
        style={{
          height,
          width: "100%",
          borderRadius: "3px",
        }}
      />
      {(disabled || loading) && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            backdropFilter: "blur(2px)",
            zIndex: 3,
          }}
        >
          {loading && <Spinner />}
        </div>
      )}
    </div>
  );
}
