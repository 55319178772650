import React, { ReactNode } from "react";
import { ContractProvider } from "../contracts/contracts";
import { CustomerProvider } from "../customers/customers";
import { DeviceProvider } from "../devices/devices";
import { InvoiceProvider } from "../invoices/invoices";
import { PropertyProvider } from "../properties/properties";
import { SettingProvider } from "../settings/settings";
import { VendorAccountProvider } from "../vendorAccounts/vendorAccounts";

interface CoreEntitiesProviderProps {
  children: ReactNode;
}

const CoreEntitiesProvider: React.FC<CoreEntitiesProviderProps> = ({
  children,
}) => {
  return (
    <SettingProvider>
      <PropertyProvider>
        <CustomerProvider>
          <ContractProvider>
            <InvoiceProvider>
              <VendorAccountProvider>
                <DeviceProvider>{children}</DeviceProvider>
              </VendorAccountProvider>
            </InvoiceProvider>
          </ContractProvider>
        </CustomerProvider>
      </PropertyProvider>
    </SettingProvider>
  );
};

export default CoreEntitiesProvider;
