import { Tag } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import { useMemo } from "react";

interface BatteryChargingModeTagProps {
  battery: Battery;
}

export default function BatteryChargingModeTag({
  battery,
}: BatteryChargingModeTagProps) {
  const is_smart_charging = useMemo(() => {
    return battery?.is_smart_charging ?? null;
  }, [battery]);

  if (is_smart_charging === null) {
    return <div>{"-"}</div>;
  }

  if (!is_smart_charging) {
    return <Tag style={{ fontWeight: 500 }}>{"Manual"}</Tag>;
  }

  return (
    <Tag
      style={{ backgroundColor: "#D1980B", color: "black", fontWeight: 500 }}
    >
      {"Smart Charging"}
    </Tag>
  );
}
