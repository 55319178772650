import { useCallback, useEffect, useState } from "react";
import { useAuthHeaders } from "../common/authentication/authentication";

// Assuming the structure of your document, adjust it according to your actual data model
interface Canvas {
  id: string;
  title: string;
  components: number[];
}

interface CanvasComponent {
  id: number;
  dataset: string;
  pos_x: number;
  pos_y: number;
  height: number;
  width: number;
}

export interface CanvasCoordinates {
  x: number;
  y: number;
}

export interface CanvasComponentDimensions {
  width: number;
  height: number;
}

type Positions = { [key: number]: CanvasCoordinates };
type Dimensions = { [key: number]: CanvasComponentDimensions };

export function useCanvasWithDataset() {
  const [isCreatingDataset, setIsCreatingDataset] = useState(false);
  const headers = useAuthHeaders();

  const createCanvasWithDataset = useCallback(
    (datasetUUID: string) => {
      if (isCreatingDataset) {
        throw Error("Already Creating dataset.");
      }
      setIsCreatingDataset(true);
      const body = JSON.stringify({ dataset_uuid: datasetUUID });
      return fetch(`/api/canvas/create-with-dataset/`, {
        headers,
        body,
        method: "POST",
      })
        .then((response) => response.json())
        .then((json) => {
          setIsCreatingDataset(false);
          return json;
        });
    },
    [headers, isCreatingDataset]
  );

  const addDatasetToCanvas = useCallback(
    (canvasID: string, datasetUUID: string) => {
      if (isCreatingDataset) {
        throw Error("Already Creating dataset.");
      }
      setIsCreatingDataset(true);
      const body = JSON.stringify({ dataset_uuid: datasetUUID });
      return fetch(`/api/canvas/${canvasID}/add-dataset/`, {
        headers,
        body,
        method: "POST",
      })
        .then((response) => response.json())
        .then((json) => {
          setIsCreatingDataset(false);
          return json;
        });
    },
    [headers, isCreatingDataset]
  );
  return { createCanvasWithDataset, addDatasetToCanvas, isCreatingDataset };
}

export function useFetchRecentCanvases() {
  const headers = useAuthHeaders();

  const [canvases, setCanvases] = useState<Canvas[] | null>(null);
  const [isFetchingCanvases, setIsFetchingCanvases] = useState<boolean>(false);

  const fetchCanvases = useCallback(() => {
    async function fetchCanvasesAsync() {
      setIsFetchingCanvases(true);
      const response = await fetch(`/api/canvas/`, {
        headers,
      });
      if (response.ok) {
        setCanvases((await response.json()).results as Canvas[]);
        setIsFetchingCanvases(false);
      } else {
        setIsFetchingCanvases(false);
        throw Error(await response.text());
      }
    }

    if (isFetchingCanvases === false) {
      fetchCanvasesAsync();
    }
  }, [isFetchingCanvases, headers]);
  useEffect(() => {
    fetchCanvases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { fetchCanvases, canvases, isFetchingCanvases };
}

export function useCanvas(canvasID: string | null) {
  const headers = useAuthHeaders();

  const [canvas, setCanvas] = useState<Canvas | null>(null);
  const [isCreatingCanvas] = useState(false);
  const [isSavingCanvas, setIsSavingCanvas] = useState(false);
  const [isFetchingCanvas, setIsFetchingCanvas] = useState(false);
  const [isFetchingComponents, setIsFetchingComponents] = useState(false);
  const [components, setComponents] = useState<CanvasComponent[] | null>(null);

  const saveCanvas = useCallback(
    (title: string, positions: Positions, dimensions: Dimensions) => {
      async function saveCanvasAsync(
        title: string,
        positions: Positions,
        dimensions: Dimensions
      ) {
        setIsSavingCanvas(true);

        const componentIDs = Array.from(
          new Set<number>([
            ...Object.keys(positions).map(Number),
            ...Object.keys(dimensions).map(Number),
          ])
        );

        const fetchPromises = componentIDs.map((componentID) => {
          const body: any = {};

          if (positions[componentID].x !== undefined) {
            body["pos_x"] = Math.round(positions[componentID].x);
          }
          if (positions[componentID].y !== undefined) {
            body["pos_y"] = Math.round(positions[componentID].y);
          }
          if (dimensions[componentID].height !== undefined) {
            body["height"] = Math.round(dimensions[componentID].height);
          }
          if (dimensions[componentID].width !== undefined) {
            body["width"] = Math.round(dimensions[componentID].width);
          }

          return fetch(`/api/canvas/component/${componentID}/`, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers,
          }).then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          });
        });

        const componentsData = await Promise.all(fetchPromises);
        setComponents(componentsData as CanvasComponent[]);

        if (!canvas?.id) {
          throw Error("Invalid Canvas ID");
        }
        const response = await fetch(`/api/canvas/${canvas.id}/`, {
          method: "PATCH",
          headers,
          body: JSON.stringify({ title }),
        });
        if (response.ok) {
          const obj = await response.json();

          setCanvas(obj as Canvas);
        } else {
          setIsSavingCanvas(false);
          throw Error(`Invalid response ${await response.text()}`);
        }
        setIsSavingCanvas(false);
      }
      if (isSavingCanvas === false) {
        saveCanvasAsync(title, positions, dimensions);
      }
    },
    [canvas?.id, headers, isSavingCanvas]
  );

  const fetchCanvas = useCallback(
    (canvasID: string) => {
      async function fetchCanvasAsync() {
        setIsFetchingCanvas(true);
        const response = await fetch(`/api/canvas/${canvasID}/`, {
          headers,
        });
        if (response.ok) {
          setCanvas((await response.json()) as Canvas);
          setIsFetchingCanvas(false);
        } else {
          setIsFetchingCanvas(false);
          throw Error(await response.text());
        }
      }

      if (isFetchingCanvas === false) {
        fetchCanvasAsync();
      }
    },
    [isFetchingCanvas, headers]
  );
  const fetchCanvasComponents = useCallback(() => {
    async function fetchCanvasComponentsAsync() {
      if (canvas === null || canvas.components.length === 0) {
        return;
      }

      setIsFetchingComponents(true);

      console.log("FETCHING COMPONENTS");

      try {
        const fetchPromises = canvas.components.map((componentID) =>
          fetch(`/api/canvas/component/${componentID}/`, {
            headers,
          }).then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
        );

        const componentsData = await Promise.all(fetchPromises);

        // Assuming setCanvasComponents is a function to update the state with the fetched components
        setComponents(componentsData as CanvasComponent[]);
      } catch (error) {
        console.error("Error fetching components:", error);
      }

      setIsFetchingComponents(false);
    }

    if (!isFetchingComponents) {
      fetchCanvasComponentsAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingComponents, canvas?.components, headers]);

  useEffect(() => {
    fetchCanvasComponents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas?.components]);

  useEffect(() => {
    if (isFetchingCanvas || isCreatingCanvas || isSavingCanvas) {
      return;
    }

    if (canvasID === null && canvas === null) {
    }

    if (canvasID !== null && canvas === null) {
      console.log("Fetch canvas:", canvasID);
      fetchCanvas(canvasID);
    }
  }, [
    canvasID,

    isCreatingCanvas,
    isFetchingCanvas,
    isSavingCanvas,
    canvas,
    fetchCanvas,
  ]);

  return {
    canvas,

    components,
    isCreatingCanvas,
    isSavingCanvas,
    isFetchingCanvas,
    saveCanvas,
  };
}
