import { Tariff } from "@ec1/types/Tariff";
import React, { useState } from "react";

// Define the types for the props
interface PlatformTariffMenuitemProps {
  tariff: Tariff;
  modifiers: {
    active: boolean;
    matchesPredicate: boolean;
  };
  handleClick: React.MouseEventHandler<HTMLElement>;
  handleFocus?: () => void;
}

export default function PlatformTariffMenuitem({
  tariff,
  modifiers,
  handleClick,
  handleFocus,
}: PlatformTariffMenuitemProps) {
  const [isHovered, setIsHovered] = useState(false);

  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <div
      onClick={handleClick}
      onFocus={handleFocus}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        width: "100%",
        borderRadius: "2px",
        fontFamily: "Barlow",
        backgroundColor: modifiers.active
          ? "#184A90"
          : isHovered
          ? "#1466c2"
          : undefined,
        padding: "4px",
        cursor: "pointer",
        transition: "background-color 0.3s", // transition to hover/active effect
      }}
    >
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {tariff.display_name}
      </div>
      <div
        style={{
          fontWeight: "lighter",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {tariff.unique_name}
      </div>
    </div>
  );
}
