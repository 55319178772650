import { Handle, Position } from "reactflow";
import ErrorIcon from "./ErrorIcon.react";
import RotatingSyncIcon from "./RotatingSyncIcon";
import TaskDoneIcon from "./TaskDoneIcon.react";

interface PlatformDatalayerBaseNodeProps {
  data: { label: string; state?: string };
}

function getIcon(state: string | undefined) {
  if (state === "done") {
    return <TaskDoneIcon color="#238C2C" width="16px" height="16px" />;
  }

  if (state === "error") {
    return <ErrorIcon width="16px" height="16px" />;
  }

  return <RotatingSyncIcon color="black" width="16px" height="16px" />;
}

export default function PlatformDatalayerBaseNode({
  data,
}: PlatformDatalayerBaseNodeProps) {
  return (
    <div
      style={{
        border: "1px solid #333",
        padding: 10,
        backgroundColor: "#D3D8DE",
        width: "200px",
      }}
    >
      <div
        style={{
          fontFamily: "Barlow",
          color: "black",
          fontSize: "12px",
          alignItems: "center",
          display: "flex",
        }}
      >
        {getIcon(data?.state)}

        <div style={{ marginLeft: "8px", fontWeight: "" }}>{data.label}</div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "8px",
        }}
      >
        <div
          style={{
            width: "20px",
            backgroundColor: data?.state === "error" ? "#AC2F33" : "#29A634",
            height: "4px",
            borderRadius: "3px",
          }}
        />
        <div
          style={{
            width: "20px",
            marginLeft: "2px",
            backgroundColor: data?.state === "error" ? "#AC2F33" : "#29A634",
            height: "4px",
            borderRadius: "3px",
          }}
        />
        <div
          style={{
            width: "20px",
            marginLeft: "2px",
            backgroundColor: data?.state === "error" ? "#AC2F33" : "#29A634",
            height: "4px",
            borderRadius: "3px",
          }}
        />
        <div
          style={{
            width: "20px",
            marginLeft: "2px",
            backgroundColor: data?.state === "done" ? "#29A634" : "#8F99A8",
            height: "4px",
            borderRadius: "3px",
          }}
        />
        <div
          style={{
            width: "20px",
            marginLeft: "2px",
            backgroundColor: data?.state === "done" ? "#29A634" : "#8F99A8",
            height: "4px",
            borderRadius: "3px",
          }}
        />
        <div
          style={{
            width: "20px",
            marginLeft: "2px",
            backgroundColor: data?.state === "done" ? "#29A634" : "#8F99A8",
            height: "4px",
            borderRadius: "3px",
          }}
        />
        <div
          style={{
            width: "20px",
            marginLeft: "2px",
            backgroundColor: data?.state === "done" ? "#29A634" : "#8F99A8",
            height: "4px",
            borderRadius: "3px",
          }}
        />
        <div
          style={{
            width: "20px",
            marginLeft: "2px",
            backgroundColor: data?.state === "done" ? "#29A634" : "#8F99A8",
            height: "4px",
            borderRadius: "3px",
          }}
        />
      </div>

      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#555" }}
      />
    </div>
  );
}
