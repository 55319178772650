import { Button, ButtonGroup } from "@blueprintjs/core";

export default function PlatformDigitalTwinExperimentToolbar() {
  return (
    <div
      style={{
        backgroundColor: "#2F343C",
        padding: "2px 12px",
        borderRadius: "4px",
        zIndex: 10000,
      }}
    >
      <ButtonGroup minimal>
        <Button icon="stop" />
        <Button icon="play" />
        <Button icon="pause" />
      </ButtonGroup>
    </div>
  );
}
