import { createUseStyles } from "react-jss";
import "../../fonts/fonts.css";
import logo from "../../logo.png";

interface ISpinningLogoProps {
  height?: string;
  verticalAlign?: string;
  disableSpinning?: boolean;
  isLoadingSpinner?: boolean;
}

interface IStylesProps {
  height: string;
  verticalAlign: string;
  isLoadingSpinner?: boolean;
}

const useStyles = createUseStyles({
  "@keyframes spinVertical": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
  spinningLogo: (props: IStylesProps) => ({
    height: props.height,
    animation: props.isLoadingSpinner
      ? "$spinVertical 2s linear infinite"
      : "$spinVertical 2s linear 1s forwards",
  }),
  loadingMessage: {
    color: "#ccc",
    fontSize: "14px",
    textAlign: "center",
    marginTop: "10px",
    fontFamily: "Space Grotesk",
  },
  logo: (props: IStylesProps) => ({
    height: props.height,
    verticalAlign: props.verticalAlign,
  }),
  centerAlign: {
    textAlign: "center",
  },
});

export default function SpinningLogo({
  height = "16px",
  verticalAlign = "middle",
  disableSpinning = false,
  isLoadingSpinner = false,
}: ISpinningLogoProps) {
  const classes = useStyles({ height, isLoadingSpinner, verticalAlign });
  return (
    <div className={classes.centerAlign}>
      <img
        className={`${!disableSpinning ? classes.spinningLogo : ""} ${
          classes.logo
        }`}
        src={logo}
        alt="Electryone AI Logo"
      />
      {isLoadingSpinner && (
        <div className={classes.loadingMessage}>Loading...</div>
      )}
    </div>
  );
}
