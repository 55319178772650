import { Button, Icon } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import { PlatformCustomer } from "../../../../__generated__/types/PlatformCustomer";
import { PlatformProperty } from "../../../../__generated__/types/PlatformProperty";

interface PlatformPropertyPopoverContentProps {
  battery: Battery;
  property: PlatformProperty;
  customer?: PlatformCustomer;
}

export default function PlatformPropertyPopoverContent({
  battery,
  property,
  customer,
}: PlatformPropertyPopoverContentProps) {
  return (
    <div style={{ fontFamily: "Barlow", padding: 10, cursor: "default" }}>
      <div
        style={{
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>{property.property_uid}</div>
        <div style={{ marginLeft: 5 }}>
          <Button
            minimal
            small
            onClick={() => {
              window.open(`/properties/${property?.id}`, "_blank");
            }}
            title="View Property"
          >
            <Icon icon="share" size={14} style={{ padding: 2 }} />
          </Button>
        </div>
      </div>

      <div
        style={{
          width: "calc(100% - 4px)",
          height: 1,
          background: "#555",
          marginTop: 10,
          marginLeft: 2,
        }}
      ></div>

      <div style={{ marginTop: 12, marginBottom: 6 }}>
        Timezone: {property.local_timezone ?? "-"}
      </div>
      <div style={{ marginTop: 12, marginBottom: 6 }}>
        {!customer ? (
          <div>Customer: -</div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              {`Customer: ${customer.first_name} ${customer.last_name}`}
            </div>
            <div style={{ marginLeft: 5 }}>
              <Button
                minimal
                small
                onClick={() => {
                  window.open(`/customers/${customer.id}`, "_blank");
                }}
                title="View Customer"
              >
                <Icon icon="share" size={14} style={{ padding: 2 }} />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
