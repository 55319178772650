import { Button, ButtonGroup, H1, Text } from "@blueprintjs/core";
import { Cell, Column, RowHeaderCell, Table2 } from "@blueprintjs/table";
import { useEffect, useMemo, useRef, useState } from "react";
import PlatformAssistantHistogramChart from "./PlatformAssistantHistogramChart.react";
import { parse_json_if_possible } from "./PlatformAssistantMessage.react";
import { useAssistantDatasetFetcher } from "./assistant";

type PlatformAssistantDatasetProps = {
  uuid: string | null;
  displayType?: string;
  showFitButton?: boolean;
  showTitle?: boolean;
};
export default function PlatformAssistantDataset({
  uuid,
  displayType,
  showFitButton = false,
  showTitle = true,
}: PlatformAssistantDatasetProps) {
  const [dataset, setDataset] = useState<any | null>(null);
  const [datasetType, setDatasetType] = useState<string | null>(null);
  const [xAxisColumn, setXAxisColumn] = useState<string | null>(null);
  const [yAxisColumn, setYAxisColumn] = useState<string | null>(null);
  const [tooltipSuffix, setTooltipSuffix] = useState<string | null>(null);
  const [dataRecords, setDataRecords] = useState<any[] | null>(null);
  const [columns, setColumns] = useState<string[] | null>(null);
  const [userFriendlyTitle, setUserFriendlyTitle] = useState<string | null>(
    null
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const [columnWidths, setColumnWidths] = useState<number[] | null>(null);

  const [parentWidth, setParentWidth] = useState<number | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        containerRef?.current !== null &&
        containerRef?.current?.parentNode !== null
      ) {
        const parentsParent = containerRef.current.parentNode.parentNode;
        // Now you have the parent's parent element
        // You can get its width like this:
        console.log((parentsParent as HTMLElement).offsetWidth);
        const containerWidth = (parentsParent as HTMLElement).offsetWidth - 68;

        if (parentWidth !== containerWidth) {
          setParentWidth(containerWidth);
        }

        // Add any other logic you need here
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [parentWidth]);

  useEffect(() => {
    if (columns !== null && parentWidth !== null) {
      const numberOfColumns = columns.length;
      const widthPerColumn = parentWidth / numberOfColumns;
      setColumnWidths(new Array(numberOfColumns).fill(widthPerColumn));
    } else {
      setColumnWidths(null);
    }
  }, [columns, parentWidth]);

  const fetchAssistantDataset = useAssistantDatasetFetcher();

  useEffect(() => {
    if (uuid !== null) {
      fetchAssistantDataset(uuid).then(setDataset);
    }
  }, [uuid, setDataset, fetchAssistantDataset]);

  useEffect(() => {
    if (dataset !== null) {
      console.log(dataset);
      const parsed = parse_json_if_possible(dataset.dataset);
      setUserFriendlyTitle(dataset.user_friendly_title);
      if (!Array.isArray(parsed)) {
        throw Error("dataset.dataset should be an array");
      }
      setDataRecords(parsed);
      if (parsed.length > 0) {
        setColumns(Object.keys(parsed[0]));
      }

      setDatasetType(dataset.dataset_type);
      setXAxisColumn(dataset.x_axis_column);
      setYAxisColumn(dataset.y_axis_column);
      setTooltipSuffix(dataset.tooltip_suffix);
    }
  }, [dataset]);

  const table = useMemo(() => {
    if (columns === null) {
      return null;
    }
    if (dataRecords === null) {
      return null;
    }
    if (columnWidths === null) {
      return null;
    }
    console.log(columnWidths);
    return (
      <Table2
        key={columnWidths[0]}
        numRows={Math.min(dataRecords?.length ?? 20, 25)}
        columnWidths={columnWidths ?? undefined}
        rowHeaderCellRenderer={(rowIndex) => (
          <RowHeaderCell name={`${rowIndex + 1}`} />
        )}
      >
        {columns.map((col) => (
          <Column
            name={col}
            cellRenderer={(rowIndex) => (
              <Cell>{String(dataRecords[rowIndex][col])}</Cell>
            )}
          />
        ))}
      </Table2>
    );
  }, [columnWidths, columns, dataRecords]);

  if (!Array.isArray(columns) || !Array.isArray(dataRecords)) {
    return <div>{uuid}</div>;
  }

  if (datasetType === "literal" && dataRecords.length === 1) {
    return (
      <div
        style={{
          color: "#8EB125",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <H1 style={{ textAlign: "center", color: "inherit" }}>
          {dataRecords[0]["result"]}
        </H1>

        <Text style={{ color: "inherit", textAlign: "center" }}>
          {userFriendlyTitle}
        </Text>
      </div>
    );
  }

  if (datasetType === "column-chart") {
    return (
      <PlatformAssistantHistogramChart
        data={dataRecords.map((x) => ({
          frequency: x[yAxisColumn ?? ""],
          category: x[xAxisColumn ?? ""],
        }))}
        chartTitle={userFriendlyTitle ?? ""}
        xAxisTitle={xAxisColumn ?? ""}
        yAxisTitle={yAxisColumn ?? ""}
        tooltipSuffix={tooltipSuffix ?? "units"}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      {showTitle && userFriendlyTitle && (
        <div
          style={{
            display: "flex",
            backgroundColor: "inherit",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "4px 4px 4px 0px",
          }}
        >
          <div style={{ marginRight: "16px", marginBottom: "4px" }}>
            {userFriendlyTitle}
          </div>

          <ButtonGroup>
            <Button outlined style={{ marginLeft: "4px" }} icon="export" />
            <Button outlined style={{ marginLeft: "4px" }} icon="envelope" />
            <Button
              outlined
              style={{ marginLeft: "4px" }}
              icon="list-columns"
            />
          </ButtonGroup>
        </div>
      )}
      <div style={{ flex: 1, overflow: "scroll", alignSelf: "stretch" }}>
        {table}
      </div>
      <div
        style={{
          marginTop: "8px",
          display: "flex",
          alignItems: "center",
          marginBottom: "8px",

          paddingRight: "4px",
        }}
      >
        <div>
          {Array.from(
            { length: (dataRecords?.length ?? 25) / 25 },
            (_, i) => i + 1
          ).map((i) => (
            <Button active={i === 1} style={{ marginRight: "4px" }}>
              {i}
            </Button>
          ))}
        </div>
        <div style={{ fontSize: "14px", textAlign: "right", flex: "1" }}>
          Number of records {dataRecords.length}
        </div>
      </div>
    </div>
  );
}
