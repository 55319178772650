import { Edge, Node } from "reactflow";
import "reactflow/dist/style.css";
import { Battery } from "../../../../__generated__/types/Battery";
import PlatformBatteryPopoverContent from "../../devices/PlatformBatteryPopoverContent.react";
import {
  SOURCE_BOTTOM_HANDLE_ID,
  SOURCE_LEFT_HANDLE_ID,
  SOURCE_RIGHT_HANDLE_ID,
  SOURCE_TOP_HANDLE_ID,
  TARGET_BOTTOM_HANDLE_ID,
  TARGET_LEFT_HANDLE_ID,
  TARGET_TOP_HANDLE_ID,
} from "./PowerNode";
import { getLayoutedElements } from "./deviceGraphViewLayout";
import { filterEdges } from "./edgeFiltering";

function sum(numbers: number[]): number {
  return numbers.reduce((accumulator, current) => accumulator + current, 0);
}
export function getInitiaDeviceGraphEdges(batteries: Battery[]) {
  let edges: Edge[] = [];

  batteries.forEach((battery) => {
    if (battery.id !== undefined) {
      edges.push({
        id: `e-big-grid-grid-${battery.id}`,
        source: `big-grid`,
        sourceHandle: SOURCE_BOTTOM_HANDLE_ID,
        targetHandle: TARGET_TOP_HANDLE_ID,
        target: `grid-${battery.id}`,
        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#0C5174", // Provide a valid color value
          color2: "#0C5174",
        },
        style: { stroke: "#2F343C" },
      });
      edges.push({
        id: `e-grid-${battery.id}-home-${battery.id}`,
        source: `grid-${battery.id}`,
        target: `home-${battery.id}`,
        sourceHandle: SOURCE_BOTTOM_HANDLE_ID,
        targetHandle: TARGET_TOP_HANDLE_ID,
        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#184A90", // Provide a valid color value
          color2: "#184A90",
        },
        style: { stroke: "#2F343C" },
      });

      edges.push({
        id: `e-grid-${battery.id}-battery-${battery.id}`,
        source: `grid-${battery.id}`,
        target: `battery-${battery.id}`,
        sourceHandle: SOURCE_BOTTOM_HANDLE_ID,
        targetHandle: TARGET_LEFT_HANDLE_ID,
        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#184A90", // Provide a valid color value
          color2: "#184A90",
        },
        style: { stroke: "#2F343C" },
      });

      edges.push({
        id: `e-solar-${battery.id}-grid-${battery.id}`,
        source: `solar-${battery.id}`,
        target: `grid-${battery.id}`,
        sourceHandle: SOURCE_RIGHT_HANDLE_ID,
        targetHandle: TARGET_BOTTOM_HANDLE_ID,
        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#866103", // Provide a valid color value
          color2: "#866103",
        },
        style: { stroke: "#2F343C" },
      });
      edges.push({
        id: `e-solar-${battery.id}-home-${battery.id}`,
        source: `solar-${battery.id}`,
        target: `home-${battery.id}`,
        sourceHandle: SOURCE_RIGHT_HANDLE_ID,
        targetHandle: TARGET_TOP_HANDLE_ID,
        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#866103", // Provide a valid color value
          color2: "#866103",
        },
        style: { stroke: "#2F343C" },
      });

      edges.push({
        id: `e-battery-${battery.id}-home-${battery.id}`,
        source: `battery-${battery.id}`,
        target: `home-${battery.id}`,
        sourceHandle: SOURCE_LEFT_HANDLE_ID,
        targetHandle: TARGET_TOP_HANDLE_ID,
        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#5C255C", // Provide a valid color value
          color2: "#5C255C",
        },
        style: { stroke: "#2F343C" },
      });

      edges.push({
        id: `e-solar-${battery.id}-battery-${battery.id}`,
        source: `solar-${battery.id}`,
        target: `battery-${battery.id}`,
        sourceHandle: SOURCE_RIGHT_HANDLE_ID,
        targetHandle: TARGET_LEFT_HANDLE_ID,
        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#866103", // Provide a valid color value
          color2: "#866103",
        },
        style: { stroke: "#2F343C" },
      });
      edges.push({
        id: `e-grid-${battery.id}-big-grid`,
        source: `grid-${battery.id}`,
        target: `big-grid`,
        sourceHandle: SOURCE_TOP_HANDLE_ID,
        targetHandle: TARGET_BOTTOM_HANDLE_ID,
        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#184A90", // Provide a valid color value
          color2: "#184A90",
        },
        style: { stroke: "#2F343C" },
      });
    }
  });

  return filterEdges(edges, batteries);
}

export function getInitiaDeviceGraphNodes(batteries: Battery[]) {
  if (batteries.length === 0) {
    return [];
  }
  const totalGridPower = sum(batteries.map((b) => b.grid_power ?? 0));

  const batteryNodes: Node[] = [];
  const gridNodes: Node[] = [];
  const homeNodes: Node[] = [];
  const solarNodes: Node[] = [];

  const edges: Edge[] = [];

  const bigGrid = {
    id: `big-grid`,
    type: "powerNode",
    position: { x: 0, y: 0 },
    data: {
      label: `${-totalGridPower} W`,
      subLabel: "Grid",
      icon: "grid",
      color: "#2196f3",
    },
  };

  batteries.forEach((battery) => {
    if (battery.id !== undefined) {
      batteryNodes.push({
        id: `battery-${battery.id}`,
        type: "powerNode",
        position: { x: 0, y: 0 },
        data: {
          tooltipContent: <PlatformBatteryPopoverContent battery={battery} />,
          label: `${-(battery.battery_power ?? 0)} W`,
          subLabel: "Battery",
          icon: "battery",
          color: "#9c27b0",
          batteryPercentage: battery.battery_level,
        },
      });

      gridNodes.push({
        id: `grid-${battery.id}`,
        type: "powerNode",
        position: { x: 0, y: 0 },
        data: {
          label: `${-(battery.grid_power ?? 0)} W`,
          subLabel: "Grid",
          icon: "grid",
          color: "#2196f3",
        },
      });

      solarNodes.push({
        id: `solar-${battery.id}`,
        type: "powerNode",
        position: { x: 400, y: 0 }, // Position for the top-center node
        data: {
          label: `${battery.solar_power ?? 0} W`,
          subLabel: "Solar",
          icon: "solar",
          color: "#ffeb3b",
        },
      });

      homeNodes.push({
        id: `home-${battery.id}`,
        type: "powerNode",
        position: { x: 0, y: 0 },
        data: {
          label: `${battery.consumer_power} W`,
          subLabel: "Home",
          icon: "home",
          color: "#00A396",
        },
      });

      edges.push({
        id: `e-solar-${battery.id}-home-${battery.id}`,
        source: `solar-${battery.id}`,
        target: `home-${battery.id}`,
        type: "normal", // Add this line
        data: {
          color1: "#9c27b0", // Provide a valid color value
          color2: "#9c27b0",
        },
        style: { stroke: "#fff" },
      });

      edges.push({
        id: `e-battery-${battery.id}-home-${battery.id}`,
        source: `battery-${battery.id}`,
        target: `home-${battery.id}`,
        type: "normal", // Add this line
        data: {
          color1: "#9c27b0", // Provide a valid color value
          color2: "#9c27b0",
        },
        style: { stroke: "#fff" },
      });

      edges.push({
        id: `e-grid-${battery.id}-battery-${battery.id}`,
        source: `grid-${battery.id}`,
        target: `battery-${battery.id}`,

        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#9c27b0", // Provide a valid color value
          color2: "#9c27b0",
        },
        style: { stroke: "#fff" },
      });

      edges.push({
        id: `e-grid-${battery.id}-solar-${battery.id}`,
        source: `grid-${battery.id}`,
        target: `solar-${battery.id}`,

        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#9c27b0", // Provide a valid color value
          color2: "#9c27b0",
        },
        style: { stroke: "#fff" },
      });

      edges.push({
        id: `e-big-grid-grid-${battery.id}`,
        source: `big-grid`,
        target: `grid-${battery.id}`,
        // animated: true,
        type: "normal", // Add this line
        data: {
          color1: "#9c27b0", // Provide a valid color value
          color2: "#9c27b0",
        },
        style: { stroke: "#fff" },
      });
    }
  });

  const initNodes = [
    ...batteryNodes,
    ...gridNodes,
    ...homeNodes,
    ...solarNodes,
    bigGrid,
  ];

  const { nodes } = getLayoutedElements(initNodes, edges);

  return nodes.map((n) => {
    if (n.id === "big-grid") {
      return {
        ...n,
        position: {
          ...n.position,
          y: n.position.y - 300,
        },
      };
    }
    return { ...n };
  });
}
