import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PlatformPowerSearchBar, {
  IFilter,
  IFilterDefinition,
} from "src/common/table/PlatformPowerSearchBar.react";
import PlatformTable, {
  TableOrdering,
} from "src/common/table/PlatformTable.react";
import { Tariff } from "../../../__generated__/types/Tariff";
import { useFetch } from "../../../common/fetcher/effects";
import PlatformListView from "../../../common/layout/PlatformListView.react";

const DEFAULT_PAGE_SIZE = 15;

interface EllipsisNowrapTextDivProps {
  children: string | undefined;
}
function EllipsisNowrapTextDiv({ children }: EllipsisNowrapTextDivProps) {
  return (
    <div
      style={{
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {children ?? ""}
    </div>
  );
}

const COLUMNS = [
  {
    id: "unique_name",
    header: "Tariff Code",
    sortable: true,
    render: (item: Tariff) => (
      <EllipsisNowrapTextDiv>{item.unique_name}</EllipsisNowrapTextDiv>
    ),
  },
  {
    id: "TARIFF_NAME",
    header: "Tariff name",
    render: (item: Tariff) => (
      <EllipsisNowrapTextDiv>{item.display_name}</EllipsisNowrapTextDiv>
    ),
  },
  {
    id: "DESCRIPTION",
    header: "Description",
    render: (item: Tariff) => (
      <EllipsisNowrapTextDiv>{item.description}</EllipsisNowrapTextDiv>
    ),
  },
].map((c) => ({ ...c, href: (item: Tariff) => `/tariffs/${item.id}` }));

const FILTER_DEFS: IFilterDefinition[] = [
  {
    id: "unique_name",
    name: "Tariff code",
    operators: ["equals", "contains", "starts with", "ends with"],
  },
  {
    id: "display_name",
    name: "Tariff name",
    operators: ["equals", "contains", "starts with", "ends with"],
  },
  {
    id: "description",
    name: "Description",
    operators: ["equals", "contains", "starts with", "ends with"],
  },
];

function extractFiltersFromURL(url: string): TableOrdering[] {
  const params = new URLSearchParams(window.location.search);
  const xx = params.getAll("ordering").map((o) => {
    let order: "ASC" | "DESC" = "ASC";
    let field = o;
    if (o.charAt(0) === "-") {
      order = "DESC";
      field = field.slice(1);
    }

    return { order, field };
  });

  return xx;
}

export default function PlatformTariffListView() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [ordering, setOrdering] = useState<TableOrdering[]>([]);
  const [filters, setFilters] = useState<IFilter[]>();
  const navigate = useNavigate();

  useEffect(() => {
    setOrdering(extractFiltersFromURL(window.location.search));
  }, []);

  const [currentUrl, endpointUrl] = useMemo(() => {
    // Get the initial GET parameters from the URL
    const initialParams = new URLSearchParams(window.location.search);

    // Create a new URLSearchParams object with the initial parameters
    const params = new URLSearchParams(initialParams);

    // Add or update parameters based on the component's state
    params.set("limit", pageSize.toString());
    params.set("offset", ((page - 1) * pageSize).toString());

    const orderingStr = ordering
      .map(({ field, order }) => {
        if (field === "unique_name") {
          return order === "ASC" ? "unique_name" : "-unique_name";
        }
        return null;
      })
      .filter(Boolean)
      .join(",");

    if (orderingStr) {
      params.set("ordering", orderingStr);
    }

    if (filters) {
      filters.forEach((f) => {
        const filterDefinition = FILTER_DEFS.find(
          (fd) => fd.id === f.filterDefinitionId
        );
        if (filterDefinition) {
          let paramKey = filterDefinition.id;
          switch (f.operator) {
            case "starts with":
              paramKey += "__istartswith";
              break;
            case "ends with":
              paramKey += "__iendswith";
              break;
            case "contains":
              paramKey += "__icontains";
              break;
            // "equals" doesn't need a suffix
          }
          params.set(paramKey, f.value ?? "");
        }
      });
    }

    return [
      `/tariffs/?${params.toString()}`,
      `/api/tariffs/?${params.toString()}`,
    ];
  }, [ordering, page, pageSize, filters]);

  useEffect(() => {
    navigate(currentUrl);
  }, [currentUrl, navigate]);

  const { data: tariffData, isLoading: tariffDataIsLoading } = useFetch<{
    results: Tariff[];
    count: number;
  }>(endpointUrl);

  return (
    <PlatformListView title="Tariffs">
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          paddingTop: "24px",
        }}
      >
        <PlatformPowerSearchBar
          filters={FILTER_DEFS}
          handleFilterChange={(newFilters) => {
            setFilters(newFilters);
          }}
        />
        <PlatformTable<Tariff>
          interactive
          glimmer={tariffDataIsLoading}
          data={tariffData?.results ?? []}
          columns={COLUMNS}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          numberOfAllItems={tariffData?.count ?? 10}
          handlePageChange={setPage}
          currentPage={page}
          ordering={ordering}
          onOrderingChange={setOrdering}
        />
      </div>
    </PlatformListView>
  );
}
