interface ErrorIconProps {
  color?: string;
  height?: string;
  width?: string;
}
export default function ErrorIcon({
  color = "#AC2F33",
  height = "24px",
  width = "24px",
}: ErrorIconProps) {
  return (
    <svg
      height={height}
      width={width}
      clipRule="evenodd"
      fillRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 21.0001 21.0001"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="图层_1">
        <path d="m0 0h21v21h-21z" fill="none" />
        <path
          d="m10.5 1.3124c5.07328 0 9.1876 4.11432 9.1876 9.1876s-4.11432 9.1876-9.1876 9.1876-9.1876-4.11432-9.1876-9.1876 4.11432-9.1876 9.1876-9.1876zm-.9843 9.9782v-4.72874c0-.54211.4422-.9843.9843-.9843.54211 0 .9843.4463.9843.9843v4.72874c0 .538-.4422.9843-.9843.9843-.54211 0-.9843-.4422-.9843-.9843zm.98004 2.04c.61605 0 1.11568.49963 1.11568 1.1156 0 .61605-.49963 1.11568-1.11568 1.11568-.61597 0-1.1156-.49963-1.1156-1.11568 0-.61597.49963-1.1156 1.1156-1.1156z"
          fill={color}
        />
      </g>
    </svg>
  );
}
