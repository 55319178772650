import { useEffect, useState } from "react";

const elems = [
  <div>
    2023-07-04 01:45:48: <VehicleLabel vehicleId={11138} /> started charging.
    Current Charge 58%
  </div>,
  <div>
    2023-07-04 01:48:04: <VehicleCluster vehicleClusterId="67B" /> preparing to
    pause 'Charging' (aFRR bid) in 180 seconds.
  </div>,
  <div>
    2023-07-04 01:48:56: <VehicleLabel vehicleId={10113} /> stopped charging.
    Current Charge 81%
  </div>,
  <div>
    2023-07-04 01:49:04: <VehicleCluster vehicleClusterId="67B" /> preparing to
    pause 'Charging' (aFRR bid) in 120 seconds.
  </div>,
  <div>
    2023-07-04 01:50:04: <VehicleCluster vehicleClusterId="67B" /> preparing to
    pause 'Charging' (aFRR bid) in 60 seconds.
  </div>,

  <div>
    2023-07-04 01:51:04: <VehicleCluster vehicleClusterId="67B" /> starts
    pausing
  </div>,
  <div>
    2023-07-04 01:51:20: <VehicleCluster vehicleClusterId="67B" /> 25% of
    Vehicles paused charging
  </div>,
  <div>
    2023-07-04 01:51:52: <VehicleLabel vehicleId={14286} /> started charging.
    Current Charge 31%
  </div>,
  <div>
    2023-07-04 01:51:38: <VehicleCluster vehicleClusterId="67B" /> 57% of
    Vehicles paused charging
  </div>,
  <div>
    2023-07-04 01:52:03: <VehicleCluster vehicleClusterId="67B" /> 87% of
    Vehicles paused charging
  </div>,
  <div>
    2023-07-04 01:52:21: <VehicleCluster vehicleClusterId="67B" /> 100% of
    Vehicles paused charging
  </div>,
];

export default function PlatformDigitalTwinExperimentConsole() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < elems.length) {
      const timer = setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [currentIndex]);
  return (
    <div
      style={{
        backgroundColor: "#160f08",
        fontFamily: "Courier",
        height: "256px",
        padding: "8px 8px",
        color: "#8F99A8",
      }}
    >
      {elems.slice(0, currentIndex).map((x) => x)}
    </div>
  );
}

interface VehicleLabelProps {
  vehicleId: number;
}
function VehicleLabel({ vehicleId }: VehicleLabelProps) {
  return (
    <div style={{ display: "inline", color: "#147EB3", cursor: "pointer" }}>
      Vehicle {vehicleId}
    </div>
  );
}

interface VehicleClusterProps {
  vehicleClusterId: string;
}
function VehicleCluster({ vehicleClusterId }: VehicleClusterProps) {
  return (
    <div style={{ display: "inline", color: "#8EB125", cursor: "pointer" }}>
      Vehicle Cluster {vehicleClusterId}
    </div>
  );
}
