import { DateTime, Duration } from "luxon";

// Interfaces
export interface ChartState {
  start: string | null;
  end: string | null;
  granularity: string;
}
export class EnergyUtils {
  static humanize(value: number, baseUnit: string): string {
    const units = [
      { suffix: "W", factor: 1 },
      { suffix: "kW", factor: 1e3 },
      { suffix: "MW", factor: 1e6 },
      { suffix: "GW", factor: 1e9 },
    ];

    const normalizedBaseUnit = baseUnit.toLowerCase();

    let baseUnitIndex = -1;
    for (let i = 0; i < units.length; i++) {
      if (units[i].suffix.toLowerCase() === normalizedBaseUnit) {
        baseUnitIndex = i;
        break;
      }
    }

    if (baseUnitIndex === -1) {
      throw new Error(`Invalid base unit: ${baseUnit}`);
    }

    let convertedValue = Math.abs(value) * units[baseUnitIndex].factor;

    let unitIndex = 0;
    while (unitIndex < units.length - 1 && convertedValue >= 1000) {
      convertedValue /= 1000;
      unitIndex++;
    }

    const roundedValue = Math.round(convertedValue * 100) / 100;
    const sign = value < 0 ? "-" : "";

    return `${sign}${roundedValue} ${units[unitIndex].suffix}`;
  }
}
// Utils
export const getChartGranularity = (
  startDate?: string | null,
  endDate?: string | null,
  granularities?: string[]
): string => {
  const start = startDate ? DateTime.fromISO(startDate) : null;
  const end = endDate ? DateTime.fromISO(endDate) : null;

  if (start && end && start.isValid && end.isValid) {
    const days = end.diff(start).as("days");

    if (granularities && granularities.length === 3) {
      if (days <= 1) {
        return granularities[0];
      } else if (days <= 3) {
        return granularities[1];
      } else if (days <= 7) {
        return granularities[2];
      }
    } else {
      // Defaults
      if (days <= 1) {
        return "15m";
      } else if (days <= 3) {
        return "30m";
      } else if (days <= 7) {
        return "1h";
      }
    }
    return "1d";
  }
  return "1h";
};

export const getChartPadding = (
  start: number,
  end: number
): { startStr: string | null; endStr: string | null } => {
  let startDt = DateTime.fromMillis(start);
  let endDt = DateTime.fromMillis(end);

  const range = endDt.diff(startDt);
  const oneHour = Duration.fromObject({ hours: 1 });
  const oneDay = Duration.fromObject({ days: 1 });
  const oneMonth = Duration.fromObject({ months: 1 });

  if (range <= oneHour) {
    startDt = startDt.minus({ minutes: 15 });
    endDt = endDt.plus({ minutes: 15 });
  } else if (range <= oneDay) {
    startDt = startDt.minus({ hours: 1 });
    endDt = endDt.plus({ hours: 1 });
  } else if (range <= oneMonth) {
    startDt = startDt.minus({ days: 1 });
    endDt = endDt.plus({ days: 1 });
  } else {
    startDt = startDt.minus({ months: 1 });
    endDt = endDt.plus({ months: 1 });
  }

  const startStr = startDt.toUTC().toISO({ includeOffset: false });
  const endStr = endDt.toUTC().toISO({ includeOffset: false });

  return { startStr, endStr };
};
