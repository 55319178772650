import { Button, Intent, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { Tariff } from "@ec1/types/Tariff";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import PlatformTariffMenuitem from "./PlatformTariffMenuItem.react";
import { useTariffAutocomplete } from "./tariffs";

interface PlatformTariffAutocompleteSelectProps {
  value: Tariff | null;
  onChange: (newValue: Tariff) => void;
  intent: Intent;
}

const useStyles = createUseStyles({
  selectError: {
    border: "1px solid #DB3737",
    borderRadius: 3,
  },
});

const renderItem: ItemRenderer<Tariff> = (
  tariff,
  { handleClick, handleFocus, modifiers, query }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <PlatformTariffMenuitem
      tariff={tariff}
      modifiers={modifiers}
      handleClick={handleClick}
      handleFocus={handleFocus}
    />
  );
};

export default function PlatformTariffAutocompleteSelect({
  value,
  onChange,
  intent,
}: PlatformTariffAutocompleteSelectProps) {
  const classes = useStyles();

  const [query, setQuery] = useState<string | null>(null);

  const tariffs = useTariffAutocomplete(query);

  return (
    <div style={{ padding: "4px 0px" }}>
      <Select<Tariff>
        items={tariffs ?? []}
        itemRenderer={renderItem}
        noResults={
          <MenuItem
            disabled={true}
            text="No results."
            roleStructure="listoption"
          />
        }
        onQueryChange={setQuery}
        onItemSelect={onChange}
        popoverProps={{ matchTargetWidth: true }}
        menuProps={{
          style: {
            maxHeight: 300,
            overflow: "auto",
          },
        }}
      >
        <div style={{ marginBottom: 6 }}>Tariff</div>
        <Button
          fill
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          alignText="left"
          icon={"label"}
          text={value?.display_name ?? "Select a tariff"}
          rightIcon="caret-down"
          className={intent === Intent.DANGER ? classes.selectError : ""}
        />
      </Select>
    </div>
  );
}
