import { Button, Icon, Popover } from "@blueprintjs/core";
import { Build, Power, PowerOff, WbSunny } from "@mui/icons-material";
import { EnergyUtils } from "src/ui/utils/chartUtils";
import { Battery } from "../../../__generated__/types/Battery";
import BatteryChargingModeChip from "./BatteryChargingModeChip.react";
import BatteryStatusChip from "./BatteryStatusChip.react";
import { getBatteryIcon } from "./PlatformSiteDeviceMapView.react";

interface PlatformBatteryPopoverContentProps {
  battery: Battery;
}
export default function PlatformBatteryPopoverContent({
  battery,
}: PlatformBatteryPopoverContentProps) {
  return (
    <div style={{ fontFamily: "Barlow", padding: 10, cursor: "default" }}>
      <div
        style={{
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          {battery?.brand} {battery?.model}
        </div>
        {battery?.battery_status && battery.station_state !== "OFFLINE" && (
          <div style={{ marginLeft: 8 }}>
            <BatteryStatusChip batteryStatus={battery?.battery_status} />
          </div>
        )}
        <div style={{ marginLeft: 5, flex: 1 }}>
          <Button
            minimal
            small
            onClick={() => {
              window.open(`/devices/${battery?.id}`, "_blank");
            }}
            title="View Battery"
          >
            <Icon icon="share" size={14} style={{ padding: 2 }} />
          </Button>
        </div>
        {battery.station_state && (
          <div
            style={{
              width: 50,
              height: 20,
              paddingTop: 1,
              paddingBottom: 1,
              textAlign: "right",
            }}
          >
            <Popover
              interactionKind="hover-target"
              position="top"
              content={
                <div
                  style={{
                    fontFamily: "Barlow",
                    padding: 10,
                    maxWidth: 250,
                    textAlign: "justify",
                  }}
                >
                  Station state:{" "}
                  {battery.station_state.charAt(0).toUpperCase() +
                    battery.station_state.slice(1).toLowerCase()}
                </div>
              }
            >
              {battery.station_state === "OFFLINE" ? (
                <PowerOff
                  style={{
                    fontSize: 18,
                    color: "#cd4246",
                  }}
                />
              ) : battery.station_state === "FAULT" ? (
                <Build
                  style={{
                    fontSize: 18,
                    color: "#ec9a3c",
                    padding: 2, // Padding because the icon is big
                  }}
                />
              ) : (
                <Power
                  style={{
                    fontSize: 18,
                    color: "#238551",
                  }}
                />
              )}
            </Popover>
          </div>
        )}
      </div>

      <div
        style={{
          width: "calc(100% - 4px)",
          height: 1,
          background: "#555",
          marginTop: 10,
          marginLeft: 2,
        }}
      ></div>

      <div style={{ marginTop: 10, display: "flex", flexDirection: "row" }}>
        {battery?.battery_level ? (
          <div
            title="Battery level"
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                marginLeft: 6,
                marginRight: 10,
                marginBottom: -2,
                fontSize: 16,
              }}
            >
              {getBatteryIcon(
                battery?.battery_level ?? 0,
                undefined,
                battery?.station_state
              )}
            </div>
            <div
              style={{
                marginTop: 1,
              }}
            >
              <strong>{battery?.battery_level}%</strong>
            </div>
          </div>
        ) : null}
        {battery?.solar_power ? (
          <div
            title="Solar generated"
            style={{
              flex: 1,
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              marginLeft: 10,
            }}
          >
            <div style={{ marginTop: 2, marginRight: 4 }}>
              <WbSunny style={{ fontSize: 16, color: "#ffeb3b" }} />
            </div>
            <div>
              <strong>
                {String(EnergyUtils.humanize(battery.solar_power ?? 0, "w"))}
              </strong>
            </div>
          </div>
        ) : null}
      </div>

      <div style={{ marginTop: 14, display: "flex", flexDirection: "row" }}>
        <div>Charging mode:</div>{" "}
        <div style={{ marginTop: -2 }}>
          <BatteryChargingModeChip
            chargingMode={battery.is_smart_charging ? "SMART" : "MANUAL"}
          />
        </div>
      </div>

      <div style={{ marginTop: 12, marginBottom: 6 }}>
        Station name: {battery?.station_name ?? "-"}
      </div>
    </div>
  );
}
