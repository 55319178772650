import { RJSFValidationError } from "@rjsf/utils";

export function errorTransformer(
  errors: RJSFValidationError[]
): RJSFValidationError[] {
  return errors.map((error) => {
    let fieldName = error.property?.split(".").pop() || "";

    switch (error.name) {
      case "required":
        error.message = `${error.params.missingProperty} is mandatory.`;
        break;
      case "pattern":
        error.message = `${fieldName} does not match the required pattern.`;
        break;
      case "minimum":
        error.message = `${fieldName} should be at least ${error.params.limit}.`;
        break;
      case "maximum":
        error.message = `${fieldName} should be at most ${error.params.limit}.`;
        break;
      case "minLength":
        if (error.params.limit === 1) {
          error.message = `${fieldName} is mandatory.`;
        } else {
          error.message = `${fieldName} should be at least ${error.params.limit} characters long.`;
        }
        break;
      case "maxLength":
        error.message = `${fieldName} should be at most ${error.params.limit} characters long.`;
        break;
      case "format":
        error.message = `${fieldName} is not a valid ${error.params.format}.`;
        break;
      case "type":
        error.message = `${fieldName} should be a ${error.params.type}.`;
        break;
      case "enum":
        error.message = `${fieldName} should be one of the allowed values.`;
        break;
      case "const":
        error.message = `${fieldName} should be exactly ${error.params.allowedValue}.`;
        break;
      default:
        // Keep the original error message for any other error types
        break;
    }

    if (error.message) {
      error.message =
        error.message.charAt(0).toUpperCase() + error.message.slice(1);
    }

    return error;
  });
}
