import {
  Button,
  Dialog,
  DialogProps,
  H4,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { Tariff } from "@ec1/types/Tariff";
import { DateTime } from "luxon";
import { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { Ec1Toaster } from "../../../ui/components/Ec1Toaster.react";
import PlatformAssignTariffDatefield from "./PlatformAssignTariffDatefield";
import PlatformTariffAutocompleteSelect from "./PlatformTariffAutocompleteSelect.react";
import { useProperties } from "./properties";

interface PlatformAssignTariffDialogProps extends DialogProps {
  propertyId: number;
  onEnrollmentSuccess: () => Promise<void>;
  setIsLoadingParent: (loading: boolean) => void;
}

const useStyles = createUseStyles({
  dialog: {
    fontFamily: "Barlow",
    padding: 24,
    width: "400px",
    boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.2) !important",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "8px",
  },
  validationError: {
    color: "#f17575",
    height: 10,
    marginLeft: 2,
  },
  selectError: {
    border: "1px solid #DB3737",
    borderRadius: 3,
  },
});

export default function PlatformAssignTariffDialog({
  propertyId,
  onEnrollmentSuccess,
  setIsLoadingParent,
  onClose,
  ...dialogProps
}: PlatformAssignTariffDialogProps) {
  const classes = useStyles();

  const { assignPropertyTariff } = useProperties();

  const [tariff, setTariff] = useState<Tariff | null>(null);
  const [from, setFrom] = useState<string>("");
  const [to, setTo] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [tariffValidationError, setTariffValidationError] =
    useState<string>("");
  const [fromValidationError, setFromValidationError] = useState<string>("");
  const [toValidationError, setToValidationError] = useState<string>("");

  const [tariffIntent, setTariffIntent] = useState<Intent>(Intent.NONE);

  const setLoadingState = useCallback(
    (loading: boolean) => {
      setIsLoading(loading);
      setIsLoadingParent(loading);
    },
    [setIsLoadingParent]
  );

  const showToast = useCallback(
    (
      message: string,
      intent: Intent,
      buttonText?: string,
      buttonOnClick?: () => void
    ) => {
      Ec1Toaster.show({
        timeout: 5000,
        message: (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 20,
                fontFamily: "Barlow",
              }}
            >
              <div style={{ marginRight: 15, height: 20 }}>{message}</div>
              {buttonText && (
                <Button
                  intent="primary"
                  text={buttonText}
                  onClick={buttonOnClick}
                  style={{
                    height: 20,
                    padding: "0 5px",
                    minHeight: "unset",
                    minWidth: "unset",
                    fontSize: 12,
                  }}
                />
              )}
            </div>
          </>
        ),
        intent: intent,
      });
    },
    []
  );

  const resetForm = useCallback(() => {
    setTariff(null);
    setFrom("");
    setTo("");
    setTariffValidationError("");
    setFromValidationError("");
    setToValidationError("");
    setTariffIntent(Intent.NONE);
    setLoadingState(false);
  }, [setLoadingState]);

  const handleTariffSelect = useCallback((tariff: Tariff) => {
    setTariff(tariff);
    setTariffValidationError("");
    setTariffIntent(Intent.NONE);
  }, []);

  const handleFromSelect = useCallback((from: string) => {
    setFrom(from);
    setFromValidationError("");
  }, []);

  const handleToSelect = useCallback((to: string) => {
    setTo(to);
    setToValidationError("");
  }, []);

  const handleEnrollClick = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      let isValid = true;
      if (!tariff) {
        setTariffIntent(Intent.DANGER);
        setTariffValidationError("Tariff selection is mandatory.");
        isValid = false;
      } else {
        setTariffValidationError("");
      }

      if (!from.trim()) {
        setFromValidationError("From cannot be blank.");
        isValid = false;
      } else {
        setFromValidationError("");
      }

      if (!to) {
        setToValidationError("To cannot be blank.");
        isValid = false;
      } else {
        setToValidationError("");
      }

      if (!isValid) {
        return;
      }

      setLoadingState(true);

      try {
        const data = await assignPropertyTariff({
          property: propertyId,
          tariff: tariff?.id || -1,
          valid_from: from,
          valid_to: to,
        });

        if (data.success) {
          await onEnrollmentSuccess();
          showToast(`Tariff assigned successfully!`, Intent.SUCCESS);
          resetForm();
        } else {
          showToast(data.error, Intent.DANGER);
        }
        setLoadingState(false);
      } catch (error) {
        setLoadingState(false);
        showToast("Tariff assignment failed. Please try again.", Intent.DANGER);
      }
    },
    [
      tariff,
      from,
      to,
      propertyId,
      setLoadingState,
      showToast,
      assignPropertyTariff,
      onEnrollmentSuccess,
      resetForm,
    ]
  );

  const handleClose = useCallback(
    (event?: React.SyntheticEvent<HTMLElement, Event>) => {
      if (!isLoading) {
        resetForm();
      }
      if (event && onClose) {
        onClose(event);
      }
    },
    [isLoading, onClose, resetForm]
  );

  return (
    <>
      <Dialog
        className={`bp5-dark ${classes.dialog}`}
        {...dialogProps}
        onClose={handleClose}
        enforceFocus={false} // Needed for datepicker dropdowns to work
      >
        {isLoading && (
          <div
            style={{
              background: "rgba(0,0,0,.5",
              width: "400px",
              height: "297px",
              position: "absolute",
              margin: -24,
              borderRadius: 3,
              zIndex: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 249,
                marginLeft: 24,
                width: 100,
              }}
            >
              <Spinner size={20} style={{ marginRight: 10 }} />
              <div style={{}}>Assigning...</div>
            </div>
          </div>
        )}
        <H4>Assign property tariff</H4>
        <div style={{ marginTop: "8px", marginBottom: "16px" }}>
          <PlatformTariffAutocompleteSelect
            value={tariff}
            onChange={handleTariffSelect}
            intent={tariffIntent}
          />
          <div className={classes.validationError}>{tariffValidationError}</div>
          <div style={{ marginTop: "10px", display: "flex" }}>
            <div style={{ margin: "4px 0px", flex: 1 }}>
              <div style={{ marginBottom: "4px" }}>Valid from:</div>
              <PlatformAssignTariffDatefield
                value={from}
                onChange={handleFromSelect}
                maxDate={
                  to
                    ? DateTime.fromISO(to)
                        .minus({ days: 1 })
                        .toFormat("yyyy-MM-dd")
                    : undefined
                }
              />
              <div className={classes.validationError}>
                {fromValidationError}
              </div>
            </div>
            <div style={{ margin: "4px 0px 4px 14px", flex: 1 }}>
              <div style={{ marginBottom: "4px" }}>Valid to:</div>
              <PlatformAssignTariffDatefield
                value={to}
                onChange={handleToSelect}
                minDate={
                  from
                    ? DateTime.fromISO(from)
                        .plus({ days: 1 })
                        .toFormat("yyyy-MM-dd")
                    : undefined
                }
              />
              <div className={classes.validationError}>{toValidationError}</div>
            </div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            outlined
            intent="warning"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <div style={{ width: "8px" }} />
          <Button
            outlined
            intent="primary"
            onClick={handleEnrollClick}
            disabled={isLoading}
          >
            Assign
          </Button>
        </div>
      </Dialog>
    </>
  );
}
