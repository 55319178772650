import { TextArea, TextAreaProps } from "@blueprintjs/core";

interface PlatformAssistantTextAreaProps extends TextAreaProps {
  initialValue?: string;
  maxRows?: number;
  minRows?: number;
}

export default function PlatformAssistantTextArea({
  placeholder = "Start exploring with the Electryone AI Assistant...",
  ...props
}: PlatformAssistantTextAreaProps) {
  return (
    <TextArea
      style={{
        width: "100%",
        borderRadius: "8px",
        fontFamily: "Barlow",
        resize: "none",
      }}
      placeholder={placeholder}
      rows={3}
      {...props}
    />
  );
}
