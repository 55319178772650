import {
  Button,
  Checkbox,
  H3,
  H6,
  HTMLSelect,
  Navbar,
  Spinner,
} from "@blueprintjs/core";
import { Cell, Column, Table2 } from "@blueprintjs/table";
import { Price } from "@ec1/types/Price";
import { Tariff } from "@ec1/types/Tariff";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useFetch } from "src/common/fetcher/effects";
import PlatformAssignTariffDatefield from "../properties/PlatformAssignTariffDatefield";

const PAGESIZE = 100;

const useStyles = createUseStyles({
  container: {
    margin: "36px 24px",
  },
  tariffDetails: {
    fontFamily: "Barlow",
    padding: "24px",
    backgroundColor: "#383E47",
    borderRadius: "4px",
  },
  tableContainer: {
    marginTop: "24px",
  },
  navbar: {
    marginBottom: "12px",
    fontFamily: "Barlow",
    boxShadow: "none !important",
    borderRadius: "4px",
  },
  navbarDisabled: {
    marginBottom: "12px",
    fontFamily: "Barlow",
    boxShadow: "none !important",
    borderRadius: "4px",
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed !important",
  },
  datePickerContainer: {
    display: "flex",
    alignItems: "center",
  },
  datePickerWrapper: {
    display: "flex",
    flexDirection: "row",
    marginRight: "15px",
    alignItems: "center",
  },
  datePickerLabel: {
    marginRight: "10px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
  },
  checkbox: {
    marginRight: "20px",
    marginBottom: "0px",
  },
  paginationInfo: {
    fontSize: "14px",
    color: "#ccc",
    minWidth: "120px",
    textAlign: "right",
  },
  pageLabel: {
    marginRight: 5,
    fontSize: 14,
  },
  pageLabelDisabled: {
    marginRight: 5,
    fontSize: 14,
    opacity: "0.5 !important",
  },
  pageSelect: {
    fontFamily: "Barlow",
    fontSize: 14,
  },
  h3: {
    fontFamily: "Barlow",
  },
  skeletonWidth240: {
    width: "240px",
  },
  skeletonWidth120: {
    width: "120px",
  },
  skeletonWidth60: {
    width: "60px",
  },
});

interface PriceData {
  results: Price[];
  count: number;
}

interface DynamicTableProps {
  data: any[];
  isLoading: boolean;
}

const DynamicTable: React.FC<DynamicTableProps> = ({ data, isLoading }) => {
  if (isLoading) {
    return <Spinner />;
  }

  if (!data || data.length === 0) {
    return <div>No data to display</div>;
  }

  const columns = Object.keys(data[0]);

  const cellRenderer = (columnKey: string) => (rowIndex: number) => {
    return <Cell>{String(data[rowIndex][columnKey])}</Cell>;
  };

  return (
    <Table2 numRows={data.length}>
      {columns.map((columnKey) => (
        <Column
          key={columnKey}
          name={columnKey}
          cellRenderer={cellRenderer(columnKey)}
        />
      ))}
    </Table2>
  );
};

export default function PlatformTariffDetailView() {
  const classes = useStyles();

  const { tariffId } = useParams<{ tariffId: string }>();

  const [currentPage, setCurrentPage] = useState(0);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [importPrices, setImportPrices] = useState(true);
  const [exportPrices, setExportPrices] = useState(true);

  const today = DateTime.now().endOf("day");
  const tomorrow = today.plus({ days: 1 });

  const fetchTariffUrl = useMemo(() => {
    if (tariffId) {
      return `/api/tariffs/${tariffId}/`;
    }
    return null;
  }, [tariffId]);

  const fetchPricesUrl = useMemo(() => {
    if (tariffId) {
      let url = `/api/prices/?tariff=${tariffId}&limit=${PAGESIZE}&offset=${
        currentPage * PAGESIZE
      }&ordering=utc_time`;

      if (startDate) {
        url += `&start_time=${startDate}`;
      }

      if (endDate) {
        url += `&end_time=${endDate}`;
      }

      if (!importPrices && exportPrices) {
        url += "&is_export_price=true";
      } else if (importPrices && !exportPrices) {
        url += "&is_export_price=false";
      } else if (!importPrices && !exportPrices) {
        return null;
      }

      return url;
    }
    return null;
  }, [tariffId, currentPage, startDate, endDate, importPrices, exportPrices]);

  const { data: tariff } = useFetch<Tariff>(fetchTariffUrl);

  const {
    data: priceData,
    isLoading: isPriceDataLoading,
    error: priceDataError,
  } = useFetch<PriceData>(fetchPricesUrl);

  const prices = useMemo(() => {
    if (priceData?.results) {
      return priceData.results.map(({ tariff, ...rest }) => rest);
    }
    return [];
  }, [priceData]);

  const totalPages = Math.ceil((priceData?.count || 0) / PAGESIZE);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleStartDateSelect = useCallback(
    (date: string | null) => {
      if (date === null) {
        setStartDate("");
        setCurrentPage(0);
        return;
      }

      const selectedDate = DateTime.fromISO(date);
      if (selectedDate > tomorrow) {
        setStartDate(tomorrow.toISODate() ?? "");
      } else {
        setStartDate(date);
      }
      setCurrentPage(0);

      if (endDate) {
        const endDateTime = DateTime.fromISO(endDate);
        if (endDateTime <= selectedDate.plus({ days: 1 })) {
          setEndDate(selectedDate.plus({ days: 1 }).toISODate() ?? "");
        }
      }
    },
    [endDate, tomorrow]
  );

  const handleEndDateSelect = useCallback(
    (date: string | null) => {
      if (date === null) {
        setEndDate("");
        setCurrentPage(0);
        return;
      }

      const selectedDate = DateTime.fromISO(date);
      if (selectedDate > tomorrow) {
        setEndDate(tomorrow.toISODate() ?? "");
      } else {
        setEndDate(date);
      }
      setCurrentPage(0);

      if (startDate) {
        const startDateTime = DateTime.fromISO(startDate);
        if (startDateTime >= selectedDate.minus({ days: 1 })) {
          setStartDate(selectedDate.minus({ days: 1 }).toISODate() ?? "");
        }
      }
    },
    [startDate, tomorrow]
  );

  const handleImportPricesChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setImportPrices(e.currentTarget.checked);
      setCurrentPage(0);
    },
    []
  );

  const handleExportPricesChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setExportPrices(e.currentTarget.checked);
      setCurrentPage(0);
    },
    []
  );

  useEffect(() => {
    setCurrentPage(0);
    setStartDate("");
    setEndDate("");
    setImportPrices(true);
    setExportPrices(true);
  }, [tariffId]);

  if (priceDataError) {
    return <div>Error loading price data: {priceDataError.message}</div>;
  }

  return (
    <SkeletonTheme baseColor="#313740" highlightColor="#657285" duration={3}>
      <div className={classes.container}>
        <div className={classes.tariffDetails}>
          <div>
            <H3 className={classes.h3}>
              {tariff?.display_name ?? (
                <Skeleton className={classes.skeletonWidth240} />
              )}
            </H3>
          </div>
          <div>
            <H6>
              {tariff?.unique_name ?? (
                <Skeleton className={classes.skeletonWidth120} />
              )}
            </H6>
          </div>
          <div>
            <H6>
              {tariff?.description ?? (
                <Skeleton className={classes.skeletonWidth120} />
              )}
            </H6>
          </div>
          <div>
            <H6>
              Slot duration:{" "}
              {tariff?.slot_duration_in_minutes ?? (
                <Skeleton className={classes.skeletonWidth60} />
              )}{" "}
              minutes
            </H6>
          </div>
        </div>

        <div className={classes.tableContainer}>
          <Navbar
            className={
              isPriceDataLoading ? classes.navbarDisabled : classes.navbar
            }
          >
            <Navbar.Group align="left">
              <div className={classes.datePickerContainer}>
                <div className={classes.datePickerWrapper}>
                  <div className={classes.datePickerLabel}>From:</div>
                  <PlatformAssignTariffDatefield
                    value={startDate}
                    onChange={handleStartDateSelect}
                    maxDate={today.toISODate() ?? ""}
                    allowEmpty
                  />
                </div>
                <div className={classes.datePickerWrapper}>
                  <div className={classes.datePickerLabel}>To:</div>
                  <PlatformAssignTariffDatefield
                    value={endDate}
                    onChange={handleEndDateSelect}
                    minDate={
                      startDate
                        ? DateTime.fromISO(startDate)
                            .plus({ days: 1 })
                            .toISODate() ?? ""
                        : undefined
                    }
                    maxDate={tomorrow.toISODate() ?? ""}
                    allowEmpty
                  />
                </div>
                <div className={classes.checkboxContainer}>
                  <Checkbox
                    className={classes.checkbox}
                    label="Import prices"
                    checked={importPrices}
                    onChange={handleImportPricesChange}
                  />
                  <Checkbox
                    className={classes.checkbox}
                    label="Export prices"
                    checked={exportPrices}
                    onChange={handleExportPricesChange}
                  />
                </div>
              </div>
            </Navbar.Group>
            <Navbar.Group align="right">
              <Button
                small
                minimal
                icon="double-chevron-left"
                onClick={() => handlePageChange(0)}
                disabled={currentPage === 0 || prices.length === 0}
              />
              <Button
                small
                minimal
                icon="chevron-left"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 0 || prices.length === 0}
              />
              <span
                className={
                  prices.length === 0
                    ? classes.pageLabelDisabled
                    : classes.pageLabel
                }
              >
                Page:
              </span>
              <HTMLSelect
                minimal
                value={currentPage}
                className={classes.pageSelect}
                options={Array.from({ length: totalPages }, (_, i) => ({
                  label: `${i + 1}`,
                  value: i,
                }))}
                onChange={(e) => handlePageChange(Number(e.target.value))}
                iconName="caret-down"
                disabled={prices.length === 0}
              />
              <Button
                small
                minimal
                icon="chevron-right"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages - 1 || prices.length === 0}
              />
              <Button
                small
                minimal
                icon="double-chevron-right"
                onClick={() => handlePageChange(totalPages - 1)}
                disabled={currentPage === totalPages - 1 || prices.length === 0}
              />
              <Navbar.Divider />
              <span className={classes.paginationInfo}>
                {prices.length === 0
                  ? "0-0 of 0 results"
                  : `${currentPage * PAGESIZE + 1}-${Math.min(
                      (currentPage + 1) * PAGESIZE,
                      priceData?.count || 0
                    )} of ${priceData?.count || 0} results`}
              </span>
            </Navbar.Group>
          </Navbar>
          <DynamicTable data={prices} isLoading={isPriceDataLoading} />
        </div>
      </div>
    </SkeletonTheme>
  );
}
