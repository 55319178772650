import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
  },
  components: {
    MuiPopper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          "& .MuiAutocomplete-option": {
            fontSize: 14,
            padding: 4,
          },
          "& .MuiPaper-root": {
            background: "#343d45",
            borderRadius: 5,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
            padding: "4px 0px",
            border: "rgba(45, 114, 210, 0.6) solid 2px",
          },
          "& .MuiPaper-elevation": {
            minHeight: 40,
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          "& .MuiInputBase-root": {
            height: "auto",
          },
          "&.MuiInputBase-input.MuiInput-input option": {
            background: "#343d45",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            fontSize: 14,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        filterForm: {
          background: "#343d45",
          borderRadius: 5,
        },
        filterFormDeleteIcon: {
          height: 44,
          "& .MuiSvgIcon-root": {
            fontSize: 14,
          },
        },
        filterFormColumnInput: {
          marginRight: 10,
          "& .MuiInput-input": {
            fontSize: 14,
            paddingLeft: 4,
          },
        },
        filterFormOperatorInput: {
          marginRight: 10,
          "& .MuiInput-input": {
            fontSize: 14,
            paddingLeft: 4,
          },
        },
        filterFormValueInput: {
          marginRight: 10,
          "& .MuiInput-input": {
            fontSize: 14,
            paddingLeft: 4,
          },
          "& .MuiOutlinedInput-root": {
            fontSize: 14,
            height: "auto",
          },
        },
      },
    },
  },

  // Add here more theme overrides
});

export default darkTheme;
