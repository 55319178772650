import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useVisibilityState from "../browser/useVisibilityState";
import { refreshAuthenticationTokens } from "./authenticationFetchers";
import {
  IAuthenticationTokens,
  isJWTValid,
  parseJWT,
  shouldRefreshJWTTokens,
} from "./authenticationJWTUtils";
import {
  getAuthenticationTokensFromLocalStorage,
  removeAuthenticationTokensFromLocalStorage,
  setAuthenticationTokensInLocalStorage,
} from "./authenticationLocalStorage";

export const TOKEN_RENEW_WINDOW = 60; // Time window for token renew (in s)

interface IAuthContext {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  requestReset: (email: string) => Promise<boolean>;
  verifyToken: (token: string) => Promise<boolean>;
  resetPassword: (token: string, newPassword: string) => Promise<boolean>;
  setAuthError: (error: string | null) => void;
  isTokenExpiring: () => boolean;
  getAccessToken: () => string | null;
  authError: string | null;
  isLoggedIn: boolean;
  tokens: IAuthenticationTokens | null;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

interface IAuthProviderProps {
  children: ReactNode;
}

async function getErrorMessageJson(response: any) {
  try {
    const contentType = response.headers.get("content-type");

    let json;

    if (contentType && contentType.indexOf("application/json") !== -1) {
      json = await response.json();
    }

    const firstPair = Object.values(json)[0];

    if (Array.isArray(firstPair) && firstPair.length > 0) {
      return firstPair[0];
    } else if (typeof firstPair === "string") {
      return firstPair;
    }
    return "No error message found.";
  } catch (error) {
    return "An error has occured.";
  }
}

async function getErrorMessageHTML(response: any) {
  try {
    const text = await response.text();
    const match = text.match(/<pre class="exception_value">(.+?)<\/pre>/);
    return match && match[1] ? match[1] : "An error has occurred.";
  } catch (error) {
    return "An error has occured.";
  }
}

export const AuthenticationProvider: React.FC<IAuthProviderProps> = ({
  children,
}) => {
  const [authError, setAuthError] = useState<string | null>(null);
  const [tokens, setTokens] = useState<IAuthenticationTokens | null>(
    getAuthenticationTokensFromLocalStorage()
  );

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    Boolean(tokens) &&
      (isJWTValid(tokens?.access) || isJWTValid(tokens?.refresh))
  );

  const login = useCallback(async (email: string, password: string) => {
    try {
      const response = await fetch("/api/jwt/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: email, password }),
      });

      if (!response.ok) {
        throw new Error(await getErrorMessageJson(response));
      }

      const data = await response.json();
      setTokens({
        access: data.access,
        refresh: data.refresh,
      });
      setIsLoggedIn(true);
      setAuthError(null);

      // Clear any leftover session data
      localStorage.removeItem("sessionExpiryData");
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setAuthError(error.message || "An error has occurred.");
      } else {
        console.error("An error has occurred.");
        setAuthError("An error has occurred.");
      }
      setTokens(null);
    }
  }, []);

  const logout = useCallback(() => {
    setTokens(null);
    setIsLoggedIn(false);
  }, []);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "tokens" && event.newValue !== null) {
        setTokens(JSON.parse(event.newValue) as IAuthenticationTokens);
        setIsLoggedIn(true);
      } else if (event.key === "tokens") {
        setTokens(null);
        setIsLoggedIn(false);
      }
    };

    // Add event listener
    window.addEventListener("storage", handleStorageChange);

    // Clean up
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const requestReset = useCallback(async (email: string) => {
    try {
      const response = await fetch("/auth/reset-password/request-reset/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(await getErrorMessageHTML(response));
      }

      return true;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setAuthError(error.message || "An error has occurred.");
      } else {
        console.error("An error has occurred.");
        setAuthError("An error has occurred.");
      }
      return false;
    }
  }, []);

  const verifyToken = useCallback(async (token: string) => {
    try {
      const response = await fetch("/auth/reset-password/verify-token/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        throw new Error(await getErrorMessageJson(response));
      }

      return true;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setAuthError(error.message || "An error has occurred.");
      } else {
        console.error("An error has occurred.");
        setAuthError("An error has occurred.");
      }
      return false;
    }
  }, []);

  const resetPassword = useCallback(
    async (token: string, new_password: string) => {
      try {
        const response = await fetch("/auth/reset-password/reset-password/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, new_password }),
        });

        if (!response.ok) {
          throw new Error(await getErrorMessageJson(response));
        }

        return true;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          setAuthError(error.message || "An error has occurred.");
        } else {
          console.error("An error has occurred.");
          setAuthError("An error has occurred.");
        }
        return false;
      }
    },
    []
  );

  const visibilityState = useVisibilityState();

  const renewToken = useCallback(async () => {
    if (tokens && tokens.refresh) {
      try {
        const newTokens = await refreshAuthenticationTokens(tokens.refresh);
        setTokens(newTokens);
      } catch (error) {
        alert(error);
        setAuthError(error instanceof Error ? error.message : "Unknown Error");
        logout();
      }
    }
  }, [tokens, logout]);

  useEffect(() => {
    async function check() {
      if (tokens && isJWTValid(tokens.access)) {
        setAuthenticationTokensInLocalStorage(tokens);
      } else if (tokens && isJWTValid(tokens.refresh)) {
        // do nothing
      } else {
        removeAuthenticationTokensFromLocalStorage();
        setIsLoggedIn(false);
      }
    }
    check();
  }, [tokens]);

  useEffect(() => {
    function check() {
      if (visibilityState === "visible") {
        const tokens = getAuthenticationTokensFromLocalStorage();
        if (shouldRefreshJWTTokens(tokens)) {
          renewToken();
        }
      }
    }
    check();
    const interval = setInterval(check, 5000);

    return () => clearInterval(interval);
  }, [renewToken, visibilityState]);

  const isTokenExpiring = useCallback(() => {
    if (tokens && tokens.access) {
      const decoded = parseJWT(tokens.access);
      const currentTime = Date.now() / 1000;
      return (
        decoded && decoded.exp && decoded.exp - currentTime < TOKEN_RENEW_WINDOW
      );
    }
    return true;
  }, [tokens]);

  const getAccessToken = useCallback(() => {
    if (tokens && isJWTValid(tokens.access)) {
      return tokens.access;
    } else {
      return null;
    }
  }, [tokens]);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        requestReset,
        verifyToken,
        resetPassword,
        setAuthError,
        isTokenExpiring,
        getAccessToken,
        authError,
        isLoggedIn,
        tokens,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthentication(): IAuthContext {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "useAuthentication must be used within an AuthenticationProvider"
    );
  }
  return context;
}

export function useAuthHeaders() {
  const { getAccessToken } = useAuthentication();
  const selectedLabel = localStorage.getItem("SELECTED_ENTERPRISE_LABEL");
  const headers = useMemo(() => {
    const ret = {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    };
    if (!selectedLabel) {
      return ret;
    } else {
      return {
        ...ret,
        "Enterprise-Label": String(selectedLabel),
      };
    }
  }, [getAccessToken, selectedLabel]);

  return headers;
}
