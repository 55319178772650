import {
  Button,
  Dialog,
  DialogProps,
  FormGroup,
  H4,
  IconSize,
  Intent,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import Form from "@rjsf/core";
import { FieldTemplateProps, RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { useBrands } from "src/core/CoreDataContext";
import { Vendor } from "../../../__generated__/types/Vendor";
import { Ec1Toaster } from "../../../ui/components/Ec1Toaster.react";
import { errorTransformer } from "../../../ui/errorHandling/Ec1ErrorTransformer";
import { useVendorAccounts } from "./vendorAccounts";

interface PlatformAddVendorAccountDialogProps extends DialogProps {
  onEnrollmentSuccess?: () => void;
  setIsLoadingParent: (loading: boolean) => void;
}

const useStyles = createUseStyles({
  dialog: {
    fontFamily: "Barlow",
    padding: 24,
    width: "400px",
    boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.2) !important",
    "& .bp5-dialog-header": {
      background: "none",
      border: "none",
      boxShadow: "none",
      width: 365,
      paddingLeft: 341,
      marginBottom: 150,
      height: 0,
      marginTop: -23,
      position: "absolute",
      "& .bp5-heading": {
        display: "none",
      },
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "8px",
  },
  validationError: {
    color: "#f17575",
    height: 14,
    overflow: "hidden",
    fontSize: 12,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 330,
    marginTop: 2,
    marginLeft: 1,
  },
  selectError: {
    border: "1px solid #DB3737",
    borderRadius: 3,
  },
  form: {
    "& fieldset": {
      border: "none",
      padding: 0,
      margin: 0,
    },
    "& legend": {
      display: "none",
    },
  },
  formGroupMarginParent: {
    marginBottom: 0,
  },
  formGroupMarginChild: {
    marginBottom: 10,
  },
  formField: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
    "& .bp5-label": {
      marginBottom: "5px",
      fontFamily: "Barlow",
      fontSize: "14px",
      fontWeight: 400,
    },
    "& input": {
      width: "100%",
      height: "30px",
      backgroundColor: "#394b59",
      color: "#f5f8fa",
      border: "1px solid #5c7080",
      borderRadius: "3px",
      padding: "0 10px",
      fontFamily: "Barlow",
      fontSize: "14px",
      "&:focus": {
        boxShadow: "0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3)",
        outline: "none",
      },
    },
  },
  password: {
    "-webkit-text-security": "disc",
    "-moz-text-security": "disc",
    "text-security": "disc",
  },
});

export default function PlatformAddVendorAccountDialog({
  onEnrollmentSuccess,
  setIsLoadingParent,
  onClose,
  ...dialogProps
}: PlatformAddVendorAccountDialogProps) {
  const classes = useStyles();
  const { vendors, createEnrollmentSession } = useVendorAccounts();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Authenticating");

  const brands = useBrands();

  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [vendorValidationError, setVendorValidationError] =
    useState<string>("");
  const [vendorIntent, setVendorIntent] = useState<Intent>(Intent.NONE);

  const formRef = useRef<any>(null);

  const navigate = useNavigate();

  const getFormData = useCallback(() => {
    return formRef.current?.state?.formData || {};
  }, []);

  const setLoadingState = useCallback(
    (loading: boolean) => {
      setIsLoading(loading);
      setIsLoadingParent(loading);
    },
    [setIsLoadingParent]
  );

  useEffect(() => {
    // Prevent Esc from cancelling the redirect
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isLoading) {
        event.preventDefault();
      }
    };

    if (isLoading) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isLoading]);

  const showToast = useCallback(
    (
      message: string,
      intent: Intent,
      buttonText?: string,
      buttonOnClick?: () => void
    ) => {
      Ec1Toaster.show({
        timeout: 5000,
        message: (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 20,
                fontFamily: "Barlow",
              }}
            >
              <div style={{ marginRight: 15, height: 20 }}>{message}</div>
              {buttonText && (
                <Button
                  intent="primary"
                  text={buttonText}
                  onClick={buttonOnClick}
                  style={{
                    height: 20,
                    padding: "0 5px",
                    minHeight: "unset",
                    minWidth: "unset",
                    fontSize: 12,
                  }}
                />
              )}
            </div>
          </>
        ),
        intent: intent,
      });
    },
    []
  );

  const resetForm = useCallback(() => {
    setSelectedVendor(null);
    setVendorValidationError("");
    setVendorIntent(Intent.NONE);
    setLoadingState(false);
  }, [setLoadingState]);

  const handleVendorSelect = useCallback((vendor: Vendor) => {
    setSelectedVendor(vendor);
    setVendorValidationError("");
    setVendorIntent(Intent.NONE);
  }, []);

  const renderAuthenticationForm = useCallback(() => {
    if (!selectedVendor) {
      return null;
    }

    if (selectedVendor.authentication_method === "REDIRECT") {
      return (
        <div
          className="bp5-text-muted bp5-text-small"
          style={{ marginTop: 8, fontStyle: "italic", fontFamily: "Barlow" }}
        >
          <p>
            Please click "Proceed", to be redirected to the vendor's website for
            authentication.
          </p>
        </div>
      );
    } else if (selectedVendor.authentication_method === "FORM") {
      let schema: RJSFSchema;
      try {
        schema = JSON.parse(selectedVendor.authentication_form_schema ?? "{}");
      } catch (error) {
        console.error("Error parsing authentication form schema:", error);
        return <p>An error has occurred. Please consider refreshing.</p>;
      }

      const uiSchema = {
        "ui:submitButtonOptions": {
          norender: true,
        },
      };

      const fieldTemplate: React.FC<FieldTemplateProps> = (props) => {
        const { id, label, required, rawErrors, children } = props;

        const isRootSchema = id === formContext.rootId;
        const error = rawErrors && rawErrors.length > 0 && rawErrors[0];

        return (
          <div
            className={`bp5-form-group ${
              isRootSchema
                ? classes.formGroupMarginParent
                : classes.formGroupMarginChild
            }`}
          >
            {!isRootSchema && (
              <label className="bp5-label" htmlFor={id}>
                {label}
                {required && <span className="bp5-text-muted">*</span>}
              </label>
            )}
            <div className="bp5-form-content">
              <div
                className={`bp5-input-group ${
                  !isRootSchema && error ? "bp5-intent-danger" : ""
                }`}
              >
                {children}
              </div>
              {!isRootSchema && (
                <div className={classes.validationError}>{error}</div>
              )}
            </div>
          </div>
        );
      };

      const inputWidget: React.FC<any> = (props) => {
        const { id, value, onChange, onBlur, disabled, schema } = props;
        return (
          <input
            id={id}
            className={`bp5-input ${
              schema.format === "password" ? classes.password : ""
            }`}
            type={
              schema.format === "string" || schema.format === "password"
                ? "text"
                : schema.type
            }
            name={Math.random().toString()}
            spellCheck="false"
            value={value || ""}
            onChange={(event) => onChange(event.target.value)}
            onBlur={(event) => onBlur(id, event.target.value)}
            disabled={disabled}
          />
        );
      };

      const formContext = {
        rootId: schema.$id || "root",
      };

      return (
        <div style={{ maxHeight: 158, overflowY: "auto" }}>
          <Form
            ref={formRef}
            schema={schema}
            uiSchema={uiSchema}
            validator={validator}
            formContext={formContext}
            formData={getFormData()}
            disabled={isLoading}
            className={`bp5-form ${classes.form}`}
            templates={{ FieldTemplate: fieldTemplate }}
            widgets={{
              TextWidget: inputWidget,
              PasswordWidget: inputWidget,
            }}
            showErrorList={false}
            transformErrors={errorTransformer}
          />
        </div>
      );
    }

    return null;
  }, [selectedVendor, getFormData, isLoading, classes]);

  const handleEnrollClick = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      if (!selectedVendor || !selectedVendor.id) {
        setVendorIntent(Intent.DANGER);
        setVendorValidationError("Vendor selection is mandatory.");
        return;
      }

      setVendorValidationError("");

      try {
        if (selectedVendor.authentication_method === "REDIRECT") {
          setLoadingMessage("Redirecting");
          setLoadingState(true);

          const enrollmentSession = await createEnrollmentSession(
            selectedVendor.id,
            null
          );

          if (enrollmentSession?.redirect_url) {
            let redirectUrl;
            if (window.location.hostname === "localhost") {
              const url = new URL(enrollmentSession.redirect_url);
              redirectUrl = `http://localhost:9090${url.pathname}${url.search}${url.hash}`;
            } else {
              redirectUrl = enrollmentSession.redirect_url;
            }
            window.location.href = redirectUrl;
          } else {
            setLoadingState(false);
          }
        } else if (selectedVendor.authentication_method === "FORM") {
          const currentFormData = getFormData();

          // Validate the form
          const { errors, errorSchema } =
            formRef.current.validate(currentFormData);

          if (errors.length === 0) {
            setLoadingMessage("Authenticating");
            setLoadingState(true);

            const enrollmentSession = await createEnrollmentSession(
              selectedVendor.id,
              currentFormData
            );

            if (
              enrollmentSession?.success &&
              enrollmentSession.enrolled_account
            ) {
              setLoadingMessage(
                "Authentication successful! Redirecting in 5 seconds"
              );

              // Add a delay of 5 seconds for flow purposes
              setTimeout(() => {
                if (onEnrollmentSuccess) {
                  onEnrollmentSuccess();
                }
                navigate(
                  `/vendor/account/${enrollmentSession.enrolled_account}/asset-enrollment`
                );
              }, 5000);
            } else {
              showToast(
                "Adding account failed. Please try again.",
                Intent.DANGER
              );
              setLoadingState(false);
            }
          } else {
            formRef.current.setState(
              {
                errors,
                errorSchema,
                validated: true,
              },
              () => {
                // Force a re-render to show validation errors
                formRef.current.forceUpdate();
              }
            );
          }
        }
      } catch (error) {
        setLoadingState(false);
        showToast("Adding account failed. Please try again.", Intent.DANGER);
      }
    },
    [
      selectedVendor,
      setLoadingState,
      createEnrollmentSession,
      getFormData,
      onEnrollmentSuccess,
      navigate,
      showToast,
    ]
  );

  const handleClose = useCallback(
    (event?: React.SyntheticEvent<HTMLElement, Event>) => {
      if (!isLoading) {
        resetForm();
      }
      if (event && onClose) {
        onClose(event);
      }
    },
    [isLoading, onClose, resetForm]
  );

  const renderVendor: ItemRenderer<Vendor> = (
    vendor: Vendor,
    { handleClick, handleFocus }
  ) => {
    const brand = brands.filter((b) => b.id === vendor.brand)[0] ?? null;
    return (
      <MenuItem
        icon={
          brand && (
            <img alt="brand-icon" src={brand.icon} height={IconSize.STANDARD} />
          )
        }
        key={vendor.id}
        onClick={handleClick}
        onFocus={handleFocus}
        text={`${vendor.name}`}
      />
    );
  };

  return (
    <>
      <Dialog
        className={`bp5-dark ${classes.dialog}`}
        {...dialogProps}
        onClose={handleClose}
        canEscapeKeyClose={!isLoading}
        canOutsideClickClose={!isLoading}
      >
        {isLoading && (
          <div
            style={{
              background: "rgba(0,0,0,.5)",
              width: "400px",
              height: "365px",
              position: "absolute",
              margin: -24,
              borderRadius: 3,
              zIndex: 1,
            }}
          >
            <div
              style={{
                flex: 1,
                height: "100%",
                textAlign: "center",
                alignContent: "center",
                marginTop: -5,
              }}
            >
              <Spinner size={42} style={{ margin: "10px 0px" }} />
              <div>{loadingMessage}...</div>
            </div>
          </div>
        )}
        <H4>Add new vendor account</H4>
        <div style={{ marginTop: "8px", height: 240 }}>
          <FormGroup
            label="Vendor"
            disabled={isLoading}
            style={{ marginBottom: 10 }}
          >
            <div style={{ minWidth: "180px" }}>
              <Select<Vendor>
                items={vendors}
                itemRenderer={renderVendor}
                noResults={
                  <MenuItem
                    disabled={true}
                    text="No results."
                    roleStructure="listoption"
                  />
                }
                onItemSelect={handleVendorSelect}
              >
                <Button
                  className={
                    vendorIntent === Intent.DANGER ? classes.selectError : ""
                  }
                  disabled={isLoading}
                  fill
                  alignText="left"
                  text={selectedVendor?.name ?? "Select a vendor"}
                  rightIcon="double-caret-vertical"
                  value="Select a vendor"
                />
              </Select>
            </div>
            <div className={classes.validationError}>
              {vendorValidationError}
            </div>
          </FormGroup>
          {renderAuthenticationForm()}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            outlined
            intent="danger"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <div style={{ width: "8px" }} />
          <Button
            outlined
            intent="primary"
            onClick={handleEnrollClick}
            disabled={isLoading}
          >
            {selectedVendor &&
            selectedVendor.authentication_method === "REDIRECT"
              ? "Proceed"
              : "Add"}
          </Button>
        </div>
      </Dialog>
    </>
  );
}
