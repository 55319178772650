import { Spinner } from "@blueprintjs/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {
  TooltipFormatterContextObject,
} from "highcharts/highstock";
import { DateTime } from "luxon";
import { useMemo, useRef, useState } from "react";
import usePropertyPrices from "./usePropertyPrices";

interface PlatformPropertyPricesChartProps {
  propertyId: number;
  height?: number;
}

export default function PlatformPropertyPricesChart({
  propertyId,
  height = 320,
}: PlatformPropertyPricesChartProps) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [isLoading, setIsLoading] = useState(false);

  const {
    initialTimestamps,
    isFetchingInitialTimestamps,
    fetchPropertyPriceSeries,
    timezone,
    timezoneLabel,
    granularity,
    currency,
  } = usePropertyPrices(propertyId);

  const hasData = useMemo(() => {
    return initialTimestamps && initialTimestamps.length === 2;
  }, [initialTimestamps]);

  const chartOptions = useMemo(() => {
    const localNow = DateTime.now().setZone(timezone || "system");
    const localDay = localNow.startOf("day").toMillis();
    const localDate = localNow.toMillis();

    return {
      chart: {
        height: height - 65,
        marginLeft: 60,
        marginRight: 20,
        events: {
          load: function (e: any) {
            if (hasData) {
              const latestTimestamp = initialTimestamps[1];
              if (latestTimestamp >= localDay) {
                // If it has current data, show the current day in full
                e.target.xAxis[0].setExtremes(
                  localNow.startOf("day").toMillis(),
                  localNow.endOf("day").toMillis() + 1 // +1 needed to include last tick label
                );
              } else {
                // Else show initially the last 24h from the last point
                const oneDayAgo = latestTimestamp - 24 * 3600 * 1000;
                e.target.xAxis[0].setExtremes(oneDayAgo, latestTimestamp);
              }
            }
          },
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          const points = this.points;

          if (
            !points ||
            points.length === 0 ||
            typeof points[0].x !== "number"
          ) {
            return "";
          }

          const pointDate = DateTime.fromMillis(points[0].x).setZone(
            timezone || "system"
          );

          const startHour = pointDate.toFormat("HH:mm");
          const endHour = pointDate
            .plus({ minutes: granularity })
            .toFormat("HH:mm");
          const format = `cccc, dd LLLL ${startHour}-${endHour}`;

          const dateStr = pointDate.toFormat(format);

          let tooltipContent = `<div style="font-size: 12px; margin-bottom: 5px;">${dateStr}</div>`;

          points.forEach((point) => {
            if (point.series.visible && typeof point.y === "number") {
              const seriesName = point.series.name;
              const valueStr = `${currency}${point.y.toFixed(2)}`;
              const bulletPoint = `<span style="color:${point.color};">\u25CF</span>`;
              tooltipContent += `<div>${bulletPoint} ${seriesName}: <b>${valueStr}</b></div>`;
            }
          });

          return tooltipContent;
        },
        style: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      time: {
        timezone,
        useUTC: timezone !== undefined,
      },
      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        y: 10,
        padding: 0,
        symbolWidth: 14,
        itemStyle: {
          color: "white",
          fontFamily: "Barlow",
        },
        itemHoverStyle: {
          color: "white",
          fontFamily: "Barlow",
        },
      },
      xAxis: {
        minRange: 4 * 60 * 60 * 1000,
        crosshair: true,
        title: {
          y: 5,
          text: `Time<br><span style="font-size: 11px; color: #999;">${timezoneLabel}</span>`,
        },
        events: {
          afterSetExtremes: function (e: any) {
            const chart = e.target.chart;

            if (!chart || !e.min || !e.max) {
              return;
            }

            chart.showLoading();
            setIsLoading(true);

            fetchPropertyPriceSeries(Math.round(e.min), Math.round(e.max))
              .then((data) => {
                const importSeries = data?.filter(
                  (s) => s.name === "import"
                )[0];
                const exportSeries = data?.filter(
                  (s) => s.name === "export"
                )[0];

                chart.series[0].update(
                  {
                    visible: !!importSeries?.data?.length,
                    showInLegend: !!importSeries?.data?.length,
                  },
                  false
                );

                chart.series[1].update(
                  {
                    visible: !!exportSeries?.data?.length,
                    showInLegend: !!exportSeries?.data?.length,
                  },
                  false
                );

                chart.series[0].setData(
                  importSeries?.data.map((p) => [p.x, p.y]) || [],
                  false
                );
                chart.series[1].setData(
                  exportSeries?.data.map((p) => [p.x, p.y]) || [],
                  false
                );

                /* Enforce extremes to redraw once and avoid loops */
                chart.xAxis[0].setExtremes(e.min, e.max, true);
              })
              .finally(() => {
                chart.hideLoading();
                setIsLoading(false);
              });
          },
        },
        plotLines:
          hasData && initialTimestamps[1] >= localDay
            ? [
                {
                  color: "#544fc5",
                  width: 1,
                  value: localDate,
                },
              ]
            : undefined,
      },
      yAxis: [
        {
          opposite: false,
          showLastLabel: true,
          title: {
            text: null,
          },
          labels: {
            y: 4,
            style: {
              fontFamily: "Barlow",
              color: "white",
            },
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              const value =
                typeof this.value === "number"
                  ? this.value.toFixed(2)
                  : this.value;
              return currency + value;
            },
          },
          gridLineWidth: 0,
        },
      ],
      series: [
        {
          name: "Import Price/kWh",
          color: "rgb(219, 44, 111)",
          type: "spline",
          showInLegend: true,
          data: initialTimestamps.map((item: number) => [item, null]),
          dataGrouping: {
            enabled: false,
          },
          zoneAxis:
            hasData && initialTimestamps[1] >= localDay ? "x" : undefined,
          zones:
            hasData && initialTimestamps[1] >= localDay
              ? [
                  {
                    value: localDate,
                    color: "rgb(219, 44, 111)",
                  },
                  {
                    color: "rgba(219, 44, 111,0.5)",
                    dashStyle: "ShortDash",
                  },
                ]
              : undefined,
        },
        {
          name: "Export Price/kWh",
          color: "rgb(186, 112, 127)",
          type: "spline",
          showInLegend: true,
          data: initialTimestamps.map((item: number) => [item, null]),
          dataGrouping: {
            enabled: false,
          },
          zoneAxis:
            hasData && initialTimestamps[1] >= localDay ? "x" : undefined,
          zones:
            hasData && initialTimestamps[1] >= localDay
              ? [
                  {
                    value: localDate,
                    color: "rgb(186, 112, 127)",
                  },
                  {
                    color: "rgba(186, 112, 127,0.5)",
                    dashStyle: "ShortDash",
                  },
                ]
              : undefined,
        },
      ],
      loading: {
        labelStyle: {
          color: "transparent",
        },
        style: { backgroundColor: "transparent" },
      },
      navigator: {
        series: [
          {
            data: initialTimestamps.map((item: number) => [item, null]),
            dataGrouping: {
              enabled: false,
            },
          },
          {
            data: initialTimestamps.map((item: number) => [item, null]),
            dataGrouping: {
              enabled: false,
            },
            visible: false,
          },
        ],
      },
    };
  }, [
    timezone,
    granularity,
    height,
    timezoneLabel,
    initialTimestamps,
    hasData,
    fetchPropertyPriceSeries,
    currency,
  ]);

  return (
    <div
      style={{
        height: height,
        backgroundColor: "rgb(37, 42, 49)",
        borderRadius: 5,
        padding: 20,
        color: "white",
      }}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 21,
              marginBottom: 10,
            }}
          >
            <b>Prices</b>
          </div>
        </div>
      </div>
      {isFetchingInitialTimestamps ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 260,
            width: "100%",
          }}
        >
          <Spinner />
        </div>
      ) : hasData ? (
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={chartOptions}
            ref={chartRef}
          />
          {isLoading && (
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
                height: height - 100,
                marginTop: 65 - height,
                zIndex: 100, // Above Highcharts
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 260,
            width: "100%",
          }}
        >
          No data available
        </div>
      )}
    </div>
  );
}
