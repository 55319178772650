import { Spinner } from "@blueprintjs/core";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  Node,
  useStore,
} from "reactflow";
import "reactflow/dist/style.css";
import { useCustomers } from "../../customers/customers";
import { useDevices } from "../../devices/devices";
import { useProperties } from "../../properties/properties";
import GridEdge from "./GridEdge.react";
import { getGridGraph } from "./GridGraph";
import GridNode, { ZOOM_THRESHOLD } from "./GridNode.react";

const useStyles = createUseStyles({
  controls: {
    "& .react-flow__controls-button": {
      fill: "#ccc",
      background: "#292f37",
      color: "white !important",
      borderBottom: "1px solid #383E47",
      "&:hover": {
        background: "#414852",
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
  minimap: {
    height: 150,
    border: "none",
    background: "#262a30",
    "& .react-flow__controls-button": {
      fill: "#ccc",
      background: "#292f37",
      color: "white !important",
      borderBottom: "1px solid #383E47",
      "&:hover": {
        background: "#414852",
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
});

export default function PlatformSiteDeviceGridView() {
  const { devices, fetchAllDevices } = useDevices();
  const { properties, fetchAllProperties } = useProperties();
  const { customers, fetchAllCustomers } = useCustomers();

  const [isLoading, setIsLoading] = useState(true);
  const [lastUpdateTime, setLastUpdateTime] = useState<Date | null>(null);
  const [ratio, setRatio] = useState(1);

  const zoomLevel = useStore((store) => store.transform[2]);
  const isZoomed = zoomLevel >= ZOOM_THRESHOLD;

  const parentRef = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    fetchAllDevices();
    fetchAllProperties();
    fetchAllCustomers();

    const intervalId = setInterval(() => {
      fetchAllDevices();
      fetchAllProperties();
      fetchAllCustomers();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [fetchAllCustomers, fetchAllDevices, fetchAllProperties]);

  useEffect(() => {
    if (devices !== null) {
      // Detects aspect ratio of the available space (only the first time) and
      // sets the ratio so that the graphs knows how to spread out optimally
      if (parentRef.current) {
        const { offsetWidth, offsetHeight } = parentRef.current;
        setRatio(Math.round(offsetWidth / offsetHeight));
      }
      setLastUpdateTime(new Date());
      setIsLoading(false);
    }
  }, [devices]);

  const nodeTypes = useMemo(() => ({ gridNode: GridNode }), []);
  const edgeTypes = useMemo(() => ({ gridEdge: GridEdge }), []);

  const graph = useMemo(
    () => getGridGraph(devices ?? [], properties, customers, ratio, zoomLevel),
    [devices, properties, customers, ratio, zoomLevel]
  );

  const getNodeStrokeColor = useCallback(
    (node: Node) => {
      if (node.data.isRoot) {
        return node.data.color;
      }
      return (node.data.isChild && !isZoomed) ||
        (!node.data.isChild && isZoomed)
        ? "transparent"
        : node.data.color;
    },
    [isZoomed]
  );

  const getNodeColor = useCallback(
    (node: Node) => {
      if (node.data.isRoot) {
        return node.data.color + "22";
      }
      return (node.data.isChild && !isZoomed) ||
        (!node.data.isChild && isZoomed)
        ? "transparent"
        : node.data.color + "22";
    },
    [isZoomed]
  );

  const lastUpdateTimeString = useMemo(
    () =>
      lastUpdateTime
        ? DateTime.fromJSDate(lastUpdateTime).toLocaleString(
            DateTime.DATETIME_MED
          )
        : "N/A",
    [lastUpdateTime]
  );

  if (isLoading) {
    return (
      <div
        ref={parentRef}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#1C2127",
        }}
      >
        <Spinner size={60} />
      </div>
    );
  }

  if (devices === null) {
    return (
      <div
        style={{ backgroundColor: "#1C2127", width: "100%", height: "100%" }}
      />
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(17, 17, 17)",
        color: "rgb(243, 244, 246)",
        overflow: "none",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          fontFamily: "Barlow",
          color: "#ABB3BF",
          fontSize: "14px",
          padding: "20px",
        }}
      >
        {`Last updated at ${lastUpdateTimeString}`}
      </div>
      <div style={{ height: "100%" }}>
        <ReactFlow
          minZoom={0.001}
          maxZoom={2}
          nodes={graph.nodes}
          edges={graph.edges}
          nodeTypes={nodeTypes}
          proOptions={{ hideAttribution: true }}
          edgeTypes={edgeTypes}
          fitView
          fitViewOptions={{ padding: 0.15 }} // Padding factor, not in px
          elementsSelectable={false}
          draggable={false}
          nodesFocusable={false} // prevent node 'halo' effect by BlueprintJS global css
          edgesFocusable={false} // prevent edge 'halo' effect by BlueprintJS global css
        >
          <Background
            variant={BackgroundVariant.Dots}
            size={1}
            color={"#383E47"}
          />
          <Controls className={classes.controls} showInteractive={false} />
          <MiniMap
            pannable
            zoomable
            className={classes.minimap}
            nodeStrokeWidth={5}
            nodeStrokeColor={getNodeStrokeColor}
            nodeColor={getNodeColor}
            nodeBorderRadius={100}
            maskColor="rgba(24, 28, 33, 0.8)"
          />
        </ReactFlow>
      </div>
    </div>
  );
}
