import { BaseEdge, EdgeProps, getBezierPath } from "reactflow";

interface NormalEdgeProps extends Partial<EdgeProps> {
  data: {
    color1: string;
    color2: string;
  };
  label?: string;
  markerEnd?: string;
  sourceX: number; // Add this
  sourceY: number; // Add this
  targetX: number; // Add this
  targetY: number; // Add this
}

const NormalEdge: React.FC<NormalEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  label,
  markerEnd,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{ stroke: `${data.color1}`, strokeWidth: 2 }}
      />

      {/* <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            backgroundColor: "#EEF0F6",
            fill: "#EEF0F6",
            pointerEvents: "all",
            padding: "4px",
          }}
          className="nodrag nopan"
        >
          {label}
        </div>
      </EdgeLabelRenderer> */}

      <circle
        style={{ filter: `drop-shadow(3px 3px 5px ${data.color2}` }}
        r="4"
        fill={`${data.color2}`}
        className="circle"
      >
        <animateMotion dur="6s" repeatCount="indefinite" path={edgePath} />
      </circle>
    </>
  );
};

const NormalEdgeWithTypeAssertion = NormalEdge as React.FC<EdgeProps>;

export default NormalEdgeWithTypeAssertion;
