export const MAPBOX_TOKEN =
  "pk.eyJ1IjoiaWthc2lub3BvdWxvcyIsImEiOiJjbHExOHUzenMwNjF4MmpsZmJpMzkzMjB2In0.kw-V94tuX3YE5okN7IQ8AA";

export interface MapLocation {
  name: string;
  lat: number;
  lng: number;
}

export const locations: MapLocation[] = [
  // Madrid locations
  { name: "Central Madrid", lat: 40.416775, lng: -3.70379 },
  { name: "Chamberí", lat: 40.43, lng: -3.7 },
  { name: "Tetuán", lat: 40.45, lng: -3.69 },
  { name: "Salamanca", lat: 40.4, lng: -3.68 },
  { name: "Arganzuela", lat: 40.38, lng: -3.72 },
  { name: "Usera", lat: 40.36, lng: -3.73 },
  { name: "Fuencarral-El Pardo", lat: 40.48, lng: -3.7 },
  { name: "Hortaleza", lat: 40.49, lng: -3.69 },
  { name: "Ciudad Lineal", lat: 40.47, lng: -3.64 },
  { name: "San Blas-Canillejas", lat: 40.45, lng: -3.61 },
  { name: "Barajas", lat: 40.43, lng: -3.6 },
  { name: "Retiro", lat: 40.41, lng: -3.66 },
  { name: "Moratalaz", lat: 40.39, lng: -3.65 },
  { name: "Vicálvaro", lat: 40.37, lng: -3.61 },
  { name: "Villaverde", lat: 40.33, lng: -3.76 },
  { name: "Carabanchel", lat: 40.32, lng: -3.73 },
  { name: "Latina", lat: 40.29, lng: -3.7 },
  { name: "Puente de Vallecas", lat: 40.27, lng: -3.67 },
  { name: "Villa de Vallecas", lat: 40.25, lng: -3.65 },
  { name: "Ensanche de Vallecas", lat: 40.23, lng: -3.63 },

  // Greater Barcelona locations
  { name: "Central Barcelona", lat: 41.385064, lng: 2.173404 },
  { name: "Eixample", lat: 41.4, lng: 2.16 },
  { name: "Gràcia", lat: 41.41, lng: 2.19 },
  { name: "Horta-Guinardó", lat: 41.43, lng: 2.17 },
  { name: "Nou Barris", lat: 41.45, lng: 2.2 },
  { name: "Sant Andreu", lat: 41.44, lng: 2.22 },
  { name: "Sants-Montjuïc", lat: 41.39, lng: 2.14 },
  { name: "Les Corts", lat: 41.37, lng: 2.13 },
  { name: "Sarrià-Sant Gervasi", lat: 41.35, lng: 2.1 },
  { name: "Collserola", lat: 41.33, lng: 2.08 },
  { name: "Baix Llobregat", lat: 41.31, lng: 2.03 },
  { name: "L'Hospitalet de Llobregat", lat: 41.29, lng: 2.01 },
  { name: "Badalona", lat: 41.45, lng: 2.25 },
  { name: "Montgat", lat: 41.48, lng: 2.23 },
  { name: "Tiana", lat: 41.5, lng: 2.24 },
  { name: "Alella", lat: 41.54, lng: 2.21 },
  { name: "Teià", lat: 41.56, lng: 2.18 },
  { name: "Masnou", lat: 41.58, lng: 2.16 },
  { name: "Montgat Nord", lat: 41.6, lng: 2.14 },
  // Add more locations as needed
];
