import Highcharts from "highcharts";
import Highstock from "highcharts/highstock";
import Moment from "moment";
import MomentTimezone from "moment-timezone";

// Set moment globally
window.moment = Moment;

// Initialize moment-timezone
MomentTimezone();

// Set Highstock global options
Highstock.setOptions({
  accessibility: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  chart: {
    backgroundColor: "transparent",
    zooming: {
      type: "x",
      mouseWheel: { enabled: false },
    },
  },
  scrollbar: {
    liveRedraw: false,
    enabled: false,
  },
  navigator: {
    enabled: true,
    handles: { enabled: false },
    height: 0,
    margin: 0,
    outlineWidth: 0,
    xAxis: { visible: false },
    yAxis: { visible: false },
    adaptToUpdatedData: false,
  },
  legend: {
    enabled: false,
  },
  title: {
    style: {
      fontFamily: "Barlow",
      color: "white",
    },
  },
  tooltip: {
    hideDelay: 0,
    backgroundColor: "#555",
    valueDecimals: 2,
    style: {
      fontFamily: "Barlow",
      color: "white",
      fontSize: "14px",
    },
  },
  xAxis: {
    type: "datetime",
    events: {
      //afterSetExtremes: afterSetExtremes,
    },
    title: {
      text: "Time",
      style: {
        fontFamily: "Barlow",
        color: "white",
      },
    },
    labels: {
      style: {
        fontFamily: "Barlow",
        color: "white",
      },
    },
    lineColor: "#8F99A8",
    tickColor: "#444",
    minRange: 60 * 60 * 1000,
  },
  yAxis: {
    opposite: false,
    showLastLabel: true,
    title: {
      style: {
        fontFamily: "Barlow",
        color: "white",
      },
    },
    labels: {
      y: 4,
      style: {
        fontFamily: "Barlow",
        color: "white",
      },
    },
    gridLineWidth: 1,
    gridLineColor: "#383E47",
    plotLines: [
      {
        value: 0,
        color: "transparent",
        zIndex: 2,
      },
    ],
  },
  plotOptions: {
    column: {
      crisp: false,
    },
  },
  rangeSelector: {
    enabled: true,
    buttonTheme: {
      fill: "#555",
      stroke: "#444",
      "stroke-width": 0,
      r: 5,
      style: {
        fontWeight: "500",
        fontFamily: "Barlow",
        color: "white",
      },
      states: {
        hover: {
          fill: "#666",
        },
        select: {
          fill: "#888",
          style: {
            color: "white",
          },
        },
        disabled: {
          fill: "#393939",
          style: {
            color: "#666",
            cursor: "default",
          },
        },
      },
    },
    inputBoxBorderColor: "transparent",
    inputBoxWidth: 60,
    inputBoxHeight: 20,
    inputStyle: {
      color: "#8e93e3",
      fontWeight: "400",
      fontFamily: "Barlow",
      fontSize: "13px",
    },
    labelStyle: {
      color: "#777",
      fontFamily: "Barlow",
      fontSize: "13px",
    },
    buttonPosition: {
      y: -5,
    },
    inputPosition: {
      y: -7,
    },
    buttons: [
      {
        type: "day",
        count: 1,
        text: "1d",
      },
      {
        type: "day",
        count: 2,
        text: "2d",
      },
      {
        type: "week",
        count: 1,
        text: "1w",
      },
      {
        type: "month",
        count: 1,
        text: "1m",
      },
      {
        type: "ytd",
        text: "YTD",
      },
      {
        type: "all",
        text: "All",
      },
    ],
    selected: 0,
  },
});

export function tooltipFormatter(
  unit: string,
  decimalPlaces: number = 3,
  isRange: boolean = false
) {
  return function (this: Highcharts.TooltipFormatterContextObject) {
    if (!this.points) {
      return "";
    }

    const point = this.points[0];
    if (!point || !point.x) {
      return "";
    }

    const series = point.series as any;
    if (!series) {
      return "";
    }

    const dataGrouping = series.currentDataGrouping;
    if (!dataGrouping) {
      return "";
    }

    type DateGroupingUnit =
      | "minute"
      | "hour"
      | "day"
      | "week"
      | "month"
      | "year";

    const dateFormats: Record<DateGroupingUnit, string[]> = isRange
      ? {
          minute: ["%A, %e %b, %H:%M", "- %H:%M"],
          hour: ["%A, %e %b, %H:%M", "- %H:%M"],
          day: ["%A, %e %b %Y", "- %A, %e %b %Y"],
          week: ["Week from %A, %e %b %Y", "- %A, %e %b %Y"],
          month: ["%B %Y", "- %B %Y"],
          year: ["%Y", "- %Y"],
        }
      : {
          minute: ["%A, %e %b %H:%M"],
          hour: ["%A, %e %b %H:%M"],
          day: ["%A, %e %b %Y"],
          week: ["Week starting on %A, %e %b %Y"],
          month: ["%B %Y"],
          year: ["%Y"],
        };

    const unitName: DateGroupingUnit =
      dataGrouping && dataGrouping.unitName in dateFormats
        ? (dataGrouping.unitName as DateGroupingUnit)
        : "minute";
    const format = dateFormats[unitName];
    const start = Highcharts.dateFormat(format[0], point.x as number);
    const end = isRange
      ? Highcharts.dateFormat(format[1], point.x + dataGrouping.totalRange)
      : start;

    let pointsStr = "";
    let tooltip =
      '<div style="fontSize: 12px">' +
      start +
      " " +
      (end !== start ? end : "") +
      '</div><div style="margin-top: 5px">';

    this.points.forEach(function (point) {
      let bullet =
        '<span style="color:' + point.series.color + '">\u25CF</span> ';
      let seriesName = point.series.name;
      let value =
        "<b>" +
        Highcharts.numberFormat(point.y as number, decimalPlaces) +
        "</b>";
      pointsStr += bullet + seriesName + ": " + value + " " + unit + "<br/>";
    });

    pointsStr += "</div>";

    return pointsStr !== "" ? tooltip + pointsStr : "";
  };
}

export function afterSetExtremes(
  e: Highcharts.AxisSetExtremesEventObject,
  type: string = "power"
) {
  return function (this: Highcharts.Axis) {
    const diff = e.max - e.min;
    const oneHour = 3600 * 1000;
    const oneDay = 24 * oneHour;
    const oneWeek = 7 * oneDay;
    const oneMonth = 30 * oneDay;
    const oneYear = 365 * oneDay;

    let groupingUnits: [string, [number]] = ["hour", [1]];

    if (type === "power" && diff <= 2 * oneHour) {
      groupingUnits = ["minute", [5]];
    } else if (diff <= 2 * oneDay) {
      groupingUnits = ["hour", [1]];
    } else if (diff <= 2 * oneWeek) {
      groupingUnits = ["day", [1]];
    } else if (diff <= 2 * oneMonth) {
      groupingUnits = ["week", [1]];
    } else if (diff <= 2 * oneYear) {
      groupingUnits = ["month", [1]];
    } else {
      groupingUnits = ["year", [1]];
    }

    this.series[0].update({
      type: this.series[0].options.type as any,
      dataGrouping: {
        forced: true,
        units: [groupingUnits],
      },
    });
  };
}
