import { Brand } from "@ec1/types/Brand";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFetch } from "src/common/fetcher/effects";
interface ICoreDataProviderProps {
  children: ReactNode;
}
interface ICoreDataContext {
  brands: Brand[];
}

const CoreDataContext = createContext<ICoreDataContext>({ brands: [] });

export function CoreDataProvider({ children }: ICoreDataProviderProps) {
  const { data: brandResponse } = useFetch<{ results: Brand[] }>(
    "/api/devices/brands/",
    { useCache: true }
  );

  const [brands, setBrands] = useState<Brand[]>([]);
  useEffect(() => {
    if (brandResponse?.results) {
      setBrands(brandResponse?.results);
    }
  }, [brandResponse?.results]);

  return (
    <CoreDataContext.Provider value={{ brands }}>
      {children}
    </CoreDataContext.Provider>
  );
}

export function useBrands() {
  const { brands } = useContext(CoreDataContext);
  return brands;
}
