// Mapping MUI DataGrid operators to API operators
export const DATAGRID_OPERATOR_MAPPING: Record<string, string> = {
  // String
  contains: "icontains",
  startsWith: "istartswith",
  endsWith: "iendswith",
  isNot: "exclude",
  isEmpty: "isnull",
  isNotEmpty: "isnull",

  // Numeric
  greaterThan: "gt",
  greaterThanOrEqual: "gte",
  lessThan: "lt",
  lessThanOrEqual: "lte",
  ">": "gt",
  ">=": "gte",
  "<": "lt",
  "<=": "lte",

  //Date
  after: "gt",
  onOrAfter: "gte",
  before: "lt",
  onOrBefore: "lte",

  // Multiple values
  isAnyOf: "in",
};
