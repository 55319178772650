import { Tag } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import { useMemo } from "react";

interface BatteryStatusTagProps {
  battery: Battery;
}
export default function BatteryStatusTag({ battery }: BatteryStatusTagProps) {
  const battery_power = useMemo(() => {
    return battery?.battery_power ?? null;
  }, [battery]);

  if (battery_power === null) {
    return <div>{"-"}</div>;
  }

  if (battery_power === 0) {
    return <Tag style={{ fontWeight: 500 }}>{"Idle"}</Tag>;
  }

  if (battery_power < 0) {
    return (
      <Tag
        style={{ backgroundColor: "#2D72D2", color: "white", fontWeight: 500 }}
      >
        {"Charging"}
      </Tag>
    );
  }

  if (battery_power > 0) {
    return (
      <Tag
        style={{ backgroundColor: "#EC9A3C", color: "black", fontWeight: 500 }}
      >
        {"Discharging"}
      </Tag>
    );
  }

  return <div>{"-"}</div>;
}
