import { Edge } from "reactflow";
import { Battery } from "../../../../__generated__/types/Battery";

export function filterEdges(edges: Edge[], batteries: Battery[]) {
  return edges.map((edge) => {
    for (let i = 0; i < batteries.length; i++) {
      const battery = batteries[i];
      const batteryId = battery.id ?? -1;
      // Grid Flows
      const gridPower = battery.grid_power ?? 0;

      if (gridPower === 0) {
        if (edge.id.startsWith(`e-grid-${batteryId}-`)) {
          edge = { ...edge, type: "default" };
        }
        if (edge.id.endsWith(`grid-${batteryId}`)) {
          edge = { ...edge, type: "default" };
        }
      }

      if (gridPower > 0) {
        if (edge.id.startsWith(`e-grid-${batteryId}-big-grid`)) {
          edge = {
            ...edge,
            type: "default",
            style: { ...edge.style, display: "none" },
          };
        }

        if (edge.id.endsWith(`-grid-${batteryId}`)) {
          edge = {
            ...edge,
            type: "default",
            style: { ...edge.style, display: "default" },
          };
        }
      }

      if (gridPower < 0) {
        if (edge.id === `e-big-grid-grid-${batteryId}`) {
          edge = {
            ...edge,
            type: "default",
            style: { ...edge.style, display: "none" },
          };
        }
        if (
          edge.id.startsWith(`e-grid-${batteryId}-`) &&
          !edge.id.endsWith(`-big-grid`)
        ) {
          edge = {
            ...edge,
            type: "default",
          };
        }
      }

      // Battery flows
      const batteryPower = battery.battery_power ?? 0;
      if (batteryPower >= 0) {
        if (edge.id.endsWith(`battery-${batteryId}`)) {
          edge = { ...edge, type: "default" };
        }
      }
      if (batteryPower <= 0) {
        if (edge.id.startsWith(`e-battery-${batteryId}-`)) {
          edge = { ...edge, type: "default" };
        }
      }

      // solarFlows
      const solarPower = battery.solar_power ?? 0;
      if (solarPower <= 0) {
        if (edge.id.startsWith(`e-solar-${batteryId}-`)) {
          return { ...edge, type: "default" };
        }
      }
    }

    return { ...edge };
  });
}
