import { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useLocation, useNavigate } from "react-router-dom";
import LogoText from "../../ui/logo/LogoText.react";
import SpinningLogo from "../../ui/logo/SpinningLogo.react";
import VantaPage from "../../ui/vanta/VantaPage.react";
import PasswordResetForm from "./PasswordResetForm.react";
import PlatformLoginForm from "./PlatformLoginForm.react";
import { useAuthentication } from "./common/authentication/authentication";

const REDIRECT_TIMEOUT = 10000; // Constant for the redirect timeout (ms)

const useStyles = createUseStyles({
  container: {
    marginTop: "20vh",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    height: "400px",
    width: "320px",
  },
  backgroundLayer: {
    backgroundColor: "white",
    borderRadius: "6px",
    position: "absolute",
    height: "100%",
    width: "100%",
    opacity: 0.1,
    boxShadow: "0 4px 8px 0 rgba(256, 256, 256, 0.2)",
    zIndex: 1,
  },
  spinningLogoLayer: {
    marginTop: "32px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    zIndex: 3,
    width: "90%",
  },
  spinningLogoLayerCentered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  logoText: {
    marginTop: "10px",
    marginBottom: "40px",
  },
  form: {
    width: "200px",
    height: "190px",
  },
  message: {
    marginBottom: "16px",
    color: "#ccc",
    fontSize: "12px",
    fontFamily: "Space Grotesk",
  },
  clickableText: {
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    fontWeight: "bold",
    "&:hover": { textDecoration: "underline" },
    cursor: "pointer",
    fontSize: "12px",
    fontFamily: "inherit",
  },
  errorMessage: {
    color: "#ff4d4f",
    fontSize: "12px",
    fontFamily: "Space Grotesk",
    textAlign: "center",
  },
});

export default function PlatformLoginPage() {
  const [resetToken, setResetToken] = useState<string | null>(null);
  const [showResetForm, setShowResetForm] = useState(false);
  const [resetRequestSuccess, setResetRequestSuccess] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { verifyToken, authError } = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleBack = useCallback(() => {
    navigate("/", { replace: true });
    setResetRequestSuccess(false);
    setPasswordResetSuccess(false);
    setShowResetForm(false);
    setResetToken(null);
  }, [navigate]);

  useEffect(() => {
    if (location.pathname === "/reset-password" && !passwordResetSuccess) {
      const token = new URLSearchParams(location.search).get("token");
      if (token) {
        verifyToken(token).then((isValid) => {
          if (isValid) {
            setResetToken(token);
            setShowResetForm(true);
          } else {
            navigate("/", { replace: true });
          }
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [location, verifyToken, navigate, passwordResetSuccess]);

  return (
    <VantaPage>
      <div className={classes.container}>
        <div className={classes.backgroundLayer} />
        {isLoading ? (
          <div className={classes.spinningLogoLayerCentered}>
            <SpinningLogo
              height="56px"
              isLoadingSpinner={true}
              key={String(isLoading)}
            />
          </div>
        ) : (
          <div className={classes.spinningLogoLayer}>
            <SpinningLogo height="56px" />
            <div className={classes.logoText}>
              <LogoText />
            </div>
            {!showResetForm &&
              !resetRequestSuccess &&
              !passwordResetSuccess && (
                <PlatformLoginForm
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  onSuccess={() => {
                    setResetRequestSuccess(true);
                    setShowResetForm(false);
                    setTimeout(() => handleBack(), REDIRECT_TIMEOUT);
                  }}
                />
              )}
            {showResetForm && !resetRequestSuccess && !passwordResetSuccess && (
              <PasswordResetForm
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                resetToken={resetToken}
                onSuccess={() => {
                  setPasswordResetSuccess(true);
                  setShowResetForm(false);
                  setTimeout(() => handleBack(), REDIRECT_TIMEOUT);
                }}
              />
            )}
            {authError && (
              <div className={classes.errorMessage}>{authError}</div>
            )}
            {resetRequestSuccess && (
              <div className={classes.form}>
                <div className={classes.message}>
                  Thank you, password reset instructions sent to your email.{" "}
                  <br />
                  <br />
                  You will be redirected to the login page automatically in 10
                  seconds, if you are not, please{" "}
                  <span className={classes.clickableText} onClick={handleBack}>
                    click here
                  </span>
                  .
                </div>
              </div>
            )}
            {passwordResetSuccess && (
              <div className={classes.form}>
                <div className={classes.message}>
                  Password successfully changed. <br />
                  <br />
                  You will be redirected to the login page automatically in 10
                  seconds, if you are not, please{" "}
                  <span className={classes.clickableText} onClick={handleBack}>
                    click here
                  </span>
                  .
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </VantaPage>
  );
}
