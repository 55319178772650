import {
  Button,
  FormGroup,
  InputGroup,
  Radio,
  RadioGroup,
  TextArea,
} from "@blueprintjs/core";
import { useState } from "react";
import { MdCampaign } from "react-icons/md";

// Step 2: Define your styles
/*const useStyles = createUseStyles({
  dialogStyle: {
    minWidth: "500px",
    width: "35%",
    fontFamily: "Barlow",
  },
});*/

//interface Audience {
//  id: number;
//  name: string;
//  audience_size: number;
//}

/*const AUDIENCES: Audience[] = [
    { id: 1, name: "All customers", audience_size: 36911 },
    { id: 2, name: "All active customers", audience_size: 4612 },
    { id: 3, name: "All active customers with EVs", audience_size: 340 },
    {
      id: 4,
      name: "All active customers with EVs and Solar",
      audience_size: 340,
    },
  ];*/

//function renderAudience(audience: Audience) {
//  return <MenuItem text={`${audience.name}`} />;
//}

interface PlatformCampaignCreationDetailsTabProps {
  createNewCampaign: (title: string, objective: string) => Promise<void>;
  isCreatingCampaign: boolean;
  isProcessing: boolean;
}

export default function PlatformCampaignCreationDetailsTab({
  createNewCampaign,
  isCreatingCampaign,
  isProcessing,
}: PlatformCampaignCreationDetailsTabProps) {
  // Step 3: Use styles hook
  //const classes = useStyles();

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const campaignType = 1;
  const campainLanguage = 1;

  return (
    <div style={{}}>
      <div style={{ marginTop: "32px" }}>
        <FormGroup
          label="Campaign name"
          disabled={isCreatingCampaign || isProcessing}
        >
          <InputGroup
            disabled={isCreatingCampaign || isProcessing}
            onValueChange={setTitle}
            value={title}
            leftElement={
              <span
                style={
                  {
                    //backgroundColor: "red",
                  }
                }
              >
                <MdCampaign
                  style={{
                    marginTop: "8px",
                    marginLeft: "6px",
                    marginRight: "6px",
                    color: "#ABB3BF",
                  }}
                />
              </span>
            }
            placeholder="Campaign name"
          />
        </FormGroup>

        <FormGroup
          label="Campaign Objective"
          disabled={isCreatingCampaign || isProcessing}
        >
          <TextArea
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setDescription(event.target.value);
            }}
            disabled={isCreatingCampaign || isProcessing}
            value={description}
            fill
            rows={4}
            placeholder="Short description of what this campaign is about"
            style={{
              width: "100%",
              fontFamily: "Barlow",
              resize: "none",
            }}
          />
        </FormGroup>
        <FormGroup disabled={isCreatingCampaign || isProcessing}>
          <RadioGroup
            disabled={isCreatingCampaign || isProcessing}
            inline
            label="Campaign Type"
            onChange={() => {}}
            selectedValue={campaignType}
          >
            <Radio label="Email" value={1} labelElement="" />
            <Radio label="Mobile (Notification)" value={2} labelElement="" />
            <Radio label="Mobile (SMS)" value={3} labelElement="" />
          </RadioGroup>
        </FormGroup>
        <FormGroup disabled={isCreatingCampaign || isProcessing}>
          <RadioGroup
            disabled={isCreatingCampaign || isProcessing}
            inline
            label="Language"
            onChange={() => {}}
            selectedValue={campainLanguage}
          >
            <Radio label="English (UK)" value={1} labelElement="" />
            <Radio label="Spanish" value={2} />
            <Radio label="Dutch" value={3} />
            <Radio label="Greek" value={4} />
          </RadioGroup>
        </FormGroup>

        {/*<Label>
              Audience
              <Select<Audience>
                items={AUDIENCES}
                popoverProps={{ position: "bottom" }}
                itemRenderer={renderAudience}
                onItemSelect={() => {}}
              >
                <Button
                  fill
                  alignText="left"
                  rightIcon="double-caret-vertical"
                  text="Select a campaign audience"
                  placeholder="Select a campaign audience"
                />
              </Select>
              </Label>*/}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={
              title.length === 0 ||
              description.length === 0 ||
              isCreatingCampaign ||
              isProcessing
            }
            intent="primary"
            onClick={() => {
              createNewCampaign(title, description);
            }}
          >
            {isCreatingCampaign
              ? "Creating..."
              : isProcessing
              ? "Processing..."
              : "Next"}
          </Button>
        </div>
      </div>
    </div>
  );
}
