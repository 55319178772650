import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  Spread,
} from "lexical";

import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents";
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode";
import PlatformAssistantDataset from "../../assistant/PlatformAssistantDataset.react";

type AssistantDatasetComponentProps = Readonly<{
  className: Readonly<{
    base: string;
    focus: string;
  }>;
  format: ElementFormatType | null;
  nodeKey: NodeKey;
  videoID: string;
}>;

function AssistantDatasetComponent({
  className,
  format,
  nodeKey,
  videoID,
}: AssistantDatasetComponentProps) {
  return (
    <BlockWithAlignableContents
      className={className}
      format={format}
      nodeKey={nodeKey}
    >
      <PlatformAssistantDataset uuid={videoID} />
    </BlockWithAlignableContents>
  );
}

export type SerializedAssistantDatasetNode = Spread<
  {
    videoID: string;
  },
  SerializedDecoratorBlockNode
>;

function convertDatasetElement(
  domNode: HTMLElement
): null | DOMConversionOutput {
  const videoID = domNode.getAttribute("data-lexical-dataset");
  if (videoID) {
    const node = $createAssistantDatasetNode(videoID);
    return { node };
  }
  return null;
}

export class AssistantDatasetNode extends DecoratorBlockNode {
  __id: string;

  static getType(): string {
    return "assistant-dataset";
  }

  static clone(node: AssistantDatasetNode): AssistantDatasetNode {
    return new AssistantDatasetNode(node.__id, node.__format, node.__key);
  }

  static importJSON(
    serializedNode: SerializedAssistantDatasetNode
  ): AssistantDatasetNode {
    const node = $createAssistantDatasetNode(serializedNode.videoID);
    node.setFormat(serializedNode.format);
    return node;
  }

  exportJSON(): SerializedAssistantDatasetNode {
    return {
      ...super.exportJSON(),
      type: "assistant-dataset",
      version: 1,
      videoID: this.__id,
    };
  }

  constructor(id: string, format?: ElementFormatType, key?: NodeKey) {
    super(format, key);
    this.__id = id;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("iframe");
    element.setAttribute("data-lexical-assistant-dataset", this.__id);
    element.setAttribute("width", "560");
    element.setAttribute("height", "315");
    element.setAttribute(
      "src",
      `https://www.youtube-nocookie.com/embed/${this.__id}`
    );
    element.setAttribute("frameborder", "0");
    element.setAttribute(
      "allow",
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    );
    element.setAttribute("allowfullscreen", "true");
    element.setAttribute("title", "YouTube video");
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      iframe: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-dataset")) {
          return null;
        }
        return {
          conversion: convertDatasetElement,
          priority: 1,
        };
      },
    };
  }

  updateDOM(): false {
    return false;
  }

  getId(): string {
    return this.__id;
  }

  getTextContent(
    _includeInert?: boolean | undefined,
    _includeDirectionless?: false | undefined
  ): string {
    return `https://www.youtube.com/watch?v=${this.__id}`;
  }

  decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || "",
      focus: embedBlockTheme.focus || "",
    };
    return (
      <AssistantDatasetComponent
        className={className}
        format={this.__format}
        nodeKey={this.getKey()}
        videoID={this.__id}
      />
    );
  }
}

export function $createAssistantDatasetNode(
  videoID: string
): AssistantDatasetNode {
  return new AssistantDatasetNode(videoID);
}

export function $isYouTubeNode(
  node: AssistantDatasetNode | LexicalNode | null | undefined
): node is AssistantDatasetNode {
  return node instanceof AssistantDatasetNode;
}
