import { Box, MenuItem, Popover, ThemeProvider, styled } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnMenu,
  GridFilterInputValue,
  GridFilterModel,
  GridFilterOperator,
  GridRow,
  GridRowParams,
  GridRowProps,
  GridSortModel,
  getGridStringOperators,
} from "@mui/x-data-grid";
import type {} from "@mui/x-data-grid/themeAugmentation";
import React, { useEffect, useRef, useState } from "react";
import { DATAGRID_OPERATOR_MAPPING } from "../config/dataGridOperators";
import darkTheme from "../themes/darkTheme";

const StyledGridColumnMenu = styled(GridColumnMenu)(() => ({
  background: "#343d45",
  borderRadius: 5,
  minWidth: 170,
  paddingTop: 4,
  paddingBottom: 4,
  "& .MuiMenuItem-root": {
    color: "#fff",
    padding: "2px 8px",
    "&:hover": {
      backgroundColor: "rgba(143, 153, 168, 0.15)",
    },
    "& .MuiListItemIcon-root": {
      marginRight: "0px",
      minWidth: "24px",
    },
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .MuiSvgIcon-root": {
    fontSize: "16px",
    color: "#abb3bf",
  },
}));

const StyledContextMenu = styled(Popover)(() => ({
  ".MuiPaper-root": {
    background: "#343d45",
    borderRadius: 5,
    boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
    minWidth: 140,
    paddingTop: 4,
    paddingBottom: 4,
    outline: "rgba(45, 114, 210, 0.6) solid 2px",
    outlineOffset: 0,
    "& .MuiMenuItem-root": {
      color: "#fff",
      padding: "2px 8px",
      "&:hover": {
        backgroundColor: "rgba(143, 153, 168, 0.15)",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
      color: "#abb3bf",
    },
  },
}));

type ContextMenuItem = {
  label: string;
  icon?: JSX.Element;
  action: (id: string | null) => void;
};

type Ec1DataGridProps<T> = {
  id: string;
  columns: GridColDef[];
  contextMenuOptions: ContextMenuItem[];
  get: (params?: {
    params: URLSearchParams;
  }) => Promise<{ rows: T[]; count: number }>;
  getRowId: (row: T) => string | number;
  onRowClick?: (row: T, event: React.MouseEvent) => void;
  onCellClick?: (param: any, event: { stopPropagation: () => void }) => void;
};

function DataGridComponent<T extends object>({
  id,
  columns,
  contextMenuOptions,
  get,
  getRowId,
  onRowClick,
  onCellClick,
}: Ec1DataGridProps<T>) {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [rows, setRows] = useState<T[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const prevPageRef = useRef(-1);

  const handleContextMenu = (event: React.MouseEvent, id: string | null) => {
    event.preventDefault();
    if (id) {
      setSelectedId(id);
      setContextMenu({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const processedRows = rows.map((row, index) => ({
    ...row,
    _isEven: index % 2 === 0,
  }));

  // Add any custom filters here
  const isNotOperator: GridFilterOperator = {
    label: "is not",
    value: "isNot",
    getApplyFilterFn: () => null,
    InputComponent: GridFilterInputValue,
  };

  const addIsNotOperatorToStringColumns = (column: GridColDef): GridColDef => {
    if (column.type === undefined || column.type === "string") {
      const defaultStringOperators = getGridStringOperators();
      return {
        ...column,
        filterOperators: [...defaultStringOperators, isNotOperator],
      };
    }
    return column;
  };

  const processedColumns = columns.map(addIsNotOperatorToStringColumns);

  useEffect(() => {
    const fetchData = async () => {
      if (paginationModel.pageSize === 0) {
        setIsLoading(true);
        return;
      }

      if (paginationModel.page !== prevPageRef.current) {
        setIsLoading(true);
        prevPageRef.current = paginationModel.page;
      }

      try {
        const queryParams = new URLSearchParams();
        queryParams.append("limit", String(paginationModel.pageSize));
        queryParams.append(
          "offset",
          String(paginationModel.page * paginationModel.pageSize)
        );

        if (sortModel.length) {
          let sortField = sortModel[0].field;
          if (sortField === "battery_status") {
            sortField = "battery_power";
          }
          const sortDirection = sortModel[0].sort === "asc" ? "" : "-";
          queryParams.append("ordering", `${sortDirection}${sortField}`);
        }

        if (filterModel.items && filterModel.items.length > 0) {
          filterModel.items.forEach((item) => {
            const operator = decodeURIComponent(item.operator);
            const apiOperator = DATAGRID_OPERATOR_MAPPING[operator] || operator;

            if (item.field) {
              switch (operator) {
                case "is":
                case "equals":
                  if (item.value) {
                    queryParams.append(item.field, item.value);
                  }
                  break;
                case "isEmpty":
                  queryParams.append(`${item.field}__${apiOperator}`, "true");
                  break;
                case "isNotEmpty":
                  queryParams.append(`${item.field}__${apiOperator}`, "false");
                  break;
                case "isAnyOf":
                  if (Array.isArray(item.value)) {
                    const combinedValue = item.value.join(",");
                    queryParams.append(
                      `${item.field}__${apiOperator}`,
                      combinedValue
                    );
                  }
                  break;
                default:
                  if (item.value) {
                    queryParams.append(
                      `${item.field}__${apiOperator}`,
                      item.value
                    );
                  }
              }
            }
          });
        }

        const data = await get({ params: queryParams });
        setRows(data.rows);
        setRowCount(data.count);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [get, paginationModel, sortModel, filterModel]);

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleRowClick = (
    params: GridRowParams<T>,
    event: React.MouseEvent
  ) => {
    if (onRowClick) {
      onRowClick(params.row, event);
    }
  };

  const CustomGridRow = (props: GridRowProps) => (
    <GridRow {...props} data-testid={id + "_row_" + props.index} />
  );

  return (
    <>
      <DataGrid
        rows={processedRows}
        columns={processedColumns}
        rowCount={rowCount}
        pageSizeOptions={[]}
        pagination
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
        autoPageSize
        loading={isLoading}
        getRowClassName={(params: GridRowParams) =>
          params.row._isEven ? "even" : "odd"
        }
        getRowId={(row) => {
          const id = getRowId(row);
          return typeof id === "number" ? id : id.toString();
        }}
        rowHeight={40}
        columnHeaderHeight={40}
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
        onCellClick={onCellClick}
        slots={{
          columnMenu: StyledGridColumnMenu,
          row: CustomGridRow,
        }}
        slotProps={{
          row: {
            onContextMenu: (event) => {
              const id = event.currentTarget.getAttribute("data-id");
              handleContextMenu(event, id);
            },
            style: { cursor: "context-menu" },
          },
          filterPanel: {
            filterFormProps: {
              logicOperatorInputProps: {
                "data-testid": "logicOperator_" + id,
              },
              operatorInputProps: {
                "data-testid": "operator_" + id,
              },
              columnInputProps: {
                "data-testid": "columnOperator_" + id,
              },
              valueInputProps: {
                "data-testid": "valueOperator_" + id,
              },
            },
          },
        }}
        sx={{
          border: "none",
          "& .MuiDataGrid-withBorderColor": {
            borderColor: "#333",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiTablePagination-displayedRows": {
            marginBottom: 0,
          },
          "& .MuiDataGrid-filterIcon": {
            marginLeft: 0,
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "hidden",
          },
          "& .MuiDataGrid-overlay": {
            background:
              rows.length > 0 ? "rgba(18, 18, 18, 0.38)" : "transparent",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer !important",
          },
        }}
      />
      <StyledContextMenu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        {contextMenuOptions.map((option, index) => (
          <MenuItem key={index} onClick={() => option.action(selectedId)}>
            {option.icon && <>{option.icon}</>}
            <span
              data-testid={id + "_rightClick_" + option.label}
              style={{ marginLeft: 8, marginTop: -2, fontSize: 14 }}
            >
              {option.label}
            </span>
          </MenuItem>
        ))}
      </StyledContextMenu>
    </>
  );
}

const Ec1DataGrid = <T extends object>(props: Ec1DataGridProps<T>) => {
  return (
    <Box
      data-testid={props.id}
      display="flex"
      flex={1}
      minHeight={"114px"} // 1 row
      maxHeight={"3114px"} // 100 rows
      // Uncommented for a striped grid
      // sx={{
      //   [`& .${gridClasses.row}.even`]: {
      //     backgroundColor: "rgba(95,107,124,.15)",
      //     "&:hover, &.Mui-hovered": {
      //       backgroundColor: "rgba(95,107,124,.3)",
      //       "@media (hover: none)": {
      //         backgroundColor: "transparent",
      //       },
      //     },
      //   },
      // }}
    >
      <ThemeProvider theme={darkTheme}>
        <DataGridComponent<T> {...props} />
      </ThemeProvider>
    </Box>
  );
};

export default Ec1DataGrid;
