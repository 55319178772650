import { Card, Elevation } from "@blueprintjs/core";
import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SpinningLogo from "../../../ui/logo/SpinningLogo.react";
import { useEnterpriseLabels } from "../enterpriseLabels/useEnterpriseLabels";
import { Contract, useContracts } from "./contracts";

export default function PlatformSiteContractDetailViewPage() {
  const navigate = useNavigate();
  const { contractId } = useParams<{ contractId: string }>();
  const { fetchContracts } = useContracts();
  const { selectedLabelObj } = useEnterpriseLabels();
  const [contract, setContract] = useState<Contract | null>(null);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState("");

  function formatDate(date: Date | undefined): string {
    if (!date) {
      return "";
    }

    const d = new Date(date);
    let day = d.getDate().toString();
    let month = (d.getMonth() + 1).toString();
    const year = d.getFullYear();

    day = day.length < 2 ? "0" + day : day;
    month = month.length < 2 ? "0" + month : month;

    return `${day}/${month}/${year}`;
  }

  const handleViewCustomerClick = (customerId: string | undefined) => {
    navigate(`/customers/${customerId}`);
  };

  useEffect(() => {
    if (!contractId) {
      setError("Contract ID is not specified.");
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        const queryParams = new URLSearchParams();
        queryParams.append("id", contractId);
        const data = await fetchContracts({ params: queryParams });
        if (data.rows.length > 0) {
          setContract(data.rows[0]);
        } else {
          setError("No contract found with the given ID.");
        }
      } catch (err) {
        setError("Failed to fetch contract data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [contractId, fetchContracts]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        padding: "64px 24px 38px 24px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontFamily: "Barlow",
          letterSpacing: 1.5,
          fontWeight: 700,
          fontSize: 24,
          color: "#D3D8DE",
          marginBottom: 24,
        }}
      >
        <div>Contract Details</div>
      </div>
      {!loading ? (
        <Card
          interactive={false}
          elevation={Elevation.TWO}
          style={{ maxWidth: 600, paddingBottom: 10 }}
        >
          <div style={{ marginLeft: 5 }}>
            <div style={{ marginBottom: "1em" }}>
              <strong>
                {selectedLabelObj?.contract_uid_field_name ?? "Contract Number"}
                :
              </strong>{" "}
              {contract?.contract_number}
            </div>
            <div style={{ marginBottom: "1em" }}>
              <strong>CUPS:</strong> {contract?.cups}
            </div>
            <div style={{ marginBottom: "1em" }}>
              <strong>Start Date:</strong> {formatDate(contract?.start_date)}
            </div>
            <div style={{ marginBottom: "1em" }}>
              <strong>End Date:</strong> {formatDate(contract?.end_date)}
            </div>
            <div style={{ marginBottom: "1em" }}>
              <strong>Contracted Power (kW):</strong>{" "}
              {contract?.contracted_power_kw}
            </div>
            <div style={{ marginBottom: "1em" }}>
              <strong>Tariff:</strong> {contract?.tariff}
            </div>
            <div style={{ marginBottom: "1em" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 5 }}>
                  <strong>
                    {selectedLabelObj?.customer_uid_field_name ??
                      "Customer UID"}
                    :
                  </strong>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    {contract?.customer}
                  </div>
                  <div style={{ height: 14, marginTop: -4, marginLeft: -4 }}>
                    <IconButton
                      onClick={() =>
                        handleViewCustomerClick(contract?.customer)
                      }
                      aria-label="view customer"
                    >
                      <Search style={{ color: "#ccc", fontSize: 12 }} />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            margin: "auto 0",
          }}
        >
          <SpinningLogo isLoadingSpinner={true} height="50px" />
        </div>
      )}
    </div>
  );
}
