import ReactFlow, { Background, BackgroundVariant } from "reactflow";
import PlatformDatalayerBaseNode from "./PlatformDatalayerBaseNode.react";
import PlatformDatalayerDatasetNode from "./PlatformDatalayerDatasetNode.react";

const nodeTypes = {
  platformDatalayerBaseNode: PlatformDatalayerBaseNode,
  platformDatalayerDatasetNode: PlatformDatalayerDatasetNode,
};

const initialElements = [
  {
    id: "1",
    type: "platformDatalayerBaseNode",
    position: { x: 50, y: 240 },
    data: { label: "Syncronising Enode data" },
  },
  {
    id: "2",
    type: "platformDatalayerBaseNode",
    position: { x: 50, y: 440 },
    data: { label: "Syncronising Telematica data", state: "done" },
  },
  {
    id: "3",
    type: "platformDatalayerBaseNode",
    position: { x: 50, y: 640 },
    data: { label: "Syncronising Tesla data", state: "done" },
  },

  {
    id: "4",
    type: "platformDatalayerDatasetNode",
    position: { x: 380, y: 590 },
    data: { label: "TESLA_VEHICLES_DATASET" },
  },

  {
    id: "5",
    type: "platformDatalayerDatasetNode",
    position: { x: 380, y: 700 },
    data: { label: "TESLA_POWERWALLS_DATASET" },
  },
  {
    id: "6",
    type: "platformDatalayerDatasetNode",
    position: { x: 380, y: 390 },
    data: { label: "TELEMATICA_VEHICLES_DATASET" },
  },
  {
    id: "7",
    type: "platformDatalayerDatasetNode",
    position: { x: 380, y: 500 },
    data: { label: "TELEMATICA_HVAC_DATASET" },
  },
  {
    id: "8",
    type: "platformDatalayerDatasetNode",
    position: { x: 380, y: 190 },
    data: { label: "ENODE_VEHICLES_DATASET", state: "in_progress" },
  },
  {
    id: "9",
    type: "platformDatalayerDatasetNode",
    position: { x: 380, y: 300 },
    data: { label: "ENODE_HVAC_DATASET", state: "in_progress" },
  },
  {
    id: "10",
    type: "platformDatalayerDatasetNode",
    position: { x: 750, y: 390 },
    data: { label: "VEHICLE_STATE_DATASET", state: "stable" },
  },
  {
    id: "11",
    type: "platformDatalayerDatasetNode",
    position: { x: 750, y: 500 },
    data: { label: "ALL_HVAC_DATASET", state: "stable" },
  },
  {
    id: "12",
    type: "platformDatalayerDatasetNode",
    position: { x: 750, y: 700 },
    data: { label: "VEHICLE_METRICS_DATASET", state: "stable" },
  },
  {
    id: "13",
    type: "platformDatalayerBaseNode",
    position: { x: 50, y: 840 },
    data: { label: "Syncronising Huawei data" },
  },
  {
    id: "14",
    type: "platformDatalayerBaseNode",
    position: { x: 50, y: 840 },
    data: { label: "Syncronising Meter data" },
  },
  {
    id: "15",
    type: "platformDatalayerBaseNode",
    position: { x: 50, y: 1040 },
    data: { label: "Syncronising CRM data", state: "error" },
  },
  {
    id: "16",
    type: "platformDatalayerDatasetNode",
    position: { x: 380, y: 1045 },
    data: { label: "CUSTOMER_DATASET", state: "stable" },
  },

  {
    id: "17",
    type: "platformDatalayerDatasetNode",
    position: { x: 380, y: 940 },
    data: { label: "CONSUMPTION_DATASET", state: "stable" },
  },
];

const initialEdges = [
  { id: "e3-4", source: "3", target: "4", style: { stroke: "#404854" } },
  { id: "e3-5", source: "3", target: "5", style: { stroke: "#404854" } },
  { id: "e2-6", source: "2", target: "6", style: { stroke: "#404854" } },
  { id: "e2-7", source: "2", target: "7", style: { stroke: "#404854" } },
  { id: "e1-8", source: "1", target: "8", style: { stroke: "#F0B726" } },
  { id: "e1-9", source: "1", target: "9", style: { stroke: "#F0B726" } },
  { id: "e8-12", source: "8", target: "12", style: { stroke: "#404854" } },
  { id: "e6-12", source: "6", target: "12", style: { stroke: "#404854" } },
  { id: "e4-12", source: "4", target: "12", style: { stroke: "#404854" } },
  { id: "e8-10", source: "8", target: "10", style: { stroke: "#404854" } },
  { id: "e6-10", source: "6", target: "10", style: { stroke: "#404854" } },
  { id: "e4-11", source: "4", target: "10", style: { stroke: "#404854" } },
  { id: "e9-11", source: "9", target: "11", style: { stroke: "#404854" } },
  { id: "e7-11", source: "7", target: "11", style: { stroke: "#404854" } },
  { id: "e14-16", source: "14", target: "17", style: { stroke: "#F0B726" } },
  { id: "e15-16", source: "15", target: "16", style: { stroke: "#AC2F33" } },
  { id: "e15-17", source: "15", target: "17", style: { stroke: "#AC2F33" } },
];

export default function PlatformDatalayerGridPage() {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#111418",
      }}
    >
      <ReactFlow
        fitView
        nodes={initialElements}
        edges={initialEdges}
        proOptions={{ hideAttribution: true }}
        nodeTypes={nodeTypes}
      >
        <Background
          variant={BackgroundVariant.Dots}
          size={1}
          color={"#404854"}
        />
      </ReactFlow>
    </div>
  );
}
