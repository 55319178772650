import { Button, HTMLSelect } from "@blueprintjs/core";
import React from "react";
import { createUseStyles } from "react-jss";
import "react-loading-skeleton/dist/skeleton.css";

const useStyles = createUseStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pagination: {
    fontFamily: "Barlow",
    display: "flex",
    alignItems: "center",
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
  },
  pageInfo: {
    margin: "0 1rem",
  },
  pageSizeSelect: {
    width: "140px",
    marginRight: "1rem",
  },
});

interface PlatformTablePaginationProps {
  pageSize: number;
  currentPage: number;
  totalItems: number;
  handlePageChange: (newPage: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

export default function PlatformTablePagination({
  pageSize,
  currentPage = 1,
  totalItems,
  handlePageChange,
  onPageSizeChange,
}: PlatformTablePaginationProps): React.ReactElement {
  const classes = useStyles();

  const numberOfPages = Math.ceil((totalItems ?? pageSize) / pageSize);

  const renderPageButtons = () => {
    const buttons = [];
    const maxVisiblePages = 5;
    let start = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let end = Math.min(numberOfPages, start + maxVisiblePages - 1);

    if (end - start + 1 < maxVisiblePages) {
      start = Math.max(1, end - maxVisiblePages + 1);
    }

    for (let i = start; i <= end; i++) {
      buttons.push(
        <Button
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
          minimal
        >
          {i}
        </Button>
      );
    }

    return buttons;
  };

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    onPageSizeChange(newPageSize);
  };

  const pageSizeOptions = [10, 15, 25, 50];

  return (
    <div className={classes.container}>
      <div className={classes.pagination}>
        <HTMLSelect
          className={classes.pageSizeSelect}
          value={pageSize}
          onChange={handlePageSizeChange}
        >
          {pageSizeOptions.map((size) => (
            <option key={size} value={size}>
              {size} per page
            </option>
          ))}
        </HTMLSelect>
        <span>
          Showing {(currentPage - 1) * pageSize + 1} -{" "}
          {Math.min(currentPage * pageSize, totalItems)} of {totalItems}
        </span>
      </div>
      <div className={classes.pagination}>
        <div className={classes.buttonGroup}>
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(1)}
            icon="double-chevron-left"
            minimal
          />
          <Button
            icon="chevron-left"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            minimal
          />
          {renderPageButtons()}
          <Button
            icon="chevron-right"
            disabled={currentPage === numberOfPages}
            onClick={() => handlePageChange(currentPage + 1)}
            minimal
          />
          <Button
            icon="double-chevron-right"
            disabled={currentPage === numberOfPages}
            onClick={() => handlePageChange(numberOfPages)}
            minimal
          />
        </div>
      </div>
    </div>
  );
}
