import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useAuthentication } from "./common/authentication/authentication";

const useStyles = createUseStyles({
  form: {
    width: "200px",
    height: "auto",
  },
  formGroup: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "16px",
  },
  inputField: {
    width: "100%",
    padding: "7px 12px",
    backgroundColor: "#00001e",
    color: "white",
    borderRadius: "4px",
    border: "none",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Space Grotesk",
    transition: "background-color 5000s ease-in-out 0s",
    boxShadow: "inset 0 0 0 1000px #00001e",
    "&:focus": {
      borderColor: "#4a90e2",
      boxShadow: "0 0 0 2px rgba(74, 144, 226, 0.5)",
      outline: "none",
      backgroundColor: "#00001e",
    },
    "&:disabled": {
      backgroundColor: "#333",
      cursor: "not-allowed",
    },
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 30px #00001e inset",
      WebkitTextFillColor: "white",
      WebkitFontFamily: "'Space Grotesk'",
    },
    "&:-webkit-autofill:focus": {
      WebkitBoxShadow: "0 0 0 30px #00001e inset",
      WebkitTextFillColor: "white",
      WebkitFontFamily: "'Space Grotesk'",
    },
  },
  resetButton: {
    width: "100%",
    padding: "6px",
    fontSize: "14px",
    fontFamily: "Space Grotesk",
    fontWeight: "900",
    color: "#000019",
    backgroundColor: "#4a90e2",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#3a78c3",
    },
    "&:disabled": {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
    },
  },
});

interface PasswordResetFormProps {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  resetToken: string | null;
  onSuccess: () => void;
}

export default function PasswordResetForm({
  isLoading,
  setIsLoading,
  resetToken,
  onSuccess,
}: PasswordResetFormProps) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { resetPassword, setAuthError } = useAuthentication();
  const classes = useStyles();

  const handleResetSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAuthError(null);

    if (!newPassword.trim() || !confirmPassword.trim()) {
      let errorMessage = "Please enter your ";

      if (!newPassword.trim() && !confirmPassword.trim()) {
        errorMessage += "new password and confirmation password.";
      } else if (!newPassword.trim()) {
        errorMessage += "new password.";
      } else {
        errorMessage += "confirmation password.";
      }

      setAuthError(errorMessage);
      return;
    }

    if (newPassword !== confirmPassword) {
      setAuthError("Passwords do not match.");
      return;
    }

    setIsLoading(true);

    try {
      const success = await resetPassword(resetToken!, newPassword);
      if (success) {
        onSuccess();
      }
    } catch (error) {
      setAuthError(error as string);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleResetSubmit} className={classes.form}>
      <div className={classes.formGroup}>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          className={classes.inputField}
          disabled={isLoading}
        />
      </div>
      <div className={classes.formGroup}>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          className={classes.inputField}
          disabled={isLoading}
        />
      </div>
      <div className={classes.formGroup}>
        <button
          type="submit"
          className={classes.resetButton}
          disabled={isLoading}
        >
          Change Password
        </button>
      </div>
    </form>
  );
}
