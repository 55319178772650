import { createUseStyles } from "react-jss";

export const BACKGROUND_COLOR = "#1C2127";

const useStyles = createUseStyles({
  background: {
    position: "fixed",
    top: 0,
    bottom: 0,
    backgroundColor: BACKGROUND_COLOR,
    width: "100%",
    height: "100vh",
    zIndex: -1,
  },
});

export default function PlatformSiteBackground() {
  const classes = useStyles();
  return <div className={classes.background} />;
}
