import { Button } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PlatformSiteDeviceGridView from "./grid/PlatformSiteDeviceGridView.react";
import PlatformDeviceListView from "./PlatformDeviceListView.react";
import PlatformSiteDeviceMapView from "./PlatformSiteDeviceMapView.react";

type DeviceListViewType = "List" | "Grid" | "Map";

interface PlatformSiteDeviceListViewPageProps {
  pageType?: DeviceListViewType;
}

export default function PlatformDeviceListViewPage({
  pageType = "List",
}: PlatformSiteDeviceListViewPageProps) {
  const navigate = useNavigate();

  const [deviceListViewType, setDeviceListViewType] = useState(pageType);

  useEffect(() => {
    setDeviceListViewType(pageType);
  }, [pageType]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {deviceListViewType === "List" ? (
        <div style={{ width: "100%", flex: 1 }}>
          <PlatformDeviceListView />
        </div>
      ) : deviceListViewType === "Grid" ? (
        <div style={{ flex: 1 }}>
          <PlatformSiteDeviceGridView />
        </div>
      ) : (
        <div style={{ flex: 1, height: "100vh" }}>
          <PlatformSiteDeviceMapView />
        </div>
      )}
      <div
        style={{
          width: "50px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "4px",
          borderLeftColor: "#111418",
          borderLeftStyle: "solid",
          borderLeftWidth: "1px",
        }}
      >
        <Button
          large
          minimal
          icon="th"
          active={deviceListViewType === "List"}
          onClick={() => {
            navigate(`/devices/`);
          }}
        />
        <Button
          large
          minimal
          icon="globe"
          style={{ marginTop: "4px" }}
          active={deviceListViewType === "Map"}
          onClick={() => {
            navigate(`/devices/map`);
          }}
        />
        <Button
          large
          minimal
          icon="graph"
          style={{ marginTop: "4px" }}
          active={deviceListViewType === "Grid"}
          onClick={() => {
            navigate(`/devices/grid`);
          }}
        />
      </div>
    </div>
  );
}
