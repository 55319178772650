import { Icon, Popover } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import {
  BatteryStd,
  ElectricBike,
  ElectricCar,
  Forest,
  Home,
  PowerOff,
  WbSunny,
} from "@mui/icons-material";
import React, { useMemo } from "react";
import {
  PiBatteryChargingVerticalDuotone,
  PiBatteryVerticalEmptyDuotone,
  PiBatteryVerticalFullDuotone,
  PiBatteryVerticalHighDuotone,
  PiBatteryVerticalLowDuotone,
  PiBatteryVerticalMediumDuotone,
  PiBatteryWarningVerticalDuotone,
} from "react-icons/pi";
import { createUseStyles } from "react-jss";
import { Handle, Position, useStore } from "reactflow";
import "reactflow/dist/style.css";
import { EnergyUtils } from "src/ui/utils/chartUtils";
import { getBatteryIconColor } from ".././PlatformSiteDeviceMapView.react";

export interface GridNodeData {
  label: string;
  icon: string;
  color: string;
  isGroup: boolean;
  isChild: boolean;
  isRoot: boolean;
  isOffline: boolean;
  battery?: Battery;
  tooltipContent?: JSX.Element;
}

const useStyles = createUseStyles({
  nodeBase: {
    border: "2px solid #222",
    padding: "10px",
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    outline: "none",
  },
  handle: {
    visibility: "hidden",
  },
  handleTop: {
    top: 0,
  },
  handleBottom: {
    bottom: 0,
  },
  handleLeft: {
    left: 0,
  },
  handleRight: {
    right: 0,
  },
  innerContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  batteryContainer: {
    display: "flex",
    flexDirection: "row",
    height: 10,
  },
  batteryIcon: {
    height: 10,
    marginTop: -4,
    marginRight: 1,
    marginLeft: -1,
  },
  batteryLevel: {
    height: 10,
    marginTop: 0,
    fontSize: 8,
    fontWeight: 500,
    fontFamily: "Barlow",
  },
  label: {
    fontFamily: "Barlow",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export function getBatteryIcon(
  batteryLevel: number,
  status: string | undefined,
  stationState: string | undefined
) {
  const color = getBatteryIconColor(batteryLevel, status, stationState);

  if (stationState === "FAULT") {
    return <PiBatteryWarningVerticalDuotone size={11} color={color} />;
  }

  if (status === "offline") {
    return <PiBatteryWarningVerticalDuotone size={11} color={color} />;
  }

  if (status && status.toLowerCase() === "charging") {
    return <PiBatteryChargingVerticalDuotone size={11} color={color} />;
  }

  if (batteryLevel === 100) {
    return <PiBatteryVerticalFullDuotone size={11} color={color} />;
  }

  if (batteryLevel >= 80) {
    return <PiBatteryVerticalHighDuotone size={11} color={color} />;
  }

  if (batteryLevel >= 30) {
    return <PiBatteryVerticalMediumDuotone size={11} color={color} />;
  }

  if (batteryLevel > 0) {
    return <PiBatteryVerticalLowDuotone size={11} color={color} />;
  }

  return <PiBatteryVerticalEmptyDuotone size={11} color={color} />;
}

// Change the value to toggle the sub-graphs at another zoom level
export const ZOOM_THRESHOLD = 0.65;

export const NODE_SIZE = 180;
export const CHILD_SIZE = 48;
export const MIN_ROOT_SIZE = 100;
export const MAX_ROOT_SIZE = 200;

export const SOURCE_TOP_HANDLE_ID = "1";
export const TARGET_TOP_HANDLE_ID = "11";

export const SOURCE_BOTTOM_HANDLE_ID = "2";
export const TARGET_BOTTOM_HANDLE_ID = "12";

export const SOURCE_LEFT_HANDLE_ID = "3";
export const TARGET_LEFT_HANDLE_ID = "13";

export const SOURCE_RIGHT_HANDLE_ID = "4";
export const TARGET_RIGHT_HANDLE_ID = "14";

export function calculateRootSize(zoomLevel: number): number {
  return Math.min(MIN_ROOT_SIZE / zoomLevel, MAX_ROOT_SIZE);
}

const GridNode: React.FC<{ data: GridNodeData }> = ({ data }) => {
  const {
    label,
    icon,
    color,
    isGroup,
    isChild,
    isRoot,
    isOffline,
    battery,
    tooltipContent,
  } = data;

  const classes = useStyles();

  // Calculate the root size according to the zoom level
  const zoomLevel = useStore((store) => store.transform[2]);
  const rootSize = useMemo(() => calculateRootSize(zoomLevel), [zoomLevel]);

  const isZoomed = useMemo(() => zoomLevel >= ZOOM_THRESHOLD, [zoomLevel]);

  // Calculate the scale to be used for root icon/font sizes
  const scale = useMemo(
    () => rootSize / (MAX_ROOT_SIZE + (MAX_ROOT_SIZE - NODE_SIZE)),
    [rootSize]
  );

  const IconComponent = useMemo(() => {
    const components = {
      solar: WbSunny,
      home: Home,
      grid: () => (
        <Icon icon="high-voltage-pole" size={isRoot ? 60 * scale : 12} />
      ),
      forest: Forest,
      bike: ElectricBike,
      car: ElectricCar,
      battery: BatteryStd,
    };
    return components[icon as keyof typeof components];
  }, [icon, isRoot, scale]);

  const displayValue = useMemo(() => {
    if (isOffline && isGroup) {
      return (
        <>
          <PowerOff
            style={{
              fontSize: isChild ? 9 : 30,
              marginRight: isChild ? 0 : 2,
            }}
          />
          Offline
        </>
      );
    }

    if (isOffline || label === null || label === undefined) {
      return "-";
    }

    const parsedValue = parseInt(label);
    if (!isNaN(parsedValue)) {
      return String(EnergyUtils.humanize(parsedValue, "w"));
    }

    return label;
  }, [isOffline, isGroup, isChild, label]);

  const nodeStyle = useMemo(
    () => ({
      width: isChild ? CHILD_SIZE : isRoot ? rootSize : NODE_SIZE,
      height: isChild ? CHILD_SIZE : isRoot ? rootSize : NODE_SIZE,
      border: `2px solid ${isOffline ? "#404854" : color}`,
    }),
    [isChild, isRoot, rootSize, isOffline, color]
  );

  const iconContainerStyle = useMemo(
    () => ({
      opacity: isOffline ? 0.5 : 1,
    }),
    [isOffline]
  );

  const labelStyle = useMemo(
    () => ({
      marginTop: isChild ? 4 : isRoot ? 16 * scale : 10,
      fontSize: isChild ? 9 : isRoot ? 40 * scale : 32,
      color: isOffline && isGroup ? "rgba(205, 66, 70, 0.8)" : "#f3f4f6",
      opacity: isOffline && !isGroup ? 0.5 : 1,
    }),
    [isChild, isRoot, scale, isOffline, isGroup]
  );

  if (isChild && !isZoomed) {
    return null;
  }

  if (isGroup && isZoomed) {
    return (
      <Popover
        interactionKind="hover"
        content={tooltipContent}
        disabled={tooltipContent === undefined}
      >
        <div
          className={classes.nodeBase}
          style={{
            width: NODE_SIZE,
            height: NODE_SIZE,
            border: "none",
          }}
        />
      </Popover>
    );
  }

  return (
    <Popover
      interactionKind="hover"
      content={tooltipContent}
      disabled={tooltipContent === undefined}
    >
      <div className={classes.nodeBase} style={nodeStyle}>
        <Handle
          type="source"
          id={SOURCE_TOP_HANDLE_ID}
          position={Position.Top}
          className={`${classes.handle} ${classes.handleTop}`}
        />
        <Handle
          type="source"
          id={SOURCE_BOTTOM_HANDLE_ID}
          position={Position.Bottom}
          className={`${classes.handle} ${classes.handleBottom}`}
        />
        <Handle
          type="source"
          id={SOURCE_LEFT_HANDLE_ID}
          position={Position.Left}
          className={`${classes.handle} ${classes.handleLeft}`}
        />
        <Handle
          type="source"
          id={SOURCE_RIGHT_HANDLE_ID}
          position={Position.Right}
          className={`${classes.handle} ${classes.handleRight}`}
        />
        <Handle
          type="target"
          id={TARGET_TOP_HANDLE_ID}
          position={Position.Top}
          className={`${classes.handle} ${classes.handleTop}`}
        />
        <Handle
          type="target"
          id={TARGET_BOTTOM_HANDLE_ID}
          position={Position.Bottom}
          className={`${classes.handle} ${classes.handleBottom}`}
        />
        <Handle
          type="target"
          id={TARGET_LEFT_HANDLE_ID}
          position={Position.Left}
          className={`${classes.handle} ${classes.handleLeft}`}
        />
        <Handle
          type="target"
          id={TARGET_RIGHT_HANDLE_ID}
          position={Position.Right}
          className={`${classes.handle} ${classes.handleRight}`}
        />
        <div className={classes.innerContent}>
          <div className={classes.iconContainer} style={iconContainerStyle}>
            {battery ? (
              <div className={classes.batteryContainer}>
                <div className={classes.batteryIcon}>
                  {getBatteryIcon(
                    battery?.battery_level ?? 0,
                    battery?.battery_status,
                    battery?.station_state
                  )}
                </div>
                <div className={classes.batteryLevel}>
                  {battery.battery_level}%
                </div>
              </div>
            ) : (
              IconComponent && (
                <IconComponent style={{ fontSize: isChild ? 12 : 48 }} />
              )
            )}
          </div>
          <div className={classes.label} style={labelStyle}>
            {displayValue}
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default GridNode;
