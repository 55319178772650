import { Button, H4, HTMLTable, Spinner, Tooltip } from "@blueprintjs/core";
import { Trash } from "@blueprintjs/icons";
import { PropertyTariffMapping } from "@ec1/types/PropertyTariffMapping";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import PlatformAssignTariffDialog from "./PlatformAssignTariffDialog.react";
import { useTariff } from "./tariffs";

interface PlatformPropertyTariffWidgetProps {
  height: number;
  propertyId: number;
  isLoading: boolean;
  selectedTariffMapping: PropertyTariffMapping | null;
  propertyTariffMappings: PropertyTariffMapping[] | null;
  setShowDeleteTariffMappingConfirm: (
    showDeleteTariffMappingConfirm: boolean
  ) => void;
  setSelectedTariffMapping: (
    selectedTariffMapping: PropertyTariffMapping
  ) => void;
  onEnrollmentSuccess: () => Promise<void>;
}

export default function PlatformPropertyTariffWidget({
  height,
  propertyId,
  isLoading,
  selectedTariffMapping,
  propertyTariffMappings,
  setShowDeleteTariffMappingConfirm,
  setSelectedTariffMapping,
  onEnrollmentSuccess,
}: PlatformPropertyTariffWidgetProps) {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState(false);

  const onSuccess = useCallback(async () => {
    setShowDialog(false);
    await onEnrollmentSuccess();
  }, [onEnrollmentSuccess]);

  const handleDeleteTariffMapping = useCallback(
    (tariffMapping: PropertyTariffMapping) => {
      setShowDeleteTariffMappingConfirm(true);
      setSelectedTariffMapping(tariffMapping);
    },
    [setShowDeleteTariffMappingConfirm, setSelectedTariffMapping]
  );

  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "#252A31",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: height,
        borderRadius: 6,
        padding: "24px 24px 8px 24px",
        minWidth: 500,
      }}
    >
      <PlatformAssignTariffDialog
        isOpen={showDialog}
        propertyId={propertyId}
        onClose={() => setShowDialog(false)}
        onEnrollmentSuccess={onSuccess}
        setIsLoadingParent={setDialogLoading}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <H4>Assigned Tariffs</H4>
        <div style={{ marginBottom: 10 }}>
          <Button
            minimal
            outlined
            rightIcon={dialogLoading ? <Spinner size={16} /> : "add"}
            onClick={() => {
              setShowDialog(true);
            }}
          >
            Add tariff
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          marginTop: 5,
        }}
      >
        <HTMLTable style={{ width: "100%" }} compact interactive>
          <thead>
            <tr>
              <th style={{ width: "50%", minWidth: 220, maxWidth: 240 }}>
                Tariff
              </th>
              <th style={{ width: "20%", minWidth: 100 }}>Start date</th>
              <th style={{ width: "20%", minWidth: 100 }}>End date</th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {propertyTariffMappings &&
              propertyTariffMappings.map((t: PropertyTariffMapping) => (
                <tr key={t.id}>
                  <td
                    style={{
                      verticalAlign: "middle",
                      width: "50%",
                      outline: "none",
                      minWidth: 220,
                      maxWidth: 240,
                    }}
                    onClick={() => {
                      navigate(`/tariffs/${t.tariff}`);
                    }}
                  >
                    <TariffName tariffId={t.tariff} />
                  </td>
                  <td
                    onClick={() => {
                      navigate(`/tariffs/${t.tariff}`);
                    }}
                    style={{
                      verticalAlign: "middle",
                      outline: "none",
                      width: "20%",
                      minWidth: 100,
                    }}
                  >
                    {t.valid_from}
                  </td>
                  <td
                    onClick={() => {
                      navigate(`/tariffs/${t.tariff}`);
                    }}
                    style={{
                      verticalAlign: "middle",
                      outline: "none",
                      width: "20%",
                      minWidth: 100,
                    }}
                  >
                    {t.valid_to}
                  </td>
                  <td style={{ verticalAlign: "middle", width: "10%" }}>
                    {selectedTariffMapping?.id === t.id && isLoading ? (
                      <Spinner style={{ width: 20, height: 20 }} size={16} />
                    ) : (
                      <Button
                        small
                        minimal
                        icon={<Trash size={14} style={{ color: "white" }} />}
                        onClick={() => handleDeleteTariffMapping(t)}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </HTMLTable>
      </div>
    </div>
  );
}

interface TariffNameProps {
  tariffId: number;
}
function TariffName({ tariffId }: TariffNameProps) {
  const tariff = useTariff(tariffId);

  if (tariff) {
    return (
      <Tooltip
        content={
          <span style={{ fontFamily: "Barlow" }}> {tariff.display_name}</span>
        }
        placement="top"
      >
        <div
          style={{
            display: "block",
            maxWidth: 195,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {tariff.display_name}
        </div>
      </Tooltip>
    );
  }

  return null;
}
