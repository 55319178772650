import { OverlayToaster, Position } from "@blueprintjs/core";
import { createRoot } from "react-dom/client";

export let Ec1Toaster: OverlayToaster;

createRoot(document.getElementById("toaster")!).render(
  <OverlayToaster
    ref={(instance) => {
      Ec1Toaster = instance!;
    }}
    position={Position.BOTTOM}
  />
);
