import {
  Button,
  ButtonGroup,
  EditableText,
  H3,
  IconSize,
  Spinner,
} from "@blueprintjs/core";
import type { Modifier } from "@dnd-kit/core";
import { DndContext, useDraggable } from "@dnd-kit/core";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PlatformAssistantDataset from "../assistant/PlatformAssistantDataset.react";
import {
  CanvasComponentDimensions,
  CanvasCoordinates,
  useCanvas,
} from "./canvas";
type DraggableProps = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  maxWidth: number;
  maxHeight: number;

  children: ReactNode;
  setDims: React.Dispatch<
    React.SetStateAction<{
      [key: number]: CanvasComponentDimensions;
    } | null>
  >;
};

function Draggable(props: DraggableProps) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });

  const [size, setSize] = useState({
    width: props.width,
    height: props.height,
  });
  const resizeDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      }
    });

    if (resizeDivRef.current) {
      resizeObserver.observe(resizeDivRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  const { id, setDims } = props;

  useEffect(() => {
    setDims((prevDims) => {
      if (prevDims === null) {
        throw Error("Unitialized POS");
      }

      const itemID = id;

      return {
        ...prevDims,
        [itemID]: {
          ...size,
        },
      };
    });
  }, [size, id, setDims]);

  const style = transform
    ? {
        transform: `translate3d(${props.x + transform.x}px, ${
          props.y + transform.y
        }px, 0)`,
      }
    : { undefined };

  return (
    <div
      ref={setNodeRef}
      style={{
        position: "absolute",

        top: 0,
        left: 0,
        transform: `translate3d(${props.x}px, ${props.y}px, 0)`,
        fontFamily: "Barlow",

        border: "#1C2127 solid 1px",
        color: "white",
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: "auto",
        zIndex: 10 ** parseInt(props.id),
        overflow: "hidden",

        ...style,
      }}
    >
      <div
        ref={resizeDivRef}
        style={{
          display: "flex",

          width: `${size.width}px`,
          height: `${size.height}px`,

          maxWidth: `${props.maxWidth - 2}px`,
          maxHeight: `${props.maxHeight - 2}px`,

          resize: "both",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            color: "white",
            margin: "4px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "24px" }}>
            <Button
              icon="drag-handle-vertical"
              {...listeners}
              {...attributes}
              minimal
            />
          </div>
        </div>
        <div style={{ flex: 1 }}>{props.children}</div>
      </div>
    </div>
  );
}

export default function PlatformCanvas() {
  const { canvasID } = useParams();

  const { isFetchingCanvas, isSavingCanvas, saveCanvas, canvas, components } =
    useCanvas(canvasID ?? null);

  const [title, setTitle] = useState<string>(canvas?.title ?? "Untitled");

  useEffect(() => {
    if (canvas?.title) {
      setTitle(canvas.title);
    }
  }, [canvas?.title]);

  const [pos, setPos] = useState<{ [key: number]: CanvasCoordinates } | null>(
    null
  );
  const [dims, setDims] = useState<{
    [key: number]: CanvasComponentDimensions;
  } | null>(null);

  const divRef = useRef<HTMLDivElement>(null);
  const [canvasWidth, setCanvasWidth] = useState<number>(0);
  const [canvasHeight, setCanvasHeight] = useState<number>(0);

  useEffect(() => {
    // Measure the width and update state
    setCanvasWidth(divRef.current?.offsetWidth || 0);
    setCanvasHeight(divRef.current?.offsetHeight || 0);
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    console.log(`canvadWidth : ${canvasWidth}`);
  }, [canvasWidth]);

  useEffect(() => {
    if (components !== null) {
      const allPos: { [key: string]: CanvasCoordinates } = {};
      const allDims: { [key: string]: CanvasComponentDimensions } = {};
      components.forEach((component) => {
        allPos[component.id] = { x: component.pos_x, y: component.pos_y };
        allDims[component.id] = {
          width: component.width,
          height: component.height,
        };
      });
      setPos(allPos);
      setDims(allDims);
    }
  }, [components]);

  const restrictToParentElement: Modifier = useCallback(
    ({ active, containerNodeRect, draggingNodeRect, transform, over }) => {
      let newX = transform.x;
      let newY = transform.y;
      if (active?.id === undefined) {
        return transform;
      }

      if (pos === null) {
        throw Error("No positions registered");
      }

      const itemID = parseInt(active.id.toString());
      if (
        pos[itemID].x +
          transform.x +
          (draggingNodeRect?.width ?? 0) +
          (draggingNodeRect?.left ?? 0) >
        (containerNodeRect?.left ?? 0) + (containerNodeRect?.width ?? 0)
      ) {
        newX =
          (containerNodeRect?.left ?? 0) +
          (containerNodeRect?.width ?? 0) -
          (draggingNodeRect?.left ?? 0) -
          (draggingNodeRect?.width ?? 0) -
          pos[itemID].x;
      }

      if (
        pos[itemID].x + transform.x + (draggingNodeRect?.left ?? 0) <
        (containerNodeRect?.left ?? 0)
      ) {
        newX =
          (containerNodeRect?.left ?? 0) -
          pos[itemID].x -
          (draggingNodeRect?.left ?? 0);
      }

      if (
        pos[itemID].y +
          transform.y +
          (draggingNodeRect?.height ?? 0) +
          (draggingNodeRect?.top ?? 0) >
        (containerNodeRect?.top ?? 0) + (containerNodeRect?.height ?? 0)
      ) {
        newY =
          (containerNodeRect?.top ?? 0) +
          (containerNodeRect?.height ?? 0) -
          (draggingNodeRect?.top ?? 0) -
          (draggingNodeRect?.height ?? 0) -
          pos[itemID].y;
      }

      if (
        pos[itemID].y + transform.y + (draggingNodeRect?.top ?? 0) <
        (containerNodeRect?.top ?? 0)
      ) {
        newY =
          (containerNodeRect?.top ?? 0) -
          pos[itemID].y -
          (draggingNodeRect?.top ?? 0);
      }
      return { ...transform, x: newX, y: newY };
    },

    [pos]
  );
  return (
    <DndContext
      modifiers={[restrictToParentElement]}
      onDragEnd={({ active, delta }) => {
        setPos((prevPos) => {
          if (prevPos === null) {
            throw Error("Unitialized POS");
          }
          const itemID = active.id.toString();

          return {
            ...prevPos,
            [itemID]: {
              x: prevPos[parseInt(itemID)].x + delta.x,
              y: prevPos[parseInt(itemID)].y + delta.y,
            },
          };
        });
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#111418",
          paddingLeft: "32px",
          paddingRight: "32px",
          paddingTop: "12px",
          paddingBottom: "32px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            //backgroundColor: "red",
            display: "flex",

            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ButtonGroup minimal>
              <Button icon="folder-close" />
              {isSavingCanvas === true || isFetchingCanvas === true ? (
                <Button
                  minimal
                  icon={
                    <Spinner
                      size={IconSize.STANDARD}
                      style={{ outline: "none" }}
                    />
                  }
                />
              ) : (
                <Button
                  icon="floppy-disk"
                  onClick={() => {
                    if (pos !== null && dims !== null) {
                      saveCanvas(title, pos, dims);
                    }
                  }}
                />
              )}
            </ButtonGroup>

            <H3
              style={{
                padding: 0,

                margin: 0,
                marginLeft: "4px",
                width: "600px",
              }}
            >
              <EditableText
                className={isFetchingCanvas ? "bp5-skeleton" : undefined}
                value={title}
                onChange={setTitle}
              />
            </H3>
          </div>
          <ButtonGroup minimal>
            <Button icon="cog" />
          </ButtonGroup>
        </div>

        <div
          ref={divRef}
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
            border: "1px dashed #2F343C",
          }}
        >
          {Array.isArray(components) &&
            pos &&
            dims &&
            components.map((component) => (
              <Draggable
                setDims={setDims}
                key={component.id}
                x={pos[component.id].x}
                y={pos[component.id].y}
                id={String(component.id)}
                width={dims[component.id].width}
                height={dims[component.id].height}
                maxWidth={canvasWidth - pos[component.id].x}
                maxHeight={canvasHeight - pos[component.id].y}
              >
                <PlatformAssistantDataset
                  uuid={component.dataset}
                  showFitButton
                />
              </Draggable>
            ))}
        </div>
      </div>
    </DndContext>
  );
}
