import { PlatformMonthlyConsumption } from "@ec1/types/PlatformMonthlyConsumption";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthHeaders } from "../common/authentication/authentication";
import capitalizeName from "./capitalizeName";

const URL = "/api/customers/";

export type Customer = {
  id: number;
  first_name: string;
  last_name: string;
  customer_uid: string;
  email: string;
  landline: string;
  mobile: string;
};

type ConsumptionData = Map<number, [number, number | null][]>;

type CustomerEnrollmentData = {
  nif: string;
};

interface ICustomerContext {
  customers: Customer[];
  totalCustomers: number;
  fetchCustomer: (customerId: number) => void;
  fetchCustomers: (params?: {
    params: URLSearchParams;
  }) => Promise<{ rows: Customer[]; count: number }>;
  fetchAllCustomers: () => void;
  enrollCustomer: (
    customerEnrollmentData: CustomerEnrollmentData
  ) => Promise<number | null>;
  enrollCustomerManual: (
    customerData: Customer
  ) => Promise<{ success: boolean; result: string }>;
  checkEnrollmentStatus: (
    customerEnrollmentId: number
  ) => Promise<{ status: string | null; customerIds?: Array<number> }>;
  deleteCustomer: (
    customerId: number
  ) => Promise<{ success: boolean; error: string }>;
}

const CustomerContext = createContext<ICustomerContext | undefined>(undefined);

interface ICustomerProviderProps {
  children: ReactNode;
}

function processData(data: PlatformMonthlyConsumption[]): ConsumptionData {
  // Group by property
  const processedData = data.reduce((accumulator, current) => {
    const key = current.related_property;
    if (!accumulator.has(key)) {
      accumulator.set(key, []);
    }
    accumulator.get(key)!.push(current);
    return accumulator;
  }, new Map<number, PlatformMonthlyConsumption[]>());

  // Sort in ascending order chronologically
  processedData.forEach((value) => {
    value.sort((a, b) => {
      if (a.year === b.year) {
        return a.month - b.month;
      }
      return a.year - b.year;
    });
  });

  // Flatten the data into [x, y] pairs, adding padding where necessary
  const flattenedData = new Map<number, Array<[number, number | null]>>();
  processedData.forEach((consumptions, propertyId) => {
    const propertyData: Array<[number, number | null]> = [];
    const currentDate = new Date();

    consumptions.forEach((consumption) => {
      const { year, month } = consumption;
      const daysInMonth = new Date(year, month, 0).getDate();

      if (consumption.datapoints.length === 0) {
        // Create padded values for all days and hours of the month
        for (let day = 1; day <= daysInMonth; day++) {
          for (let hour = 0; hour < 24; hour++) {
            const date = new Date(Date.UTC(year, month - 1, day, hour));
            const timestamp = date.getTime();
            if (new Date(timestamp) <= currentDate) {
              propertyData.push([timestamp, null]);
            }
          }
        }
      } else {
        // Process and flatten existing datapoints
        consumption.datapoints.forEach((dayData, dayIndex) => {
          dayData.forEach((hourValue, hourIndex) => {
            const date = new Date(
              Date.UTC(year, month - 1, dayIndex + 1, hourIndex)
            );
            const timestamp = date.getTime();
            if (new Date(timestamp) <= currentDate) {
              propertyData.push([
                timestamp,
                hourValue !== undefined ? hourValue : null,
              ]);
            }
          });
        });
      }
    });

    flattenedData.set(propertyId, propertyData);
  });

  return flattenedData;
}

export const CustomerProvider: React.FC<ICustomerProviderProps> = ({
  children,
}) => {
  const [totalCustomers, setTotalCustomers] = useState(
    parseInt(localStorage.getItem("@CACHED_NUMBER_OF_CUSTOMERS") ?? "0")
  );
  const headers = useAuthHeaders();

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isFetchingAllCustomers, setIsFetchingAllCustomers] =
    useState<boolean>(false);

  const [isFetchingCustomer, setIsFetchingCustomer] = useState<{
    [key: number]: boolean;
  } | null>(null);

  const fetchCustomer = useCallback(
    (customerId: number) => {
      async function fetchCustomerAsync() {
        const filteredCustomers = customers.filter(
          (customer) => customer.id === customerId
        );

        if (filteredCustomers.length === 1) {
          return filteredCustomers[0];
        } else {
          if (
            isFetchingCustomer === null ||
            isFetchingCustomer[customerId] !== true
          ) {
            setIsFetchingCustomer((prev) => {
              return { ...(prev ?? undefined), [customerId]: true };
            });
            if (customerId) {
              const response = await fetch(`${URL}${customerId}/`, {
                headers,
              });

              const customer = (await response.json()) as Customer;

              setCustomers((prevCustomers) => {
                return [
                  ...prevCustomers.filter((c) => c.id !== customerId),
                  customer,
                ];
              });
            }

            setIsFetchingCustomer((prev) => {
              return { ...(prev ?? undefined), [customerId]: false };
            });
          }
        }
      }

      fetchCustomerAsync();
    },

    [customers, headers, isFetchingCustomer]
  );

  const fetchAllCustomers = useCallback(() => {
    async function fetchAllCustomersAsync() {
      setIsFetchingAllCustomers(true);
      const response = await fetch(URL, { headers });
      const data = await response.json();
      if (response.ok) {
        setCustomers(
          data.results.map((result: Customer) => ({
            ...result,
            first_name: capitalizeName(result.first_name),
            last_name: capitalizeName(result.last_name),
          })) as Customer[]
        );
      } else {
        setCustomers([]);
      }

      localStorage.setItem("@CACHED_NUMBER_OF_CUSTOMERS", data.count);
      setTotalCustomers(data.count);

      setIsFetchingAllCustomers(false);
    }
    if (!isFetchingAllCustomers) {
      fetchAllCustomersAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, totalCustomers]); // don't add isFetchingAllCustomers

  const fetchCustomers = useCallback(
    async ({ params }: { params?: URLSearchParams } = {}): Promise<{
      rows: Customer[];
      count: number;
    }> => {
      // Check if we can use the cached customers
      if (customers && customers.length > 0 && params) {
        const offset = params.get("offset");
        const limit = params.get("limit");
        const paramCount = Array.from(params.keys()).length;

        if (
          offset !== null &&
          limit &&
          !isNaN(parseInt(offset)) &&
          !isNaN(parseInt(limit)) &&
          paramCount === 2
        ) {
          const offsetNum = parseInt(offset);
          const limitNum = parseInt(limit);

          // Use cached customers if offset is within range
          if (offsetNum < customers.length) {
            return {
              rows: customers
                .slice(
                  offsetNum,
                  Math.min(offsetNum + limitNum, customers.length)
                )
                .map((result) => ({
                  ...result,
                  first_name: capitalizeName(result.first_name),
                  last_name: capitalizeName(result.last_name),
                })),
              count: totalCustomers,
            };
          }
        }
      }

      // If we can't use cached customers, proceed with the API fetch

      try {
        const queryParams = params || new URLSearchParams();
        const response = await fetch(`${URL}?${queryParams.toString()}`, {
          headers,
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        setCustomers((prevCustomers) => {
          const prevCustomersFiltered = prevCustomers.filter(
            (customer) =>
              !data.results.map((c: Customer) => c.id).includes(customer.id)
          );
          return [...prevCustomersFiltered, ...data.results];
        });

        return {
          rows: data.results.map((result: Customer) => {
            return {
              ...result,
              first_name: capitalizeName(result.first_name),
              last_name: capitalizeName(result.last_name),
            };
          }),
          count: data.count,
        };
      } catch (error) {
        console.error(error);
        return { rows: [], count: 0 };
      }
    },
    [customers, headers, totalCustomers]
  );

  const enrollCustomer = useCallback(
    async (
      customerEnrollmentData: CustomerEnrollmentData
    ): Promise<number | null> => {
      try {
        const response = await fetch(`${URL}enrollment-session/`, {
          headers,
          method: "POST",
          body: JSON.stringify(customerEnrollmentData),
        });
        const data = await response.json();
        return data.pk;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    [headers]
  );

  const enrollCustomerManual = useCallback(
    async (
      customer: Customer
    ): Promise<{ success: boolean; result: string }> => {
      try {
        const response = await fetch(`${URL}`, {
          headers,
          method: "POST",
          body: JSON.stringify(customer),
        });

        if (response.ok) {
          const data = await response.json();
          return { success: true, result: data.id };
        }

        let json;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          json = await response.json();
        }

        return { success: false, result: json };
      } catch (error) {
        console.error(error);
        return { success: false, result: error as string };
      }
    },
    [headers]
  );

  const checkEnrollmentStatus = useCallback(
    async (
      customerEnrollmentId: number
    ): Promise<{ status: string | null; customerIds?: Array<number> }> => {
      try {
        const response = await fetch(
          `${URL}enrollment-session/${customerEnrollmentId}/`,
          { headers }
        );
        const data = await response.json();
        if (data.status === "completed") {
          return { status: data.status, customerIds: data.customers };
        }
        return { status: data.status };
      } catch (error) {
        console.error(error);
        return { status: null };
      }
    },
    [headers]
  );

  const getErrorMessageHTML = useCallback(async (response: any) => {
    try {
      const text = await response.text();
      const match = text.match(/<pre class="exception_value">(.+?)<\/pre>/);
      return match && match[1] ? match[1] : "An error has occurred.";
    } catch (error) {
      return "An error has occurred.";
    }
  }, []);

  const deleteCustomer = useCallback(
    async (
      customerId: number
    ): Promise<{ success: boolean; error: string }> => {
      try {
        const response = await fetch(`/api/customers/${customerId}/`, {
          headers,
          method: "DELETE",
        });
        if (response.ok) {
          return { success: true, error: "" };
        }
        throw new Error(await getErrorMessageHTML(response));
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          return {
            success: false,
            error: error.message || "An error has occurred.",
          };
        } else {
          console.error("An error has occurred.");
          return { success: false, error: "An error has occurred." };
        }
      }
    },
    [headers, getErrorMessageHTML]
  );

  useEffect(() => {
    fetchAllCustomers();
  }, [fetchAllCustomers, headers]);

  return (
    <CustomerContext.Provider
      value={{
        customers,
        totalCustomers,
        fetchCustomer,
        fetchCustomers,
        fetchAllCustomers,
        enrollCustomer,
        enrollCustomerManual,
        checkEnrollmentStatus,
        deleteCustomer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export function useCustomers(): ICustomerContext {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error("useCustomers must be used within an CustomerProvider");
  }
  return context;
}

export function useCustomer(customerId: number) {
  const { customers, fetchCustomer } = useCustomers();

  const [customer, setCustomer] = useState<Customer | null>(null);

  useEffect(() => {
    const filteredCustomers = customers.filter((c) => c.id === customerId);
    if (filteredCustomers.length > 0) {
      setCustomer(filteredCustomers[0]);
    } else {
      setCustomer(null);
    }
  }, [customerId, customers]);

  useEffect(() => {
    if (customerId) {
      fetchCustomer(customerId);
    }
  }, [fetchCustomer, customerId]);

  return customer;
}

export function useCustomerConsumptionData(customerId: number) {
  const headers = useAuthHeaders();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [consumptionData, setConsumptionData] =
    useState<ConsumptionData | null>(null);

  useEffect(() => {
    async function fetchBatteryMetricsAsync() {
      if (customerId && !isFetching) {
        setIsFetching(true);
        const url = `/api/consumption/monthly/?customer_id=${customerId}`;
        const response = await fetch(url, { headers });
        const data = await response.json();
        setConsumptionData(processData(data.results));
        setIsFetching(false);
      }
    }
    fetchBatteryMetricsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsFetching, headers, customerId]); // do not add isFetching

  return { consumptionData, isFetching };
}
