import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthHeaders } from "../common/authentication/authentication";

const URL = "/api/invoices/";

export type Invoice = {
  id: number;
  invoice_number: string;
  contract: string;
  customer: string;
  cups: string;
  end_date: string;
  total_amount: number;
};

interface IInvoiceContext {
  fetchInvoices: (params?: {
    params: URLSearchParams;
  }) => Promise<{ rows: Invoice[]; count: number }>;
  totalInvoices: number;
}

const InvoiceContext = createContext<IInvoiceContext | undefined>(undefined);

interface IInvoiceProviderProps {
  children: ReactNode;
}

export const InvoiceProvider: React.FC<IInvoiceProviderProps> = ({
  children,
}) => {
  const headers = useAuthHeaders();

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [isFetchingAllInvoices, setIsFetchingAllInvoices] =
    useState<boolean>(false);

  const fetchAllInvoices = useCallback(() => {
    async function fetchAllInvoicesAsync() {
      setIsFetchingAllInvoices(true);
      const response = await fetch(URL, { headers });
      const data = await response.json();
      if (response.ok) {
        setInvoices(data.results as Invoice[]);
      } else {
        setInvoices([]);
      }

      localStorage.setItem("@CACHED_NUMBER_OF_INVOICES", data.count);
      setTotalInvoices(data.count);

      setIsFetchingAllInvoices(false);
    }
    if (!isFetchingAllInvoices) {
      fetchAllInvoicesAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, totalInvoices]); // don't add isFetchingAllInvoices

  const fetchInvoices = useCallback(
    async ({ params }: { params?: URLSearchParams } = {}): Promise<{
      rows: Invoice[];
      count: number;
    }> => {
      // Check if we can use the cached invoices
      if (invoices && invoices.length > 0 && params) {
        const offset = params.get("offset");
        const limit = params.get("limit");
        const paramCount = Array.from(params.keys()).length;

        if (
          offset !== null &&
          limit &&
          !isNaN(parseInt(offset)) &&
          !isNaN(parseInt(limit)) &&
          paramCount === 2
        ) {
          const offsetNum = parseInt(offset);
          const limitNum = parseInt(limit);

          // Use cached invoices if offset is within range
          if (offsetNum < invoices.length) {
            return {
              rows: invoices.slice(
                offsetNum,
                Math.min(offsetNum + limitNum, invoices.length)
              ),
              count: totalInvoices,
            };
          }
        }
      }

      // If we can't use cached invoices, proceed with the API fetch
      try {
        const queryParams = params || new URLSearchParams();
        const response = await fetch(`${URL}?${queryParams.toString()}`, {
          headers,
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        return { rows: data.results, count: data.count };
      } catch (error) {
        console.error(error);
        return { rows: [], count: 0 };
      }
    },
    [invoices, headers, totalInvoices]
  );

  useEffect(() => {
    fetchAllInvoices();
  }, [fetchAllInvoices, headers]);

  return (
    <InvoiceContext.Provider value={{ fetchInvoices, totalInvoices }}>
      {children}
    </InvoiceContext.Provider>
  );
};

export function useInvoices(): IInvoiceContext {
  const context = useContext(InvoiceContext);
  if (context === undefined) {
    throw new Error("useInvoices must be used within an InvoiceProvider");
  }
  return context;
}
