import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import "normalize.css";
import "reactflow/dist/style.css";
import PlatformDataStudioGraphGrid from "./PlatformDataStudioGraphGrid.react";

import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github_dark";

export default function PlatformDataStudio() {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <PlatformDataStudioGraphGrid />
    </div>
  );
}
