import { Button, Spinner } from "@blueprintjs/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VendorAccount } from "../../../__generated__/types/VendorAccount";
import Ec1DataGrid from "../../../ui/components/Ec1DataGrid.react";
import PlatformAddVendorAccountDialog from "./PlatformAddVendorAccountsDialog.react";
import { useVendorAccounts } from "./vendorAccounts";

export default function PlatformSiteVendorAccountListViewPage() {
  const { vendors, fetchVendorAccounts } = useVendorAccounts();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [gridKey, setGridKey] = useState<number>(0);

  const navigate = useNavigate();

  const allVendors = useMemo(() => {
    return vendors.reduce((acc, vendor) => {
      if (vendor.id !== undefined) {
        acc[vendor.id] = vendor.name || "Unknown";
      }
      return acc;
    }, {} as Record<string, string>);
  }, [vendors]);

  const handleActionClick = useCallback(
    (vendorAccountId: string) => {
      navigate(`/vendor/account/${vendorAccountId}`);
    },
    [navigate]
  );

  const handleContextMenuItemClick = useCallback(
    (action: string, id: string | null) => {
      if (action === "view" && id) {
        handleActionClick(id);
      }
    },
    [handleActionClick]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "id", headerName: "Vendor Account ID", flex: 1 },
      {
        field: "vendor",
        headerName: "Vendor",
        flex: 3,
        valueGetter: (params) => {
          const vendorId = params.row.vendor;
          return vendorId !== undefined
            ? allVendors[vendorId] || vendorId
            : "N/A";
        },
      },
      {
        field: "vendor_account_uid",
        headerName: "Account UID",
        flex: 3,
      },
    ],
    [allVendors]
  );

  const contextMenuOptions = useMemo(
    () => [
      {
        label: "View Vendor account",
        icon: <VisibilityIcon />,
        action: (id: string | null) => handleContextMenuItemClick("view", id),
      },
    ],
    [handleContextMenuItemClick]
  );

  const refreshVendorAccountList = useCallback(() => {
    setIsDialogOpen(false);
    setGridKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          overflow: "hidden",
          padding: "24px 24px 38px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontFamily: "Barlow",
            letterSpacing: 1.5,
            fontWeight: 700,
            fontSize: 24,
            color: "#D3D8DE",
            marginBottom: 24,
          }}
        >
          <div>Vendor accounts</div>
          <div style={{ marginTop: "12px" }}>
            <Button
              minimal
              outlined
              rightIcon={dialogLoading ? <Spinner size={16} /> : "add"}
              onClick={() => {
                setIsDialogOpen(true);
              }}
            >
              Add new Vendor account
            </Button>
            <PlatformAddVendorAccountDialog
              isOpen={isDialogOpen}
              onClose={() => setIsDialogOpen(false)}
              onEnrollmentSuccess={refreshVendorAccountList}
              setIsLoadingParent={setDialogLoading}
            />
          </div>
        </div>
        <Ec1DataGrid<VendorAccount>
          id="gridVendorAccounts"
          key={gridKey}
          get={fetchVendorAccounts}
          columns={columns}
          contextMenuOptions={contextMenuOptions}
          getRowId={(row: VendorAccount) => row.id ?? "0"}
          onRowClick={(vendorAccount, event) => {
            handleActionClick(String(vendorAccount.id));
          }}
        />
      </div>
    </div>
  );
}
