export enum GateKeeper {
    CAN_USE_ASSISTANT = "can_use_assistant",
    CAN_USE_BATTERY_COMPARISON_GRAPH = "can_use_battery_comparison_graph",
    CAN_USE_BATTERY_CONTROLS = "can_use_battery_controls",
    CAN_USE_BUILDER = "can_use_builder",
    CAN_USE_CAMPAIGNS = "can_use_campaigns",
    CAN_USE_CANVAS = "can_use_canvas",
    CAN_USE_COMPOSER = "can_use_composer",
    CAN_USE_CUSTOMER_MAP = "can_use_customer_map",
    CAN_USE_DATA_DRIVE = "can_use_data_drive",
    CAN_USE_DATA_STUDIO = "can_use_data_studio",
    CAN_USE_DATALAYER_PAGE = "can_use_datalayer_page",
    CAN_USE_PROPERTIES = "can_use_properties",
    CAN_USE_VENDOR_ACCOUNTS = "can_use_vendor_accounts",
    CAN_VIEW_BATTERY_METRICS_CHART = "can_view_battery_metrics_chart",
    CAN_VIEW_COST_COMPARISON = "can_view_cost_comparison",
    CAN_VIEW_EXPERIMENTAL_FEATURES = "can_view_experimental_features",
}
