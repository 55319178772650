import { Button, H3, HTMLTable } from "@blueprintjs/core";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Contract, useContracts } from "../contracts/contracts";
import { useEnterpriseLabels } from "../enterpriseLabels/useEnterpriseLabels";

interface CustomerContractsTableProps {
  customerId: string;
}
export default function CustomerContractsTable({
  customerId,
}: CustomerContractsTableProps) {
  const { fetchContracts } = useContracts();
  const { selectedLabelObj } = useEnterpriseLabels();
  const [page, setPage] = useState<number>(1);

  const [contracts, setContracts] = useState<Contract[]>([]);

  useEffect(() => {
    async function fetchContractsAsync() {
      const queryParams = new URLSearchParams();
      queryParams.append("customer", customerId);

      const data = await fetchContracts({ params: queryParams });
      setContracts(
        data.rows.sort(
          (a, b) =>
            new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
        )
      );
    }
    fetchContractsAsync();
  }, [customerId, fetchContracts]);

  const navigate = useNavigate();

  const paginationButtons = useMemo(() => {
    if (contracts.length < 10) {
      return null;
    } else {
      const number_of_pages = Math.ceil(contracts.length / 10);

      return Array.from({ length: number_of_pages }, (_, i) => i + 1).map(
        (x) => (
          <Button
            key={x}
            active={page === x}
            style={{ marginRight: "4px" }}
            onClick={() => setPage(x)}
          >
            {x}
          </Button>
        )
      );
    }
  }, [contracts.length, page]);
  return (
    <div style={{ fontFamily: "Barlow" }}>
      <H3>Contracts</H3>
      <HTMLTable style={{ width: "100%" }} className="bp5-interactive">
        <thead>
          <tr>
            <th>
              {selectedLabelObj?.contract_uid_field_name ?? "Contract Number"}
            </th>
            <th>CUPS</th>
            <th>Tarrif</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {contracts.slice((page - 1) * 10, page * 10).map((contract) => (
            <tr
              key={contract.contract_number}
              onClick={() => {
                navigate(`/contracts/${contract.id}`);
              }}
            >
              <td>{contract.contract_number}</td>
              <td>{contract.cups}</td>
              <td>{contract.tariff}</td>
              <td>{String(contract.start_date)}</td>
              <td>{String(contract.end_date)}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      <div style={{ marginTop: "8px" }}>{paginationButtons}</div>
    </div>
  );
}
