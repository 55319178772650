import { useCallback, useState } from "react";
import { useAssistantDatasetFetcher } from "../assistant/assistant";
import { useAuthHeaders } from "../common/authentication/authentication";

// Assuming the structure of your document, adjust it according to your actual data model
interface Document {
  id: string;
  title: string;
  content: any;
}

export function useComposer() {
  const headers = useAuthHeaders();

  const [isCreatingDocument, setIsCreatingDocument] = useState(false);
  const [isSavingDocument, setIsSavingDocument] = useState(false);
  const [isFetchingDocument, setIsFetchingDocument] = useState(false);

  const [document, setDocument] = useState<Document | null>(null);

  const fetchDataset = useAssistantDatasetFetcher();

  const createDocumentWithUUID = useCallback(
    (uuid: string) => {
      async function createDocumentWithUUIDAsync(uuid: string) {
        setIsCreatingDocument(true);

        const result = await fetchDataset(uuid);

        if (!(result instanceof Object)) {
          setIsCreatingDocument(false);
          setDocument(null);
          throw Error("Could not get dataset");
        }

        const title =
          JSON.parse(result?.dataset)?.user_friendly_title ??
          "Untitled Document";

        const response = await fetch("/api/composer/document/", {
          method: "post",
          body: JSON.stringify({ title, content: null }),
          headers,
        });
        if (response.ok) {
          setDocument((await response.json()) as Document);
          setIsCreatingDocument(false);
        } else {
          setIsCreatingDocument(false);
          setDocument(null);
          throw Error(await response.text());
        }
      }

      if (isCreatingDocument === false) {
        createDocumentWithUUIDAsync(uuid);
      }
    },
    [headers, setIsCreatingDocument, isCreatingDocument, fetchDataset]
  );

  const createDocument = useCallback(() => {
    async function createDocumentAsync() {
      setIsCreatingDocument(true);
      const response = await fetch("/api/composer/document/", {
        method: "post",
        body: JSON.stringify({ title: "Untitled Document", content: null }),
        headers,
      });
      if (response.ok) {
        setDocument((await response.json()) as Document);
        setIsCreatingDocument(false);
      } else {
        setIsCreatingDocument(false);
        setDocument(null);
        throw Error(await response.text());
      }
    }

    if (isCreatingDocument === false) {
      createDocumentAsync();
    }
  }, [headers, setIsCreatingDocument, isCreatingDocument]);

  const saveDocument = useCallback(
    (documentID: string, draftDocument: Document) => {
      async function saveDocumentAsync() {
        setIsSavingDocument(true);
        const response = await fetch(`/api/composer/document/${documentID}/`, {
          method: "put",
          body: JSON.stringify({ ...draftDocument }),
          headers,
        });
        if (response.ok) {
          setDocument((await response.json()) as Document);
          setIsSavingDocument(false);
        } else {
          setIsSavingDocument(false);
          throw Error(await response.text());
        }
      }

      if (isSavingDocument === false) {
        saveDocumentAsync();
      }
    },
    [headers, setIsSavingDocument, isSavingDocument]
  );

  const fetchDocument = useCallback(
    (documentID: string) => {
      async function fetchDocumentAsync() {
        setIsFetchingDocument(true);
        const response = await fetch(`/api/composer/document/${documentID}/`, {
          headers,
        });
        if (response.ok) {
          setDocument((await response.json()) as Document);
          setIsFetchingDocument(false);
        } else {
          setIsFetchingDocument(false);
          throw Error(await response.text());
        }
      }

      if (isFetchingDocument === false) {
        fetchDocumentAsync();
      }
    },
    [headers, setIsFetchingDocument, isFetchingDocument]
  );

  return {
    createDocument,
    createDocumentWithUUID,
    fetchDocument,
    saveDocument,
    document,
    isCreatingDocument,
    isSavingDocument,
    isFetchingDocument,
  };
}
