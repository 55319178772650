import { Button, IconSize, Spinner } from "@blueprintjs/core";
import { AssetEnrollmentCandidate } from "@ec1/types/AssetEnrollmentCandidate";
import { AssetEnrollmentSession } from "@ec1/types/AssetEnrollmentSession";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch, useFetcher } from "src/common/fetcher/effects";
import PlatformVendorAccountDeviceEnrolmentCandidateItem from "./PlatformVendorAccountDeviceEnrolmentCandidateItem.react";

export default function PlatformVendorAccountDeviceEnrolmentPage() {
  const navigate = useNavigate();

  const [enrolledDevices, setEnrolledDevices] = useState<string[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

  const { accountId, deviceEnrollmentSessionId } = useParams<{
    accountId: string;
    deviceEnrollmentSessionId?: string;
  }>();

  const createEnrollmentSessionUrl = useMemo(() => {
    if (deviceEnrollmentSessionId) {
      return null;
    }
    return "/api/devices/enrollment-session/";
  }, [deviceEnrollmentSessionId]);

  const [sessionStatus, setSessionStatus] = useState<string | null>(null);

  const currentEnrollmentSessionUrl = useMemo(() => {
    if (deviceEnrollmentSessionId) {
      return `/api/devices/enrollment-session/${deviceEnrollmentSessionId}/`;
    }
    return null;
  }, [deviceEnrollmentSessionId]);

  const { data: newEnrollmentSessionData } = useFetch<AssetEnrollmentSession>(
    createEnrollmentSessionUrl,
    {
      method: "POST",
      body: JSON.stringify({ vendor_account: parseInt(accountId ?? "0") }),
    }
  );

  const { data: currentEnrollmentSessionData } =
    useFetch<AssetEnrollmentSession>(currentEnrollmentSessionUrl, {
      pollingInterval: [
        "queued",
        "processing",
        "",
        "selection_request",
      ].includes(sessionStatus ?? "")
        ? 2000
        : 0,
    });

  const { data: candidatesData } = useFetch<{
    results: AssetEnrollmentCandidate[];
  }>(
    sessionStatus === "selection_request"
      ? `/api/asset/vendor/account/enrollment-candidates/?session=${currentEnrollmentSessionData?.uuid}`
      : null
  );

  useEffect(() => {
    if (newEnrollmentSessionData?.uuid) {
      navigate(
        `/vendor/account/${accountId}/asset-enrollment/${newEnrollmentSessionData?.uuid}`
      );
    }
  }, [accountId, navigate, newEnrollmentSessionData?.uuid]);

  useEffect(() => {
    if (sessionStatus === "completed") {
      navigate(`/vendor/account/${accountId}/`);
    }
  }, [accountId, navigate, sessionStatus]);

  useEffect(() => {
    setSessionStatus(currentEnrollmentSessionData?.status ?? null);
    setEnrolledDevices(currentEnrollmentSessionData?.enrolled_assets ?? []);
  }, [currentEnrollmentSessionData]);

  const [patchUrl, patchBody] = useMemo(() => {
    return [
      `/api/devices/enrollment-session/${deviceEnrollmentSessionId}/`,
      JSON.stringify({ selected_assets: selectedDevices }),
    ];
  }, [deviceEnrollmentSessionId, selectedDevices]);

  const {
    data: fetchedPatchData,
    fetch: fetchPatch,
    isLoading: isFetchingPatch,
  } = useFetcher<AssetEnrollmentSession>(patchUrl, {
    method: "PATCH",
    body: patchBody,
  });

  useEffect(() => {
    setSessionStatus(fetchedPatchData?.status ?? null);
  }, [fetchedPatchData]);

  return (
    <div>
      DEVICE ENROLMENT VIEW {accountId} {deviceEnrollmentSessionId ?? "??"} -{" "}
      {createEnrollmentSessionUrl ?? "??"}
      <div>NEW SESSION: {JSON.stringify(newEnrollmentSessionData ?? null)}</div>
      <div>
        CURRENT SESSION: {JSON.stringify(currentEnrollmentSessionData ?? null)}
      </div>
      <div>SESSION STATUS: {sessionStatus}</div>
      {["queued", "processing", null].includes(String(sessionStatus)) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Barlow",
            alignItems: "center",
          }}
        >
          <div>
            <Spinner />
          </div>
          <div style={{ marginTop: "24px" }}>Fetching devices from server</div>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {candidatesData && (
          <div style={{ width: "700px" }}>
            <div
              style={{
                marginBottom: "12px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>{`${selectedDevices.length} assets selected`}</div>
              <div>
                <Button
                  disabled={selectedDevices?.length === 0}
                  rightIcon={
                    isFetchingPatch ? (
                      <Spinner size={IconSize.STANDARD} />
                    ) : undefined
                  }
                  onClick={() => {
                    fetchPatch();
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
            {candidatesData?.results?.map((x, idx) => (
              <PlatformVendorAccountDeviceEnrolmentCandidateItem
                {...x}
                disabled={enrolledDevices.includes(x.unique_id ?? "")}
                selected={selectedDevices.includes(x.unique_id ?? "")}
                onSelect={() => {
                  setSelectedDevices((prev) =>
                    [
                      ...prev.filter((d) => d !== x.unique_id),
                      x.unique_id ?? "",
                    ].filter((d) => d.length > 0)
                  );
                }}
                onUnselect={() => {
                  setSelectedDevices((prev) =>
                    [...prev.filter((d) => d !== x.unique_id)].filter(
                      (d) => d.length > 0
                    )
                  );
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
