import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
} from "react";
import { useAuthHeaders } from "../common/authentication/authentication";

interface ChangePasswordErrorResponse {
  old_password?: string[];
  new_password?: string[];
}

interface SettingContextType {
  changePassword: (
    oldPassword: string,
    newPassword: string
  ) => Promise<{
    status: string;
    detail: ChangePasswordErrorResponse | null;
  }>;
}

const SettingContext = createContext<SettingContextType | undefined>(undefined);

export const SettingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const headers = useAuthHeaders();

  const changePassword = useCallback(
    async (old_password: string, new_password: string) => {
      try {
        const response = await fetch("/api/users/change-password/", {
          headers,
          method: "POST",
          body: JSON.stringify({ old_password, new_password }),
        });

        if (!response.ok) {
          const responseDetail: ChangePasswordErrorResponse =
            await response.json();

          return {
            status: "Changing password failed. Please try again.",
            detail: responseDetail,
          };
        }
        return { status: "", detail: null };
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          return {
            status: "Changing password failed. Please try again.",
            detail: null,
          };
        } else {
          console.error("An unknown error has occurred.");
          return {
            status: "Changing password failed. Please try again.",
            detail: null,
          };
        }
      }
    },
    [headers]
  );

  return (
    <SettingContext.Provider value={{ changePassword }}>
      {children}
    </SettingContext.Provider>
  );
};

export function useSettings(): SettingContextType {
  const context = useContext(SettingContext);
  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingProvider");
  }
  return context;
}
