export interface IAuthenticationTokens {
  access: string;
  refresh: string;
}
export function parseJWT(token: string) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(base64));
  } catch (error) {
    console.error("Failed to parse token:", error);
    return null;
  }
}
export function calculateSecondsUntilJTWExpiry(jwtToken: string) {
  const decodedToken = parseJWT(jwtToken);
  const currentTime = Math.floor(Date.now() / 1000);
  return decodedToken.exp - currentTime;
}

export function isJWTValid(jwtToken?: string | null) {
  if (jwtToken) {
    return calculateSecondsUntilJTWExpiry(jwtToken) > 0;
  }
  return false;
}

export function shouldRefreshJWTTokens(tokens: IAuthenticationTokens | null) {
  if (tokens) {
    const { access } = tokens;
    const secondsUntilExpiry = calculateSecondsUntilJTWExpiry(access);
    return secondsUntilExpiry < 100;
  }
  return false;
}
