import { useAuthHeaders } from "@ec1/authentication/authentication";
import { useEffect, useState } from "react";
import { GateKeeper } from "./__generated__/gatekeepers";

type GatekeeperStatusDict = {
  [key in GateKeeper]?: boolean;
};

// Initialize with all required properties set to a boolean value
const fetchInProgress: GatekeeperStatusDict = {};

export default function useGatekeeper(gk: GateKeeper) {
  const headers = useAuthHeaders();

  // Initialize gkStatus with the cached value, if available
  const cachedStatus = localStorage.getItem(`gkStatus-${gk}`);
  const initialStatus = cachedStatus ? JSON.parse(cachedStatus) : null;

  const [gkStatus, setGkStatus] = useState<boolean | null>(initialStatus);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === `gkStatus-${gk}`) {
        const newStatus = event.newValue ? JSON.parse(event.newValue) : null;
        setGkStatus(newStatus);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [gk]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchGKStatusAsync() {
      if (fetchInProgress[gk] === true) {
        return;
      }
      fetchInProgress[gk] = true;
      try {
        const response = await fetch(`/api/gk/${gk}/`, { headers, signal });
        if (response.ok) {
          const result = await response.json();
          const status = result["enabled"] as boolean;
          localStorage.setItem(`gkStatus-${gk}`, JSON.stringify(status));
          setGkStatus(status);
        } else {
          console.error(await response.text());
        }
      } catch (error) {
        if (!(error instanceof Error && error.name === "AbortError")) {
          console.error("Fetch error:", error);
        }
      }
      fetchInProgress[gk] = false;
    }

    fetchGKStatusAsync();

    // abort fetch if component unmounts
    return () => {
      controller.abort();
    };
  }, [headers, gk]);

  return gkStatus;
}
