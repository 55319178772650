import { Tag } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import { useMemo } from "react";

interface BatteryGridStatusTagProps {
  battery: Battery;
}

export default function BatteryGridStatusTag({
  battery,
}: BatteryGridStatusTagProps) {
  const grid_state = useMemo(() => {
    return battery?.grid_state ?? null;
  }, [battery]);

  if (grid_state === null) {
    return <div>{"-"}</div>;
  }

  if (grid_state === "IDLE") {
    return <Tag style={{ fontWeight: 500 }}>{"Idle"}</Tag>;
  }

  if (grid_state === "EXPORTING") {
    return (
      <Tag
        style={{ backgroundColor: "#D4F17E", color: "black", fontWeight: 500 }}
      >
        {"Exporting"}
      </Tag>
    );
  }

  if (grid_state === "IMPORTING") {
    return (
      <Tag
        style={{ backgroundColor: "#D0B090", color: "black", fontWeight: 500 }}
      >
        {"Importing"}
      </Tag>
    );
  }

  return <div>{"-"}</div>;
}
