import { useAuthHeaders } from "@ec1/authentication/authentication";
import { useCallback, useEffect, useRef, useState } from "react";

interface FetchOptions {
  authenticated?: boolean;
  body?: undefined | BodyInit;
  method?: "POST" | "GET";
  useCache?: boolean;
  pollingInterval?: number; // New option for polling
}

interface FetcherResult<T> {
  data: T | null;
  isLoading: boolean;
  error: Error | null;
  fetch: (body?: BodyInit) => Promise<T | null>;
}
interface FetcherOptions {
  authenticated?: boolean;
  body?: undefined | BodyInit;
  method?: "POST" | "GET" | "PATCH";
  useCache?: boolean;
}

export function useFetch<T>(
  url: string | null,
  options: FetchOptions = {}
): {
  data: T | null;
  isLoading: boolean;
  error: Error | null;
  refetch: () => void;
} {
  const {
    authenticated = true,
    method = "GET",
    body,
    useCache = false,
    pollingInterval = 0, // Default to 0 (no polling)
  } = options;
  const authHeaders = useAuthHeaders();

  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const abortControllerRef = useRef<AbortController | null>(null);
  const pollingIntervalRef = useRef<number | null>(null);

  const fetchData = useCallback(() => {
    if (!url) {
      setData(null);
      return;
    }

    // Abort previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for this fetch request
    abortControllerRef.current = new AbortController();

    setIsLoading(true);
    setError(null);

    // Check cache if enabled
    if (useCache) {
      const cachedData = localStorage.getItem(url);
      if (cachedData) {
        setData(JSON.parse(cachedData) as T);
      }
    }

    setIsLoading(true);

    fetch(url, {
      method,
      body,
      headers: authenticated
        ? authHeaders
        : { "Content-Type": "application/json" },
      signal: abortControllerRef.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setIsLoading(true);
        return response.json();
      })
      .then((result) => {
        setData(result as T);
        // Cache the result if caching is enabled
        setIsLoading(true);
        if (useCache) {
          localStorage.setItem(url, JSON.stringify(result));
        }
      })
      .catch((e) => {
        if (e.name === "AbortError") {
          // Ignore abort errors
          return;
        }
        setError(
          e instanceof Error ? e : new Error("An unknown error occurred")
        );
        setData(null);
        setIsLoading(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url, method, body, authenticated, authHeaders, useCache]);

  useEffect(() => {
    fetchData();

    // Set up polling if interval is greater than 0
    if (pollingInterval > 0) {
      pollingIntervalRef.current = window.setInterval(
        fetchData,
        pollingInterval
      );
    }

    return () => {
      // Abort the fetch if the component unmounts or dependencies change
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // Clear the polling interval
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [fetchData, pollingInterval]);

  return { data, isLoading, error, refetch: fetchData };
}

export function useFetcher<T>(
  url: string | null,
  options: FetcherOptions = {}
): FetcherResult<T> {
  const {
    authenticated = true,
    method = "GET",
    body = undefined,
    useCache = false,
  } = options;

  const authHeaders = useAuthHeaders();
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetch = useCallback(
    async (providedBody?: BodyInit): Promise<T | null> => {
      if (!url) {
        return null;
      }

      // Abort previous request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      setIsLoading(true);

      // Create a new AbortController for this fetch request
      abortControllerRef.current = new AbortController();

      setIsLoading(true);
      setError(null);
      let result = null;
      try {
        if (useCache) {
          const cachedData = localStorage.getItem(url);
          if (cachedData) {
            setData(JSON.parse(cachedData) as T);
          }
        }

        const response = await window.fetch(url, {
          method,
          body: body ? body : providedBody,
          headers: authenticated
            ? authHeaders
            : { "Content-Type": "application/json" },
          signal: abortControllerRef.current.signal,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        result = await response.json();
        setData(result as T);

        // Cache the result if caching is enabled
        if (useCache) {
          localStorage.setItem(url, JSON.stringify(result));
        }
      } catch (e) {
        if (e instanceof Error && e.name === "AbortError") {
          // Ignore abort errors
          return null;
        }
        setError(
          e instanceof Error ? e : new Error("An unknown error occurred")
        );
      } finally {
        setIsLoading(false);
        return result;
      }
    },
    [url, useCache, method, body, authenticated, authHeaders]
  );

  return { data, isLoading, error, fetch };
}
