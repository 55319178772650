import { useAuthHeaders } from "@ec1/authentication/authentication";
import { Dataset } from "@ec1/types/Dataset";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "src/common/fetcher/effects";

import { Cell, Column, Table2 } from "@blueprintjs/table";
export default function PlatformFilesystem() {
  const { datasetUUID: paramDatasetUUID } = useParams();

  const fetchAllUrl = useMemo(() => {
    if (!paramDatasetUUID) {
      return "/api/datalayer/dataset/";
    }
    return null;
  }, [paramDatasetUUID]);

  const fetchItemUrl = useMemo(() => {
    if (paramDatasetUUID) {
      return `/api/datalayer/dataset/${paramDatasetUUID}/json/`;
    }
    return null;
  }, [paramDatasetUUID]);

  const { data } = useFetch<{ results: Dataset[] }>(fetchAllUrl);

  const { data: itemJSON } = useFetch<string>(fetchItemUrl);

  const navigate = useNavigate();

  const authHeaders = useAuthHeaders();

  const handleDownload = async () => {
    try {
      // Replace 'yourAccessToken' with the actual method to get your access token

      const response = await fetch(
        `/api/datalayer/dataset/${paramDatasetUUID}/csv/`,
        {
          headers: { ...authHeaders, "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error("Download failed");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `dataset_${paramDatasetUUID}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  if (itemJSON) {
    return (
      <div>
        <div>
          <button onClick={handleDownload}>Download CSV</button>
        </div>
        <div style={{ padding: "64px 128px" }}>
          <DynamicTable data={JSON.parse(itemJSON)} />
        </div>
      </div>
    );
  }

  return (
    <div style={{ padding: "24px" }}>
      <div>DATASETS</div>
      {data?.results.map((item) => (
        <div
          key={item.uuid}
          style={{ background: "gray", marginTop: "8px", cursor: "pointer" }}
          onClick={() => navigate(`/drive/${item.uuid}`)}
        >
          {item.unique_name}
        </div>
      ))}
    </div>
  );
}

const DynamicTable = ({ data }: { data: any }) => {
  if (!data || data.length === 0) {
    return <div>No data to display</div>;
  }

  const columns = Object.keys(data[0]);

  const cellRenderer = (columnKey: string) => (rowIndex: number) => {
    return <Cell>{String(data[rowIndex][columnKey])}</Cell>;
  };

  return (
    <Table2 numRows={data.length}>
      {columns.map((columnKey) => (
        <Column
          key={columnKey}
          name={columnKey}
          cellRenderer={cellRenderer(columnKey)}
        />
      ))}
    </Table2>
  );
};
