import { Button, IconSize, Spinner } from "@blueprintjs/core";

import { useCallback, useEffect, useRef, useState } from "react";
import AceEditor from "react-ace";
import PlatformAssistantTextArea from "../assistant/PlatformAssistantTextArea.react";
import { useAuthHeaders } from "../common/authentication/authentication";
import PlatformBuilderToolbar from "./PlatformBuilderToolbar.react";
interface PlatformBuilderSideViewProps {
  currentContent: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
}

function get_request(code: string, userMessage: string) {
  console.log({ code, userMessage });
  return JSON.stringify({ request: JSON.stringify({ code, userMessage }) });
}

export default function PlatformBuilderSideView({
  currentContent,
  setContent,
}: PlatformBuilderSideViewProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [textAreaValue, setTextAreaValue] = useState<string>("");

  //const[(threadID, setThreadID)] = useState<string | null>(null);

  const [code, setCode] = useState<string>(currentContent);
  const headers = useAuthHeaders();

  const createCopilotRequest = useCallback(() => {
    async function createCopilotRequestAsync() {
      const response = await fetch("/api/copilot/request/", {
        method: "post",
        headers,
        body: get_request(code, textAreaValue),
      });
      let formatted = null;
      if (response.ok) {
        let result = await response.json();
        const result_id = result?.id;
        while (formatted === null) {
          const response = await fetch(`/api/copilot/request/${result_id}/`, {
            headers,
          });
          result = await response.json();
          formatted = result?.formatted_response;
          console.log("FORMATTED", formatted);
        }
        return String(formatted);
      } else {
        throw Error(await response.text());
      }
    }

    return createCopilotRequestAsync();
  }, [headers, code, textAreaValue]);

  useEffect(() => {
    setCode(currentContent);
  }, [currentContent]);
  const editorRef = useRef<any>(null);

  function handleChange(newValue: string) {
    setCode(newValue);
  }

  function handleSubmit() {
    setIsSubmitting(true);
    createCopilotRequest().then((rspStr) => {
      setCode(rspStr);
      setTextAreaValue("");
      setIsSubmitting(false);
    });
  }

  return (
    <div
      style={{
        //backgroundColor: "red",
        width: isOpen ? "600px" : undefined,
        display: "flex",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRight: "1px solid #1C2127",
          borderLeft: "1px solid #404854",
          padding: "0 2px",
        }}
      >
        {isOpen ? (
          <Button
            onClick={() => setIsOpen(false)}
            minimal
            icon="double-chevron-right"
          />
        ) : (
          <Button
            onClick={() => setIsOpen(true)}
            minimal
            icon="double-chevron-left"
          />
        )}
      </div>
      {isOpen ? (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <PlatformBuilderToolbar hasRun={false} />
          <div style={{ flex: 1, marginTop: "12px" }}>
            <AceEditor
              ref={editorRef}
              width="100%"
              height="100%"
              onLoad={function (editor) {}}
              mode="python"
              theme="github_dark"
              onChange={handleChange}
              name="UNIQUE_ID_OF_DIV"
              editorProps={{ $blockScrolling: true }}
              value={code}
              highlightActiveLine={false}
              style={{ padding: "8px" }}
              setOptions={{
                showLineNumbers: true,
                tabSize: 4,
              }}
            />
          </div>
          <div
            style={{
              padding: "26px 24px 0px 24px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              intent="primary"
              icon="confirm"
              style={{ margin: "0px 4px" }}
              onClick={() => {
                setContent(code);
              }}
            >
              Apply to original
            </Button>
            <Button intent="danger" icon="reset" style={{ margin: "0px 4px" }}>
              Reset to original
            </Button>
          </div>
          <div
            style={{ padding: "24px", display: "flex", alignItems: "flex-end" }}
          >
            <PlatformAssistantTextArea
              disabled={isSubmitting}
              placeholder="Ask Electryone AI Assistant how to adjust your code..."
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setTextAreaValue(event.target.value);
              }}
              value={textAreaValue}
              onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault(); // Prevents the default action of the Enter key
                  handleSubmit(); // Calls the function to submit the message
                }
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "8px",
              }}
            >
              <Button
                minimal={isSubmitting}
                icon={
                  isSubmitting ? (
                    <Spinner size={IconSize.STANDARD} />
                  ) : (
                    "send-message"
                  )
                }
                onClick={handleSubmit}
              ></Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
