import { H5, Spinner } from "@blueprintjs/core";
import { PropertyTariffMapping } from "@ec1/types/PropertyTariffMapping";
import { Tariff } from "@ec1/types/Tariff";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useFetch } from "src/common/fetcher/effects";
import { getCurrencySymbol, getCurrentTariffId } from "../properties/tariffs";
import useBatteryChargingPlan, {
  ChartBatteryChargingPlanPoint,
  ChartBatteryChargingPlanSocPoint,
} from "./useBatteryChargingPlan";

interface PlatformBatteryChargingScheduleChartProps {
  batteryId: number;
  height?: number;
}

export default function PlatformBatteryChargingScheduleChart({
  batteryId,
  height = 240,
}: PlatformBatteryChargingScheduleChartProps) {
  const {
    device,
    batteryChargingPlan,
    isFetchingBatteryChargingPlan,
    timezone,
    timezoneLabel,
  } = useBatteryChargingPlan(batteryId);

  const hasData = useMemo(() => {
    return (
      batteryChargingPlan &&
      batteryChargingPlan.socData &&
      batteryChargingPlan.socData.length > 0
    );
  }, [batteryChargingPlan]);

  const tariffMappingFetchUrl = useMemo(() => {
    if (device?.assigned_property) {
      return `/api/tariffs/property-mapping/?property=${device?.assigned_property}`;
    }
    return null;
  }, [device?.assigned_property]);

  const { data: propertyTariffMappingResponse } = useFetch<{
    results: PropertyTariffMapping[];
  }>(tariffMappingFetchUrl, { useCache: true });

  const tariffId = useMemo(() => {
    return getCurrentTariffId(propertyTariffMappingResponse?.results) ?? null;
  }, [propertyTariffMappingResponse?.results]);

  const tariffFetchUrl = useMemo(() => {
    if (tariffId) {
      return `/api/tariffs/${tariffId}/`;
    }
    return null;
  }, [tariffId]);

  const { data: tariff } = useFetch<Tariff>(tariffFetchUrl, { useCache: true });

  const chartOptions = useMemo(() => {
    const localNow = DateTime.now().setZone(timezone || "system");

    return {
      chart: {
        height: height - 20,
        marginLeft: 58,
        marginRight: 45,
        marginTop: 20,
        marginBottom: 108,
        zoomType: false,
      },
      title: {
        text: null,
      },
      time: {
        timezone,
        useUTC: timezone !== undefined,
      },
      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        x: 3,
        y: 8,
        padding: 0,
        symbolWidth: 14,
        itemStyle: {
          color: "white",
          fontFamily: "Barlow",
          cursor: "default",
        },
        itemHoverStyle: {
          color: "white",
          fontFamily: "Barlow",
          cursor: "default",
        },
        style: {
          pointerEvents: "none",
        },
      },
      xAxis: {
        lineWidth: 0,
        tickLength: 5,
        crosshair: true,
        title: {
          x: 3,
          text: `Time<br><span style="font-size: 11px; color: #999;">${timezoneLabel}</span>`,
        },
        plotBands: [
          {
            from: batteryChargingPlan?.solarPeriod?.start,
            to: batteryChargingPlan?.solarPeriod?.end,
            color: "rgba(255, 87, 51, 0.25)",
          },
          ...(batteryChargingPlan?.chargeSlots.map((slot) => ({
            from: slot.start,
            to: slot.end,
            color:
              slot.start <= localNow.toMillis()
                ? "rgba(62, 176, 233, 0.35)"
                : "rgba(62, 176, 233, 0.25)",
          })) || []),
          ...(batteryChargingPlan?.exportSlots.map((slot) => ({
            from: slot.start,
            to: slot.end,
            color:
              slot.start <= localNow.toMillis()
                ? "rgba(25, 189, 60, 0.35)"
                : "rgba(25, 189, 60, 0.25)",
          })) || []),
        ],
      },
      yAxis: [
        {
          opposite: false,
          showLastLabel: true,
          title: {
            text: null,
          },
          labels: {
            y: 4,
            style: {
              fontFamily: "Barlow",
              color: "white",
            },
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              const value =
                typeof this.value === "number"
                  ? this.value.toFixed(2)
                  : this.value;
              return getCurrencySymbol(tariff?.currency || "") + value;
            },
          },
          gridLineWidth: 0,
        },
        {
          min: 0,
          max: 105,
          opposite: true,
          endOnTick: false,
          tickInterval: 50,
          showLastLabel: true,
          title: {
            text: null,
          },
          labels: {
            y: 4,
            style: {
              fontFamily: "Barlow",
              color: "white",
            },
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              return this.value + "%";
            },
          },
          gridLineWidth: 0,
        },
      ],
      tooltip: {
        shared: true,
      },
      series: [
        {
          yAxis: 0,
          type: "line",
          name: "Import Price",
          color: "#DB2C6F",
          zIndex: 3,
          step: true,
          showInLegend: false,
          data: batteryChargingPlan?.importPrices.map(
            (p: ChartBatteryChargingPlanPoint) => [p.timestamp, p.value]
          ),
          tooltip: {
            valueDecimals: 2,
            valuePrefix: getCurrencySymbol(tariff?.currency || ""),
          },
          marker: {
            enabled: false,
          },
        },
        {
          yAxis: 0,
          type: "line",
          name: "Export Price",
          color: "#4e0d26",
          zIndex: 2,
          step: true,
          showInLegend: false,
          data: batteryChargingPlan?.exportPrices.map(
            (p: ChartBatteryChargingPlanPoint) => [p.timestamp, p.value]
          ),
          tooltip: {
            valueDecimals: 2,
            valuePrefix: getCurrencySymbol(tariff?.currency || ""),
          },
          marker: {
            enabled: false,
          },
        },
        {
          yAxis: 1,
          type: "column",
          borderWidth: 0,
          name: "SoC",
          zIndex: 1,
          showInLegend: false,
          color: "rgb(255, 210, 102)",
          data: batteryChargingPlan?.socData.map(
            (p: ChartBatteryChargingPlanSocPoint) => ({
              x: p.timestamp,
              y: p.value,
              color: "rgb(255, 210, 102)",
              // p.timestamp > batteryChargingPlan?.lastMetricTimestamp
              //   ? "rgb(255, 210, 102)"
              //   : p.state === "grid charging"
              //   ? "#3eb0e9"
              //   : p.state === "solar charging"
              //   ? "#b7410e"
              //   : "rgb(255, 210, 102)",
              opacity:
                p.timestamp > batteryChargingPlan?.lastMetricTimestamp
                  ? 0.3
                  : 0.75,
            })
          ),
          tooltip: {
            valueDecimals: 1,
            valueSuffix: "%",
          },
        },
        // Fake series to add legend items
        {
          name: "Grid Charging",
          color: "#3eb0e9",
          type: "spline",
          events: {
            legendItemClick: function () {
              return false;
            },
          },
        },
        {
          name: "Solar Window",
          color: "#b7410e",
          type: "spline",
          events: {
            legendItemClick: function () {
              return false;
            },
          },
        },
        {
          name: "Exporting",
          color: "rgb(25, 189, 60)",
          type: "spline",
          events: {
            legendItemClick: function () {
              return false;
            },
          },
        },
        {
          name: "Battery Level",
          color: "rgb(255, 210, 102)",
          type: "spline",
          events: {
            legendItemClick: function () {
              return false;
            },
          },
        },
        {
          name: "Import Price/kWh",
          color: "#DB2C6F",
          type: "spline",
          events: {
            legendItemClick: function () {
              return false;
            },
          },
        },
        {
          name: "Export Price/kWh",
          color: "#4e0d26",
          type: "spline",
          events: {
            legendItemClick: function () {
              return false;
            },
          },
        },
      ],
      rangeSelector: {
        enabled: false,
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        spline: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          dataGrouping: {
            enabled: false,
          },
        },
        column: {
          dataGrouping: {
            enabled: false,
          },
        },
      },
    };
  }, [batteryChargingPlan, height, tariff?.currency, timezone, timezoneLabel]);

  return (
    <div
      style={{
        height: "100%",
        borderRadius: 5,
        color: "white",
        padding: 10,

        display: "flex",
        flexDirection: "column",
      }}
    >
      <H5>Charging Schedule</H5>
      {isFetchingBatteryChargingPlan ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Spinner />
        </div>
      ) : hasData ? (
        <div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          No data available
        </div>
      )}
    </div>
  );
}
