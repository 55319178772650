import {
  Button,
  Dialog,
  DialogProps,
  FormGroup,
  H4,
  InputGroup,
  Intent,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { Ec1Toaster } from "../../../ui/components/Ec1Toaster.react";
import useBrands, { Brand } from "./brands/useBrands";
import { useDevices } from "./devices";

interface PlatformAddDeviceDialogProps extends DialogProps {
  onEnrollmentSuccess?: () => void;
  setIsLoadingParent: (loading: boolean) => void;
}

const useStyles = createUseStyles({
  dialog: {
    fontFamily: "Barlow",
    padding: 24,
    width: "400px",
    boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.2) !important",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "8px",
  },
  validationError: {
    color: "#f17575",
    height: 10,
  },
  selectError: {
    border: "1px solid #DB3737",
    borderRadius: 3,
  },
});

const renderBrand: ItemRenderer<Brand> = (
  brand: Brand,
  { handleClick, handleFocus, modifiers }
) => {
  return (
    <MenuItem
      key={brand.id}
      onClick={handleClick}
      onFocus={handleFocus}
      text={`${brand.name}`}
    />
  );
};

export default function PlatformAddDeviceDialog({
  onEnrollmentSuccess,
  setIsLoadingParent,
  onClose,
  ...dialogProps
}: PlatformAddDeviceDialogProps) {
  const classes = useStyles();
  const brands = useBrands();
  const { enrollDevice, checkEnrollmentStatus } = useDevices();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [brandValidationError, setBrandValidationError] = useState<string>("");
  const [usernameValidationError, setUsernameValidationError] =
    useState<string>("");
  const [passwordValidationError, setPasswordValidationError] =
    useState<string>("");

  const [usernameIntent, setUsernameIntent] = useState<Intent>(Intent.NONE);
  const [passwordIntent, setPasswordIntent] = useState<Intent>(Intent.NONE);
  const [brandIntent, setBrandIntent] = useState<Intent>(Intent.NONE);

  const setLoadingState = useCallback(
    (loading: boolean) => {
      setIsLoading(loading);
      setIsLoadingParent(loading);
    },
    [setIsLoadingParent]
  );

  const showToast = useCallback(
    (
      message: string,
      intent: Intent,
      buttonText?: string,
      buttonOnClick?: () => void
    ) => {
      if (intent === Intent.DANGER) {
        setUsernameIntent(Intent.DANGER);
        setPasswordIntent(Intent.DANGER);
      }

      Ec1Toaster.show({
        timeout: 5000,
        message: (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 18,
                fontFamily: "Barlow",
              }}
            >
              <div style={{ marginRight: 15, height: 20 }}>{message}</div>
              {buttonText && (
                <Button
                  intent="primary"
                  text={buttonText}
                  onClick={buttonOnClick}
                  style={{
                    height: 20,
                    padding: "0 5px",
                    minHeight: "unset",
                    minWidth: "unset",
                    fontSize: 12,
                  }}
                />
              )}
            </div>
          </>
        ),
        intent: intent,
      });
    },
    []
  );

  const resetForm = useCallback(() => {
    setUsername("");
    setPassword("");
    setSelectedBrand(null);
    setBrandValidationError("");
    setUsernameValidationError("");
    setPasswordValidationError("");
    setBrandIntent(Intent.NONE);
    setUsernameIntent(Intent.NONE);
    setPasswordIntent(Intent.NONE);
    setLoadingState(false);
  }, [setLoadingState]);

  const handleBrandSelect = useCallback((brand: Brand) => {
    setSelectedBrand(brand);
    setBrandValidationError("");
    setBrandIntent(Intent.NONE);
  }, []);

  const checkEnrollment = useCallback(
    async (deviceEnrollmentId: number) => {
      try {
        const data = await checkEnrollmentStatus(deviceEnrollmentId);

        if (data.status === "completed") {
          setLoadingState(false);
          onEnrollmentSuccess?.();
          showToast(
            `Device${
              data.deviceIds && data.deviceIds.length > 1 ? "s" : ""
            } enrolled successfully!`,
            Intent.SUCCESS,
            `View Device${
              data.deviceIds && data.deviceIds.length > 1 ? "s" : ""
            }`,
            () => {
              if (data.deviceIds && data.deviceIds.length) {
                data.deviceIds.forEach((deviceId) => {
                  window.open(`/devices/${deviceId}`, "_blank");
                });
              }
            }
          );
          resetForm();
        } else if (data.status === "queued" || data.status === "processing") {
          setTimeout(() => {
            checkEnrollment(deviceEnrollmentId);
          }, 1000);
        } else {
          setLoadingState(false);
          showToast("Enrollment failed. Please try again.", Intent.DANGER);
        }
      } catch (error) {
        setLoadingState(false);
        showToast("Enrollment failed. Please try again.", Intent.DANGER);
      }
    },
    [
      checkEnrollmentStatus,
      onEnrollmentSuccess,
      showToast,
      setLoadingState,
      resetForm,
    ]
  );

  const handleEnrollClick = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      let isValid = true;
      if (!selectedBrand) {
        setBrandIntent(Intent.DANGER);
        setBrandValidationError("Brand selection is mandatory.");
        isValid = false;
      } else {
        setBrandValidationError("");
      }

      if (!username.trim()) {
        setUsernameIntent(Intent.DANGER);
        setUsernameValidationError("Username cannot be blank.");
        isValid = false;
      } else {
        setUsernameValidationError("");
      }

      if (!password) {
        setPasswordIntent(Intent.DANGER);
        setPasswordValidationError("Password cannot be blank.");
        isValid = false;
      } else {
        setPasswordValidationError("");
      }

      if (!isValid) {
        return;
      }

      setLoadingState(true);

      try {
        const deviceEnrollmentId = await enrollDevice({
          brand: selectedBrand?.id || -1,
          username,
          password,
        });
        if (!deviceEnrollmentId) {
          setLoadingState(false);
          showToast("Enrollment failed. Please try again.", Intent.DANGER);
          return;
        }
        await checkEnrollment(deviceEnrollmentId);
      } catch (error) {
        setLoadingState(false);
        showToast("Enrollment failed. Please try again.", Intent.DANGER);
      }
    },
    [
      selectedBrand,
      username,
      password,
      enrollDevice,
      checkEnrollment,
      setLoadingState,
      showToast,
    ]
  );

  const handleClose = useCallback(
    (event?: React.SyntheticEvent<HTMLElement, Event>) => {
      if (!isLoading) {
        resetForm();
      }
      if (event && onClose) {
        onClose(event);
      }
    },
    [isLoading, onClose, resetForm]
  );

  return (
    <>
      <Dialog
        className={`bp5-dark ${classes.dialog}`}
        {...dialogProps}
        onClose={handleClose}
      >
        {isLoading && (
          <div
            style={{
              background: "rgba(0,0,0,.5",
              width: "400px",
              height: "359px",
              position: "absolute",
              margin: -24,
              borderRadius: 3,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 310,
                marginLeft: 24,
                width: 100,
              }}
            >
              <Spinner size={20} style={{ marginRight: 10 }} />
              <div style={{}}>Enrolling...</div>
            </div>
          </div>
        )}
        <H4>Enroll new device</H4>
        <div style={{ marginTop: "8px" }}>
          <FormGroup label="Brand" disabled={isLoading}>
            <div style={{ minWidth: "180px" }}>
              <Select<Brand>
                items={brands}
                itemRenderer={renderBrand}
                noResults={
                  <MenuItem
                    disabled={true}
                    text="No results."
                    roleStructure="listoption"
                  />
                }
                onItemSelect={handleBrandSelect}
              >
                <Button
                  className={
                    brandIntent === Intent.DANGER ? classes.selectError : ""
                  }
                  disabled={isLoading}
                  fill
                  alignText="left"
                  text={selectedBrand?.name ?? "Select a brand"}
                  rightIcon="double-caret-vertical"
                  value="Select a brand"
                />
              </Select>
            </div>
            <div className={classes.validationError}>
              {brandValidationError}
            </div>
          </FormGroup>
          <FormGroup label="Username" disabled={isLoading}>
            <InputGroup
              type="username"
              disabled={isLoading}
              value={username}
              autoComplete="new-username"
              onChange={(e) => {
                const isEmpty = e.target.value === "";
                const intent = isEmpty ? Intent.DANGER : Intent.NONE;
                const error = isEmpty ? "Username cannot be blank." : "";
                setUsernameIntent(intent);
                setUsernameValidationError(error);
                setUsername(e.target.value);
              }}
              intent={usernameIntent}
            />
            <div className={classes.validationError}>
              {usernameValidationError}
            </div>
          </FormGroup>
          <FormGroup label="Password" disabled={isLoading}>
            <InputGroup
              type="password"
              disabled={isLoading}
              value={password}
              autoComplete="new-password"
              onChange={(e) => {
                const isEmpty = e.target.value === "";
                const intent = isEmpty ? Intent.DANGER : Intent.NONE;
                const error = isEmpty ? "Password cannot be blank." : "";
                setPasswordIntent(intent);
                setPasswordValidationError(error);
                setPassword(e.target.value);
              }}
              intent={passwordIntent}
            />
            <div className={classes.validationError}>
              {passwordValidationError}
            </div>
          </FormGroup>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            outlined
            intent="danger"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <div style={{ width: "8px" }} />
          <Button
            outlined
            intent="primary"
            onClick={handleEnrollClick}
            disabled={isLoading}
          >
            Enroll
          </Button>
        </div>
      </Dialog>
    </>
  );
}
