import { Search } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Ec1DataGrid from "../../../ui/components/Ec1DataGrid.react";
import PlatformSiteCustomerClickableLabel from "../customers/PlatformSiteCustomerClickableLabel.react";
import { useEnterpriseLabels } from "../enterpriseLabels/useEnterpriseLabels";
import { Invoice, useInvoices } from "./invoices";

export default function PlatformInvoiceListView() {
  const { fetchInvoices } = useInvoices();
  const navigate = useNavigate();
  const { selectedLabelObj } = useEnterpriseLabels();

  const handleActionClick = useCallback(
    (invoiceId: string) => {
      navigate(`/invoices/${invoiceId}`);
    },
    [navigate]
  );

  const handleContextMenuItemClick = useCallback(
    (action: string, id: string | null) => {
      if (action === "view" && id) {
        handleActionClick(id);
      }
    },
    [handleActionClick]
  );

  const handleViewContractClick = useCallback(
    (contractId: string) => {
      navigate(`/contracts/${contractId}`);
    },
    [navigate]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "invoice_number",
        headerName:
          selectedLabelObj?.invoice_uid_field_name ?? "Invoice Number",
        flex: 1,
      },
      {
        field: "contract",
        headerName:
          selectedLabelObj?.contract_uid_field_name ?? "Contract Number",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "right",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                marginRight: 4,
                textAlign: "right",
                width: 30,
              }}
            >
              {params.value}
            </div>
            <div style={{ height: 14, marginTop: -2 }}>
              <IconButton
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleViewContractClick(params.value);
                }}
                aria-label="view contract"
              >
                <Search style={{ color: "#ccc", fontSize: 12 }} />
              </IconButton>
            </div>
          </div>
        ),
      },
      {
        field: "end_date",
        headerName: "Invoice Date",
        flex: 1,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "customer",
        headerName: "Customer",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
          <PlatformSiteCustomerClickableLabel customerId={params.value} />
        ),
      },
      { field: "cups", headerName: "CUPS", flex: 1 },
      { field: "total_amount", headerName: "Total Amount", flex: 1 },
    ],
    [handleViewContractClick, selectedLabelObj]
  );

  const contextMenuOptions = useMemo(
    () => [
      {
        label: "View Invoice",
        icon: <VisibilityIcon />,
        action: (id: string | null) => handleContextMenuItemClick("view", id),
      },
    ],
    [handleContextMenuItemClick]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        marginTop: "32px",
      }}
    >
      <Ec1DataGrid<Invoice>
        id="gridInvoices"
        get={fetchInvoices}
        columns={columns}
        contextMenuOptions={contextMenuOptions}
        getRowId={(row: Invoice) => row.id}
        onRowClick={(invoice, event) => {
          handleActionClick(String(invoice.id));
        }}
      />
    </div>
  );
}
