import { ReactNode } from "react";
import { createUseStyles } from "react-jss";

interface PlatformListViewProps {
  title?: string;
  children: ReactNode;
}

const useStyles = createUseStyles({
  wrapper: {
    padding: "24px 24px 8px 24px",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    minHeight: 0,
  },
  title: {
    fontFamily: "Barlow",
    letterSpacing: 1,
    fontWeight: 600,
    fontSize: 24,
  },
});

export default function PlatformListView({
  title,
  children,
}: PlatformListViewProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
      <div
        style={{
          flex: 1,
          minHeight: 0,
        }}
      >
        {children}
      </div>
    </div>
  );
}
