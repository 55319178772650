import { Icon } from "@blueprintjs/core";
import mapboxgl from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { createUseStyles } from "react-jss";
import { MAPBOX_TOKEN, MapLocation } from "../../barcelonaLocations";
import useResizeObserver from "../useResizeObserver";

mapboxgl.accessToken = MAPBOX_TOKEN;

const useStyles = createUseStyles({
  popup: {
    color: "black",
    fontFamily: "Barlow",
  },
});

type Props = { locations: MapLocation[] };

export default function PlatformAssistantMessageMap({ locations }: Props) {
  console.log(locations);
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [lng, setLng] = useState(-3.8443426);
  const [lat, setLat] = useState(40.4380986);
  const [zoom, setZoom] = useState(5);

  const dimensions = useResizeObserver(mapContainer);

  const classes = useStyles();

  useEffect(() => {
    if (map.current || !mapContainer.current) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v11",
      center: [lng, lat],
      zoom: zoom,
      projection: {
        name: "mercator",
      },
    });

    // Add markers to the map

    locations.forEach((marker) => {
      const el = document.createElement("div");
      const root = createRoot(el);

      root.render(
        <Icon
          icon="drive-time"
          color="#238C2C"
          size={16}
          style={{
            backgroundColor: "#383E47",
            padding: "4px",
            borderRadius: "8px",
          }}
        />
      );

      const popup = new mapboxgl.Popup({
        offset: 12,
        closeButton: false,
        className: classes.popup,
      }).setText(marker.name);
      if (map.current) {
        new mapboxgl.Marker()
          .setLngLat([marker.lng, marker.lat])
          .setPopup(popup)
          .addTo(map.current);
      }
    });

    map.current.on("move", () => {
      if (map.current === null) {
        return;
      }
      setLng(parseFloat(map.current.getCenter().lng.toFixed(4)));
      setLat(parseFloat(map.current.getCenter().lat.toFixed(4)));
      setZoom(parseFloat(map.current.getZoom().toFixed(2)));
    });
  });

  useEffect(() => {
    map.current?.resize();
  }, [dimensions]);

  return (
    <div
      ref={mapContainer}
      className="dark"
      style={{ height: "100%", width: "100%" }}
    />
  );
}
