import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { EnterpriseLabel } from "../../../__generated__/types/EnterpriseLabel";
import {
  useAuthHeaders,
  useAuthentication,
} from "../common/authentication/authentication";

const ENTERPRISE_LABELS = "ENTERPRISE_LABEL_LS_KEY";
const SELECTED_ENTERPRISE_LABEL = "SELECTED_ENTERPRISE_LABEL";
const SELECTED_ENTERPRISE_LABEL_OBJECT = "SELECTED_ENTERPRISE_LABEL_OBJECT";

interface IEnterpriseLabelContext {
  enterpriseLabels: EnterpriseLabel[];
  selectedLabel: string;
  selectedLabelObj: EnterpriseLabel;
  hasContracts?: boolean;
  hasInvoices?: boolean;
  handleSetSelectedLabel: (
    newLabel: string,
    newLabelObj: EnterpriseLabel
  ) => void;
}

const EnterpriseLabelContext = createContext<
  IEnterpriseLabelContext | undefined
>(undefined);

interface IEnterpriseLabelProviderProps {
  children: ReactNode;
}

export const EnterpriseLabelProvider: React.FC<
  IEnterpriseLabelProviderProps
> = ({ children }) => {
  const headers = useAuthHeaders();
  const { isLoggedIn } = useAuthentication();

  const [enterpriseLabels, setEnterpriseLabels] = useState<EnterpriseLabel[]>(
    []
  );

  const [selectedLabel, setSelectedLabel] = useState(
    localStorage.getItem(SELECTED_ENTERPRISE_LABEL) ?? enterpriseLabels[0]?.name
  );

  const [selectedLabelObj, setSelectedLabelObj] = useState(
    localStorage.getItem(ENTERPRISE_LABELS)
      ? (
          JSON.parse(
            localStorage.getItem(ENTERPRISE_LABELS) ?? "{}"
          ) as EnterpriseLabel[]
        )[0]
      : enterpriseLabels[0]
  );

  const [hasContracts, hasInvoices] = useMemo(() => {
    const matched = enterpriseLabels.filter((e) => e.name === selectedLabel);
    if (matched.length > 0) {
      return [matched[0].has_contracts, matched[0].has_invoices];
    }
    return [false, false];
  }, [enterpriseLabels, selectedLabel]);

  const handleSetSelectedLabel = (
    newLabel: string,
    newLabelObj: EnterpriseLabel
  ) => {
    setSelectedLabel(newLabel);
    localStorage.setItem(SELECTED_ENTERPRISE_LABEL, newLabel);

    localStorage.setItem(
      SELECTED_ENTERPRISE_LABEL_OBJECT,
      JSON.stringify(newLabelObj)
    );
    setSelectedLabelObj(newLabelObj);
  };

  useEffect(() => {
    if (
      selectedLabel !== localStorage.getItem(SELECTED_ENTERPRISE_LABEL) &&
      selectedLabel
    ) {
      localStorage.setItem(SELECTED_ENTERPRISE_LABEL, selectedLabel);
      localStorage.setItem(
        SELECTED_ENTERPRISE_LABEL_OBJECT,
        JSON.stringify(selectedLabelObj)
      );
    }
  }, [selectedLabel, selectedLabelObj]);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function fetchLabelsAsync() {
      if (!isLoggedIn) {
        return;
      }
      setIsFetching(true);
      // Try to load labels from localStorage first
      const storedLabels = localStorage.getItem(ENTERPRISE_LABELS);
      const selectedLabelObjStr = localStorage.getItem(
        SELECTED_ENTERPRISE_LABEL_OBJECT
      );
      if (storedLabels) {
        const labels = JSON.parse(storedLabels) as EnterpriseLabel[];
        setEnterpriseLabels(labels);
        if (labels && labels.length > 0) {
          handleSetSelectedLabel(
            localStorage.getItem(SELECTED_ENTERPRISE_LABEL) ?? labels[0]?.name,
            selectedLabelObjStr
              ? (JSON.parse(selectedLabelObjStr) as EnterpriseLabel)
              : labels[0]
          );
        }
      }
      // fetch in case there are updates
      const url = "/api/enterprise-labels/";
      const response = await fetch(url, { method: "get", headers });
      if (response.ok) {
        const result = await response.json();
        const fetchedLabels = result.results as EnterpriseLabel[];

        setEnterpriseLabels(fetchedLabels);
        localStorage.setItem(ENTERPRISE_LABELS, JSON.stringify(fetchedLabels));

        if (!selectedLabel && fetchedLabels && fetchedLabels.length > 0) {
          handleSetSelectedLabel(fetchedLabels[0].name, fetchedLabels[0]);
        }
      } else {
        console.error(await response.text());
      }
      setIsFetching(false);
    }
    if (!isFetching) {
      fetchLabelsAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, isLoggedIn]); // do not add isFetching

  return (
    <EnterpriseLabelContext.Provider
      value={{
        enterpriseLabels,
        selectedLabel,
        selectedLabelObj,
        handleSetSelectedLabel,
        hasContracts,
        hasInvoices,
      }}
    >
      {children}
    </EnterpriseLabelContext.Provider>
  );
};

export function useEnterpriseLabels(): IEnterpriseLabelContext {
  const context = useContext(EnterpriseLabelContext);
  if (context === undefined) {
    throw new Error(
      "useEnterpriseLabels must be used within an ContextProvider"
    );
  }
  return context;
}
