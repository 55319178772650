import React from "react";
import { createUseStyles } from "react-jss";

interface FieldProps {
  label: string;
  value: string | React.ReactNode;
  href?: string;
  onClick?: () => void;
}

const useStyles = createUseStyles({
  field: {
    display: "flex",
    fontFamily: "Barlow",
    alignItems: "center",
  },
  label: {
    fontWeight: "600",
  },
  value: {
    marginLeft: "8px",
  },
  link: {
    color: "#8abbff",
    textDecoration: "underline",
    cursor: "pointer",
    fontFamily: "Barlow",
  },
});

export const PlatformHeaderField: React.FC<FieldProps> = ({
  href,
  label,
  value,
  onClick,
}) => {
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent) => {
    if (!href && onClick) {
      e.preventDefault();
      onClick();
    }
  };

  const ValueComponent = () => {
    if (href) {
      return (
        <a href={href} className={classes.link}>
          {value}
        </a>
      );
    } else if (onClick) {
      return (
        <span onClick={handleClick} className={classes.link}>
          {value}
        </span>
      );
    } else {
      return <span>{value}</span>;
    }
  };

  return (
    <div className={classes.field}>
      <div className={classes.label}>{label + ":"} </div>
      <div className={classes.value}>
        <ValueComponent />
      </div>
    </div>
  );
};
