import { Button, H3 } from "@blueprintjs/core";
import { EnrolledAsset } from "@ec1/types/EnrolledAsset";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "src/common/fetcher/effects";
import { GateKeeper } from "../../../gk/__generated__/gatekeepers";
import useGatekeeper from "../../../gk/useGatekeeper";
import SpinningLogo from "../../../ui/logo/SpinningLogo.react";
import { useVendorAccount } from "./vendorAccounts";

export default function PlatformSiteVendorAccountDetailViewPage() {
  const canUseVendorAccounts = useGatekeeper(
    GateKeeper.CAN_USE_VENDOR_ACCOUNTS
  );

  const { accountId } = useParams<{ accountId: string }>();
  const parsedAccountId = parseInt(accountId ?? "");

  const { vendorAccount, fetchVendorAccount } =
    useVendorAccount(parsedAccountId);

  const fetchAssetsUrl = useMemo(() => {
    if (parsedAccountId) {
      return `/api/asset/vendor/account/enrolled-asset/?vendor_account=${parsedAccountId}`;
    }
    return null;
  }, [parsedAccountId]);

  const { data: enrolledAssetData } = useFetch<{ results: EnrolledAsset[] }>(
    fetchAssetsUrl
  );

  useEffect(() => {
    const interval = setInterval(() => {
      fetchVendorAccount(parsedAccountId);
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [parsedAccountId, fetchVendorAccount]);
  const navigate = useNavigate();

  if (!canUseVendorAccounts) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        padding: "24px",
      }}
    >
      {vendorAccount !== null ? (
        <div style={{ flex: 1, fontFamily: "Barlow" }}>
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <div
              style={{
                borderRadius: 6,
                padding: 24,
                paddingBottom: 18,
                fontFamily: "Barlow",
                width: "100%",
                backgroundColor: "#252A31",
                fontFeatureSettings: "Barlow",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginLeft: 5,
                  fontFamily: "Barlow",
                }}
              >
                <div
                  style={{
                    fontFamily: "Barlow",
                    letterSpacing: 1,
                    fontWeight: 700,
                    fontSize: 24,
                    color: "rgb(211, 216, 222)",
                    marginBottom: 16,
                    flex: 1,
                  }}
                >
                  <H3>
                    VendorAccount UID: {`${vendorAccount?.vendor_account_uid}`}
                  </H3>
                </div>
                <Button
                  rightIcon={"small-plus"}
                  icon={"drive-time"}
                  outlined
                  onClick={() => {
                    navigate(
                      `/vendor/account/${parsedAccountId}/asset-enrollment/`
                    );
                  }}
                >
                  Enrol new devices
                </Button>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "12px" }}>
            {enrolledAssetData?.results &&
              enrolledAssetData.results.map((asset, idx) => (
                <div
                  key={idx}
                  style={{ marginTop: "12px", border: "1px", fontSize: "12px" }}
                >
                  {JSON.stringify(asset)}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            margin: "auto 0",
          }}
        >
          <SpinningLogo isLoadingSpinner={true} height="50px" />
        </div>
      )}
    </div>
  );
}
