import { PropertyTariffMapping } from "@ec1/types/PropertyTariffMapping";
import { Tariff } from "@ec1/types/Tariff";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useAuthHeaders } from "../common/authentication/authentication";

export function getCurrentTariffId(
  tariffData?: PropertyTariffMapping[]
): number | null {
  if (!tariffData) {
    return null;
  }
  const currentDate = DateTime.now();

  for (const mapping of tariffData) {
    const validFrom = DateTime.fromISO(mapping.valid_from);
    const validTo = DateTime.fromISO(mapping.valid_to);

    if (currentDate >= validFrom && currentDate <= validTo) {
      return mapping.tariff ?? null;
    }
  }

  return null;
}

export function getCurrencySymbol(currencyCode: string): string {
  const currencySymbols: { [key: string]: string } = {
    GBP: "£",
    EUR: "€",
    EURO: "€",
    USD: "$",
    JPY: "¥",
    // Add more currency codes and symbols as needed
  };

  return currencySymbols[currencyCode] || currencyCode;
}

export function useTariff(tariffId: number) {
  const headers = useAuthHeaders();
  const [tariff, setTariff] = useState<Tariff | null>(null);

  useEffect(() => {
    async function fetchTariffAsync() {
      const response = await fetch(`/api/tariffs/${tariffId}/`, { headers });
      const data = (await response.json()) as Tariff;
      setTariff(data);
    }

    if (tariffId) {
      fetchTariffAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffId]);

  return tariff;
}

export function useTariffAutocomplete(query: string | null, limit = 10) {
  const headers = useAuthHeaders();
  const [suggestions, setSuggestions] = useState<Tariff[] | null>(null);
  const fetchController = useRef<AbortController | null>(null);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      // If there's an ongoing fetch, abort it before starting a new one
      if (fetchController.current) {
        fetchController.current.abort();
      }

      fetchController.current = new AbortController();
      const { signal } = fetchController.current;

      // Convert to function expression
      const fetchTariffAsync = async () => {
        let url = `/api/tariffs/?limit=10`;
        if (query) {
          url += `&search=${query}`;
        }
        try {
          const response = await fetch(url, { headers, signal });
          if (response.ok) {
            const data = (await response.json())["results"] as Tariff[];
            setSuggestions(data);
          } else {
            throw new Error("Failed to fetch tariffs");
          }
        } catch (error) {
          if (error instanceof Error) {
            console.error("Fetch failed:", error.message); // Access message safely
          } else {
            console.error("An unexpected error occurred");
          }
        }
      };

      fetchTariffAsync();
    }, 300); // Debounce delay of 300ms

    return () => {
      // Cleanup both the timeout and the fetch
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      if (fetchController.current) {
        fetchController.current.abort();
      }
    };
  }, [query, headers]);

  return suggestions;
}
