import { Divider, H5, Popover, Spinner } from "@blueprintjs/core";
import { Tariff } from "@ec1/types/Tariff";
import { useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../fetcher/effects";
import PlatformTableFilterChip from "./PlatformTableFilterChip.react";

export interface IFilterDefinition {
  id: string;
  name: string;
  operators: string[];
}

export interface IFilter {
  filterDefinitionId: string;
  operator?: string;
  value?: string | undefined;
  valid?: boolean | undefined;
}

const useStyles = createUseStyles({
  filterBar: {
    width: "100%",
    minHeight: "40px",
    backgroundColor: "#161a1f",
    borderRadius: 4,
    border: "1px solid #383E47",
    display: "flex",
    alignItems: "center",
    padding: "4px 8px",
  },
  filterWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  popoverContent: {
    padding: "8px",
    fontFamily: "Barlow",
  },
  popoverHeader: {
    margin: 0,
    marginBottom: "4px",
  },
  filterItem: {
    padding: "8px 6px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#184A90",
    },
  },
  filterChip: {
    fontFamily: "Barlow",
    fontSize: "12px",
    backgroundColor: "#404854",
    padding: "4px",
    color: "white",
    borderRadius: "2px",
    display: "flex",
    alignItems: "center",
  },
  filterSelect: {
    fontSize: "12px",
    fontFamily: "Barlow",
    marginLeft: "4px",
  },
  filterOption: {
    fontFamily: "Barlow",
  },
  searchInput: {
    background: "transparent",
    border: "none",
    color: "white",
    fontFamily: "Barlow",
    fontSize: "12px",
    outline: "none",
    padding: "4px",
    "&::placeholder": {
      color: "#8A9BA8",
    },
  },
  loadingContainer: {
    display: "flex",
  },
  loadingText: {
    marginLeft: "8px",
  },
  showAllMatches: {
    marginLeft: "8px",
    fontSize: "12px",
    marginTop: "8px",
  },
  removeFilterButton: {
    marginLeft: "4px",
  },
  searchInputContainer: {
    //backgroundColor: "black",
    width: "100%",
  },
  matchesHeader: {
    marginTop: "8px",
  },
});

interface PlatformPowerSearchBarProps {
  filters: IFilterDefinition[];
  handleFilterChange: (newFilters: IFilter[]) => void;
}

function validateSelectedFilters(filters: IFilter[]) {
  return [...filters.map((f) => ({ ...f, valid: f?.value ? true : false }))];
}

export default function PlatformPowerSearchBar({
  filters: filterDefinitions,
  handleFilterChange,
}: PlatformPowerSearchBarProps) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<IFilter[]>([]);

  const [mostRecentValidFilters, setMostRecentValidFilters] = useState<
    IFilter[]
  >([]);

  useEffect(() => {
    handleFilterChange(mostRecentValidFilters);
  }, [handleFilterChange, mostRecentValidFilters]);

  const [isOpen, setIsOpen] = useState(false);
  const latestInputRef = useRef<HTMLInputElement>(null);

  const searchUrl = useMemo(() => {
    return searchQuery ? `/api/tariffs/?limit=5&search=${searchQuery}` : null;
  }, [searchQuery]);

  const { data: searchResultsData, isLoading: isLoadingSearchResults } =
    useFetch<{ results: Tariff[]; count: number }>(searchUrl);

  const handleFilterDefinitionClick = (filterDefinition: IFilterDefinition) => {
    setSelectedFilters([
      ...selectedFilters,
      {
        filterDefinitionId: filterDefinition.id,
        value: "",
        valid: false,
      },
    ]);
    setIsOpen(false);
  };

  const handleRemoveFilter = (indexToRemove: number) => {
    setSelectedFilters(
      selectedFilters.filter((_, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    if (searchQuery?.length > 0) {
      setIsOpen(true);
    }
  }, [searchQuery]);

  useEffect(() => {
    latestInputRef.current?.focus();
  }, [selectedFilters]);

  const handleSelectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Backspace" &&
      searchQuery === "" &&
      selectedFilters.length > 0
    ) {
      handleRemoveFilter(selectedFilters.length - 1);
    } else if (e.key === "Escape") {
      e.preventDefault();
      setIsOpen(false);
    } else if (e.key === " ") {
      e.stopPropagation();
      setIsOpen(true);
    } else {
      setIsOpen(true);
    }
  };

  const selectedFiltersAreValid = useMemo(() => {
    const result =
      selectedFilters.filter((f) => f.valid === false).length === 0;
    if (result) {
      setMostRecentValidFilters(selectedFilters);
    }
    return result;
  }, [selectedFilters]);

  return (
    <Popover
      interactionKind="click"
      enforceFocus={false}
      position="bottom"
      onClose={() => setIsOpen(false)}
      autoFocus={false}
      matchTargetWidth
      isOpen={isOpen && selectedFiltersAreValid}
      content={
        <div className={classes.popoverContent}>
          <H5 className={classes.popoverHeader}>Filter by</H5>
          {filterDefinitions.map((f, idx) => (
            <div
              key={idx}
              className={classes.filterItem}
              onClick={() => handleFilterDefinitionClick(f)}
            >
              {f.name}
            </div>
          ))}

          {(searchResultsData?.results?.length || isLoadingSearchResults) && (
            <>
              <Divider />
              <H5
                className={`${classes.popoverHeader} ${classes.matchesHeader}`}
              >
                Matches
              </H5>
              {searchResultsData?.results?.map((t, i) => (
                <div
                  key={i}
                  className={classes.filterItem}
                  onClick={() => navigate(`/tariffs/${t.id}`)}
                >
                  {t.display_name}
                </div>
              ))}
              <div>
                {isLoadingSearchResults ? (
                  <div className={classes.loadingContainer}>
                    <Spinner size={16} />
                    <div className={classes.loadingText}>
                      Loading matches...
                    </div>
                  </div>
                ) : (
                  <>
                    <Divider />
                    <div className={classes.showAllMatches}>
                      {`Show all ${searchResultsData?.count ?? "-"} matches`}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      }
    >
      <div className={classes.filterBar} onClick={() => setIsOpen(true)}>
        <div className={classes.filterWrapper}>
          {selectedFilters.map((filter, index) => (
            <PlatformTableFilterChip
              filterDefinition={
                filterDefinitions.filter(
                  (fd) => fd.id === filter.filterDefinitionId
                )[0]
              }
              key={index}
              onChange={(value) => {
                setSelectedFilters((prev) => {
                  prev[index].value = value;
                  return validateSelectedFilters([...prev]);
                });
              }}
              onOperatorChange={(op) => {
                setSelectedFilters((prev) => {
                  prev[index].operator = op;
                  return validateSelectedFilters([...prev]);
                });
              }}
              filter={filter}
              onRemove={() => handleRemoveFilter(index)}
              inputRef={
                index === selectedFilters.length - 1
                  ? latestInputRef
                  : undefined
              }
            />
          ))}
          <div
            style={{
              flex: 1,
            }}
          >
            <input
              className={`${classes.searchInput} ${classes.searchInputContainer}`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={selectedFilters.length === 0 ? "Search" : ""}
            />
          </div>
        </div>
      </div>
    </Popover>
  );
}
