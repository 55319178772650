import { Cell, Column, RowHeaderCell, Table2 } from "@blueprintjs/table";
import { DateTime } from "luxon";
import { useMemo } from "react";
import Markdown from "react-markdown";
import PlatformAssistantCategoryLineChart from "./PlatformAssistantCategoryLineChart.react";
import PlatformAssistantDataset from "./PlatformAssistantDataset.react";
import PlatformAssistantHistogramChart from "./PlatformAssistantHistogramChart.react";
import PlatformAssistantLineChart from "./PlatformAssistantLineChart.react";
import PlatformAssistantMessageExportWrapper from "./PlatformAssistantMessageExportWrapper.react";
import PlatformAssistantMessageMap from "./PlatformAssistantMessageMap.react";
import { IMessage } from "./assistant";

export function parse_json_if_possible(text: string): any | null {
  try {
    console.log(text);
    return JSON.parse(text);
  } catch (e) {
    console.log(e);
    return null;
  }
}

type Props = {
  message: IMessage;
  showSuggestions?: boolean;
  onSetMessage?: (message: string) => void;
};

export default function PlatformAIAssistantMessage({
  message,
  showSuggestions = false,
  onSetMessage,
}: Props) {
  const parsedMessage = useMemo(() => {
    return parse_json_if_possible(message.text);
  }, [message.text]);

  const messageTextBody = useMemo(() => {
    if (parsedMessage !== null) {
      return String(parsedMessage?.text_markdown ?? parsedMessage?.text ?? "");
    } else {
      return message.text;
    }
  }, [message, parsedMessage]);

  const messageAdditionalTextBody = useMemo(() => {
    if (parsedMessage !== null) {
      return String(parsedMessage?.additional_text ?? "");
    } else {
      return "";
    }
  }, [parsedMessage]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#2F343C",
          fontFamily: "barlow",
          margin: "10px 0px",
          marginBottom: "12px",
          borderRadius: "2px",
          padding: "12px 16px",
          paddingBottom: "32px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontWeight: "700" }}>
            {message.role === "user" ? "Alejandro" : "Electryone AI Assistant"}
          </div>
          <div
            style={{
              fontSize: "12px",
              color: "#D3D8DE",
            }}
          >
            {DateTime.fromISO(message.completion_time).toRelative()}
          </div>
        </div>
        <div style={{ marginTop: "24px" }}>
          <Markdown>{messageTextBody}</Markdown>
        </div>

        <div>
          {(parsedMessage?.widgets ?? []).map((el: any) => {
            const data = el.dataset ?? null;
            return el.dataframe_source !== null ? (
              <div
                style={{ width: "100%", overflow: "hidden", marginTop: "16px" }}
              >
                <PlatformAssistantMessageExportWrapper
                  uuid={el.dataframe_source}
                >
                  <PlatformAssistantDataset
                    uuid={el.dataframe_source}
                    displayType={el.widget_type}
                  />
                </PlatformAssistantMessageExportWrapper>
              </div>
            ) : null;

            /* eslint-disable no-unreachable */

            if (data !== null) {
              const parsedData = parse_json_if_possible(data).slice(0, 25);
              if (parsedData?.length > 0) {
                if (el.type === "map") {
                  return (
                    <PlatformAssistantMessageExportWrapper uuid={el.uuid}>
                      <PlatformAssistantMessageMap locations={parsedData} />
                    </PlatformAssistantMessageExportWrapper>
                  );
                }

                if (el.type === "histogram") {
                  return (
                    <PlatformAssistantMessageExportWrapper uuid={el.uuid}>
                      <PlatformAssistantHistogramChart
                        data={parsedData}
                        chartTitle={el.user_friendly_title}
                        xAxisTitle={el.xAxisTitle}
                        yAxisTitle={el.yAxisTitle}
                        tooltipSuffix={el.tooltipSuffix}
                      />
                    </PlatformAssistantMessageExportWrapper>
                  );
                }

                if (el.type === "linechart") {
                  console.log(Object.keys(parsedData[0]).includes("category"));
                  if (Object.keys(parsedData[0]).includes("category")) {
                    return (
                      <PlatformAssistantMessageExportWrapper uuid={el.uuid}>
                        <PlatformAssistantCategoryLineChart
                          data={parsedData}
                          chartTitle={el.user_friendly_title}
                          xAxisTitle={el.xAxisTitle}
                          yAxisTitle={el.yAxisTitle}
                          tooltipSuffix={el.tooltipSuffix}
                        />
                      </PlatformAssistantMessageExportWrapper>
                    );
                  } else {
                    return (
                      <PlatformAssistantMessageExportWrapper uuid={el.uuid}>
                        <PlatformAssistantLineChart
                          data={parsedData}
                          chartTitle={el.user_friendly_title}
                          xAxisTitle={el.xAxisTitle}
                          yAxisTitle={el.yAxisTitle}
                          tooltipSuffix={el.tooltipSuffix}
                        />
                      </PlatformAssistantMessageExportWrapper>
                    );
                  }
                }

                const columns = Object.keys(parse_json_if_possible(data)[0]);
                const numberOfRows = parsedData.length;
                return (
                  <PlatformAssistantMessageExportWrapper uuid={el.uuid}>
                    <Table2
                      numRows={numberOfRows}
                      rowHeaderCellRenderer={(rowIndex) => (
                        <RowHeaderCell name={`${rowIndex + 1}`} />
                      )}
                    >
                      {columns.map((col) => (
                        <Column
                          name={col}
                          cellRenderer={(rowIndex) => (
                            <Cell>{parsedData[rowIndex][col]}</Cell>
                          )}
                        />
                      ))}
                    </Table2>
                  </PlatformAssistantMessageExportWrapper>
                );
              }
            }
            return null;
          })}
          {messageAdditionalTextBody ? (
            <div style={{ marginTop: "8px" }}>
              <Markdown>{messageAdditionalTextBody}</Markdown>
            </div>
          ) : null}
        </div>
      </div>
      {showSuggestions ? (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {(parsedMessage?.followup_user_suggestions ?? []).map(
            (suggestion: string) => (
              <div
                style={{
                  width: "30%",
                  backgroundColor: "#404854",
                  padding: "16px",
                  fontFamily: "Barlow",
                  borderRadius: "12px",
                  cursor: "pointer",
                  marginBottom: "12px",
                }}
                onClick={
                  onSetMessage !== undefined
                    ? () => {
                        onSetMessage(suggestion);
                      }
                    : undefined
                }
              >
                {suggestion}
              </div>
            )
          )}
        </div>
      ) : null}
    </div>
  );
}
