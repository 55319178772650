import { Icon } from "@blueprintjs/core";
import { useMemo } from "react";
import { Handle, Position } from "reactflow";

interface PlatformDatalayerDatasetNodeProps {
  data: { label: string; state?: string };
}

export default function PlatformDatalayerDatasetNode({
  data,
}: PlatformDatalayerDatasetNodeProps) {
  const [borderColour, backgroundColor] = useMemo(() => {
    if (data?.state === "in_progress") {
      return ["#e5a506", "#fde4a6"];
    }

    if (data?.state === "stable") {
      return ["#738091", "#DCE0E5"];
    }

    //return ["#1aa1e5", "#d1ecfa"];
    return ["#32cd3d", "#d6f5d8"];
  }, [data?.state]);
  return (
    <div
      style={{
        border: `2px solid ${borderColour}`,
        borderRadius: "2px",
        padding: 10,
        backgroundColor: backgroundColor,
        width: "240px",
      }}
    >
      <div
        style={{
          fontFamily: "Barlow",
          color: "black",
          fontSize: "12px",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Icon icon="th-list" color="#555" />
        <div style={{ marginLeft: "6px" }}>{data.label}</div>
      </div>

      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#555" }}
      />
    </div>
  );
}
