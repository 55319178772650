import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { BatteryChargingPlan } from "../../../__generated__/types/BatteryChargingPlan";
import { BatteryForecast12h } from "../../../__generated__/types/BatteryForecast12h";
import { BatteryMetrics } from "../../../__generated__/types/BatteryMetrics";
import { useAuthHeaders } from "../common/authentication/authentication";
import { usePropertyTimezone } from "../properties/properties";
import { useDevice } from "./devices";

export const BATTERY_METRICS_URL = `/api/devices/15m/battery-metrics/`;
export const BATTERY_FORECASTS_URL = `/api/devices/15m/battery-forecasts/`;
export const BATTERY_CHARGING_PLAN_URL = `/api/devices/battery-charging-plan/`;
export const PROPERTIES_URL = `/api/properties/`;

type ChartBatteryChargingPlanState =
  | "idle"
  | "grid charging"
  | "solar charging"
  | "discharging"
  | "exporting";

export interface ChartBatteryChargingPlanPoint {
  timestamp: number;
  value: number;
}

export interface ChartBatteryChargingPlanSocPoint
  extends ChartBatteryChargingPlanPoint {
  state: ChartBatteryChargingPlanState;
}

interface ChartBatteryChargingPlanPeriod {
  start: number;
  end: number;
}

interface ChartBatteryChargingPlan {
  socData: ChartBatteryChargingPlanSocPoint[];
  importPrices: ChartBatteryChargingPlanPoint[];
  exportPrices: ChartBatteryChargingPlanPoint[];
  solarPeriod: ChartBatteryChargingPlanPeriod | null;
  chargeSlots: ChartBatteryChargingPlanPeriod[];
  exportSlots: ChartBatteryChargingPlanPeriod[];
  lastMetricTimestamp: number;
}

export default function useBatteryChargingPlan(batteryId: number) {
  const headers = useAuthHeaders();
  const { device } = useDevice(batteryId);
  const property = device?.assigned_property;
  const { timezone, timezoneLabel, isTimezoneLoaded } =
    usePropertyTimezone(property);

  const [batteryChargingPlan, setBatteryChargingPlan] =
    useState<ChartBatteryChargingPlan | null>(null);
  const [isFetchingBatteryChargingPlan, setIsFetchingBatteryChargingPlan] =
    useState<boolean>(true);

  const chargingPower = device?.avg_charging_power_in_w ?? 100;
  const solarChargingPower = device?.avg_solar_charging_power_in_w ?? 400;
  const dischargingPower = device?.avg_consumption_power_in_w ?? 100;
  const batteryCapacity = device?.battery_capacity_in_wh ?? 5000;

  const dischargingPercentageIn15mins =
    ((dischargingPower / batteryCapacity) * 100) / 4;
  const chargingPercentageIn15mins =
    ((chargingPower / batteryCapacity) * 100) / 4;
  const solarChargingPercentageIn15mins =
    ((solarChargingPower / batteryCapacity) * 100) / 4;

  const fetchBatteryChargingPlan = useCallback(async () => {
    setIsFetchingBatteryChargingPlan(true);

    const localNow = DateTime.now().setZone(timezone);
    const utcNow = localNow.toUTC().toMillis();

    const startTime = localNow.startOf("day").minus({ hours: 3 }).toUTC(); // -3 hours to have extra points to fall back to if needed
    const endTime = localNow.startOf("day").plus({ days: 1 }).toUTC();
    const startTimeStr = startTime.toISO({ includeOffset: false });
    const endTimeStr = endTime.toISO({ includeOffset: false });

    const windowNow = localNow.toMillis();
    const windowStart = localNow.startOf("day").toMillis();
    const windowEnd = localNow.endOf("day").toMillis();

    const interpolatePrices = (
      prices: ChartBatteryChargingPlanPoint[],
      endTime: number
    ): ChartBatteryChargingPlanPoint[] => {
      if (prices.length === 0) {
        return prices;
      }

      const interpolatedPrices: ChartBatteryChargingPlanPoint[] = [];
      for (let i = 0; i < prices.length; i++) {
        const currentPrice = prices[i];
        const nextPrice = prices[i + 1] || {
          timestamp: endTime,
          value: currentPrice.value,
        };

        interpolatedPrices.push(currentPrice);

        const currentTime = DateTime.fromMillis(currentPrice.timestamp);
        const nextTime = DateTime.fromMillis(nextPrice.timestamp);
        let interpolationTime = currentTime.plus({ minutes: 15 });

        while (interpolationTime < nextTime) {
          interpolatedPrices.push({
            timestamp: interpolationTime.toMillis(),
            value: currentPrice.value,
          });
          interpolationTime = interpolationTime.plus({ minutes: 15 });
        }
      }

      return interpolatedPrices;
    };

    let result: ChartBatteryChargingPlan = {
      socData: [],
      importPrices: [],
      exportPrices: [],
      solarPeriod: null,
      chargeSlots: [],
      exportSlots: [],
      lastMetricTimestamp: 0,
    };

    let solarTimes = [];
    let lastMetricTimestamp = 0;
    let chargingPlan: BatteryChargingPlan | null = null;

    try {
      const metricsFetch = fetch(
        `${BATTERY_METRICS_URL}?battery=${batteryId}&utc_time__gte=${startTimeStr}&utc_time__lte=${endTimeStr}&ordering=utc_time`,
        { headers }
      );
      const forecastsFetch = fetch(
        `${BATTERY_FORECASTS_URL}?battery=${batteryId}&utc_time__gte=${startTimeStr}&utc_time__lte=${endTimeStr}&ordering=utc_time`,
        { headers }
      );
      const chargingPlanFetch = fetch(
        `${BATTERY_CHARGING_PLAN_URL}?battery=${batteryId}`,
        { headers }
      );

      let importPricesFetch, exportPricesFetch;

      if (property) {
        importPricesFetch = fetch(`${PROPERTIES_URL}${property}/prices/`, {
          headers,
        });
        exportPricesFetch = fetch(
          `${PROPERTIES_URL}${property}/export-prices/`,
          { headers }
        );
      } else {
        importPricesFetch = Promise.resolve({
          ok: true,
          json: () => Promise.resolve([]),
        });
        exportPricesFetch = Promise.resolve({
          ok: true,
          json: () => Promise.resolve([]),
        });
      }

      const [
        metricsResponse,
        forecastsResponse,
        chargingPlanResponse,
        importPricesResponse,
        exportPricesResponse,
      ] = await Promise.all([
        metricsFetch,
        forecastsFetch,
        chargingPlanFetch,
        importPricesFetch,
        exportPricesFetch,
      ]);

      if (metricsResponse.ok) {
        const data = await metricsResponse.json();
        if (data && data.results && data.results.length > 0) {
          result.socData = data.results
            .map(
              (m: BatteryMetrics) =>
                ({
                  timestamp: DateTime.fromISO(m.utc_time, {
                    zone: "utc",
                  }).toMillis(),
                  value: m.soc,
                  state:
                    m.battery_power_in_kw > 0.1 &&
                    m.solar_power_in_kw < 0.5 &&
                    m.grid_power_in_kw < -0.1
                      ? "grid charging"
                      : m.battery_power_in_kw > 0
                      ? "solar charging"
                      : "discharging",
                } as ChartBatteryChargingPlanSocPoint)
            )
            .filter(
              (p: ChartBatteryChargingPlanSocPoint) =>
                p.timestamp >= windowStart && p.timestamp <= windowNow
            );
          lastMetricTimestamp =
            result.socData[result.socData.length - 1].timestamp;
          solarTimes = data.results
            .filter((m: BatteryMetrics) => m.solar_power_in_kw > 0.5)
            .map((m: BatteryMetrics) =>
              DateTime.fromISO(m.utc_time, { zone: "utc" }).toMillis()
            );
        }
      }

      if (forecastsResponse.ok) {
        const data = await forecastsResponse.json();
        if (data && data.results && data.results.length > 0) {
          const forecastData = data.results
            .map(
              (f: BatteryForecast12h) =>
                ({
                  timestamp: DateTime.fromISO(f.utc_time, {
                    zone: "utc",
                  }).toMillis(),
                  value: 10,
                  state:
                    f.solar_power_in_kw && f.solar_power_in_kw > 0.5
                      ? "solar charging"
                      : "discharging",
                } as ChartBatteryChargingPlanSocPoint)
            )
            .filter(
              (p: ChartBatteryChargingPlanSocPoint) =>
                p.timestamp > lastMetricTimestamp && p.timestamp <= windowEnd
            );
          result.socData = result.socData.concat(
            forecastData.filter(
              (p: ChartBatteryChargingPlanSocPoint) =>
                p.timestamp > lastMetricTimestamp
            )
          );
          solarTimes = solarTimes.concat(
            data.results
              .filter(
                (f: BatteryForecast12h) =>
                  f.solar_power_in_kw && f.solar_power_in_kw > 0.5
              )
              .map((f: BatteryForecast12h) =>
                DateTime.fromISO(f.utc_time, { zone: "utc" }).toMillis()
              )
          );
        }
      }

      if (chargingPlanResponse.ok) {
        const data = await chargingPlanResponse.json();
        if (data && data.results && data.results.length > 0) {
          chargingPlan = data.results[0] as BatteryChargingPlan;

          if (chargingPlan) {
            const slotLength = 15; // 15min to match metrics

            // Convert time slots to DateTime objects in the local timezone
            const convertSlot = (slot: string) =>
              localNow.set({
                hour: parseInt(slot.split(":")[0], 10),
                minute: parseInt(slot.split(":")[1], 10),
                second: 0,
                millisecond: 0,
              });

            // Function to expand slots as slots are in 30min intervals but metrics in 15min intervals
            const expandSlots = (slots: string[] | undefined): DateTime[] => {
              if (!slots) {
                return [];
              }
              const expandedSlots: DateTime[] = [];
              slots.forEach((slot) => {
                const slotTime = convertSlot(slot);
                expandedSlots.push(slotTime);
                expandedSlots.push(slotTime.plus({ minutes: 15 }));
              });
              return expandedSlots;
            };

            const chargeSlots = expandSlots(chargingPlan.charge_slots);
            const exportSlots = expandSlots(chargingPlan.export_slots);
            const idleSlots = expandSlots(chargingPlan.idle_slots);

            // Function to determine the state based on timestamp
            const getState = (
              timestamp: number
            ): ChartBatteryChargingPlanState => {
              const dateTime = DateTime.fromMillis(timestamp, {
                zone: timezone,
              });

              const isInSlot = (slots: DateTime[]) =>
                slots.some((slot) => {
                  const slotEnd = slot.plus({ minutes: slotLength });
                  return dateTime >= slot && dateTime < slotEnd;
                });

              if (isInSlot(chargeSlots)) {
                return "grid charging";
              }
              if (isInSlot(exportSlots)) {
                return "exporting";
              }
              if (isInSlot(idleSlots)) {
                return "idle";
              }
              return "discharging";
            };

            // Update the state only for future data points
            result.socData = result.socData.map((point) => {
              if (point.timestamp > utcNow) {
                const state = getState(point.timestamp);
                return {
                  ...point,
                  state:
                    point.state === "solar charging" && state !== "exporting"
                      ? "solar charging"
                      : state,
                };
              }
              return point;
            });

            // Add charge slot periods
            if (chargingPlan.charge_slots) {
              result.chargeSlots = chargingPlan.charge_slots.map((slot) => {
                const slotTime = convertSlot(slot);
                return {
                  start: slotTime.toMillis(),
                  end: slotTime.plus({ minutes: 30 }).toMillis(),
                } as ChartBatteryChargingPlanPeriod;
              }) as ChartBatteryChargingPlanPeriod[];
            }

            // Add charge slot periods from metrics with "grid charging" state
            const metricChargeSlots: ChartBatteryChargingPlanPeriod[] = [];
            let currentChargeSlot: ChartBatteryChargingPlanPeriod | null = null;

            result.socData.forEach((point, index) => {
              if (point.state === "grid charging") {
                if (!currentChargeSlot) {
                  currentChargeSlot = {
                    start: point.timestamp,
                    end: point.timestamp,
                  };
                }
                // Extend the end time of the current charge slot
                currentChargeSlot.end = point.timestamp;
              } else {
                if (currentChargeSlot) {
                  // Add 15 minutes to the end time to include the full slot
                  currentChargeSlot.end += 15 * 60 * 1000;
                  metricChargeSlots.push(currentChargeSlot);
                  currentChargeSlot = null;
                }
              }
            });

            // Add the last charge slot if it's still open
            if (currentChargeSlot) {
              (currentChargeSlot as ChartBatteryChargingPlanPeriod).end +=
                15 * 60 * 1000;
              metricChargeSlots.push(currentChargeSlot);
            }

            // Merge the charge slots from the charging plan and metrics
            result.chargeSlots = [...result.chargeSlots, ...metricChargeSlots];

            // Sort and merge overlapping charge slots
            result.chargeSlots.sort((a, b) => a.start - b.start);
            const mergedChargeSlots: ChartBatteryChargingPlanPeriod[] = [];
            result.chargeSlots.forEach((slot) => {
              if (
                mergedChargeSlots.length === 0 ||
                slot.start > mergedChargeSlots[mergedChargeSlots.length - 1].end
              ) {
                mergedChargeSlots.push(slot);
              } else {
                mergedChargeSlots[mergedChargeSlots.length - 1].end = Math.max(
                  mergedChargeSlots[mergedChargeSlots.length - 1].end,
                  slot.end
                );
              }
            });
            result.chargeSlots = mergedChargeSlots;
          }
        }
      }

      const timestampWithNoData =
        lastMetricTimestamp +
        (chargingPlan?.socs?.length ?? 0) * 30 * 60 * 1000;

      const smoothenedSocs = [] as number[];
      if (chargingPlan?.socs?.length) {
        // Get the last metric timestamp
        const lastMetricDateTime = DateTime.fromMillis(lastMetricTimestamp, {
          zone: timezone,
        });
        const lastMetricMinute = lastMetricDateTime.minute;

        // If the last timestamp is at XX:00 or XX:30, add an extra midpoint
        if (lastMetricMinute === 0 || lastMetricMinute === 30) {
          smoothenedSocs.push(
            (result.socData[result.socData.length - 1].value +
              chargingPlan.socs[0]) /
              2
          );
        }

        // Add a midpoint for every pair of values
        for (let i = 0; i < chargingPlan.socs.length; i++) {
          smoothenedSocs.push(chargingPlan.socs[i]); // Add the original value
          if (i < chargingPlan.socs.length - 1) {
            const midpoint =
              (chargingPlan.socs[i] + chargingPlan.socs[i + 1]) / 2;
            smoothenedSocs.push(midpoint); // Add the midpoint
          } else {
            smoothenedSocs.push(chargingPlan.socs[i]); // Push the last value to be the same
          }
        }
      }

      // Update the SOC for forecast data to account for charging/discharging
      result.socData.forEach((point, i) => {
        if (point.timestamp > lastMetricTimestamp) {
          point.value = 0;
          if (point.timestamp < timestampWithNoData) {
            const index = Math.floor(
              (point.timestamp - lastMetricTimestamp) / 1000 / 60 / 15
            );
            point.value = smoothenedSocs[index] ?? 50;
          } else if (false) {
            // do nothing (?)
            // BEGINNNING OF FUTURE SETTING
            if (point.state === "grid charging") {
              point.value = Math.min(
                result.socData[i - 1].value + chargingPercentageIn15mins,
                100
              );
            } else if (
              point.state === "solar charging" ||
              point.state === "exporting"
            ) {
              point.value = Math.min(
                Math.max(
                  result.socData[i - 1].value -
                    dischargingPercentageIn15mins +
                    solarChargingPercentageIn15mins,
                  20
                ),
                100
              );
            } else if (point.state === "idle") {
              point.value = result.socData[i - 1].value;
            } else {
              point.value = Math.max(
                result.socData[i - 1].value - dischargingPercentageIn15mins,
                20
              );
            }
          }

          // END OF FUTURE SETTING
        }
      });

      // Remove any unneccessarry solar charging states due to full battery
      result.socData.forEach((point, i) => {
        if (
          point.state === "solar charging" &&
          Math.round(result.socData[i - 1].value) === 100
        ) {
          result.socData[i].state = "discharging";
        }
      });

      const exportSlots: ChartBatteryChargingPlanPeriod[] = [];
      let currentExportSlot: ChartBatteryChargingPlanPeriod | null = null;

      result.socData.forEach((point, index) => {
        if (point.state === "exporting") {
          if (!currentExportSlot) {
            currentExportSlot = {
              start: point.timestamp,
              end: point.timestamp,
            };
          }
          // Extend the end time of the current export slot
          currentExportSlot.end = point.timestamp;
        } else {
          if (currentExportSlot) {
            // Add 15 minutes to the end time to include the full slot
            currentExportSlot.end += 15 * 60 * 1000;
            exportSlots.push(currentExportSlot);
            currentExportSlot = null;
          }
        }
      });

      // Add the last export slot if it's still open
      if (currentExportSlot) {
        (currentExportSlot as ChartBatteryChargingPlanPeriod).end +=
          15 * 60 * 1000;
        exportSlots.push(currentExportSlot);
      }

      // Add the exportSlots to the result
      result.exportSlots = exportSlots;

      result.socData = result.socData.sort(
        (
          a: ChartBatteryChargingPlanSocPoint,
          b: ChartBatteryChargingPlanSocPoint
        ) => a.timestamp - b.timestamp
      );

      if (property && importPricesResponse.ok) {
        const data = await importPricesResponse.json();
        if (data && data.results && data.results.length > 0) {
          const rawImportPrices = data.results
            .map((p: any) => ({
              timestamp: DateTime.fromISO(p.utc_time, {
                zone: "utc",
              }).toMillis(),
              value: p.price_per_kwh,
            }))
            .filter(
              (p: ChartBatteryChargingPlanSocPoint) =>
                p.timestamp >= windowStart && p.timestamp <= windowEnd
            )
            .sort(
              (
                a: ChartBatteryChargingPlanPoint,
                b: ChartBatteryChargingPlanPoint
              ) => a.timestamp - b.timestamp
            );
          result.importPrices = interpolatePrices(rawImportPrices, windowEnd);
        }
      }

      if (property && exportPricesResponse.ok) {
        const data = await exportPricesResponse.json();
        if (data && data.results && data.results.length > 0) {
          const rawExportPrices = data.results
            .map((p: any) => ({
              timestamp: DateTime.fromISO(p.utc_time, {
                zone: "utc",
              }).toMillis(),
              value: p.price_per_kwh,
            }))
            .filter(
              (p: ChartBatteryChargingPlanSocPoint) =>
                p.timestamp >= windowStart && p.timestamp <= windowEnd
            )
            .sort(
              (
                a: ChartBatteryChargingPlanPoint,
                b: ChartBatteryChargingPlanPoint
              ) => a.timestamp - b.timestamp
            );
          result.exportPrices = interpolatePrices(rawExportPrices, windowEnd);
        }
      }

      if (solarTimes.length > 1) {
        solarTimes = solarTimes.sort();
        result.solarPeriod = {
          start: solarTimes[0],
          end: solarTimes[solarTimes.length - 1],
        } as ChartBatteryChargingPlanPeriod;
      }

      result.lastMetricTimestamp = lastMetricTimestamp;

      setBatteryChargingPlan(result);
    } catch (error) {
      if (error instanceof Error) {
        if (error.name !== "AbortError") {
          console.error("Fetch error:", error.message);
        } else {
          console.error("An unexpected error occurred", error);
        }
      }
    } finally {
      setIsFetchingBatteryChargingPlan(false);
    }
  }, [
    batteryId,
    chargingPercentageIn15mins,
    dischargingPercentageIn15mins,
    headers,
    property,
    solarChargingPercentageIn15mins,
    timezone,
  ]);

  useEffect(() => {
    if (isTimezoneLoaded) {
      fetchBatteryChargingPlan();
    }
  }, [fetchBatteryChargingPlan, isTimezoneLoaded]);

  return {
    device,
    batteryChargingPlan,
    isFetchingBatteryChargingPlan,
    timezone,
    timezoneLabel,
  };
}
