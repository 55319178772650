import { Icon, Popover } from "@blueprintjs/core";
import { Chip } from "@mui/material";

type CHARGING_MODE = "MANUAL" | "SMART";

interface BatteryChargingModeChipProps {
  chargingMode: CHARGING_MODE;
  dark?: boolean;
}

const ModeColor = {
  SMART: "#D1980B",
  MANUAL: "#C5CBD3",
};

const DarkModeColor = {
  SMART: "#946638",
  MANUAL: "#2F343C",
};

function getChargingModeTooltip(mode: string) {
  switch (mode) {
    case "SMART":
      return "Smart charging mode charges the battery automatically and optimizes charging based on usage patterns and electricity prices.";
    case "MANUAL":
      return "Manual mode charges the battery through user input and without optimization.";
    default:
      return "Charging mode is unknown.";
  }
}

export default function BatteryChargingModeChip({
  chargingMode,
  dark = false,
}: BatteryChargingModeChipProps) {
  const color = dark ? DarkModeColor[chargingMode] : ModeColor[chargingMode];
  return (
    <Popover
      interactionKind="hover-target"
      position="top"
      content={
        <div
          style={{
            fontFamily: "Barlow",
            padding: 10,
            maxWidth: 250,
            textAlign: "justify",
          }}
        >
          {getChargingModeTooltip(chargingMode)}
        </div>
      }
    >
      <Chip
        label={chargingMode === "SMART" ? "Smart" : "Manual"}
        style={{
          height: 24,
          marginLeft: "5px",
          border: "solid 1px",
          borderColor: color,
          backgroundColor: "transparent",
          color,
          fontWeight: "bold",
          fontSize: 12,
        }}
        icon={
          <Icon
            icon={chargingMode === "SMART" ? "gantt-chart" : "switch"}
            color={color}
          />
        }
      />
    </Popover>
  );
}
