import { Icon } from "@blueprintjs/core";
import {
  BatteryStd,
  ElectricBike,
  ElectricCar,
  Forest,
  Home,
  WbSunny,
} from "@mui/icons-material";
import React, { CSSProperties } from "react";
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";

interface PowerNodeData {
  label: string;
  subLabel: string;
  icon: string;
  color: string;
  batteryPercentage?: number;
  tooltipContent?: JSX.Element;
}

const nodeBaseStyle: CSSProperties = {
  border: "2px solid #222",
  padding: "10px",
  borderRadius: "50%",
  width: "80px",
  height: "80px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

export const SOURCE_TOP_HANDLE_ID = "1";
export const TARGET_TOP_HANDLE_ID = "11";

export const SOURCE_BOTTOM_HANDLE_ID = "2";
export const TARGET_BOTTOM_HANDLE_ID = "12";

export const SOURCE_LEFT_HANDLE_ID = "3";
export const TARGET_LEFT_HANDLE_ID = "13";

export const SOURCE_RIGHT_HANDLE_ID = "4";
export const TARGET_RIGHT_HANDLE_ID = "14";

// Custom node component
const DigitalTwinNode: React.FC<{ data: PowerNodeData }> = ({ data }) => {
  const { label, icon, color, batteryPercentage } = data;

  const IconComponent = {
    solar: WbSunny,
    home: Home,
    grid: () => <Icon icon="high-voltage-pole" size={24} />,
    forest: Forest,
    bike: ElectricBike,
    car: ElectricCar,
    battery: BatteryStd,
    // add other icons for different types as needed
  }[icon];

  return (
    <div
      style={{
        ...nodeBaseStyle,

        border: `2px solid ${color}`,
      }}
    >
      <Handle
        type="source"
        id={SOURCE_TOP_HANDLE_ID}
        position={Position.Top}
        style={{ visibility: "hidden", top: "0" }}
      />
      <Handle
        type="target"
        id={TARGET_TOP_HANDLE_ID}
        position={Position.Top}
        style={{ visibility: "hidden", top: "0" }}
      />
      <Handle
        type="source"
        id={SOURCE_BOTTOM_HANDLE_ID}
        position={Position.Bottom}
        style={{ visibility: "hidden", top: "calc(100% - 7px)" }}
      />
      <Handle
        type="target"
        id={TARGET_BOTTOM_HANDLE_ID}
        position={Position.Bottom}
        style={{ visibility: "hidden", top: "calc(100% - 7px)" }}
      />
      <Handle
        type="source"
        id={SOURCE_LEFT_HANDLE_ID}
        position={Position.Left}
        style={{ visibility: "hidden", left: 0 }}
      />
      <Handle
        type="target"
        id={TARGET_LEFT_HANDLE_ID}
        position={Position.Left}
        style={{ visibility: "hidden", left: 0 }}
      />
      <Handle
        type="source"
        id={SOURCE_RIGHT_HANDLE_ID}
        position={Position.Right}
        style={{ visibility: "hidden", left: "calc(100% - 7px)" }}
      />
      <Handle
        type="target"
        id={TARGET_RIGHT_HANDLE_ID}
        position={Position.Right}
        style={{ visibility: "hidden", left: "calc(100% - 7px)" }}
      />
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {IconComponent && <IconComponent style={{ fontSize: "24px" }} />}
          {batteryPercentage !== undefined && (
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
              {batteryPercentage}%
            </div>
          )}
        </div>
        <div
          style={{ marginTop: "8px", fontSize: "12px", fontFamily: "Barlow" }}
        >
          {label}
        </div>
      </div>
    </div>
  );
};

export default DigitalTwinNode;
