import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthHeaders } from "../common/authentication/authentication";

const URL = "/api/contracts/";

export type Contract = {
  id: number;
  contract_number: string;
  cups: string;
  start_date: Date;
  end_date: Date;
  contracted_power_kw: number;
  tariff: number;
  customer: string;
};

interface IContractContext {
  fetchContracts: (params?: {
    params: URLSearchParams;
  }) => Promise<{ rows: Contract[]; count: number }>;
  totalContracts: number;
}

const ContractContext = createContext<IContractContext | undefined>(undefined);

interface IContractProviderProps {
  children: ReactNode;
}

export const ContractProvider: React.FC<IContractProviderProps> = ({
  children,
}) => {
  const headers = useAuthHeaders();

  const [contracts, setContracts] = useState<Contract[]>([]);
  const [totalContracts, setTotalContracts] = useState(0);
  const [isFetchingAllContracts, setIsFetchingAllContracts] =
    useState<boolean>(false);

  const fetchAllContracts = useCallback(() => {
    async function fetchAllContractsAsync() {
      setIsFetchingAllContracts(true);
      const response = await fetch(URL, { headers });
      const data = await response.json();
      if (response.ok) {
        setContracts(data.results as Contract[]);
      } else {
        setContracts([]);
      }

      localStorage.setItem("@CACHED_NUMBER_OF_CONTRACTS", data.count);
      setTotalContracts(data.count);

      setIsFetchingAllContracts(false);
    }
    if (!isFetchingAllContracts) {
      fetchAllContractsAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, totalContracts]); // don't add isFetchingAllContracts

  const fetchContracts = useCallback(
    async ({ params }: { params?: URLSearchParams } = {}): Promise<{
      rows: Contract[];
      count: number;
    }> => {
      // Check if we can use the cached contracts
      if (contracts && contracts.length > 0 && params) {
        const offset = params.get("offset");
        const limit = params.get("limit");
        const paramCount = Array.from(params.keys()).length;

        if (
          offset !== null &&
          limit &&
          !isNaN(parseInt(offset)) &&
          !isNaN(parseInt(limit)) &&
          paramCount === 2
        ) {
          const offsetNum = parseInt(offset);
          const limitNum = parseInt(limit);

          // Use cached contracts if offset is within range
          if (offsetNum < contracts.length) {
            return {
              rows: contracts.slice(
                offsetNum,
                Math.min(offsetNum + limitNum, contracts.length)
              ),
              count: totalContracts,
            };
          }
        }
      }

      // If we can't use cached contracts, proceed with the API fetch
      try {
        const queryParams = params || new URLSearchParams();
        const response = await fetch(`${URL}?${queryParams.toString()}`, {
          headers,
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        return { rows: data.results, count: data.count };
      } catch (error) {
        console.error(error);
        return { rows: [], count: 0 };
      }
    },
    [contracts, headers, totalContracts]
  );

  useEffect(() => {
    fetchAllContracts();
  }, [fetchAllContracts, headers]);

  return (
    <ContractContext.Provider value={{ fetchContracts, totalContracts }}>
      {children}
    </ContractContext.Provider>
  );
};

export function useContracts(): IContractContext {
  const context = useContext(ContractContext);
  if (context === undefined) {
    throw new Error("useContracts must be used within an ContractProvider");
  }
  return context;
}
