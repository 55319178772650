import { Edge, Node } from "reactflow";
import "reactflow/dist/style.css";
import { Battery } from "../../../../__generated__/types/Battery";
import { PlatformCustomer } from "../../../../__generated__/types/PlatformCustomer";
import { PlatformProperty } from "../../../../__generated__/types/PlatformProperty";
import PlatformBatteryPopoverContent from ".././PlatformBatteryPopoverContent.react";
import {
  MAX_ROOT_SIZE,
  NODE_SIZE,
  SOURCE_BOTTOM_HANDLE_ID,
  SOURCE_LEFT_HANDLE_ID,
  SOURCE_RIGHT_HANDLE_ID,
  SOURCE_TOP_HANDLE_ID,
  TARGET_BOTTOM_HANDLE_ID,
  TARGET_LEFT_HANDLE_ID,
  TARGET_RIGHT_HANDLE_ID,
  TARGET_TOP_HANDLE_ID,
  ZOOM_THRESHOLD,
  calculateRootSize,
} from "./GridNode.react";
import PlatformPropertyPopoverContent from "./PlatformPropertyPopoverContent.react";

interface DeviceGraphResult {
  nodes: Node[];
  edges: Edge[];
}

interface RotationMap {
  [key: string]: string;
}

/*
 *  Mappings to dictate the clockwise rotation of the handles of
 *  the nodes, in order to be able to programmatically rotate the
 *  sub-graphs according to which side of the canvas they are in
 */
const rotationMap: RotationMap = {
  [SOURCE_TOP_HANDLE_ID]: SOURCE_RIGHT_HANDLE_ID,
  [SOURCE_RIGHT_HANDLE_ID]: SOURCE_BOTTOM_HANDLE_ID,
  [SOURCE_BOTTOM_HANDLE_ID]: SOURCE_LEFT_HANDLE_ID,
  [SOURCE_LEFT_HANDLE_ID]: SOURCE_TOP_HANDLE_ID,
  [TARGET_TOP_HANDLE_ID]: TARGET_RIGHT_HANDLE_ID,
  [TARGET_RIGHT_HANDLE_ID]: TARGET_BOTTOM_HANDLE_ID,
  [TARGET_BOTTOM_HANDLE_ID]: TARGET_LEFT_HANDLE_ID,
  [TARGET_LEFT_HANDLE_ID]: TARGET_TOP_HANDLE_ID,
};

// Rotates an array of node handles clockwise, according to the rotationMap, n times
function rotateArray(arr: string[][], n: number): string[][] {
  return arr.map((subArr) =>
    subArr.map((handleId) => {
      let currentId = handleId;
      for (let i = 0; i < n; i++) {
        currentId = rotationMap[currentId];
      }
      return currentId;
    })
  );
}

// Shifts an array of node coordinates clockwise, n times
function shiftPositions(
  positions: { x: number; y: number }[],
  n: number
): { x: number; y: number }[] {
  for (let i = 0; i < n; i++) {
    positions = [positions[positions.length - 1], ...positions.slice(0, -1)];
  }
  return positions;
}

// Splits the total number of nodes to two parts, with bias according to the given ration
// Ensures the second number is always even, for visual symmetry on the vertical sides
function splitNodes(num: number, ratio: number) {
  function ensureEvenSecondPart(total: number, ratio: number) {
    let secondPart = Math.round(total / (1 + ratio) / 2) * 2;
    let firstPart = total - secondPart;
    if (firstPart / secondPart < ratio) {
      secondPart -= 2;
      firstPart = total - secondPart;
    }
    return [firstPart, secondPart];
  }
  const splitValues = ensureEvenSecondPart(num, ratio);
  return splitValues;
}

export function getTooltipContent(
  battery: Battery,
  type: "battery" | "home",
  properties?: PlatformProperty[],
  customers?: PlatformCustomer[]
) {
  if (type === "battery") {
    return <PlatformBatteryPopoverContent battery={battery} />;
  } else if (type === "home") {
    if (battery.assigned_property && properties) {
      const property = properties?.find(
        (p) => p.id === battery.assigned_property
      );
      if (property && customers) {
        const customer = customers?.find((c) => c.id === property?.customer);
        return (
          <PlatformPropertyPopoverContent
            battery={battery}
            property={property}
            customer={customer}
          />
        );
      }
    }
  }
}

export function getGridGraph(
  batteries: Battery[],
  properties: PlatformProperty[],
  customers: PlatformCustomer[],
  ratio: number,
  zoomLevel: number
): DeviceGraphResult {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  if (batteries.length === 0) {
    return { nodes, edges };
  }

  const isZoomed = zoomLevel >= ZOOM_THRESHOLD;

  const totalGridPower = batteries
    .map((b) => b.grid_power ?? 0)
    .reduce((accumulator, current) => accumulator + current, 0);

  const centerX = 0;
  const centerY = 0;

  const totalNodes = batteries.length;

  const [hNodes, vNodes] = splitNodes(totalNodes, ratio);

  const spacing = 100;

  const nodeSize = NODE_SIZE + spacing;
  const rootSize = calculateRootSize(zoomLevel) + spacing;

  const nodeOffset = rootSize - nodeSize;
  const scaleOffset = Math.max(
    MAX_ROOT_SIZE * (totalNodes / 28),
    MAX_ROOT_SIZE
  );
  const hOffset = Math.max(
    nodeSize / 2,
    (Math.ceil(hNodes / 2) * nodeSize) / 2
  );
  const vOffset = Math.max(
    nodeSize / 2,
    (Math.ceil(vNodes / 2) * nodeSize) / 2
  );
  const offsetOdd = hNodes % 2 === 1 ? nodeSize / 2 : 0;

  const startX = centerX - hOffset;
  const startY = centerY - vOffset;

  nodes.push({
    id: `big-grid`,
    type: "gridNode",
    position: { x: centerX, y: centerY },
    data: {
      label: `${totalGridPower} W`,
      subLabel: "Grid",
      icon: "grid",
      color: "#2196f3",
      isRoot: true,
    },
  });

  let hCounter = 0;
  let vCounter = 0;

  batteries.forEach((battery) => {
    if (battery.id !== undefined) {
      const gridPower = battery?.grid_power ?? 0;
      const batteryPower = battery?.battery_power ?? 0;
      const solarPower = battery?.solar_power ?? 0;
      const consumerPower = battery?.consumer_power ?? 0;
      const isStationOffline = battery.station_state === "OFFLINE";
      let x, y, position;

      /*
       *  Node layout algorithm
       *  Spreads out the nodes in an as perfect rectangle as possible to
       *  to ensure visual symmetry and avoid edge overlap when the number
       *  of nodes is very large.
       *  No of nodes on left and right sides is always equal.
       *  No of nodes on top and bottom sides may differ by 1 (in case of odd nodes)
       */
      if (hCounter < hNodes) {
        if (hCounter < hNodes / 2) {
          position = "top";
          x = startX + hCounter * nodeSize + rootSize / 2;
          y = centerY - vOffset - scaleOffset;
        } else {
          position = "bottom";
          x =
            startX +
            (hCounter - Math.ceil(hNodes / 2)) * nodeSize +
            rootSize / 2 +
            offsetOdd; //Offset odd only for bottom
          y = centerY + vOffset + scaleOffset + nodeOffset;
        }
        hCounter++;
      } else {
        if (vCounter < vNodes / 2) {
          position = "left";
          x = centerX - hOffset - scaleOffset;
          y = startY + vCounter * nodeSize + rootSize / 2;
        } else {
          position = "right";
          x = centerX + hOffset + scaleOffset + nodeOffset;
          y =
            startY +
            (vCounter - Math.ceil(vNodes) / 2) * nodeSize +
            rootSize / 2;
        }
        vCounter++;
      }

      const gridNode = {
        id: `grid-${battery.id}`,
        type: "gridNode",
        position: { x, y },
        data: {
          tooltipContent: getTooltipContent(
            battery,
            isZoomed ? "home" : "battery",
            properties,
            customers
          ),
          label: `${gridPower} W`,
          subLabel: "Home",
          icon: "home",
          color: "#00A396",
          isGroup: true,
          isOffline: isStationOffline,
        },
      };

      if (!isZoomed) {
        nodes.push(gridNode);
      }

      let sourceHandle = "";
      let targetHandle = "";

      // Offset positions for the sub-graph, based on the coords of the parent
      // Set such that the inner sub-graph is symmetrical and even to the surrounding circle
      let positions = [
        { x: x + 66, y: y + 160 },
        { x: x + 160, y: y + 66 },
        { x: x + 66, y: y - 28 },
        { x: x - 28, y: y + 66 },
      ];

      // Handle positions for the nodes on the top side.
      // For the other sides, the rotating functions are used accordingly
      let sourcePositions = [
        // Inner grid
        [SOURCE_TOP_HANDLE_ID, SOURCE_TOP_HANDLE_ID],
        //Solar
        [
          SOURCE_BOTTOM_HANDLE_ID,
          SOURCE_BOTTOM_HANDLE_ID,
          SOURCE_BOTTOM_HANDLE_ID,
        ],
        //Home
        [SOURCE_RIGHT_HANDLE_ID],
        //Battery
        [SOURCE_LEFT_HANDLE_ID],
      ];
      let targetPositions = [
        // Inner grid
        [TARGET_TOP_HANDLE_ID, TARGET_TOP_HANDLE_ID],
        //Solar
        [],
        //Home
        [
          TARGET_RIGHT_HANDLE_ID,
          TARGET_RIGHT_HANDLE_ID,
          TARGET_RIGHT_HANDLE_ID,
        ],
        //Battery
        [TARGET_LEFT_HANDLE_ID, TARGET_LEFT_HANDLE_ID],
      ];

      switch (position) {
        case "top":
          sourceHandle =
            gridPower > 0 ? SOURCE_BOTTOM_HANDLE_ID : SOURCE_TOP_HANDLE_ID;
          targetHandle =
            gridPower > 0 ? TARGET_TOP_HANDLE_ID : TARGET_BOTTOM_HANDLE_ID;
          break;
        case "bottom":
          sourceHandle =
            gridPower > 0 ? SOURCE_TOP_HANDLE_ID : SOURCE_BOTTOM_HANDLE_ID;
          targetHandle =
            gridPower > 0 ? TARGET_BOTTOM_HANDLE_ID : TARGET_TOP_HANDLE_ID;
          positions = shiftPositions(positions, 2);
          sourcePositions = rotateArray(sourcePositions, 2);
          targetPositions = rotateArray(targetPositions, 2);
          break;
        case "left":
          sourceHandle =
            gridPower > 0 ? SOURCE_RIGHT_HANDLE_ID : SOURCE_LEFT_HANDLE_ID;
          targetHandle =
            gridPower > 0 ? TARGET_LEFT_HANDLE_ID : TARGET_RIGHT_HANDLE_ID;
          positions = shiftPositions(positions, 3);
          sourcePositions = rotateArray(sourcePositions, 3);
          targetPositions = rotateArray(targetPositions, 3);
          break;
        case "right":
          sourceHandle =
            gridPower > 0 ? SOURCE_LEFT_HANDLE_ID : SOURCE_RIGHT_HANDLE_ID;
          targetHandle =
            gridPower > 0 ? TARGET_RIGHT_HANDLE_ID : TARGET_LEFT_HANDLE_ID;
          positions = shiftPositions(positions, 1);
          sourcePositions = rotateArray(sourcePositions, 1);
          targetPositions = rotateArray(targetPositions, 1);
          break;
      }

      const subGridNodeId = `grid-inner-${battery.id}`;

      // Sets the direction of the grid edge according to the gridPower value
      if (gridPower > 0) {
        edges.push({
          id: `e-grid-${battery.id}-big-grid`,
          source: isZoomed ? subGridNodeId : gridNode.id,
          target: `big-grid`,
          type: "gridEdge",
          data: {
            color1: "#184A90",
            color2: "#184A90",
          },
          sourceHandle: sourceHandle,
          targetHandle: targetHandle,
        });
      } else {
        edges.push({
          id: `e-big-grid-grid-${battery.id}`,
          source: `big-grid`,
          target: isZoomed ? subGridNodeId : gridNode.id,
          type: "gridEdge",
          data: {
            color1: "#0C5174",
            color2: "#0C5174",
            isDisabled: gridPower === 0,
          },
          sourceHandle: sourceHandle,
          targetHandle: targetHandle,
        });
      }

      if (!isZoomed) {
        return;
      }

      nodes.push({
        id: subGridNodeId,
        type: "gridNode",
        position: positions[0],
        data: {
          label: `${gridPower} W`,
          subLabel: "Grid",
          icon: "grid",
          color: "#2196f3",
          isChild: true,
          isOffline: isStationOffline,
        },
      });

      nodes.push({
        id: `battery-${battery.id}`,
        type: "gridNode",
        position: positions[1],
        data: {
          tooltipContent: getTooltipContent(battery, "battery"),
          label: `${battery.battery_power ?? 0} W`,
          subLabel: "Battery",
          icon: "battery",
          color: "#9c27b0",
          battery: battery,
          isChild: true,
          isOffline: isStationOffline,
        },
      });

      nodes.push({
        id: `solar-${battery.id}`,
        type: "gridNode",
        position: positions[2],
        data: {
          label: `${battery.solar_power ?? 0} W`,
          subLabel: "Solar",
          icon: "solar",
          color: "#ffeb3b",
          isChild: true,
          isOffline: isStationOffline,
        },
      });

      nodes.push({
        id: `home-${battery.id}`,
        type: "gridNode",
        position: positions[3],
        data: {
          tooltipContent: getTooltipContent(
            battery,
            "home",
            properties,
            customers
          ),
          label: `${battery.consumer_power} W`,
          subLabel: "Home",
          icon: "home",
          color: "#00A396",
          isChild: true,
          isOffline: isStationOffline,
        },
      });

      if (gridPower < 0 && batteryPower < 0) {
        edges.push({
          id: `e-grid-inner-${battery.id}-battery-${battery.id}`,
          source: `grid-inner-${battery.id}`,
          target: `battery-${battery.id}`,
          type: "gridEdge",
          data: {
            color1: "#2196f3",
            color2: "#2196f3",
            isChild: true,
          },
          sourceHandle: sourcePositions[0][1],
          targetHandle: targetPositions[3][0],
        });
      } else if (gridPower > 0 && batteryPower > 0) {
        edges.push({
          id: `e-battery-${battery.id}-grid-inner-${battery.id}`,
          source: `battery-${battery.id}`,
          target: `grid-inner-${battery.id}`,
          type: "gridEdge",
          data: {
            color1: "#9c27b0",
            color2: "#9c27b0",
            isChild: true,
          },
          sourceHandle: sourcePositions[3][0],
          targetHandle: targetPositions[0][1],
        });
      } else {
        edges.push({
          id: `e-battery-${battery.id}-grid-inner-${battery.id}`,
          source: `battery-${battery.id}`,
          target: `grid-inner-${battery.id}`,
          type: "gridEdge",
          data: {
            color1: "#9c27b0",
            color2: "#9c27b0",
            isChild: true,
            isDisabled: true,
          },
          sourceHandle: sourcePositions[3][0],
          targetHandle: targetPositions[0][1],
        });
      }

      edges.push({
        id: `e-grid-inner-${battery.id}-home-${battery.id}`,
        source: `grid-inner-${battery.id}`,
        target: `home-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#2196f3",
          color2: "#2196f3",
          isChild: true,
          isDisabled: consumerPower <= 0 || gridPower >= 0,
        },
        sourceHandle: sourcePositions[0][0],
        targetHandle: targetPositions[2][0],
      });

      edges.push({
        id: `e-solar-${battery.id}-home-${battery.id}`,
        source: `solar-${battery.id}`,
        target: `home-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#ffeb3b",
          color2: "#ffeb3b",
          isChild: true,
          isDisabled: solarPower <= 0 || consumerPower <= 0,
        },
        sourceHandle: sourcePositions[1][0],
        targetHandle: targetPositions[2][2],
      });

      edges.push({
        id: `e-solar-${battery.id}-battery-${battery.id}`,
        source: `solar-${battery.id}`,
        target: `battery-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#ffeb3b",
          color2: "#ffeb3b",
          isChild: true,
          isDisabled: solarPower <= 0 || batteryPower >= 0,
        },
        sourceHandle: sourcePositions[1][2],
        targetHandle: targetPositions[3][1],
      });

      edges.push({
        id: `e-solar-${battery.id}-grid-inner-${battery.id}`,
        source: `solar-${battery.id}`,
        target: `grid-inner-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#ffeb3b",
          color2: "#ffeb3b",
          isChild: true,
          isDisabled: solarPower <= 0 || gridPower <= 0,
        },
        sourceHandle: sourcePositions[1][1],
        targetHandle: targetPositions[0][0],
      });

      edges.push({
        id: `e-battery-${battery.id}-home-${battery.id}`,
        source: `battery-${battery.id}`,
        target: `home-${battery.id}`,
        type: "gridEdge",
        data: {
          color1: "#9c27b0",
          color2: "#9c27b0",
          isChild: true,
          isDisabled: batteryPower <= 0 || consumerPower <= 0,
        },
        sourceHandle: sourcePositions[3][0],
        targetHandle: targetPositions[2][1],
      });
    }
  });

  edges.sort((a, b) =>
    a.data.isDisabled === b.data.isDisabled ? 0 : !a.data.isDisabled ? 1 : -1
  );

  return { nodes: nodes, edges: edges };
}
