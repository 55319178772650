import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Ec1DataGrid from "../../../ui/components/Ec1DataGrid.react";
import PlatformSiteCustomerClickableLabel from "../customers/PlatformSiteCustomerClickableLabel.react";
import { useEnterpriseLabels } from "../enterpriseLabels/useEnterpriseLabels";
import { Contract, useContracts } from "./contracts";

function formatDate(date: Date | undefined): string {
  if (!date) {
    return "";
  }

  const d = new Date(date);
  let day = d.getDate().toString();
  let month = (d.getMonth() + 1).toString();
  const year = d.getFullYear();

  day = day.length < 2 ? "0" + day : day;
  month = month.length < 2 ? "0" + month : month;

  return `${day}/${month}/${year}`;
}

export default function PlatformContractListView() {
  const { fetchContracts } = useContracts();
  const navigate = useNavigate();
  const { selectedLabelObj } = useEnterpriseLabels();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "contract_number",
        headerName:
          selectedLabelObj?.contract_uid_field_name ?? "Contract Number",
        flex: 1,
      },
      { field: "cups", headerName: "CUPS", flex: 2 },
      {
        field: "start_date",
        headerName: "Start Date",
        flex: 1,
        renderCell: (params) => formatDate(params.row.start_date),
      },
      {
        field: "end_date",
        headerName: "End Date",
        flex: 1,
        renderCell: (params) => formatDate(params.row.end_date),
      },
      {
        field: "contracted_power_kw",
        headerName: "Contracted Power (kW)",
        flex: 1,
      },
      { field: "tariff", headerName: "Tariff", flex: 1 },
      {
        field: "customer",
        headerName: "Customer",
        flex: 2,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
          <PlatformSiteCustomerClickableLabel customerId={params.value} />
        ),
      },
    ],
    [selectedLabelObj]
  );

  const handleActionClick = useCallback(
    (contractId: string) => {
      navigate(`/contracts/${contractId}`);
    },
    [navigate]
  );

  const handleContextMenuItemClick = useCallback(
    (action: string, id: string | null) => {
      if (action === "view" && id) {
        handleActionClick(id);
      }
    },
    [handleActionClick]
  );

  const contextMenuOptions = useMemo(
    () => [
      {
        label: "View Contract",
        icon: <VisibilityIcon />,
        action: (id: string | null) => handleContextMenuItemClick("view", id),
      },
    ],
    [handleContextMenuItemClick]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        marginTop: "36px",
      }}
    >
      <Ec1DataGrid<Contract>
        id="gridContracts"
        get={fetchContracts}
        columns={columns}
        contextMenuOptions={contextMenuOptions}
        getRowId={(row: Contract) => row.id}
        onRowClick={(contract, event) => {
          handleActionClick(String(contract.id));
        }}
      />
    </div>
  );
}
