import { Button, ButtonGroup } from "@blueprintjs/core";
import { useEffect, useMemo, useRef } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Node,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
} from "reactflow";
import NormalEdgeWithTypeAssertion from "../platform/studio/CustomEdge.react";
import DigitalTwinNode, {
  SOURCE_BOTTOM_HANDLE_ID,
  SOURCE_TOP_HANDLE_ID,
  TARGET_BOTTOM_HANDLE_ID,
  TARGET_TOP_HANDLE_ID,
} from "../platform/studio/graphNodes/DigitalTwinNode";
import PlatformDigitalTwinExperimentConsole from "./PlatformDigitalTwinExperimentConsole.react";
import PlatformDigitalTwinExperimentSidebar from "./PlatformDigitalTwinExperimentSidebar.react";
import PlatformDigitalTwinExperimentToolbar from "./PlatformDigitalTwinExperimentToolbar.react";
import {
  getDigitalTwinEdges,
  getDigitalTwinNodes,
  getEVs,
} from "./digitalTwinDemoData";

const edgeTypes = {
  normal: NormalEdgeWithTypeAssertion, // Add this line
};

const VPP_EVS = getEVs()
  .filter((ev) => ev.isCharging === true)
  .slice(0, 30);

export default function PlatformDigitalTwinPage() {
  const [nodes, setNodes, onNodesChange] = useNodesState(getDigitalTwinNodes());
  const [edges, setEdges, onEdgesChange] = useEdgesState(getDigitalTwinEdges());
  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);

  const [repositionedNodes, repositionedEdges] = useMemo(() => {
    const initialNodes = getDigitalTwinNodes();
    const center = { x: 500, y: 500 }; // Center around which to draw arcs

    // vppNodes calculation (Left)
    let vppNodes = initialNodes.filter((node) =>
      VPP_EVS.map((ev) => `ev-${ev.id}`).includes(node.id)
    );

    const vppIDS = new Set(VPP_EVS.map((ev) => `ev-${ev.id}`));

    let vppIdx = 0;
    let nonVppIdx = 0;

    const radiusVpp = 1000; // Radius for vppNodes
    const angleStepVpp = ((5 / 6) * Math.PI) / (vppNodes.length - 1 || 1);
    const radiusNonVpp = 1600; // Radius for nonVppNodes
    const angleStepNonVpp =
      ((5 / 6) * Math.PI) / (initialNodes.length - vppNodes.length - 1 || 1);

    const newNodes: Node[] = [];

    initialNodes.forEach((node) => {
      if (vppIDS.has(node.id)) {
        const angle = angleStepVpp * vppIdx + Math.PI / 12; // Starting from left
        node.position.x = center.x + radiusVpp * Math.cos(angle);
        node.position.y = center.y + radiusVpp * Math.sin(angle);
        vppIdx += 1;
        newNodes.push(node);
      } else if (node.id.startsWith("ev-")) {
        const angle = -(angleStepNonVpp * nonVppIdx) - Math.PI / 12; // Starting from right, moving counter-clockwise
        node.position.x = center.x + radiusNonVpp * Math.cos(angle);
        node.position.y = center.y + radiusNonVpp * Math.sin(angle);
        nonVppIdx += 1;
        newNodes.push(node);
      } else {
        newNodes.push(node);
      }
    });

    const vppIDs = vppNodes.map((n) => n.id);

    const newEdges = getDigitalTwinEdges().map((edge) => {
      let sourceHandle = SOURCE_TOP_HANDLE_ID;
      let targetHandle = TARGET_BOTTOM_HANDLE_ID;

      if (vppIDs.includes(edge.target)) {
        sourceHandle = SOURCE_BOTTOM_HANDLE_ID;
        targetHandle = TARGET_TOP_HANDLE_ID;
      }
      return {
        ...edge,
        sourceHandle,
        targetHandle,
      };
    });

    return [newNodes, newEdges];
  }, []);

  const nodeTypes = useMemo(
    () => ({
      powerNode: DigitalTwinNode,
    }),
    []
  );

  useEffect(() => {
    let animationFrameId: number;

    const animatePositions = (
      startPositions: Node[],
      endPositions: Node[],
      duration: number
    ) => {
      const startTime = performance.now();

      const animate = (currentTime: number) => {
        const elapsedTime = currentTime - startTime;
        const fraction = Math.min(elapsedTime / duration, 1); // Ensure fraction is capped at 1

        const updatedNodes = startPositions.map((node, index) => {
          const startPosition = node.position;
          const endPosition = endPositions[index].position;

          // Calculate current position based on time fraction
          const x =
            startPosition.x + (endPosition.x - startPosition.x) * fraction;
          const y =
            startPosition.y + (endPosition.y - startPosition.y) * fraction;

          return {
            ...node,
            position: { x, y },
          };
        });

        setNodes(updatedNodes);

        if (fraction < 1) {
          animationFrameId = requestAnimationFrame(animate);
        }
      };

      animationFrameId = requestAnimationFrame(animate);
    };

    const timer = setTimeout(() => {
      setNodes((currentNodes) => {
        const newNodes = repositionedNodes;

        animatePositions(currentNodes, newNodes, 1000); // Animate over 1000ms

        setEdges(repositionedEdges);

        return newNodes; // Update positions through animation
      });
    }, 5500); // Adjust delay as needed

    return () => {
      clearTimeout(timer);
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [repositionedEdges, repositionedNodes, setEdges, setNodes]);

  useEffect(() => {
    // Set a timeout to delay the execution of the code block
    const timeoutId = setTimeout(() => {
      const vppids = VPP_EVS.slice(0, Math.round(VPP_EVS.length / 4)).map(
        (ev) => `ev-${ev.id}`
      );
      setEdges((prevEdges) => {
        return prevEdges.map((edge) => {
          if (vppids.includes(edge.target)) {
            return { ...edge, type: "default" };
          }
          return { ...edge };
        });
      });
    }, 14000); // 8000 milliseconds equals 8 seconds

    // Cleanup function to clear the timeout if the component unmounts before the timeout is completed
    return () => clearTimeout(timeoutId);
  }, [setEdges]);

  useEffect(() => {
    // Set a timeout to delay the execution of the code block
    const timeoutId = setTimeout(() => {
      const vppids = VPP_EVS.slice(0, Math.round(VPP_EVS.length / 2)).map(
        (ev) => `ev-${ev.id}`
      );
      setEdges((prevEdges) => {
        return prevEdges.map((edge) => {
          if (vppids.includes(edge.target)) {
            return { ...edge, type: "default" };
          }
          return { ...edge };
        });
      });
    }, 18000); // 8000 milliseconds equals 8 seconds

    // Cleanup function to clear the timeout if the component unmounts before the timeout is completed
    return () => clearTimeout(timeoutId);
  }, [setEdges]);

  useEffect(() => {
    // Set a timeout to delay the execution of the code block
    const timeoutId = setTimeout(() => {
      const vppids = VPP_EVS.slice(0, Math.round((VPP_EVS.length / 6) * 5)).map(
        (ev) => `ev-${ev.id}`
      );
      setEdges((prevEdges) => {
        return prevEdges.map((edge) => {
          if (vppids.includes(edge.target)) {
            return { ...edge, type: "default" };
          }
          return { ...edge };
        });
      });
    }, 20000); // 8000 milliseconds equals 8 seconds

    // Cleanup function to clear the timeout if the component unmounts before the timeout is completed
    return () => clearTimeout(timeoutId);
  }, [setEdges]);

  useEffect(() => {
    // Set a timeout to delay the execution of the code block
    const timeoutId = setTimeout(() => {
      const vppids = VPP_EVS.map((ev) => `ev-${ev.id}`);
      setEdges((prevEdges) => {
        return prevEdges.map((edge) => {
          if (vppids.includes(edge.target)) {
            return { ...edge, type: "default" };
          }
          return { ...edge };
        });
      });
    }, 22000); // 8000 milliseconds equals 8 seconds

    // Cleanup function to clear the timeout if the component unmounts before the timeout is completed
    return () => clearTimeout(timeoutId);
  }, [setEdges]);

  useEffect(() => {
    if (reactFlowInstance.current) {
      reactFlowInstance.current.fitView({
        padding: 0.3,
        includeHiddenNodes: true,
        duration: 1000,
        nodes: nodes,
      });
    }
  }, [nodes]);
  return (
    <div
      style={{
        backgroundColor: "#130f24",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <div style={{ flex: 1, position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "24px",
            left: "32px",
          }}
        >
          <PlatformDigitalTwinExperimentToolbar />
        </div>
        <div
          style={{
            position: "absolute",
            top: "24px",
            right: "32px",
          }}
        >
          <ButtonGroup minimal>
            <Button icon="globe" />
            <Button icon="layout-auto" active={true} />
          </ButtonGroup>
        </div>
        <div
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <div style={{ flex: 1 }}>
            <ReactFlow
              minZoom={0.1}
              nodeTypes={nodeTypes}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              proOptions={{ hideAttribution: true }}
              edgeTypes={edgeTypes}
              fitView
              onInit={(instance) => {
                console.log("React Flow loaded:", instance);
                reactFlowInstance.current = instance;
              }}
            >
              <Background
                variant={BackgroundVariant.Dots}
                size={1}
                color={"#383E47"}
              />
            </ReactFlow>
          </div>
          <PlatformDigitalTwinExperimentConsole />
        </div>
      </div>
      <PlatformDigitalTwinExperimentSidebar />
    </div>
  );
}
