import { Button, H3, HTMLTable } from "@blueprintjs/core";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Invoice, useInvoices } from "../invoices/invoices";

interface CustomerInvoicesTableProps {
  customerId: string;
}
export default function CustomerInvoicesTable({
  customerId,
}: CustomerInvoicesTableProps) {
  const { fetchInvoices } = useInvoices();

  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    async function fetchInvoicesAsync() {
      const queryParams = new URLSearchParams();
      queryParams.append("customer", customerId);

      const data = await fetchInvoices({ params: queryParams });
      setInvoices(
        data.rows.sort(
          (a, b) =>
            new Date(b.end_date).getTime() - new Date(a.end_date).getTime()
        )
      );
    }
    fetchInvoicesAsync();
  }, [customerId, fetchInvoices]);

  const paginationButtons = useMemo(() => {
    if (invoices.length < 10) {
      return null;
    } else {
      const number_of_pages = Math.ceil(invoices.length / 10);

      return Array.from({ length: number_of_pages }, (_, i) => i + 1).map(
        (pageNumber) => (
          <Button
            key={pageNumber}
            active={page === pageNumber}
            style={{ marginRight: "4px" }}
            onClick={() => setPage(pageNumber)}
          >
            {pageNumber}
          </Button>
        )
      );
    }
  }, [invoices.length, page]);

  const navigate = useNavigate();
  return (
    <div style={{ fontFamily: "Barlow" }}>
      <H3>Invoices</H3>
      <HTMLTable style={{ width: "100%" }} className="bp5-interactive">
        <thead>
          <tr>
            <th>CUPS</th>
            <th>Invoice Date</th>
            <th>Total amount</th>
          </tr>
        </thead>
        <tbody>
          {invoices.slice((page - 1) * 10, page * 10).map((invoice) => (
            <tr
              style={{
                cursor: "pointer",
              }}
              key={invoice.invoice_number}
              onClick={() => {
                navigate(`/invoices/${invoice.id}`);
              }}
            >
              <td>{invoice.cups}</td>
              <td>{invoice.end_date}</td>
              <td>{`€${invoice.total_amount}`}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      <div style={{ marginTop: "8px" }}>{paginationButtons}</div>
    </div>
  );
}
