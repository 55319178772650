import { Popover } from "@blueprintjs/core";
import {
  Bolt,
  MoreHoriz,
  OfflineBoltOutlined,
  Power,
} from "@mui/icons-material";
import { Chip } from "@mui/material";

interface BatteryStatusChipProps {
  batteryStatus: string;
}

function getStatusColor(battery_status: string, dark = false) {
  if (battery_status === "charging") {
    return dark ? "#238C2C" : "#53c958";
  }
  if (battery_status === "discharging") {
    return dark ? "#B83211" : "#ff871f";
  }
  if (battery_status === "offline") {
    return "#D33D17";
  }
  return dark ? "#252A31" : "#e0e0e0";
}

function getStatusText(battery_status: string) {
  if (battery_status === "charging") {
    return "Charging";
  }
  if (battery_status === "discharging") {
    return "Discharging";
  }
  if (battery_status === "offline") {
    return "Offline";
  }

  return "Idle";
}

function getBatteryStatusTooltip(battery_status: string) {
  switch (battery_status) {
    case "charging":
      return "The battery is currently charging.";
    case "discharging":
      return "The battery is currently discharging.";
    case "offline":
      return "The battery is currently offline and not operating.";
    case "idle":
      return "The battery is online but not currently charging or discharging.";
    default:
      return "Status is unknown.";
  }
}

function getStatusTextColor(battery_status: string, dark = false) {
  return getStatusColor(battery_status, dark);
}

function getStatusIcon(battery_status: string, dark = false) {
  if (battery_status === "charging") {
    return (
      <Bolt
        style={{
          color: getStatusColor(battery_status, dark),
          fontSize: 20,
          marginRight: -12,
          marginTop: 0,
        }}
      />
    );
  }
  if (battery_status === "discharging") {
    return (
      <Power
        style={{
          color: getStatusColor(battery_status, dark),
          fontSize: 20,
          marginRight: -12,
          marginTop: 0,
        }}
      />
    );
  }

  if (battery_status === "offline") {
    return (
      <OfflineBoltOutlined
        style={{
          color: getStatusColor(battery_status, dark),
          fontSize: 18,
          marginRight: -8,
          marginTop: 0,
        }}
      />
    );
  }
  return (
    <MoreHoriz
      style={{
        color: getStatusColor(battery_status, dark),
        fontSize: 18,
        marginRight: -8,
        marginTop: 0,
      }}
    />
  );
}

export default function BatteryStatusChip({
  batteryStatus,
}: BatteryStatusChipProps) {
  return (
    <Popover
      interactionKind="hover-target"
      position="top"
      content={
        <div
          style={{
            fontFamily: "Barlow",
            padding: 10,
            maxWidth: 250,
            textAlign: "justify",
          }}
        >
          {getBatteryStatusTooltip(batteryStatus)}
        </div>
      }
    >
      <Chip
        label={getStatusText(batteryStatus)}
        style={{
          height: 24,
          border: "solid 1px",
          borderColor: getStatusColor(batteryStatus),
          backgroundColor: "transparent",
          color: getStatusTextColor(batteryStatus),
          fontWeight: "bold",
          fontSize: 12,
        }}
        icon={getStatusIcon(batteryStatus)}
      />
    </Popover>
  );
}
