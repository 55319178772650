import { IAuthenticationTokens } from "./authenticationJWTUtils";

const LOCAL_STORAGE_TOKEN_KEY = "@@AUTH_TOKENS";

export function getAuthenticationTokensFromLocalStorage() {
  const storedTokens = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  if (storedTokens) {
    try {
      const parsedTokens = JSON.parse(storedTokens);
      if (Object.keys(parsedTokens).length > 0) {
        return parsedTokens as IAuthenticationTokens;
      }
    } catch (error) {
      console.error("Failed to parse tokens:", error);
    }
  }
  return null;
}

export function setAuthenticationTokensInLocalStorage(
  tokens: IAuthenticationTokens
) {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, JSON.stringify(tokens));
}

export function removeAuthenticationTokensFromLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  localStorage.removeItem("sessionExpiryData");
}
