import { Icon, Intent } from "@blueprintjs/core";
import { DateInput3 } from "@blueprintjs/datetime2";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import { DateTime } from "luxon";
import { useState } from "react";

interface PlatformAssignTariffDatefieldProps {
  value: string | null;
  onChange: (newValue: string) => void;
  minDate?: string;
  maxDate?: string;
  allowEmpty?: boolean;
}

export default function PlatformAssignTariffDatefield({
  value,
  onChange,
  minDate = "2010-01-01", // Default minimum date
  maxDate = "2030-12-31", // Default maximum date
  allowEmpty = false,
}: PlatformAssignTariffDatefieldProps) {
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (date: string | null) => {
    if (date) {
      if (validateDate(date)) {
        onChange(date);
        setIsValid(true);
      } else {
        onChange(date);
        setIsValid(false);
      }
    } else {
      if (allowEmpty) {
        onChange("");
        setIsValid(true);
      } else {
        onChange("");
        setIsValid(false);
      }
    }
  };

  const validateDate = (date: string): boolean => {
    const regex = /^\d{4}-\d{2}-\d{2}$/; // Basic YYYY-MM-DD format check
    if (!date.match(regex)) {
      return false;
    }

    const inputDate = new Date(date);
    const min = new Date(minDate);
    const max = new Date(maxDate);

    return inputDate >= min && inputDate <= max;
  };

  const initialMonth = value
    ? undefined
    : minDate === "2010-01-01" && maxDate === "2030-12-31"
    ? DateTime.now().startOf("month").toJSDate()
    : minDate === "2010-01-01"
    ? DateTime.fromISO(maxDate).startOf("month").toJSDate()
    : DateTime.fromISO(minDate).startOf("month").toJSDate();

  return (
    <div style={{ marginTop: 6, marginBottom: 4 }}>
      <DateInput3
        inputProps={{
          leftIcon: (
            <Icon
              icon="calendar"
              size={14}
              style={{ color: "#abb3bf", marginTop: 6 }}
            />
          ),
          style: { marginTop: -2 },
          intent: isValid ? Intent.NONE : Intent.DANGER,
        }}
        popoverProps={{ placement: "bottom" }}
        dayPickerProps={{ style: { fontFamily: "Barlow" } }}
        value={value ?? undefined}
        onChange={handleInputChange}
        onError={() => {
          onChange("");
          setIsValid(allowEmpty);
        }}
        placeholder="YYYY-MM-DD"
        minDate={new Date(minDate)}
        maxDate={new Date(maxDate)}
        highlightCurrentDay
        initialMonth={initialMonth}
        canClearSelection={allowEmpty}
      />
    </div>
  );
}
