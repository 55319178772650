import { useEffect, useRef, useState } from "react";

import {
  Button,
  ButtonGroup,
  Divider,
  EditableText,
  H3,
  Icon,
} from "@blueprintjs/core";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
} from "lexical";
import { createUseStyles } from "react-jss";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Campaign } from "@mui/icons-material";
import { useComposer } from "./composer";
import { AssistantDatasetNode } from "./nodes/AssistantDatasetNode";
import AssistantDatasetPlugin, {
  ASSISTANT_DATASET_COMMAND,
} from "./plugins/AssistantDatasetPlugin";

const useStyles = createUseStyles({
  errorStyle: { color: "red", fontSize: "12px" },
  textBold: {
    fontWeight: "bold",
  },
  textItalic: {
    fontStyle: "italic",
  },
  textUnderline: {
    textDecoration: "underline",
  },
});

function MyCustomAutoFocusPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.focus();
  }, [editor]);

  return null;
}

function onError(error: Error): void {
  console.error(error);
}

interface Document {
  id: string;
  title: string;
  content: any;
}

export default function PlatformDocumentComposer(): JSX.Element {
  const { documentID: paramDocumentID } = useParams();

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const datasetUUID = queryParams.get("create_with_dataset");

  const [documentID] = useState<null | string>(paramDocumentID ?? null);

  const {
    document,
    createDocument,
    createDocumentWithUUID,
    saveDocument,
    fetchDocument,
    isCreatingDocument,
    isSavingDocument,
    isFetchingDocument,
  } = useComposer();

  const [title, setTitle] = useState<string>("----");
  const [draftDocument, setDraftDocument] = useState<Document | null>(null);

  const editorRef = useRef(null);

  const classes = useStyles();

  const initialConfig: InitialConfigType = {
    namespace: "MyLexicalEditor",
    nodes: [AssistantDatasetNode],
    theme: {
      text: {
        bold: classes.textBold,
        italic: classes.textItalic,
        underline: classes.textUnderline,
      },
    },
    onError,
  };

  useEffect(() => {
    if (title !== draftDocument?.title) {
      setDraftDocument((prevDocument) => {
        if (prevDocument !== null) {
          prevDocument.title = title;
          return { ...prevDocument, id: document?.id ?? "" };
        }

        return prevDocument;
      });
    }
  }, [title, draftDocument?.title, document?.id]);

  useEffect(() => {
    if (editorRef.current != null && document?.content != null) {
      const e: LexicalEditor = editorRef.current;
      console.log(document?.content);
      e.setEditorState(e.parseEditorState(document?.content));
    }
    if (
      editorRef.current != null &&
      document?.id != null &&
      datasetUUID !== null &&
      document?.content == null
    ) {
      const e: LexicalEditor = editorRef.current;

      e.dispatchCommand(ASSISTANT_DATASET_COMMAND, datasetUUID);
    }

    if (document?.title != null) {
      setTitle(document.title);
    }
  }, [document, datasetUUID]);

  const toggleBold = () => {
    if (editorRef.current == null) {
      return;
    }

    const editor: LexicalEditor = editorRef.current;

    if (editor == null) {
      return;
    }

    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
      }
    });
  };

  useEffect(() => {
    if (document?.id) {
      navigate(`/composer/${document.id}`);
    }
  }, [document?.id, navigate]);

  useEffect(() => {
    if (documentID === null && document === null && !isCreatingDocument) {
      if (datasetUUID === null) {
        createDocument();
      } else {
        createDocumentWithUUID(datasetUUID);
      }
    } else if (
      documentID !== null &&
      document === null &&
      !isFetchingDocument
    ) {
      fetchDocument(documentID);
    }
  }, [
    document,
    documentID,
    createDocument,
    createDocumentWithUUID,
    datasetUUID,
    isCreatingDocument,
    fetchDocument,
    isFetchingDocument,
  ]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        backgroundColor: "#404854",

        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/*<div>Document {JSON.stringify(document)}</div>
      <div style={{ padding: "24px", width: "800px", margin: "64px" }}>
        Draft Document {JSON.stringify(draftDocument)}
    </div>*/}
      <div
        style={{
          width: "100%",
          fontFamily: "Verdana",
          color: "#F6F7F9",
          outline: "none",
          minHeight: "400px",
          padding: "24px",
          paddingLeft: "164px",
          paddingRight: "164px",
        }}
      >
        <div style={{ marginBottom: "24px" }}>
          <H3>
            <EditableText value={title} onChange={setTitle} />
          </H3>

          <div
            style={{
              marginTop: "12px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "-8px",
            }}
          >
            <ButtonGroup minimal alignText="left">
              <Button text="File" />
              <Button text="Edit" />
              <Button text="Help" />
            </ButtonGroup>
          </div>
          <div
            style={{
              color: "black",
              backgroundColor: "#5F6B7C",
              borderRadius: "12px",
              marginTop: "8px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ButtonGroup large minimal>
              <Button
                icon={
                  isSavingDocument ? (
                    <Icon icon="add" style={{ color: "#F6F7F9" }} />
                  ) : (
                    <Icon icon="floppy-disk" style={{ color: "#F6F7F9" }} />
                  )
                }
                onClick={() => {
                  if (draftDocument !== null) {
                    saveDocument(draftDocument.id, draftDocument);
                  }
                }}
              />
              <Button icon="print" />
              <Divider />
              <Button icon="undo" />
              <Button icon="redo" />
              <Divider />
              <Button
                onClick={toggleBold}
                icon={<Icon icon="bold" style={{ color: "#F6F7F9" }} />}
              />
              <Button icon="italic" />
              <Button icon="underline" />
              <Divider />
              <Button icon="align-left" />
              <Button icon="align-center" />
              <Button icon="align-right" />
              <Button icon="align-justify" />
              <Button
                icon={
                  <Campaign
                    style={{ fontSize: 19, color: "white", margin: "-4px" }}
                  />
                }
              />
            </ButtonGroup>
          </div>
        </div>
        {/*<div>
          <FormGroup label="Subject:">
            <InputGroup />
          </FormGroup>
              </div>*/}

        <LexicalComposer initialConfig={initialConfig}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                style={{
                  backgroundColor: "none",
                  outline: "none",
                  minHeight: "400px",
                }}
              />
            }
            placeholder={<div />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin
            onChange={(editorState) => {
              setDraftDocument((prevDocument) => {
                if (prevDocument !== null) {
                  prevDocument["content"] = editorState.toJSON();
                  return { ...prevDocument, id: document?.id ?? "" };
                }
                return {
                  id: document?.id ?? "",
                  title: document?.title ?? "",
                  content: editorState.toJSON(),
                };
              });
            }}
          />
          <MyCustomAutoFocusPlugin />
          <AssistantDatasetPlugin />
          <EditorRefPlugin editorRef={editorRef} />
          <TabIndentationPlugin />
        </LexicalComposer>
      </div>
    </div>
  );
}
