import {
  Button,
  ButtonGroup,
  Divider,
  IconSize,
  Spinner,
} from "@blueprintjs/core";

interface PlatformBuilderToolbarProps {
  hasRun?: boolean;
  handleRun?: () => void;
  isRunning?: boolean;
}

const SPINNER = <Spinner size={IconSize.STANDARD} />;

export default function PlatformBuilderToolbar({
  hasRun = true,
  handleRun,
  isRunning = false,
}: PlatformBuilderToolbarProps) {
  return (
    <div style={{ display: "flex", margin: "4px 12px" }}>
      <ButtonGroup minimal>
        <Button icon="floppy-disk" />
      </ButtonGroup>
      <Divider />
      <ButtonGroup minimal>
        <Button icon="undo" />
        <Button icon="redo" />
      </ButtonGroup>
      {hasRun ? (
        <>
          <Divider />
          <ButtonGroup minimal>
            <Button icon={isRunning ? SPINNER : "play"} onClick={handleRun} />
            <Button icon="stop" />
            <Button icon="bug" />
          </ButtonGroup>
        </>
      ) : null}
    </div>
  );
}
